import React, { PropsWithChildren } from 'react'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { PostAssetRequest } from '@/types/request'
import { Asset, AssetFilter, LookupRequestType, Pagination } from '@/types/assets'
import { DateTime } from 'luxon'
import { appendToFormData } from '@/utils/formData'
import { PersonalFormValues, CompanyFormValues } from '@/types/form-data'
import { Order } from '@/pages/history/AssetsTable'

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

interface ApiContextValues {
  api: AxiosInstance
}
const ApiContext = React.createContext<ApiContextValues>({ api: {} as AxiosInstance })

export const ApiContextProvider = ({ children }: PropsWithChildren) => {
  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>
}

export const useApi = (): ApiContextValues => React.useContext(ApiContext)

const defaultHeaders = [
  'fullName',
  'address',
  'city',
  'state',
  'zipCode',
  'company',
  'industry',
  'location'
]

const buildCsv = (
  rows: Array<PersonalFormValues | CompanyFormValues>,
  headers = defaultHeaders
) => {
  const csvFileData = [
    headers.join(','),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...rows.map((row) => headers.map((header) => row[header]).join(','))
  ]

  const csvContent = csvFileData.join('\n')

  return new File([csvContent], 'inputs.csv', { type: 'text/csv' })
}

export const Apis = {
  getFlatfileAuthToken: (type: LookupRequestType) =>
    api.post<{ token: string }>(`/flatfile/auth?type=${type}`),
  postAsset: (req: PostAssetRequest) => {
    req.rows.forEach((row) => {
      for (const key in row) {
        if (typeof row[key as keyof typeof row] === 'string') {
          row[key as keyof typeof row] = (row[key as keyof typeof row] as string).replace(/,/g, '')
        }
      }
    })

    const csv = buildCsv(req.rows)
    const formData = new FormData()

    formData.append(csv.name, csv)

    appendToFormData(formData, 'filename', req.filename)
    appendToFormData(formData, 'requestEmails', req.requestEmails)
    appendToFormData(formData, 'requestEmailsHighAccuracy', req.requestEmailsHighAccuracy)
    appendToFormData(formData, 'requestPhones', req.requestPhones)
    appendToFormData(formData, 'requestPhonesHighAccuracy', req.requestPhonesHighAccuracy)
    appendToFormData(formData, 'requestWorkEmails', req.requestWorkEmails)
    appendToFormData(formData, 'requestedType', req.requestedType)

    return api.post<{ asset: Asset }>('/asset/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  postAssetVerifyAndReplace: (req: PostAssetRequest) => {
    const csv = buildCsv(req.rows, ['fullName', 'workEmail', 'company', 'industry', 'location'])

    const formData = new FormData()

    formData.append(csv.name, csv)

    appendToFormData(formData, 'filename', req.filename)
    appendToFormData(formData, 'requestedType', req.requestedType)
    appendToFormData(formData, 'requestWorkEmails', req.requestWorkEmails)
    appendToFormData(formData, 'verifyReplace', req.verifyReplace)

    return api.post<{ asset: Asset }>('/asset/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getAsset: (assetId: string, options?: AxiosRequestConfig<unknown>) =>
    api.get<{ asset: Asset }>(`/asset/${assetId}`, options),
  updateRating: (assetId: string, payload: { rating: number }) =>
    api.patch<{ asset: Asset }>(`/asset/${assetId}`, payload),
  getAllAssets: (
    page: number,
    limit: number,
    includeEmpty: boolean,
    filter: AssetFilter = {},
    order: Order,
    orderingColumn: string
  ) => {
    const queryParams = {
      page,
      limit,
      q: filter.query,
      minMatches: filter.minMatches,
      maxMatches: filter.maxMatches,
      startDate: filter.startDate
        ? DateTime.fromFormat(filter.startDate, 'yyyy-MM-dd').toFormat('yyyy-MM-dd')
        : undefined,
      endDate: filter.endDate
        ? DateTime.fromFormat(filter.endDate, 'yyyy-MM-dd').toFormat('yyyy-MM-dd')
        : undefined,
      status: filter.status?.map((i: any) => i.id),
      requestedType: filter.requestedType,
      single: filter.single,
      includeEmpty: includeEmpty,
      order: order,
      orderingColumn: orderingColumn
    }

    return api.get<{ assets: Asset[]; pagination: Pagination }>(`/asset`, { params: queryParams })
  },
  getFileMetadata: (batchId: string) =>
    api.get<any, { data: { originalFile: string } }>(`/flatfile/batch/metadata/${batchId}`),
  uploadFile: (file: File, onUploadProgress: (progress: number) => void) => {
    // todo: implmement file upload logic. this is mocking progress update.
    return new Promise((resolve) => {
      let progress = 0
      const intervalId = setInterval(() => {
        progress += 10
        onUploadProgress(progress)
        if (progress >= 100) {
          clearInterval(intervalId)
          resolve({
            url: 'https://drive.google.com/file/d/1x4BHnHNPe9gbTS-4PhKdeyY88ZwXPGb4/view?usp=sharing'
          })
        }
      }, 300)
    })
  }
}
