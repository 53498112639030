import { trackEvent } from '@/analytics'
import { NoRecentMatches } from '@/components/NoRecentMatches'
import useAssetTable from '@/components/Tables/assetTable'
import { useIsCurrentUserActivated } from '@/reducers/user'
import { Asset } from '@/types/assets'
import { Box, Button, Typography } from '@mui/material'
import { RowSelectionState } from '@tanstack/react-table'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import AssetsTable, { Order } from '../history/AssetsTable'
import { BulkLookupsInactiveUser } from '../history/History'
import AssetTypeTabs, { useSelectedTab } from './AssetTypeTabs'

const RecentFiles = () => {
  const isUserActivated = useIsCurrentUserActivated()

  const [selectedTab, setSelectedTab] = useSelectedTab()
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const [order, setOrder] = useState<Order>('desc')
  const [orderingColumn, setOrderingColumn] = useState<Required<keyof Asset>>('createdAt')

  const { pathname } = useLocation()
  const requestedType = pathname.replace(/.*\/(.*)$/, '$1')

  const { allMatches, columns } = useAssetTable({
    selectedTab,
    page: 'Dashboard',
    rowSelection: rowSelection,
    order,
    orderingColumn,
    filter: { requestedType, single: selectedTab.isSingle }
  })

  return (
    <div>
      <Typography variant="h2" mb={3}>
        Recent Matches
      </Typography>
      <div className="mx-6">
        <AssetTypeTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          page={'Dashboard'}
        />
      </div>
      <Box className="history-list">
        {!isUserActivated ? (
          <BulkLookupsInactiveUser className="gap-3" page="Dashboard" />
        ) : (
          <>
            {allMatches.length === 0 ? (
              <NoRecentMatches />
            ) : (
              <>
                <AssetsTable
                  allMatches={allMatches}
                  columns={columns}
                  showStatus={selectedTab.index === 0}
                  page={'Dashboard'}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  order={order}
                  setOrder={setOrder}
                  orderingColumn={orderingColumn}
                  setOrderingColumn={setOrderingColumn}
                />
                <Box mt={4}>
                  <Button
                    variant="outlined"
                    size="large"
                    href="/history"
                    onClick={() => {
                      trackEvent('View all history', { page: 'Dashboard' })
                    }}
                  >
                    View All History
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </div>
  )
}

export default RecentFiles
