import React from 'react'
import { OutlinedInput, InputProps, TextField, IconButton, SvgIcon, styled } from '@mui/material'
import { IMaskInput } from 'react-imask'

import { ReactComponent as CrossIcon } from '@/assets/icons/ic-cross.svg'

import { Control, Controller, FieldValues } from 'react-hook-form'
import { DateTime } from 'luxon'

interface ComponentProps extends InputProps {
  onClear?: () => void
  name: string
  control?: Control<FieldValues> | undefined
}

const StyledTextField = styled(TextField)(({ value, theme }) => ({
  '& input': {
    color: value ? undefined : theme.palette.text.secondary
  }
}))

const DateInput: React.FC<ComponentProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <StyledTextField
          variant="outlined"
          fullWidth
          type="date"
          {...field}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  )
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

interface ComponentProps extends InputProps {
  onClear?: () => void
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props
  console.log((other as any)?.value)

  return (
    <IMaskInput
      {...other}
      mask="0000.00.00"
      definitions={{}}
      inputRef={ref as any}
      onAccept={(value: any) => {
        onChange({
          target: {
            name: props.name,
            value: DateTime.fromFormat(value, 'yyyy.MM.dd').toFormat('yyyy-MM-dd')
          }
        })
      }}
      overwrite
    />
  )
})

export const DateInput2: React.FC<ComponentProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <OutlinedInput
          fullWidth
          type="date"
          {...field}
          inputComponent={TextMaskCustom as any}
          endAdornment={
            props.value ? (
              <IconButton
                aria-label="Clear"
                onClick={() => props.onClear && props.onClear()}
                size="small"
              >
                <SvgIcon component={CrossIcon} inheritViewBox sx={{ fontSize: 16 }} />
              </IconButton>
            ) : (
              <></>
            )
          }
          {...props}
        />
      )}
    />
  )
}

export default DateInput
