import { Box, Button, styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

const NavButton = styled(Button)({
  padding: '11px 16px 8px',
  color: '#515468',
  borderBottom: 'none',
  fontSize: 14,
  textDecoration: 'none',
  lineHeight: '24px',
  fontWeight: 700,
  '&:hover': {
    '+ .nav-border-bottom': {
      visibility: 'visible'
    }
  }
})

interface NavMenuProps {
  to?: string
  label?: string
}

const NavBorderBottom = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive'
})((props: { isActive: boolean }) => ({
  background: '#2721AE',
  borderRadius: '4px 4px 0 0',
  height: 4,
  width: '100%',
  visibility: props.isActive ? 'visible' : 'hidden',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
}))

const NavMenu: React.FC<NavMenuProps> = ({ to = '', label }) => {
  return (
    <NavLink to={to} style={{ textDecoration: 'none', flex: 1 }}>
      {({ isActive }) => (
        <>
          <NavButton sx={{ width: '100%' }}>{label}</NavButton>
          <NavBorderBottom className="nav-border-bottom" isActive={isActive} />
        </>
      )}
    </NavLink>
  )
}

const SettingsTabs = () => {
  return (
    <Box
      sx={{
        mx: 5,
        display: { xs: 'none', md: 'flex' },
        alignItems: 'flex-end'
      }}
    >
      <NavMenu to="/settings/profile" label="Profile settings" />
      <NavMenu to="/settings/billing" label="Payment settings" />
    </Box>
  )
}

export default SettingsTabs
