import { Form } from 'react-bootstrap'

import { useController, UseControllerProps, FieldValues } from 'react-hook-form'
import InputError from '../Input/InputError'

type FormInputProps<FieldsType extends FieldValues> = UseControllerProps<FieldsType> & {
  controlId?: string
  label?: string | React.ReactNode
  disabled?: boolean
  type?: string
  placeholder?: string
}

const FormTextarea = <FieldValuesType extends FieldValues>({
  control,
  name,
  rules,
  controlId,
  label,
  disabled,
  type,
  placeholder
}: FormInputProps<FieldValuesType>) => {
  const { field, formState } = useController({ name, control, rules })
  const errorMessage = formState?.errors?.[name]?.message as string

  return (
    <Form.Group className="mb-3" controlId={controlId}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        as="textarea"
        type={type}
        placeholder={placeholder}
        {...field}
        disabled={disabled}
        style={{ height: '70px', resize: 'none' }}
      />
      {!!errorMessage && typeof errorMessage === 'string' && <InputError error={errorMessage} />}
    </Form.Group>
  )
}

export default FormTextarea
