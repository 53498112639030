import { DateTime } from 'luxon'
import { Chip, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Link } from 'react-router-dom'
import { Asset, AssetStatus } from '@/types/assets'
import { PAGE, trackEvent } from '@/analytics'
import { getStatusColorStyle } from '@/components/Tables/assetTable'
import { ReactComponent as IcPhoneFlip } from '@/assets/icons/ic-phone-flip.svg'
import { ReactComponent as IcEnvelope } from '@/assets/icons/ic-envelope.svg'
import { useState } from 'react'
import { getAssetDownload } from '@/services/asset'

const StatusChip = ({ status }: { status: AssetStatus }) => {
  const colorStyle = getStatusColorStyle(status)

  return (
    <Chip
      label={status}
      sx={{
        textTransform: 'capitalize',
        fontWeight: 500,
        ...colorStyle
      }}
      size="small"
    />
  )
}

const DownloadAssetButton = ({ asset }: { asset: Asset }) => {
  const [isLoading, setIsLoading] = useState(false)

  const fetchAssetLink = async () => {
    setIsLoading(true)

    try {
      const link = await getAssetDownload(asset._id)
      window.open(link.data.url, '_blank')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <LoadingButton size="small" loading={isLoading} variant="outlined" onClick={fetchAssetLink}>
      Download
    </LoadingButton>
  )
}

const MobileBulkAssetCard = ({ asset, page }: { asset: Asset; page: PAGE }) => {
  const toLink = `/asset/${asset._id}`

  const trackClick = () => {
    trackEvent('Select history record', { page })
  }

  return (
    <div className="bg-white rounded-xl border border-background.tertiary">
      <div className="p-4 flex justify-between items-center">
        <div>
          <Link to={toLink} className="no-underline" onClick={trackClick}>
            <Typography variant="body1" fontWeight={700} color="text.primary">
              {asset.label ? asset.label : <i>(No label)</i>}
            </Typography>
          </Link>
          <Typography variant="caption" fontWeight={500} color="text.quaternary">
            {DateTime.fromISO(asset.createdAt).toLocaleString(DateTime.DATE_SHORT)}
          </Typography>
        </div>
        <DownloadAssetButton asset={asset} />
      </div>
      <div className="w-full border border-t-0 border-r-0 border-l-0 border-background.tertiary"></div>
      <div className="p-4">
        <div className="flex justify-between items-center">
          <Link to={toLink}>
            <Typography variant="caption" fontWeight={500} color="text.primary">
              {asset.filename}
            </Typography>
          </Link>
          <StatusChip status={asset.status} />
        </div>
      </div>
      <div className="p-4">
        <div className="flex items-center gap-8">
          <div className="flex gap-4 items-center">
            <IcEnvelope className="fill-text.quaternary h-4 w-4" />
            <Typography variant="caption" fontWeight={500}>
              {asset.statistics?.enrichedEmails}
            </Typography>
          </div>
          <div className="flex gap-4 items-center">
            <IcPhoneFlip className="fill-text.quaternary h-4 w-4" />
            <Typography variant="caption" fontWeight={500}>
              {asset.statistics?.enrichedPhones}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

const MobileBulkAssetsTable = ({ allMatches, page }: { allMatches: Asset[]; page: PAGE }) => {
  return (
    <div className="flex flex-col gap-4 mb-4">
      {allMatches.map((asset) => (
        <MobileBulkAssetCard key={asset._id} asset={asset} page={page} />
      ))}
    </div>
  )
}

export default MobileBulkAssetsTable
