import { Typography } from '@mui/material'
import { ReactComponent as IcCircleInfo } from '@/assets/icons/ic-circle-info.svg'
import { formatMoney, formatNumber } from '@/utils'
import { usePricingSchema } from './hooks'
import { useBillingTracker } from '@/pages/dashboard/hooks'

const Row = ({ price, numberOfMatches }: { price: number; numberOfMatches: number }) => {
  const isFree = price === 0
  const isZeroMatches = numberOfMatches === 0

  const getCorrespondingLabel = (numberOfMatches: number) => {
    if (numberOfMatches === 0) {
      return '-'
    }
    if (numberOfMatches === 1) {
      return '1 match'
    }
    return `${formatNumber(numberOfMatches)} matches`
  }

  return (
    <tr>
      <td className="pl-2 pb-4">{isFree ? 'Free matches' : `${formatMoney(price)}/match`}</td>

      <td className="pb-4">{getCorrespondingLabel(numberOfMatches)}</td>

      <td className="pb-4 text-end w-0 whitespace-nowrap pr-2">
        {isZeroMatches ? '-' : formatMoney(price * numberOfMatches)}
      </td>
    </tr>
  )
}

const EstimationSummary = ({ cost, matches }: { cost: number; matches: number }) => {
  const { data: billingTracker } = useBillingTracker()

  const userBalance = billingTracker?.userBalance ?? undefined

  return (
    <>
      <tr className="rounded-lg">
        <td className="bg-[#F3F3F6] px-2 pt-3 rounded-tl-lg font-bold">
          Maximum cost for this enrichment
        </td>
        <td className="bg-[#F3F3F6] px-2 pt-3 font-bold">
          {matches === 1 ? '1 match' : `${formatNumber(matches)} matches`}
        </td>
        <td className="bg-[#F3F3F6] px-2 pt-3 rounded-tr-lg font-bold text-end w-0 whitespace-nowrap">
          {formatMoney(cost)}
        </td>
      </tr>

      {userBalance === undefined || userBalance === 0 ? null : (
        <tr className="rounded-lg">
          <td className="bg-[#F3F3F6] px-2 py-3 font-bold">Maximum total cost due now</td>
          <td className="bg-[#F3F3F6] px-2 py-3 font-bold">-</td>
          <td className="bg-[#F3F3F6] px-2 py-3 font-bold text-end w-0 whitespace-nowrap">
            {formatMoney(userBalance + cost)}
          </td>
        </tr>
      )}

      <tr className="rounded-lg">
        <td className="bg-[#F3F3F6] px-2 py-3 font-medium rounded-b-lg" colSpan={3}>
          <div className="flex items-start text-sm">
            <IcCircleInfo className="fill-primary.main mr-1.5 flex-shrink-0 mt-1" />
            For total balances over $375, we&apos;ll perform a pre-authorization, placing a
            temporary hold on funds until the payment amount is confirmed and processed.
          </div>
        </td>
      </tr>
    </>
  )
}

const PricingTable = ({
  matchNum,
  moneyTotal,
  estimatedMaxCost,
  totalRecords
}: {
  matchNum: number
  moneyTotal: number
  estimatedMaxCost: number
  totalRecords: number
}) => {
  const { data: pricingSchema } = usePricingSchema()
  const { data: billingTracker } = useBillingTracker()
  const offset = billingTracker?.accrued_credits ?? 0
  const freeCredits = billingTracker?.free_credits ?? 0

  if (!pricingSchema) {
    return null
  }
  const remainingMatches = matchNum - freeCredits

  return (
    <>
      <table className="w-full border-separate border-spacing-y-0 border-spacing-x-0">
        <tbody className="border-spacing-y-2">
          {freeCredits > 0 && (
            <Row price={0} numberOfMatches={remainingMatches > 0 ? freeCredits : matchNum} />
          )}
          {pricingSchema
            ? pricingSchema.pricingSchema.map((pricing) => {
                const floor = Math.max(pricing.floor - offset, 1)
                const ceiling = Math.max(pricing.ceiling - offset, 0)

                const price = pricing.price
                const numberOfMatches =
                  floor <= remainingMatches
                    ? Math.min(remainingMatches - floor + 1, ceiling - floor + 1)
                    : 0

                return <Row key={pricing.floor} price={price} numberOfMatches={numberOfMatches} />
              })
            : null}
        </tbody>
        <tfoot className="border-t border-background.tertiary pt-4">
          <tr className="border-t border-background.tertiary pt-4">
            <td className="p-0">
              <div className="bg-background.tertiary h-0.5 my-4" />
            </td>
            <td className="p-0">
              <div className="bg-background.tertiary h-0.5 my-4" />
            </td>
            <td className="p-0">
              <div className="bg-background.tertiary h-0.5 my-4" />
            </td>
          </tr>
          <tr>
            <td />
            <td className="pb-4">
              <Typography variant="h3" fontWeight={800} className="whitespace-nowrap">
                {matchNum === 1 ? '1 match' : `${formatNumber(matchNum)} matches`}
              </Typography>
            </td>
            <td className="text-end pb-4 pr-2">
              <Typography variant="h3" fontWeight={800} className="whitespace-nowrap">
                {formatMoney(moneyTotal)}
              </Typography>
            </td>
          </tr>

          <tr className="border-t pt-4">
            <td className="p-0">
              <div className="my-2" />
            </td>
            <td className="p-0">
              <div className="my-2" />
            </td>
            <td className="p-0">
              <div className="my-2" />
            </td>
          </tr>

          <EstimationSummary matches={totalRecords} cost={estimatedMaxCost} />
        </tfoot>
      </table>
    </>
  )
}

export default PricingTable
