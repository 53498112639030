import { Button, Typography } from '@mui/material'
import ErrorAlert from './ErrorAlert'

const PreventDeleteAlert = ({
  text = 'Cannot delete your account until outstanding payment is made.'
}: {
  text?: string
}) => {
  return (
    <ErrorAlert>
      <div className="flex flex-col items-start">
        <Typography fontWeight={700}>{text}</Typography>
        <Button variant="contained" size="small" sx={{ my: 1.5 }} href="/billing">
          Pay now
        </Button>
      </div>
    </ErrorAlert>
  )
}

export default PreventDeleteAlert
