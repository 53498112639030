import { createColumnHelper } from '@tanstack/react-table'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { PaymentHistoryFilter } from '@/types/billing'
import { getTransactions } from '@/services/transactions'
import { Transaction } from '@/types/transactions'
import { CardIcon } from '@/pages/settings/billing/CreditCardComp'
import TransactionAmount from '@/components/Payments/TransactionAmount'
import TransactionStatusPill from '@/components/Payments/TransactionStatusPill'
import TransactionInvoice from '@/components/Payments/TransactionInvoice'
import { Typography } from '@mui/material'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE_ROWS = 5
const EMPTY_FILTERS = {}

const columnHelper = createColumnHelper<Transaction>()

const getPaymentTypeString = (type: 'monthly' | 'one-time') => {
  switch (type) {
    case 'monthly':
      return 'Monthly payment'
    default:
      return 'One-time payment'
  }
}

const useTransactionsTable = ({
  pageNumber = DEFAULT_PAGE,
  perPageRows = DEFAULT_PER_PAGE_ROWS,
  filter = EMPTY_FILTERS,
  setTotalPage,
  setTotalResults
}: {
  pageNumber?: number
  perPageRows?: number
  filter?: PaymentHistoryFilter
  setTotalPage?: React.Dispatch<React.SetStateAction<number>>
  setTotalResults?: React.Dispatch<React.SetStateAction<number>>
}) => {
  const [allTransactions, setAllTransactions] = useState<Transaction[]>([])

  const fetchTransactions = async (argFilter?: PaymentHistoryFilter) => {
    try {
      const { data } = await getTransactions(pageNumber, perPageRows, argFilter || filter)
      setAllTransactions(data.transactions)
      if (setTotalPage) {
        setTotalPage(data.pagination.pages)
      }
      if (setTotalResults) {
        setTotalResults(data.pagination.total)
      }
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error
      }

      if (error.response?.status !== 401) {
        throw error
      }
    }
  }

  useEffect(() => {
    fetchTransactions()
  }, [pageNumber, perPageRows])

  const columns = [
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: 'DATE OF PAYMENT',
      cell: (info) => (
        <Typography
          variant="caption"
          title={DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATETIME_FULL)}
        >
          {DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
      )
    }),
    columnHelper.accessor('type', {
      id: 'type',
      header: 'PAYMENT TYPE',
      cell: (info) => (
        <Typography variant="caption">{getPaymentTypeString(info.getValue())}</Typography>
      )
    }),
    columnHelper.accessor('paymentMethodBrand', {
      id: 'cc',
      header: 'PAYMENT METHOD',
      cell: (info) => (
        <span className="flex items-center">
          <CardIcon brand={info.getValue()} />
          <Typography variant="caption" fontWeight={700} ml={2}>
            <span style={{ letterSpacing: 2, fontSize: 14 }}>•••• •••• ••••</span>&nbsp;&nbsp;
            {info.row.original.paymentMethodLast4}
          </Typography>
        </span>
      )
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'STATUS',
      cell: (info) => <TransactionStatusPill status={info.getValue()} />
    }),
    columnHelper.accessor('cost', {
      id: 'cost',
      header: 'AMOUNT',
      cell: (info) => {
        const transaction = info.row.original

        return (
          <TransactionAmount
            finalAmount={transaction?.amountReceived}
            initialAmount={transaction.cost}
          />
        )
      }
    }),
    columnHelper.accessor('cost', {
      id: 'invoice',
      header: 'INVOICE',
      cell: (info) => {
        const transaction = info.row.original
        const isCanceled = transaction.status === 'canceled'

        return (
          <TransactionInvoice
            billingSheetsId={[transaction.billingSheetId]}
            isDisabled={isCanceled}
            title="Invoice"
          />
        )
      }
    })
  ]

  return {
    allTransactions,
    columns,
    fetchTransactions
  }
}

export default useTransactionsTable
