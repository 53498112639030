import BillingAddressForm from '@/pages/settings/billing/BillingAddressForm'
import { useAddresses } from '@/pages/settings/billing/hooks'
import { useUserPaymentInfo } from '@/pages/settings/billing/usePaymentMethods'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import Heading from './heading'

import { PAGE, trackEvent } from '@/analytics'
import { BillingSheet } from '@/types/billing'
import { stripePromise } from '@/utils/payments'
import EditIcon from '@mui/icons-material/Edit'
import { Elements } from '@stripe/react-stripe-js'
import AddressSummary from './AddressSummary'
import ConfirmPayment from './ConfirmPayment'
import CreditCardSection from './CreditCardSection'

const useCardSelector = () => {
  const [selectedCard, setSelectedCard] = useState<string | null>(null)
  const [selectedCardError, setSelectedCardError] = useState<string | null>(null)

  const handleCardSelect = useCallback((newSelectedCard: string | null) => {
    setSelectedCard(newSelectedCard)
    setSelectedCardError(null)
  }, [])

  const validateSelectedCard = useCallback(() => {
    if (!selectedCard) {
      setSelectedCardError('A payment method must be selected')
      return false
    }

    return true
  }, [selectedCard])

  return {
    selectedCard,
    selectedCardError,
    validateSelectedCard,
    handleCardSelect
  }
}

const ConfirmAndPay = ({
  billingSheet,
  page = 'Payment'
}: {
  billingSheet: BillingSheet
  page?: PAGE
}) => {
  const { payload: userInfo } = useUserPaymentInfo()
  const { isLoading: isAddressLoading, formState, control, onSubmit, register } = useAddresses()
  const [showEditAddress, setShowEditAddress] = useState(false)
  const { selectedCard, handleCardSelect, selectedCardError, validateSelectedCard } =
    useCardSelector()
  const isLoading = isAddressLoading || !userInfo

  const onSubmitHandler = async (event: React.BaseSyntheticEvent | undefined) => {
    await onSubmit(event)
    setShowEditAddress(false)
  }

  return (
    <div className="flex-1 flex flex-col gap-6">
      <Heading />
      <Box
        sx={{
          backgroundColor: 'background.secondary',
          padding: '24px 32px 24px 32px',
          borderRadius: 3,
          mt: 3
        }}
      >
        <div className="flex justify-between items-start">
          <Typography variant="h3" mb={3}>
            Billing address
            {isLoading ? <CircularProgress size={20} sx={{ marginLeft: 2 }} /> : null}
          </Typography>
          <Button
            startIcon={<EditIcon />}
            size="small"
            variant="outlined"
            disabled={isLoading}
            onClick={() => setShowEditAddress((prev) => !prev)}
          >
            Change billing address
          </Button>
        </div>
        {showEditAddress && (
          <BillingAddressForm
            formState={formState}
            control={control}
            disabled={isLoading}
            register={register}
            onSubmit={(e) => {
              trackEvent('Updates billing address', { page })
              return onSubmitHandler(e)
            }}
          />
        )}
        {userInfo && !showEditAddress && <AddressSummary userInfo={userInfo} />}

        <CreditCardSection
          selectedCard={selectedCard}
          setSelectedCard={handleCardSelect}
          error={selectedCardError}
          page={page}
        />

        <Box className="flex justify-between items-center" mt={4}>
          <Elements stripe={stripePromise}>
            <ConfirmPayment
              billingSheet={billingSheet}
              validateSelectedCard={validateSelectedCard}
              selectedCard={selectedCard}
            />
          </Elements>
        </Box>
      </Box>
    </div>
  )
}

export default ConfirmAndPay
