import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '.'

const slice = createSlice({
  name: 'lookup',
  initialState: {
    isRetry: false
  },
  reducers: {
    setRetry: (state, action: PayloadAction<boolean>) => {
      state.isRetry = action.payload
    }
  }
})

export const selectIsRetry = (state: RootState) => state.lookup.isRetry

export const { setRetry } = slice.actions
export default slice.reducer
