import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { AccuracyMode, KeyOfMatchMode } from '@/types/match'
import { MatchTypeChecked } from '@/pages/dashboard/QuickMatching/SubmitArea'

interface MatchSelectBoxProps {
  accuracyMode: AccuracyMode
  icon: React.ReactNode
  isChecked: boolean
  labelText: string
  name: KeyOfMatchMode
  onChanged: (fieldName: KeyOfMatchMode) => void
}

const StyledTag = styled('div')(({ theme }) => ({
  borderRadius: 30,
  padding: '8px 12px',
  fontSize: 14,
  border: '1px solid #DDE3F6',
  fontWeight: 500,
  backgroundColor: theme.palette.background.default,
  whiteSpace: 'nowrap'
}))

const MatchSelectBox = ({
  icon,
  labelText,
  accuracyMode,
  onChanged,
  name,
  isChecked
}: MatchSelectBoxProps) => {
  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation()
    name !== 'workEmail' && onChanged(name)
  }

  return (
    <Paper
      sx={{
        py: 2.5,
        px: 3,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: 2,
        borderColor: isChecked ? 'primary.main' : 'transparent',
        width: 'min(550px,100%)'
      }}
      elevation={isChecked ? 0 : 2}
      onClick={handleChange}
    >
      {name === 'workEmail' ? (
        <MatchTypeChecked icon={icon} label={labelText} />
      ) : (
        <FormControlLabel
          control={
            <Checkbox checked={isChecked} onClick={handleChange} sx={{ color: '#2721ae' }} />
          }
          label={
            <Stack direction="row" alignItems="center" gap={1}>
              {icon}
              <Typography variant="body1" fontWeight="700" whiteSpace="nowrap">
                {labelText}
              </Typography>
            </Stack>
          }
          onClick={(event) => {
            event.stopPropagation()
          }}
        />
      )}
      <Stack direction="row" alignItems="center" gap={2}>
        <StyledTag>
          {accuracyMode === AccuracyMode.ALL ? 'All the matches' : 'Highest accuracy'}
        </StyledTag>
      </Stack>
    </Paper>
  )
}

export default MatchSelectBox
