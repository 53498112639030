import { api } from '@/providers/ApiProvider'
import { Inquiry } from '@/types/inquiry'
import { appendToFormData } from '@/utils/formData'
import { AxiosRequestConfig } from 'axios'

const postInquiry = (data: Inquiry, options?: AxiosRequestConfig<unknown>) => {
  const formData = new FormData()
  console.log(data)

  data.files.forEach((file) => {
    formData.append(file.name, file)
  })
  appendToFormData(formData, 'link', data.link)
  appendToFormData(formData, 'note', data.note)
  appendToFormData(formData, 'meeting', data.meeting?.toISOString())
  appendToFormData(formData, 'batchId', data?.batchId)
  if (data.phone) {
    appendToFormData(formData, 'phoneNumber', data.phone)
  } else {
    appendToFormData(formData, 'emailAddress', data.email)
  }

  return api.post<Inquiry>('/inquiry', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...options?.headers
    },
    ...options
  })
}

export { postInquiry }
