import useSWR from 'swr'
import { BillingTracker } from '@/types/user'
import { api } from '@/providers/ApiProvider'

const fetcher = async (url: string) => {
  const result = await api.get<BillingTracker>(url)

  return result.data
}

export const useBillingTracker = () => {
  return useSWR('/payments/billing', fetcher)
}
