import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'

import { ReactComponent as IconMinus } from '@/assets/icons/ic-minus.svg'
import DateInput from '@/components/Input/DateInput'
import NumberInput from '@/components/Input/NumberInput'
import { PaymentHistoryFilter } from '@/types/billing'
import { Grid } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control } from 'react-hook-form'

export interface ComponentProps extends PropsWithChildren<any> {
  control: Control<PaymentHistoryFilter, any>
}

const FilterBar: React.FC<ComponentProps> = ({ children, control }) => {
  return (
    <>
      <form className="hidden md:block">
        <Grid container spacing={2} alignItems="end">
          <Grid item xs={6} md={5}>
            <InputLabel sx={{ mb: 1 }}>DATE OF PAYMENT</InputLabel>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DateInput
                placeholder={`31.12.${new Date().getFullYear()}`}
                sx={{ flexGrow: 1 }}
                control={control}
                name="startDate"
              />
              <Typography color="primary.main" sx={{ textDecoration: 'line-through', mx: '12px' }}>
                <SvgIcon component={IconMinus} inheritViewBox sx={{ fontSize: 16 }} />
              </Typography>
              <DateInput
                placeholder={`31.12.${new Date().getFullYear()}`}
                sx={{ flexGrow: 1 }}
                control={control}
                name="endDate"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={5}>
            <InputLabel sx={{ mb: 1 }}>AMOUNT</InputLabel>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <NumberInput
                control={control}
                placeholder="Min."
                sx={{ flexGrow: 1 }}
                name="minAmount"
              />
              <Typography color="primary.main" sx={{ textDecoration: 'line-through', mx: '12px' }}>
                <SvgIcon component={IconMinus} inheritViewBox sx={{ fontSize: 16 }} />
              </Typography>
              <NumberInput
                control={control}
                placeholder="Max."
                sx={{ flexGrow: 1 }}
                name="maxAmount"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2} sm={3} className="text-end">
            {children}
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default FilterBar
