import { api } from '@/providers/ApiProvider'
import { BillingSheet } from '@/types/billing'
import { BillingTracker, StripeCustomer } from '@/types/user'
import { PaymentIntent, PaymentMethod, SetupIntent } from '@stripe/stripe-js'
import { AxiosRequestConfig } from 'axios'

const getCustomerPaymentMethods = (options?: AxiosRequestConfig<unknown>) => {
  return api.get<PaymentMethod[]>('/payments/customers/paymentMethods', options)
}

const getPaymentIntent = (id: string, options?: AxiosRequestConfig<unknown>) => {
  return api.get<PaymentIntent>(`/payments/${id}`, options)
}

const getUserBilling = () => {
  return api.get<BillingTracker>(`/payments/billing`)
}

const getBillingCheck = () => {
  return api.get(`/payments/billing/check`)
}

const createBillingSettlement = () => {
  return api.post<BillingSheet>(`/payments/billing/sheet/settle`)
}

const getBillingSheetPdf = (id: string) => {
  return api.get<Blob>(`/payments/billing/sheet/${id}/pdf`, { responseType: 'blob' })
}

const getUserPaymentInfo = (options?: AxiosRequestConfig<unknown>) => {
  return api.get<StripeCustomer>('/payments/customers/info', options)
}

const deleteCustomerPaymentMethods = (id: string, options?: AxiosRequestConfig<unknown>) => {
  return api.delete<PaymentMethod>(`/payments/customers/paymentMethods/${id}`, options)
}

const createSetupIntent = (options?: AxiosRequestConfig<unknown>) => {
  return api.post<SetupIntent>(`/payments/customers/paymentMethods`, options)
}

const createPaymentForBillingSheet = (
  billingSheetId: string,
  data: { paymentMethodId: string }
) => {
  return api.post<PaymentIntent>(`/payments/billing/sheet/${billingSheetId}/pay`, data)
}

const markPaymentMethodDefault = (id: string, options?: AxiosRequestConfig<unknown>) => {
  return api.patch<StripeCustomer>(`/payments/customers/paymentMethods/${id}/default`, options)
}

const updateCustomerAddress = (
  data: {
    city?: string
    country?: string
    address?: string
    zipCode?: string
    state?: string
  },
  options?: AxiosRequestConfig<unknown>
) => {
  return api.patch<StripeCustomer>(`/payments/customers/address`, data, options)
}

export {
  getCustomerPaymentMethods,
  getPaymentIntent,
  getUserBilling,
  getBillingCheck,
  getUserPaymentInfo,
  deleteCustomerPaymentMethods,
  createSetupIntent,
  markPaymentMethodDefault,
  updateCustomerAddress,
  createPaymentForBillingSheet,
  getBillingSheetPdf,
  createBillingSettlement
}
