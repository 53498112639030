import { ReactComponent as DisabledBulkEnrichmentIcon } from '@/assets/icons/ic-bulk-enrichments-disabled.svg'
import { ReactComponent as BulkEnrichmentIcon } from '@/assets/icons/ic-bulk-enrichments.svg'
import { ReactComponent as SingleEnrichmentIcon } from '@/assets/icons/ic-single-enrichment.svg'
import { Card } from '@mui/material'
import { ActiveTabArrow, Tab } from './Tabs'

import { trackEvent } from '@/analytics'
import { useIsCurrentUserActivated } from '@/reducers/user'
import { useGetAndClearSearchParam } from '@/utils'
import useIsMobile from '@/utils/isMobile'
import { maxBy } from 'lodash'
import { useCallback, useEffect } from 'react'
import { historyTabs, useSelectedTab } from '../AssetTypeTabs'
import BulkEnrichment from './BulkEnrichment'
import SingleEnrichmentDashboard from './SingleEnrichmentDashboard'

const QuickEnrich = ({ matchType }: { matchType: 'personal' | 'company' }) => {
  const [activeTab, setActiveTab] = useSelectedTab(true)
  const isUserActivated = useIsCurrentUserActivated()

  useIsMobile(
    useCallback(
      (isMobile: boolean) => {
        if (isMobile) {
          setActiveTab(historyTabs[1])
        }
      },
      [setActiveTab]
    )
  )

  const assetIdParam = useGetAndClearSearchParam('assetView')

  useEffect(() => {
    if (assetIdParam) {
      setActiveTab(historyTabs[1])
    }
  }, [assetIdParam, setActiveTab])

  useEffect(() => {
    const outOfBoundTab = maxBy(historyTabs, 'index')
    if (!outOfBoundTab) return

    if (activeTab.index < outOfBoundTab.index) return
    setActiveTab(historyTabs[1])
  }, [activeTab.index, setActiveTab])

  return (
    <div>
      <div className="hidden md:flex mt-6 gap-3 md:gap-6">
        <Tab
          disabled={!isUserActivated}
          active={activeTab.index === 0}
          icon={isUserActivated ? <BulkEnrichmentIcon /> : <DisabledBulkEnrichmentIcon />}
          text="Bulk Lookup"
          onClick={() => {
            setActiveTab(historyTabs[0])
            trackEvent('Select bulk look up mode', { page: 'Dashboard' })
          }}
        />

        <Tab
          active={activeTab.index === 1}
          icon={<SingleEnrichmentIcon />}
          text="Single Search"
          onClick={() => {
            setActiveTab(historyTabs[1])
            trackEvent('Select single search mode', { page: 'Dashboard' })
          }}
        />
      </div>

      <div>
        <Card sx={{ p: 3, mt: 2, position: 'relative', overflow: 'unset' }}>
          <ActiveTabArrow activeTab={activeTab.index} />

          <BulkEnrichment matchType={matchType} active={activeTab.index === 0} />
          <SingleEnrichmentDashboard
            matchType={matchType}
            defaultAssetId={assetIdParam}
            active={activeTab.index === 1}
          />
        </Card>
      </div>
    </div>
  )
}

export default QuickEnrich
