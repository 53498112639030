import CloseIcon from '@mui/icons-material/Close'
import { Alert, AlertColor, AlertTitle, IconButton } from '@mui/material'

interface StateAlertProps {
  className?: string
  onClose: () => void
  title: string
  message: string
  severity: AlertColor
}

export const StateAlert = ({ className, onClose, title, message, severity }: StateAlertProps) => {
  return (
    <Alert
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      className={`${className} border`}
      severity={severity}
    >
      <AlertTitle className="font-bold text-black">{title}</AlertTitle>
      {message}
    </Alert>
  )
}

export default StateAlert
