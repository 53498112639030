import { BillingSheet } from '@/types/billing'
import ItemsTable from '@/components/Payments/Summary/ItemsTable'
import TotalRow from '@/components/Payments/Summary/TotalRow'
import './print.scss'
import { useInternalBillingSheet } from './utils'
import Footer from './Footer'
import Header from './Header'
import Recipient from './Recipient'
import Sender from './Sender'

const BillingSheetContent = ({ billingSheet }: { billingSheet: BillingSheet }) => {
  return (
    <>
      <ItemsTable billingSheet={billingSheet} />

      <div className="mt-6">
        <TotalRow billingSheet={billingSheet} />
      </div>
    </>
  )
}

const BillingReceiptTemplate = () => {
  const billingSheet = useInternalBillingSheet()

  if (!billingSheet?.data) {
    return null
  }

  return (
    <div className="m-10 flex flex-col">
      <Header billingSheet={billingSheet.data} />

      <div className="flex justify-between items-start mt-10">
        <Recipient billingSheet={billingSheet.data} />
        <Sender billingSheet={billingSheet.data} />
      </div>

      <div className="flex flex-col mt-10">
        <BillingSheetContent billingSheet={billingSheet.data} />
      </div>

      <Footer />

      <div id="loaded" className="print:hidden">
        loaded
      </div>
    </div>
  )
}

export default BillingReceiptTemplate
