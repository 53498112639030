import { LoadingButton as MuiLoadingButton } from '@mui/lab'
import { ButtonProps, styled } from '@mui/material'

import type { PAGE, TYPE } from '@/analytics/types'
import Button from '../Button/Button'

interface LoadingButtonProps extends Omit<ButtonProps, 'onClick'> {
  children: string
  track: {
    event: TYPE
    page: PAGE
  }
}

const StyleLoadingButton = styled(MuiLoadingButton)(() => ({
  '& span': {
    left: '90%'
  }
}))

const LoadingButton = ({
  children,
  className,
  track,
  type = 'submit',
  ...restProps
}: LoadingButtonProps) => {
  return (
    <Button track={track}>
      {(form, trackEvent) => (
        <StyleLoadingButton
          className={className}
          disabled={form.formState.isSubmitting}
          loading={form.formState.isSubmitting}
          onClick={trackEvent}
          size="large"
          type={type}
          variant="contained"
          {...restProps}
        >
          {children}
        </StyleLoadingButton>
      )}
    </Button>
  )
}

export default LoadingButton
