import { ReactComponent as ChecklistIcon } from '@/assets/icons/ic-checklist.svg'
import { ReactComponent as WarningIcon } from '@/assets/icons/ic-warning.svg'
import { Box, Button, Typography } from '@mui/material'

import QuickEnrichList from './QuickEnrichList'

const BulkEnrichment = ({
  active,
  matchType
}: {
  active: boolean
  matchType: 'personal' | 'company'
}) => {
  return (
    <Box sx={{ display: active ? undefined : 'none' }}>
      <div className="hidden md:block">
        <span className="flex gap-2 mt-1">
          <ChecklistIcon className="h-6 w-6 fill-text.primary" />
          <Typography variant="h3">Bulk enrich your existing contact list</Typography>
        </span>
        <QuickEnrichList matchType={matchType} />
      </div>
      <div className="flex flex-col gap-4 px-1 items-center md:hidden">
        <WarningIcon className="h-6 w-6 fill-primary.main" />
        <Typography variant="h3" className="text-center">
          Switch to a device with a larger screen
        </Typography>
        <Typography variant="caption" color="text.quaternary" className="text-center">
          Please note that bulk enrichments can only be performed on a device with a larger screen.
          On a mobile device you can only perform single enrichments.
        </Typography>
        <Typography variant="caption" color="text.quaternary" className="text-center" mt={2}>
          If you wish to enrich your existing spreadsheet, try a desktop/ tablet device or let us
          take care of it.
        </Typography>
        <Button href="/let-us-handle" variant="contained" size="large" sx={{ mt: 4 }}>
          Let Us Take Care of It
        </Button>
        <Button href="/let-us-handle" size="large" sx={{ mb: 4 }}>
          Watch a Demonstration
        </Button>
      </div>
    </Box>
  )
}

export default BulkEnrichment
