import { api } from '@/providers/ApiProvider'
import { AllowedAssetUpdateFields, AssetDownloadResponse, AssetResponse } from '@/types/assets'
import { handleBillingError } from '@/utils/errors/billing'
import { PaymentIntent } from '@stripe/stripe-js'
import axios, { AxiosRequestConfig } from 'axios'
import { NavigateFunction } from 'react-router-dom'
import useSWR from 'swr'

const getAsset = (assetId: string, options?: AxiosRequestConfig<unknown>) => {
  return api.get<AssetResponse>(`/asset/${assetId}`, options)
}

const getAssetDownload = (assetId: string) => {
  return api.get<AssetDownloadResponse>(`/asset/${assetId}/download`)
}

const swrGetAssetDownload = async (url: string) => {
  const result = await api.get<AssetDownloadResponse>(url)

  return result.data
}

const useGetAssetDownload = (assetId: string) => {
  return useSWR(`/asset/${assetId}/download`, swrGetAssetDownload)
}

const downloadMultipleAssets = (assetIds: string[]) => {
  return api.post<string>(`/asset/download`, { assetIds })
}

const updateAsset = (
  assetId: string,
  data: AllowedAssetUpdateFields,
  options?: AxiosRequestConfig<unknown>
) => {
  return api.patch<AssetResponse>(`/asset/${assetId}`, data, options)
}

const processAsset = async ({
  assetId,
  options,
  navigate
}: {
  assetId: string
  options?: AxiosRequestConfig<unknown>
  navigate: NavigateFunction
}) => {
  try {
    return await api.post<AssetResponse>(`/asset/${assetId}/process`, options)
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error
    }

    handleBillingError({ error, navigate, assetId })
  }
}

const createPaymentForAsset = async ({
  assetId,
  paymentMethodId,
  options
}: {
  assetId: string
  paymentMethodId: string
  options?: AxiosRequestConfig<unknown>
}) => {
  return await api.post<{
    message: string
    paymentIntent: PaymentIntent
  }>(`/asset/${assetId}/createPayment`, { paymentMethodId }, options)
}

export {
  getAsset,
  updateAsset,
  createPaymentForAsset,
  processAsset,
  getAssetDownload,
  useGetAssetDownload,
  downloadMultipleAssets
}
