import { api } from '@/providers/ApiProvider'
import { PricingSchema } from '@/types/pricing'
import useSWR from 'swr'

const fetcher = async (url: string) => {
  const result = await api.get<{ pricingSchema: PricingSchema[] }>(url)

  return result.data
}

export const usePricingSchema = () => {
  return useSWR(`/pricing/schema`, fetcher)
}
