import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { BaseSyntheticEvent } from 'react'

import SearchInput from '@/components/Input/SearchInput'
import { AssetFilter } from '@/types/assets'
import { styled } from '@mui/material'
import { Control } from 'react-hook-form'

export const ResetButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  paddingLeft: 16,

  '&.MuiButton-root.Mui-disabled': {
    color: '#C4C5D1',
    backgroundColor: 'transparent',

    '& svg': {
      fill: '#C4C5D1'
    }
  },

  '& svg': {
    fill: theme.palette.primary.main
  }
}))

export interface ComponentProps {
  onReset?: () => void
  onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
  control: Control<AssetFilter, any>
}

export const ASSET_REQUESTED_TYPE = [
  {
    id: 'company',
    label: 'Company'
  },
  {
    id: 'address',
    label: 'Address'
  }
]

const FilterBar: React.FC<ComponentProps> = ({ onSubmit, onReset, control }) => {
  return (
    <>
      <form onSubmit={onSubmit} onReset={onReset}>
        <Stack direction="row" spacing={{ xs: 2, sm: 3 }}>
          <Box sx={{ flexGrow: 1 }}>
            <SearchInput
              placeholder="Search for people by name or files by name or label"
              fullWidth
              control={control}
              name="query"
            />
          </Box>
          <Box>
            <Button variant="contained" color="primary" size="large" type="submit" fullWidth>
              Search
            </Button>
          </Box>
        </Stack>
      </form>
    </>
  )
}

export default FilterBar
