import { Outlet } from 'react-router-dom'
import './layout.scss'
import { ReactComponent as Logo } from '@/assets/icons/logo.svg'
import Footer from './Footer'

const Header = () => {
  return (
    <header className="flex flex-shrink-0 lg:hidden items-center justify-center h-20 border-solid border-b border-t-0 border-l-0 border-r-0 border-background.tertiary">
      <Logo className="w-[164px] h-[64px]" />
    </header>
  )
}

const AuthLayout = () => {
  return (
    <div className="h-screen flex flex-col">
      <Header />
      <main className="flex-1 lg:flex-none pt-6 lg:pt-20">
        <Outlet />
      </main>
      <div className="flex flex-shrink-0 lg:hidden">
        <Footer />
      </div>
    </div>
  )
}

export default AuthLayout
