import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { Alert, Box, Button, Card, Container, Divider, Typography, styled } from '@mui/material'
import SettingsTabs from '../tabs'
import { useAuth } from '@/reducers/user'
import { passwordErrorText, passwordValidator } from '@/utils/validators'
import FormInput from '@/components/Form/Input/Input'
import { resetPassword } from '@/services/auth'
import { useState } from 'react'
import { ReactComponent as CheckIconRaw } from '@/assets/icons/ic-check.svg'
import axios from 'axios'
import { trackEvent } from '@/analytics'
import Banners from '@/pages/dashboard/Banners'

const CheckIcon = styled(CheckIconRaw)(({ theme }) => ({
  fill: theme.palette.secondary.main
}))

interface FormValues {
  oldPassword: string
  password: string
}

const schema = object({
  oldPassword: string(),
  password: passwordValidator
}).required()

const ChangePasswordPage = () => {
  const { user } = useAuth()
  const [completed, setCompleted] = useState(false)
  const [serverError, setServerError] = useState(undefined)

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: yupResolver(schema)
  })

  const { isValid } = formState

  const onSubmit = async (values: FormValues) => {
    if (!isValid) {
      return
    }

    try {
      setServerError(undefined)

      await resetPassword({ password: values.password, oldPassword: values.oldPassword })
      setCompleted(true)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setServerError(error.response?.data?.error)
      }
    }
  }

  return (
    <>
      <Banners page="Profile Settings" />
      <SettingsTabs />

      <Container sx={{ py: 5 }}>
        <Typography variant="h1" color="primary.main">
          Profile settings
        </Typography>
        {!!user && (
          <Box mt={5} className="flex flex-col items-start" gap={3}>
            <Typography variant="h2">Change password</Typography>
            <Card sx={{ p: 5, width: 'min(500px, 100%)' }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                  control={control}
                  formState={formState}
                  name="oldPassword"
                  label="Current password"
                  type="password"
                  controlId="oldPassword"
                  rules={{ required: true }}
                />
                <Divider sx={{ mb: 1 }} />
                <FormInput
                  control={control}
                  formState={formState}
                  name="password"
                  label="New password"
                  type="password"
                  controlId="password"
                  helperText={passwordErrorText}
                  rules={{ required: true }}
                />
                {serverError && (
                  <Alert sx={{ mb: 2 }} severity="error">
                    {serverError}
                  </Alert>
                )}

                <div className="flex justify-between">
                  <Button variant="outlined" size="small" href="/settings/profile">
                    Cancel
                  </Button>
                  <div>
                    {completed && <CheckIcon className="h-5 w-5 mr-4" />}
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      disabled={formState.isSubmitting || completed}
                      onClick={() => {
                        trackEvent('Changes password', { page: 'Profile Settings' })
                      }}
                    >
                      {completed ? 'Saved' : 'Save'}
                    </Button>
                  </div>
                </div>
              </form>
            </Card>
          </Box>
        )}
      </Container>
    </>
  )
}

export default ChangePasswordPage
