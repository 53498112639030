import { useAuth } from '@/reducers/user'
import { useCallback, useEffect, useState } from 'react'
import { requestMagicLink } from '@/services/auth'
import { useInterval } from 'react-use'
import { DateTime } from 'luxon'
import { getTimeDiff } from '@/pages/plans/hooks'
import { PAGE, trackEvent } from '@/analytics'

// const MAGIC_LINK_THROTTLE_TIME = 60 // 1 minute

const isSendingRequestsAllowed = (noSendBefore?: string) => {
  if (!noSendBefore) {
    return true
  }

  const now = new Date()
  const noSendBeforeDate = new Date(noSendBefore)

  return noSendBeforeDate < now
}

export const useVerifyEmail = (page: PAGE) => {
  const { user, revalidate } = useAuth()
  const email = user?.email as string

  const [isLoading, setIsLoading] = useState(false)
  const [noSendBefore, setNoSendBefore] = useState(user?.magicLinksAllowedFrom)
  const [canSend, setCanSend] = useState(isSendingRequestsAllowed(noSendBefore))
  const [countdown, setCountdown] = useState<string | undefined>(
    noSendBefore ? getTimeDiff(DateTime.now(), DateTime.fromISO(noSendBefore), 's') : undefined
  )
  const [error, setError] = useState(false)

  const handleSendEmail = useCallback(async () => {
    try {
      setIsLoading(true)

      await requestMagicLink(email)
      trackEvent('Resend Email Verification', { page })

      revalidate()
      setCanSend(false)
    } catch (error) {
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }, [email, revalidate, page])

  useInterval(() => {
    setCanSend(isSendingRequestsAllowed(noSendBefore))
  }, 1000)

  useInterval(
    () => {
      if (!noSendBefore) {
        return
      }
      setCountdown(getTimeDiff(DateTime.now(), DateTime.fromISO(noSendBefore), 's'))
    },
    canSend ? null : 1000
  )

  useEffect(() => {
    if (user?.magicLinksAllowedFrom) {
      setNoSendBefore(user?.magicLinksAllowedFrom)
      setCanSend(isSendingRequestsAllowed(user?.magicLinksAllowedFrom))
    }
  }, [user?.magicLinksAllowedFrom])

  return {
    isLoading,
    canSend,
    countdown: Number(countdown) >= 0 ? countdown : '0',
    handleSendEmail,
    error
  }
}
