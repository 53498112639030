import useSWR from 'swr'
import { BillingSheet } from '@/types/billing'

import { useParams } from 'react-router-dom'
import { api } from '@/providers/ApiProvider'

const fetcher = async (url: string) => {
  // Auth header is created on backend and injected by playwright
  const result = await api.get<BillingSheet>(url)

  return result.data
}

export const useInternalBillingSheet = (paramId?: string) => {
  const { id } = useParams()

  return useSWR(`/internal/billing/sheet/${paramId || id}`, fetcher)
}
