import { AccuracyMatchChip } from '@/components/common/AccuracyMatchChip'
import { AccuracyTooltip } from './Tooltips'

const HighAccuracyMatchChip = () => (
  <AccuracyMatchChip
    color="success"
    label="High Accuracy Match"
    wrappWithTooltip={AccuracyTooltip}
  />
)

export default HighAccuracyMatchChip
