import { Drawer } from '@mui/material'
import SidebarContent from './SidebarContent'

const MobileSidebar = ({
  handleDrawerToggle,
  handleSignOut,
  isOpen
}: {
  handleDrawerToggle: () => unknown
  handleSignOut: () => unknown
  isOpen: boolean
}) => {
  const container = window !== undefined ? () => window.document.body : undefined

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={isOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      sx={{
        display: 'block',
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' }
      }}
      className="md:hidden"
    >
      <div className="flex flex-col justify-stretch px-3 py-6">
        <SidebarContent handleDrawerToggle={handleDrawerToggle} handleSignOut={handleSignOut} />
      </div>
    </Drawer>
  )
}

export default MobileSidebar
