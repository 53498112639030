/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Container,
  Typography,
  Box,
  Button,
  InputLabel,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  SvgIcon,
  Card
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import TextareaAutosize from '@/components/Textarea/TextareaAutosize'
import { ReactComponent as NoteIcon } from '@/assets/icons/ic-note-circle.svg'
import { useDispatch, useSelector } from 'react-redux'
import { newInquiry, selectLetUsHandleItFlowInquiry, setInquiryNote } from '@/reducers/inquiry'
import { emailValidator, phoneValidator } from '@/utils/validators'
import { postInquiry } from '@/services/inquiry'
import {
  DiscussOverEmailCard,
  DiscussOverPhoneCard,
  KnowYourCost,
  SetupMeetingCard
} from './Components'
import ListTooLarge from './ListTooLarge'

const stringValidator = Yup.string().min(5).required()

const validate = (validationRule: any, obj: any) => {
  try {
    validationRule.validateSync(obj)
    return undefined
  } catch (error) {
    // @ts-ignore
    return error.message
  }
}

const LetUsHandleContactPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state }: any = useLocation()
  console.log({ state })

  const [contactMethod, setContactMethod] = useState<string | null>(null)
  const [contactDetail, setContactDetail] = useState<string | null>(null)
  const [errors, setErrors] = useState<{ note?: string; phone?: string; email?: string }>({})
  const inquiry = useSelector(selectLetUsHandleItFlowInquiry)

  useEffect(() => {
    if (!inquiry) {
      dispatch(newInquiry())
    }
  }, [])

  const validateNote = () => {
    let errorFound = false
    const noteError = validate(stringValidator, inquiry?.note)
    if (noteError) {
      setErrors((prev) => ({ ...prev, note: noteError }))
      errorFound = true
    }

    if (contactDetail === 'phone') {
      const phoneError = validate(phoneValidator, inquiry?.phone)
      if (phoneError) {
        setErrors((prev) => ({ ...prev, phone: phoneError }))
        errorFound = true
      }
    }

    if (contactDetail === 'email') {
      const emailError = validate(emailValidator, inquiry?.email)
      if (emailError) {
        setErrors((prev) => ({ ...prev, email: emailError }))
        errorFound = true
      }
    }

    return !errorFound
  }

  const validateFile = () => {
    let errorFound = false

    const noteError = validate(stringValidator, inquiry?.note)
    if (noteError) {
      setErrors((prev) => ({ ...prev, note: noteError }))
      errorFound = true
    }

    if (contactMethod === 'phone') {
      const phoneError = validate(phoneValidator, inquiry?.phone)
      if (phoneError) {
        setErrors((prev) => ({ ...prev, phone: phoneError }))
        errorFound = true
      }
    }

    if (contactMethod === 'email') {
      const emailError = validate(emailValidator, inquiry?.email)
      if (emailError) {
        setErrors((prev) => ({ ...prev, email: emailError }))
        errorFound = true
      }
    }

    return !errorFound
  }

  const validateStep = () => {
    setErrors({})

    if (state?.file || state?.batchId) {
      return validateFile()
    }

    if (contactMethod === 'note') {
      return validateNote()
    }

    return true
  }

  const handleClickNext = () => {
    if (!validateStep()) {
      return
    }

    if (!inquiry) {
      return
    }

    if (contactMethod === 'meeting') {
      navigate('/let-us-handle/schedule', {
        state: {
          ...state,
          note: inquiry?.note,
          files: state?.file ? [state.file] : [],
          link: state?.link,
          batchId: state?.batchId
        }
      })
    } else {
      postInquiry({
        ...inquiry,
        phone: contactDetail === 'phone' || contactMethod === 'phone' ? inquiry.phone : '',
        files: state?.file ? [state.file] : [],
        link: state?.link,
        batchId: state?.batchId
      })

      navigate('/let-us-handle/success', {
        state: {
          type: contactDetail || contactMethod
        }
      })
    }
  }

  if (state?.batchId) {
    return <ListTooLarge />
  }

  if (state?.file || state?.batchId) {
    return (
      <Container sx={{ py: 5 }}>
        <Typography variant="h1" color="primary.main" mb={1}>
          How can we help?
        </Typography>

        <Grid container spacing={4}>
          <Grid item lg={6} md={12} xs={12}>
            <Typography variant="body1" fontWeight={400} mb={2}>
              Just leave a note:
            </Typography>

            <TextareaAutosize
              minRows={5}
              maxRows={10}
              sx={{
                width: '100%',
                borderColor: errors?.note ? theme.palette.error.main : undefined
              }}
              onChange={(event) => {
                dispatch(setInquiryNote(event.target.value))
              }}
              value={inquiry?.note}
            />
            {errors?.note ? (
              <InputLabel sx={{ color: theme.palette.error.main }}>{errors?.note}</InputLabel>
            ) : null}

            <Typography variant="h3" mt={4} mb={3}>
              How can we get back in touch?
            </Typography>

            <RadioGroup
              value={contactMethod}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) =>
                setContactMethod(value)
              }
            >
              <SetupMeetingCard />
              <DiscussOverPhoneCard active={contactMethod === 'phone'} error={errors?.phone} />
              <DiscussOverEmailCard active={contactMethod === 'email'} error={errors?.email} />
            </RadioGroup>
            <Box mt={5} width={'100%'}>
              <KnowYourCost />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 6 }}>
          <Button variant="outlined" size="large" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button
            variant="contained"
            size="large"
            disabled={!contactMethod}
            onClick={handleClickNext}
          >
            Next
          </Button>
        </Box>
      </Container>
    )
  } else {
    return (
      <Container sx={{ py: 5 }}>
        <Typography variant="h1" color="primary.main" mb={1}>
          How can we help?
        </Typography>

        <Grid container spacing={4}>
          <Grid item lg={6} md={12} xs={12}>
            <Typography variant="body1" fontWeight={400} mb={2}>
              Select which option works best for you to discuss your needs:
            </Typography>

            <RadioGroup
              value={contactMethod}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) =>
                setContactMethod(value)
              }
            >
              <SetupMeetingCard />
              <Card sx={{ padding: 2, mb: 2 }}>
                <FormControlLabel
                  value="note"
                  control={<Radio />}
                  label={
                    <Typography variant="body1" fontWeight={700}>
                      <SvgIcon
                        component={NoteIcon}
                        inheritViewBox
                        sx={{ ml: 1, mr: 2, fontSize: 40 }}
                      />
                      {"Simply leave a note and we'll contact you"}
                    </Typography>
                  }
                />
                {contactMethod === 'note' && (
                  <Box mt={3} width={'100%'}>
                    <InputLabel sx={{ color: theme.palette.text.quaternary }}>
                      TELL US ABOUT YOUR EXPECTATIONS
                    </InputLabel>
                    <TextareaAutosize
                      minRows={5}
                      maxRows={10}
                      sx={{
                        width: '100%',
                        mt: 1,
                        borderColor: errors?.note ? theme.palette.error.main : undefined
                      }}
                      onChange={(event) => {
                        dispatch(setInquiryNote(event.target.value))
                      }}
                      value={inquiry?.note}
                    />
                    {errors?.note ? (
                      <InputLabel sx={{ color: theme.palette.error.main }}>
                        {errors?.note}
                      </InputLabel>
                    ) : null}
                  </Box>
                )}
              </Card>
            </RadioGroup>

            {contactMethod === 'note' && (
              <>
                <Typography variant="h3" mt={4} mb={3}>
                  How can we get back in touch?
                </Typography>
                <RadioGroup
                  value={contactDetail}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) =>
                    setContactDetail(value)
                  }
                >
                  <DiscussOverPhoneCard active={contactDetail === 'phone'} error={errors?.phone} />
                  <DiscussOverEmailCard active={contactDetail === 'email'} error={errors?.email} />
                </RadioGroup>
              </>
            )}

            <Box mt={2} width={'100%'}>
              <KnowYourCost />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 6 }}>
          <Button variant="outlined" size="large" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button
            variant="contained"
            size="large"
            disabled={!contactMethod || (contactMethod === 'note' && !contactDetail)}
            onClick={handleClickNext}
          >
            Next
          </Button>
        </Box>
      </Container>
    )
  }
}

export default LetUsHandleContactPage
