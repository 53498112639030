import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PersonalFormValues, personalFormValuesValidationSchema } from '@/types/form-data'
import SubmitArea from './SubmitArea'
import { Apis } from '@/providers/ApiProvider'
import FormInput from '@/components/Form/Input/Input'
import { STEPS } from '@/pages/upload'
import { processAsset } from '@/services/asset'
import { trackEvent } from '@/analytics'
import axios from 'axios'
import SendEmailVerificationModal from '@/components/Modal/SendEmailVerificationModal'
import { isUserNotActivatedError } from '@/utils/errors/utils'

// webpack issue, keep this here
if (typeof window === 'undefined') {
  console.log(STEPS)
}

export const useRequestMatchTypes = () => {
  const [requestPhones, setRequestPhones] = useState(true)
  const [requestEmails, setRequestEmails] = useState(true)
  const [requestWorkEmails, setRequestWorkEmails] = useState(true)

  // const matchSelectPreference = useMatchSelectPreferences()

  // useEffect(() => {
  //   if (
  //     matchSelectPreference.email.active !== undefined &&
  //     matchSelectPreference.phone.active !== undefined
  //   ) {
  //     setRequestEmails(matchSelectPreference.email.active)
  //     setRequestPhones(matchSelectPreference.phone.active)
  //   }
  // }, [
  //   matchSelectPreference.email.active === undefined,
  //   matchSelectPreference.phone.active === undefined
  // ])

  return {
    requestPhones,
    requestEmails,
    setRequestPhones,
    setRequestEmails,
    requestWorkEmails,
    setRequestWorkEmails
  }
}

const QuickPersonalForm = ({
  setActiveAssetId
}: {
  children?: React.ReactNode
  setActiveAssetId: (assetId: string) => unknown
}) => {
  const { control, handleSubmit, formState } = useForm<PersonalFormValues>({
    resolver: yupResolver(personalFormValuesValidationSchema)
  })
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false)

  const navigate = useNavigate()

  const { requestPhones, requestEmails, setRequestPhones, setRequestEmails, requestWorkEmails } =
    useRequestMatchTypes()

  const handlePersonalFormSubmit = async (formData: PersonalFormValues) => {
    trackEvent('Single Search - Get Contact Info', {
      page: 'Dashboard',
      type: 'personal',
      requestEmails,
      requestPhones,
      formPayload: formData
    })

    const { data } = await Apis.postAsset({
      filename: `${formData.fullName}`,
      requestEmails: requestEmails,
      requestPhones: requestPhones,
      rows: [formData],
      requestedType: 'address'
    })

    await processAsset({ assetId: data.asset._id, navigate: navigate })

    return data.asset._id
  }

  const handleFormSubmit = async (values: PersonalFormValues) => {
    try {
      const id = await handlePersonalFormSubmit(values)

      setActiveAssetId(id)
    } catch (error) {
      if (axios.isAxiosError(error) && isUserNotActivatedError(error)) {
        setSendEmailModalOpen(true)
      }
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <FormInput
              formState={formState}
              control={control}
              name="fullName"
              label="FULL NAME"
              controlId="fieldFullName"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <FormInput
              formState={formState}
              control={control}
              name="address"
              label="Address"
              controlId="fieldAddress"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <FormInput
              formState={formState}
              control={control}
              name="city"
              label="City"
              controlId="fieldCity"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <FormInput
              formState={formState}
              control={control}
              name="state"
              label="State"
              controlId="fieldState"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <FormInput
              formState={formState}
              control={control}
              name="zipCode"
              label="Zip Code"
              controlId="fieldZipcode"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitArea
              lookupType="address"
              isLoading={formState.isSubmitting}
              requestEmails={requestEmails}
              requestPhones={requestPhones}
              requestWorkEmails={requestWorkEmails}
              setRequestEmails={setRequestEmails}
              setRequestPhones={setRequestPhones}
            />
          </Grid>
        </Grid>
      </form>
      <SendEmailVerificationModal
        open={sendEmailModalOpen}
        setOpen={setSendEmailModalOpen}
        page="Dashboard"
      />
    </>
  )
}

export default QuickPersonalForm
