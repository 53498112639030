import mixpanel from 'mixpanel-browser'

import { MIXPANEL_TOKEN } from './init'
import { Payload, TYPE } from './types'

export const mixpanelTrack = (event: TYPE, payload?: Payload | undefined) => {
  console.log('mixpanelTrack', event, payload)

  if (!MIXPANEL_TOKEN) {
    return
  }
  mixpanel.track(event, payload)
}

export const mixpanelTrackUserProfile = ({
  userId,
  name,
  email
}: {
  userId: string
  name: string
  email: string
}) => {
  if (!MIXPANEL_TOKEN) {
    return
  }

  mixpanel.identify(userId)
  mixpanel.people.set_once({ $name: name, $email: email })
}

export const trackEvent = (event: TYPE, payload?: Payload | undefined) => {
  return mixpanelTrack(event, payload)
}
