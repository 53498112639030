import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Asset } from '@/types/assets'
import type { RootState } from './'

export interface AssetsState {
  assetId: string
  current: Asset | null
  config: {
    email: boolean
    phone: boolean
  }
  isProcessing: boolean
}

const initialState: AssetsState = {
  assetId: '',
  current: null,
  config: {
    email: false,
    phone: false
  },
  isProcessing: false
}

export const AssetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setCurrentAsset: (state, action: PayloadAction<Asset>) => {
      state.current = action.payload
    },
    setAssetConfig: (state, action: PayloadAction<any>) => {
      state.config = action.payload
    }
  }
})

export const { setCurrentAsset, setAssetConfig } = AssetsSlice.actions

export const selectCurrentAsset = (state: RootState) => state.assets.current
export const selectAssetConfig = (state: RootState) => state.assets.config

export default AssetsSlice.reducer
