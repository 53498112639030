import { Button, useTheme, Box } from '@mui/material'
import { DragDropCaption } from '@/components/Upload/DragDropFile'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { trackEvent } from '@/analytics'

const QuickEnrichList = ({ matchType }: { matchType: 'personal' | 'company' }) => {
  const theme = useTheme()

  const linkToBulk = `/lookup/${matchType === 'company' ? 'company' : 'address'}/bulk`

  return (
    <div>
      <Box
        mt={2}
        sx={{
          borderRadius: '24px',
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='${encodeURIComponent(
            theme.palette.background.tertiary
          )}' stroke-width='7' stroke-dasharray='5%2c 15' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
          paddingTop: 14,
          paddingBottom: 14,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Button
          href={linkToBulk}
          variant="contained"
          size="large"
          sx={{ whiteSpace: 'nowrap', marginLeft: 'auto', marginRight: 'auto' }}
          onClick={() => {
            trackEvent('Bulk - get started with your spreadsheet', { page: 'Dashboard' })
          }}
        >
          Getting started with your spreadsheet
        </Button>
        <Button
          size="small"
          href={linkToBulk}
          startIcon={<HelpOutlineIcon />}
          sx={{ marginTop: 2, marginLeft: 'auto', marginRight: 'auto' }}
          onClick={() => {
            trackEvent('Bulk - see upload instructions', { page: 'Dashboard' })
          }}
        >
          How to upload your file?
        </Button>
      </Box>
      <Box mt={1}>
        <DragDropCaption />
      </Box>
    </div>
  )
}

export default QuickEnrichList
