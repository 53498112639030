import { useEffect, useState } from 'react'
import {
  createSetupIntent,
  deleteCustomerPaymentMethods,
  markPaymentMethodDefault
} from '@/services/payments'
import { useFetchPaymentMethods } from './usePaymentMethods'
import { StripeCustomer } from '@/types/user'
import { atom, useAtom } from 'jotai'
import axios from 'axios'
import { BILLING_ERROR_CODES } from '@/utils/errors/billing'
import { PAGE, trackEvent } from '@/analytics'

const loadingAtom = atom(false)

export const useCards = ({
  userInfo,
  page,
  refreshUser
}: {
  userInfo?: StripeCustomer
  page: PAGE
  refreshUser: () => unknown
}) => {
  const [isLoading, setIsLoading] = useAtom(loadingAtom)
  const [showAdd, setShowAdd] = useState(false)
  const [canDeletePaymentMethod, setCanDeletePaymentMethod] = useState(true)
  const [showCantDeletePaymentMethod, setShowCantDeletePaymentMethod] = useState(false)
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const { payload: paymentMethods, refetch: refreshPaymentMethods } =
    useFetchPaymentMethods(setIsLoading)

  useEffect(() => {
    if (paymentMethods?.length) {
      const canDelete = paymentMethods.length > 1
      setCanDeletePaymentMethod(canDelete)
    }
  }, [paymentMethods?.length])

  useEffect(() => {
    if (canDeletePaymentMethod) {
      setShowCantDeletePaymentMethod(false)
    }
  }, [canDeletePaymentMethod])

  const onPaymentMethodDelete = async (id: string) => {
    setIsLoading(true)
    try {
      await deleteCustomerPaymentMethods(id)

      refreshPaymentMethods()
      refreshUser()
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error)
      }

      if (
        axios.isAxiosError(error) &&
        error.response?.data?.errors?.[0]?.code === BILLING_ERROR_CODES.OUTSTANDING_PAYMENT
      ) {
        setShowCantDeletePaymentMethod(true)
      }
    }

    setIsLoading(false)
  }

  const onAdd = async () => {
    setIsLoading(true)
    const setupIntent = await createSetupIntent()
    setClientSecret(setupIntent.data.client_secret)
    setShowAdd(true)
    setIsLoading(false)
  }

  const onAddCancel = () => {
    setShowAdd(false)
    setIsLoading(false)
  }

  const onAddSuccess = async () => {
    setIsLoading(true)
    trackEvent('Adds a new CC', { page })
    await refreshPaymentMethods()
    refreshUser()
    setShowAdd(false)
    setIsLoading(false)
  }

  const onMarkDefault = async (id: string) => {
    setIsLoading(true)
    await markPaymentMethodDefault(id)
    await refreshUser()
    setShowAdd(false)
    setIsLoading(false)
  }

  return {
    paymentMethods,
    isLoading,
    showAdd,
    onAdd,
    onAddCancel,
    onAddSuccess,
    onPaymentMethodDelete,
    clientSecret,
    defaultPaymentMethodId: userInfo?.invoice_settings.default_payment_method,
    onMarkDefault,
    canDeletePaymentMethod,
    showCantDeletePaymentMethod
  }
}
