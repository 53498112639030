import { useBillingTracker } from '@/pages/dashboard/hooks'
import { formatNumber } from '@/utils'
import { Typography } from '@mui/material'

const Credits = () => {
  const { data: billingTracker } = useBillingTracker()

  const credits = billingTracker?.free_credits ?? 10

  if (!credits) return null

  return (
    <Typography className="mt-2 text-[14px] text-[#858798]">
      You have {formatNumber(credits)} free matches left
    </Typography>
  )
}

export default Credits
