import { UseFormReturn } from 'react-hook-form'

import { trackEvent } from '@/analytics'
import type { PAGE, TYPE } from '@/analytics/types'
import useFormContextProvider from '@/hooks/useFormContextProvider'

interface ButtonProps {
  children: (form: UseFormReturn, handleTrackEvent: () => void) => React.ReactElement
  track: {
    event: TYPE
    page: PAGE
  }
}

const Button = ({ children, track }: ButtonProps) => {
  const form = useFormContextProvider()

  const handleTrackEvent = () => {
    const { event, page } = track
    trackEvent(event, { page })
  }

  // TODO: Check if children is LoadingButton or Button from MUI
  return <>{children(form, handleTrackEvent)}</>
}

export default Button
