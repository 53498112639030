import { ReactComponent as FaceFrownIcon } from '@/assets/icons/face-frown-slight.svg'
import { ReactComponent as LightBulbOnIcon } from '@/assets/icons/lightbulb-on.svg'
import useSearchType from '@/hooks/useSearchType'
import { selectIsRetry } from '@/reducers/lookup'
import { Asset } from '@/types/assets'

import { Alert, Box, Stack, SvgIcon, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import RetryCompanyForm from './RetryLookupForm/RetryCompanyForm'
import RetryLookupCompanyMessage from './RetryLookupForm/RetryLookupCompanyMessage'

export const SearchByCompanyInfo = ({ domain }: { domain: string }) => {
  return (
    <Stack className="mt-10" direction="row" spacing={1}>
      <Box>
        <SvgIcon className="items-end mt-2" component={LightBulbOnIcon} />
      </Box>
      <Box>
        We checked&nbsp;
        <Box component="span" className="font-bold text-[#2721ae] underline">
          {domain}
        </Box>
        &nbsp;for associated work emails. Is it not the correct URL?&nbsp;
        <Box component="span" className="font-bold">
          Update it for better results!
        </Box>
      </Box>
    </Stack>
  )
}

interface Props {
  asset: Asset
  domain: string
  onRestart: () => void
  setActiveAssetId: (assetId: string) => unknown
}

const EmptyCompanyWorkContactResults = ({ asset, domain, setActiveAssetId, onRestart }: Props) => {
  const isRetryLookup = useSelector(selectIsRetry)
  const { isDomainURL } = useSearchType()
  const searchByCompanyName = isDomainURL(asset.singleEnrichment.company)

  const boxWidth =
    !asset.singleEnrichment.enrichedPhone && !asset.singleEnrichment.enrichedEmail
      ? 'max-w-[60%]'
      : 'max-w-[100%]'

  const retryWithNoResults =
    isRetryLookup &&
    !asset.singleEnrichment.enrichedWorkEmail &&
    !asset.singleEnrichment.enrichedWorkEmail2

  const resultAlertMessage = !retryWithNoResults
    ? "We couldn't find any work emails for this individual."
    : "Sorry, we couldn't find any contact information for this individual."

  return (
    <>
      <Box className={boxWidth}>
        {!retryWithNoResults && (
          <Typography variant="body1" fontWeight={700} color="primary.main">
            Work contacts
          </Typography>
        )}
        <Alert
          className="my-4 flex items-center rounded-lg border border-yellow-400 mt-6 bg-[#FFEEC3]"
          icon={<FaceFrownIcon />}
          severity="warning"
        >
          <Typography className="text-black" fontWeight={500}>
            {resultAlertMessage}
          </Typography>
        </Alert>
        {!searchByCompanyName ? (
          <Stack className="mt-10" direction="row" spacing={1}>
            <SvgIcon className="items-end mt-1" component={LightBulbOnIcon} />
            <RetryLookupCompanyMessage asset={asset}>
              {({ result, asset }) => (
                <>
                  Based on {asset.singleEnrichment.company}, we checked the following websites for
                  associated work emails: {result}. &nbsp;
                  <strong>Retry with the correct URL for better results!</strong>
                </>
              )}
            </RetryLookupCompanyMessage>
          </Stack>
        ) : (
          <SearchByCompanyInfo domain={domain} />
        )}
      </Box>
      <Box>
        <RetryCompanyForm asset={asset} setActiveAssetId={setActiveAssetId} onRestart={onRestart} />
      </Box>
    </>
  )
}

export default EmptyCompanyWorkContactResults
