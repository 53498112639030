const URL = 'https://helloarcher.io/privacy-policy/'

const PrivacyPolicyLink = ({ label = ' Privacy Policy' }: { label?: string }) => {
  return (
    <a href={URL} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  )
}

export default PrivacyPolicyLink
