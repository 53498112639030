import { useForm } from 'react-hook-form'
import { object, string, boolean } from 'yup'
import { getRequiredStringValidator } from '@/utils/validators'
import { COUNTRIES } from '@/utils/countries'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateCustomerAddress } from '@/services/payments'
import { useEffect } from 'react'
import { useUserPaymentInfo } from './usePaymentMethods'

export interface BillingFormType {
  fullName: string
  address: string
  city: string
  zipCode: string
  country: string
  update: boolean
}

const schema = object({
  fullName: getRequiredStringValidator('Full name is required'),
  address: getRequiredStringValidator('Address is required'),
  city: getRequiredStringValidator('City is required'),
  zipCode: getRequiredStringValidator('Zip code is required'),
  country: string().oneOf(
    COUNTRIES.map((country) => country.code),
    'Country must be selected'
  ),
  update: boolean().optional()
}).required()

export const useAddresses = () => {
  const { payload: userInfo, refetch: refreshUser } = useUserPaymentInfo()

  const { control, handleSubmit, formState, setValue, getValues, register, trigger } =
    useForm<BillingFormType>({
      defaultValues: {
        update: true
      },
      resolver: yupResolver(schema)
    })

  const onSubmit = async (formData: BillingFormType) => {
    await updateCustomerAddress(formData)
    await refreshUser()
  }

  useEffect(() => {
    if (userInfo) {
      setValue('fullName', userInfo.name)
      setValue('address', userInfo?.address?.line1 || '')
      setValue('country', userInfo?.address?.country || '')
      setValue('zipCode', userInfo?.address?.postal_code || '')
      setValue('city', userInfo?.address?.city || '')
    }
  }, [
    userInfo?.name,
    userInfo?.address?.line1,
    userInfo?.address?.country,
    userInfo?.address?.postal_code,
    userInfo?.address?.city,
    setValue
  ])

  return {
    isLoading: formState.isSubmitting,
    onSubmit: handleSubmit(onSubmit),
    formState,
    control,
    getValues,
    register,
    trigger
  }
}
