import { Box } from '@mui/material'

import { Asset } from '@/types/assets'
import useWrapItemsWithComponent from '@/hooks/useWrapItemsWithComponent'
import { ReactNode } from 'react'

// TODO: Duplicate of src/pages/dashboard/QuickMatching/RetryLookupForm/RetryLookupDomainMessage.tsx
interface Props {
  asset: Asset
  children?: ({ asset, result }: { asset: Asset; result: ReactNode }) => ReactNode
}

interface DefaultMessageProps {
  result: ReactNode
  asset: Asset
}

const DefaultMessage = ({ result, asset }: DefaultMessageProps) => {
  return (
    <>
      Based on {asset.singleEnrichment.company}, we checked the following websites for associated
      work emails: {result}. <strong>Retry with the correct URL for better results!</strong>
    </>
  )
}

const RetryLookupCompanyMessage = ({ asset, children = DefaultMessage }: Props) => {
  const { wrapItemsWithComponent } = useWrapItemsWithComponent()

  const { urls } = asset.singleEnrichment
  if (urls.length === 0) return null

  const result = wrapItemsWithComponent(urls)

  return (
    <Box component="span" className="font-medium">
      <>{children({ asset, result })}</>
    </Box>
  )
}

export default RetryLookupCompanyMessage
