import { useAuth } from '@/reducers/user'
import { Alert } from '@mui/material'

const DeleteAlert = () => {
  const { isDeleted, resetIsDeleted } = useAuth()

  return isDeleted ? (
    <div className="flex justify-center mb-4">
      <Alert
        variant="filled"
        className="rounded-xl"
        onClose={() => {
          resetIsDeleted()
        }}
      >
        Your account has been successfully <b>deleted</b>
      </Alert>
    </div>
  ) : null
}

export default DeleteAlert
