import {
  Alert,
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  Paper,
  Radio,
  Typography,
  useTheme
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useUserPaymentInfo } from '@/pages/settings/billing/usePaymentMethods'
import { useEffect, useRef } from 'react'
import { useCards } from '@/pages/settings/billing/useCards'
import CreditCardComp from '@/pages/settings/billing/CreditCardComp'
import { CardBox } from '@/pages/settings/billing/CardBox'
import StripeAdd from '@/pages/settings/billing/stripeAdd'
import { PaymentMethod } from '@stripe/stripe-js'
import { PAGE } from '@/analytics'

const useAutoSelectCard = ({
  paymentMethods,
  defaultPaymentMethodId,
  setSelectedCard
}: {
  paymentMethods: PaymentMethod[] | undefined
  defaultPaymentMethodId: string | null | undefined
  setSelectedCard: (newSelectedCard: string | null) => unknown
}) => {
  useEffect(() => {
    if (!paymentMethods) {
      return
    }
    const foundPaymentMethod = paymentMethods.find((pm) => pm.id === defaultPaymentMethodId)

    if (foundPaymentMethod) {
      setSelectedCard(foundPaymentMethod.id)
      return
    }

    if (paymentMethods.length > 0 && defaultPaymentMethodId === null) {
      setSelectedCard(paymentMethods[0].id)
    }
  }, [paymentMethods, defaultPaymentMethodId, setSelectedCard])
}

const CreditCardSection = ({
  selectedCard,
  setSelectedCard,
  page,
  error
}: {
  selectedCard: string | null
  setSelectedCard: (newSelectedCard: string | null) => unknown
  page: PAGE
  error?: string | null
}) => {
  const { payload: userInfo, refetch: refreshUser } = useUserPaymentInfo()
  const ref = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const {
    isLoading,
    paymentMethods,
    defaultPaymentMethodId,
    onAdd,
    showAdd,
    clientSecret,
    onAddCancel,
    onAddSuccess
  } = useCards({ userInfo, refreshUser, page })

  useAutoSelectCard({ defaultPaymentMethodId, setSelectedCard, paymentMethods })

  useEffect(() => {
    if (error && ref.current) {
      const headerOffset = 32
      const elementPosition = ref.current.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }, [error])

  return (
    <Box ref={ref} className="flex flex-col gap-3 mt-8">
      <div className="flex justify-between">
        <Typography variant="h3">
          Select card
          {isLoading ? <CircularProgress size={20} sx={{ marginLeft: 2 }} /> : null}
        </Typography>
        {!showAdd && (
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            disabled={isLoading}
            onClick={onAdd}
          >
            Add new card
          </Button>
        )}
      </div>
      {!!error && <Alert severity="error">{error}</Alert>}
      {paymentMethods?.map((paymentMethod) => (
        <CardBox
          key={paymentMethod.id}
          sx={
            selectedCard == paymentMethod.id
              ? {
                  backgroundColor: theme.palette.background.default,
                  border: `2px solid ${theme.palette.primary.main}`
                }
              : {
                  borderWidth: '2px'
                }
          }
        >
          <CardActionArea
            onClick={() => {
              setSelectedCard(paymentMethod.id)
            }}
            disabled={isLoading}
            sx={{ display: 'flex', alignItems: 'flex-start' }}
          >
            <Radio
              disabled={isLoading}
              sx={{ mt: 3, ml: 1, mr: 1 }}
              checked={selectedCard === paymentMethod.id}
            />
            <Box sx={{ padding: '24px 32px 24px 0' }} className="flex-1">
              <CreditCardComp
                brand={paymentMethod?.card?.brand}
                last4={paymentMethod?.card?.last4}
                exp_year={paymentMethod?.card?.exp_year}
                exp_month={paymentMethod?.card?.exp_month}
                isDefault={defaultPaymentMethodId === paymentMethod.id}
              />
            </Box>
          </CardActionArea>
        </CardBox>
      ))}
      {paymentMethods?.length === 0 && (
        <Typography variant="caption" fontWeight={500}>
          No payment method added
        </Typography>
      )}

      {showAdd && clientSecret ? (
        <Paper sx={{ borderRadius: 3 }} elevation={3} className="p-6 md:p-8">
          <StripeAdd
            onReset={onAddCancel}
            clientSecret={clientSecret}
            disabled={isLoading}
            onSuccess={onAddSuccess}
          />
        </Paper>
      ) : null}
    </Box>
  )
}

export default CreditCardSection
