import { AccuracyStatus } from './accuracy-status.type'
import { LookupStatus } from './lookup-status.type'

type ObjectValues<T> = T[keyof T]

export enum AssetStatus {
  UPLOADED = 'uploaded',
  READY = 'ready',
  PROCESSING = 'processing',
  paid = 'paid',
  ERROR = 'error'
}

export const VerificationFlags = {
  RED_GREEN_CARD: 'Verified_Replaced_RED-GREEN',
  BLUE_GREEN_CARD: 'Verified_Replaced_BLUE-GREEN',
  GREEN_CARD: 'Verified_GREEN',
  RED_CARD: 'NOT-Verified_RED'
} as const

export const Reliabilities = {
  'Verified_Replaced_RED-GREEN': 'success',
  'Verified_Replaced_BLUE-GREEN': 'info',
  Verified_GREEN: 'success',
  'NOT-Verified_RED': 'error'
} as const

export const ReliabilitiesLabels = {
  Inaccurate: 'error',
  Accurate: 'success',
  Invalid: 'error',
  Probable: 'info'
} as const

interface AssetStatistics {
  enrichedEmails: number
  enrichedWorkEmails: number
  enrichedPhones: number
  hiddenEmails: number
  hiddenPhones: number
  highAccuracyWorkEmails: number
  highAccuracyEmails: number
  highAccuracyPhones: number
  lowAccuracyWorkEmails: number
  lowAccuracyEmails: number
  lowAccuracyPhones: number
  mediumAccuracyWorkEmails: number
  mediumAccuracyEmails: number
  mediumAccuracyPhones: number
  totalEnriched: number
  totalSubmitted: number
}

export const lookupTypes = {
  address: 'Address Lookup',
  company: 'Company Lookup',
  'verify-replace-work': 'Verify & Replace'
} as const

export type LookupRequestType = keyof typeof lookupTypes

export type VerificationFlag = ObjectValues<typeof VerificationFlags>

export interface Asset {
  _id: string
  filename: string
  fileKey?: string
  processedFileKey?: string
  calculatedPrice?: number
  rating?: number
  status: AssetStatus
  label?: string
  createdAt: string
  updatedAt: string
  requestPhones: boolean
  requestEmails: boolean
  verifyReplace: boolean
  requestWorkEmails: boolean
  requestPhonesHighAccuracy: boolean
  requestEmailsHighAccuracy: boolean
  requestedType: LookupRequestType
  statistics: AssetStatistics
  singleEnrichment: {
    isSingleEnrichment: boolean
    fullName: string // TODO: Ask Joao if this is always a string
    firstName?: string
    lastName?: string
    company: string
    enrichedEmail: string
    enrichedEmailAccuracy: AccuracyStatus
    enrichedEmailDeliverableStatus: LookupStatus
    enrichedWorkEmail: string
    enrichedWorkEmailAccuracy: AccuracyStatus
    enrichedWorkEmailDeliverableStatus: LookupStatus
    enrichedWorkEmail2?: string
    enrichedWorkEmail2Accuracy?: AccuracyStatus
    enrichedWorkEmail2DeliverableStatus: LookupStatus
    enrichedPhone: string
    enrichedPhoneAccuracy: AccuracyStatus
    linkedin?: string
    jobChange?: boolean
    companyWebsite?: string
    urls: string[]
    verification: VerificationFlag
    verificationStatus: string
    verificationLabel: keyof typeof ReliabilitiesLabels
    workEmail: string
  }
}

export interface AssetResponse {
  message: string
  asset: Asset
}

export interface AssetDownloadResponse {
  message: string
  url: string
}

export type AllowedAssetUpdateFields = Partial<
  Pick<
    Asset,
    | 'rating'
    | 'label'
    | 'requestPhones'
    | 'requestEmails'
    | 'requestPhonesHighAccuracy'
    | 'requestEmailsHighAccuracy'
  >
>

export interface Pagination {
  page: number
  pages: number
  totalAssets: number
}

export interface AssetFilter {
  query?: string
  minMatches?: string
  maxMatches?: string
  status?: any
  startDate?: string
  endDate?: string
  single?: boolean
  requestedType?: any
}
