import { BaseTooltip } from '@/components/Chip/Tooltips'
import { Box } from '@mui/material'

interface Props {
  children: string
  className?: string
  limit?: number
}

const TooltipFullText = ({ children, className = '!text-primary.main', limit = 500 }: Props) => {
  if (children.length <= limit) return <>{children}</>

  const text = children.slice(0, limit) + '...'

  return (
    <BaseTooltip title={children}>
      <Box className={className}>{text}</Box>
    </BaseTooltip>
  )
}

export default TooltipFullText
