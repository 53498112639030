import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useController, UseControllerProps, FieldValues, FormState } from 'react-hook-form'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import InputError from './InputError'
import formatPhoneNumber from '@/utils/phoneNumber'

export type FormInputProps<
  FieldValuesType extends FieldValues,
  T extends FieldValues = FieldValues
> = UseControllerProps<FieldValuesType> & {
  controlId?: string
  label?: string | React.ReactNode
  type?: string
  placeholder?: string
  helperText?: string
  className?: string
  variant?: 'phone'
  error?: string | React.ReactNode
  formState?: FormState<T>
  disabled?: boolean
  autoComplete?: string
  childComponent?: React.ReactNode
  tooltipText?: string
}

const FormInput = <FieldValuesType extends FieldValues>({
  control,
  name,
  rules,
  controlId,
  label,
  type = 'text',
  placeholder,
  helperText,
  error,
  variant,
  formState,
  disabled,
  autoComplete,
  className,
  childComponent
}: FormInputProps<FieldValuesType>) => {
  const errorMessage = error ?? (formState?.errors?.[name]?.message as string)

  const { field } = useController({ name, control, rules })
  const [showPass, setShowPass] = useState(false)

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    if (variant === 'phone') {
      value = formatPhoneNumber(e.target.value)
    }
    field.onChange(value)
  }

  return (
    <Form.Group className={`mb-3 ${className}`} controlId={controlId}>
      <div className="flex flex-row">
        {label && <Form.Label className="my-auto pb-3">{label}</Form.Label>}
        {childComponent}
      </div>
      <div className="c-form-input">
        <Form.Control
          {...field}
          autoComplete={autoComplete}
          disabled={disabled}
          isInvalid={!!errorMessage}
          type={type === 'password' ? (showPass ? 'text' : 'password') : type}
          placeholder={placeholder}
          onChange={handleInput}
        />
        {type === 'password' &&
          (showPass ? (
            <AiOutlineEye className="c-form-input__icon" onClick={() => setShowPass(!showPass)} />
          ) : (
            <AiOutlineEyeInvisible
              className="c-form-input__icon"
              onClick={() => setShowPass(!showPass)}
            />
          ))}
      </div>
      {!!errorMessage && <InputError error={errorMessage} />}
      {helperText && <Form.Text className="text-muted text-xs">{helperText}</Form.Text>}
    </Form.Group>
  )
}

export default FormInput
