import { Link } from 'react-router-dom'
import { styled } from '@mui/material'
import { ReactComponent as LeftArrowIcon } from '@/assets/icons/ic-left-arrow-2.svg'

const BackIcon = styled(LeftArrowIcon)({
  marginRight: '8px',
  paddingBottom: '2px'
})

const StyledLink = styled(Link)(({ theme }) => ({
  fill: theme.palette.primary.main,
  color: theme.palette.primary.main,
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '24px'
}))

interface Props {
  children: React.ReactNode
}

const BackLink = ({ children }: Props) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <StyledLink to="/auth/sign-in" style={{ textDecoration: 'none' }}>
        <BackIcon />
        {children}
      </StyledLink>
    </div>
  )
}

export default BackLink
