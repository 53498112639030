import { toNumber } from 'lodash'
import { useCallback } from 'react'

function useHandlePageChange(totalPage: number, setPageNumber: (pageNumber: number) => void) {
  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const inputValue = event.target.value.trim()

      if (inputValue === '') return

      let selectedPageNumber = toNumber(inputValue)
      selectedPageNumber = Math.max(1, Math.min(selectedPageNumber, totalPage))

      setPageNumber(selectedPageNumber)
    },
    [totalPage, setPageNumber]
  )
}

export default useHandlePageChange
