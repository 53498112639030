import { trackEvent } from '@/analytics'
import StateAlert from '@/components/Alert/StateAlert'

import { createPaymentForBillingSheet } from '@/services/payments'
import { BillingSheet } from '@/types/billing'
import { Button } from '@mui/material'
import { useStripe } from '@stripe/react-stripe-js'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import QuickPayButton from './QuickPayButton'

const ConfirmPayment = ({
  billingSheet,
  validateSelectedCard,
  selectedCard
}: {
  billingSheet: BillingSheet
  validateSelectedCard: () => boolean
  selectedCard: string | null
}) => {
  const [paymentFailed, setPaymentFailed] = useState(false)
  const [paymentProcessing, setPaymentProcessing] = useState(false)

  const stripe = useStripe()

  const navigate = useNavigate()

  const maybeClientSecret = billingSheet?.transaction?.payment.client_secret

  const confirmPayment = useCallback(
    async (clientSecret: string, paymentMethodId?: string) => {
      if (!stripe) return

      const options = paymentMethodId ? { payment_method: paymentMethodId } : undefined
      const confirmCardPaymentResult = await stripe.confirmCardPayment(clientSecret, options)

      if (!confirmCardPaymentResult.error) navigate('/billing', { state: { status: 'success' } })
      else setPaymentFailed(true)

      setPaymentProcessing(false)
    },
    [navigate, stripe]
  )

  const createPaymentIntent = useCallback(
    async (paymentMethodId: string) => {
      const { data: paymentIntent } = await createPaymentForBillingSheet(billingSheet._id, {
        paymentMethodId
      })
      return paymentIntent
    },
    [billingSheet._id]
  )

  const requestPayment = useCallback(
    async (paymentMethodId: string) => {
      if (!stripe) return

      if (maybeClientSecret) {
        await confirmPayment(maybeClientSecret, paymentMethodId)
        return
      }

      const { client_secret } = await createPaymentIntent(paymentMethodId)

      await confirmPayment(client_secret as string)
    },
    [confirmPayment, createPaymentIntent, maybeClientSecret, stripe]
  )

  const onPayNow = useCallback(async () => {
    if (!selectedCard) {
      validateSelectedCard()
      return
    }

    trackEvent('Makes Enrichment Payment', {
      page: 'Payment',
      amount: billingSheet.amount,
      totalSubmitted: billingSheet.amount
    })

    setPaymentProcessing(true)
    setPaymentFailed(false)

    await requestPayment(selectedCard)
  }, [selectedCard, billingSheet, requestPayment, validateSelectedCard])

  return (
    <div className="flex flex-col gap-8 flex-1">
      {paymentFailed && (
        <StateAlert
          className="bg-[#FFD5D5] border-danger"
          title="Payment Failed"
          message="Please review your payment details and try again."
          onClose={() => {
            setPaymentFailed(false)
          }}
          severity="error"
        />
      )}
      <div className="flex justify-between">
        <QuickPayButton amount={billingSheet.amount} createPaymentIntent={createPaymentIntent} />
        <Button
          variant="contained"
          size="large"
          className="float-right"
          onClick={onPayNow}
          disabled={paymentProcessing}
        >
          Pay now
        </Button>
      </div>
    </div>
  )
}

export default ConfirmPayment
