import { api } from '@/providers/ApiProvider'
import { User } from '@/types/user'
import useSWR from 'swr'
import { getInfo } from '@/services/auth'
import { atom, useAtom, useSetAtom } from 'jotai'
import axios, { AxiosError } from 'axios'
import { useCallback } from 'react'

const UNAPPROVED_USER_ALLOWED_ENRICHMENTS = 3

interface AuthContextValues {
  user?: User | null
  isLoading: boolean
  revalidate: (deleted?: boolean) => Promise<User | undefined>
  isDeleted: boolean
  resetIsDeleted: () => unknown
}

const userAtom = atom(undefined, (_, set, payload) => {
  set(userAtom, payload)
})

userAtom.onMount = (set) => {
  getInfo()
    .then((response) => {
      set(response.data)
    })
    .catch((error) => {
      if (!axios.isAxiosError(error)) {
        throw error
      }
      if (error.response?.status === 401 || error.code === AxiosError.ERR_NETWORK) {
        set(null)
        return
      }

      throw error
    })
}

const fetcher = async (url: string) => {
  try {
    const result = await api.get<User>(url)
    return result.data
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error
    }
    if (error.response?.status === 401 || error.code === AxiosError.ERR_NETWORK) {
      return undefined
    }

    throw error
  }
}

const isDeletedAtom = atom(false)

export const useAuth = (): AuthContextValues => {
  const authInfo = useSWR(`/auth/info`, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false
  })
  const [isDeleted, setIsDeleted] = useAtom(isDeletedAtom)

  const revalidate = useCallback((deleted = false) => {
    if (deleted) {
      setIsDeleted(true)
    } else {
      setIsDeleted(false)
    }

    return authInfo.mutate()
  }, [])

  const resetIsDeleted = useCallback(() => {
    setIsDeleted(false)
  }, [])

  return {
    isLoading: authInfo.isLoading,
    user: authInfo.data,
    revalidate: revalidate,
    isDeleted,
    resetIsDeleted
  }
}

export const useSignIn = () => {
  const setAtom = useSetAtom(userAtom)
  const { revalidate } = useAuth()

  return (user: User) => {
    revalidate()
    setAtom(user)
  }
}

export const useSignOut = () => {
  const { revalidate } = useAuth()

  return () => {
    revalidate()
  }
}

export const useIsCurrentUserActivated = () => {
  const { user } = useAuth()

  return user?.activatedAt !== null
}

const getIsVerified = async () => {
  const response = await api.get<{ isVerified: boolean }>('/user/is-verified')

  return response.data
}

export const useIsVerifiedUpdates = (): boolean | undefined => {
  const isUserActivated = useIsCurrentUserActivated()

  const payload = useSWR(isUserActivated ? null : `/user/is-verified`, getIsVerified, {
    refreshInterval: 5000
  })

  return payload.data?.isVerified
}

export const useCanCurrentUserEnrich = () => {
  const { user } = useAuth()

  const isUserActivated = useIsCurrentUserActivated()

  if (isUserActivated) {
    return true
  }

  const totalSubmitted = user?.statistics?.totalSubmitted || 0

  if (totalSubmitted >= UNAPPROVED_USER_ALLOWED_ENRICHMENTS) {
    return false
  }

  return true
}
