import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'

import { ReactComponent as IcCircleInfo } from '@/assets/icons/ic-circle-info.svg'

const InfoIcon = styled(IcCircleInfo)(
  ({ theme }) => `
    fill: ${theme.palette.text.quaternary};
    height: 16px;
    width: 16px;
    margin-left: 0.5rem;
  `
)

const NumberOfResults = () => {
  const tooltipContent =
    'Our machine learning algorithms source and analyze contact information from a variety of sources to match with the records you share with us. To distinguish the quality of the results, we assign a probability score to the likelihood that each match actually belongs to the person in question. Even a “low” or “medium” match has a good chance of being correct, but we use these terms to transparently share our confidence in the results.'

  return (
    <Typography mt={2} fontWeight={700} color="text.quaternary">
      Number of results & accuracy
      <Tooltip title={tooltipContent} arrow placement="right">
        <InfoIcon />
      </Tooltip>
    </Typography>
  )
}

export default NumberOfResults
