import { trackEvent } from '@/analytics'
import TransactionInvoice from '@/components/Payments/TransactionInvoice'
import { PaymentHistoryFilter } from '@/types/billing'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TuneIcon from '@mui/icons-material/Tune'
import {
  Badge,
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useHandlePageChange from '../../history/handlePageChange'
import FilterBar from './FilterBar'
import MobileFilterBar from './MobileFilterBar'
import MobileTransactionCard from './MobileTransactionCard'
import useTransactionsTable from './transactionsTable'

const Th = styled('th')(({ theme }) => ({
  fontSize: '12px',
  padding: '1rem 0.5rem',
  color: theme.palette.text.quaternary,
  fill: theme.palette.text.quaternary
}))

const usePaymentHistory = () => {
  const { handleSubmit, control, watch, getValues, reset } = useForm<PaymentHistoryFilter>()

  const onSubmit = (values: PaymentHistoryFilter) => {
    trackEvent('Filters history', { page: 'Billing', params: values })
    fetchTransactions(values)
  }

  useEffect(() => {
    const subscription = watch(
      debounce((data) => {
        return onSubmit(data)
      }, 350)
    )
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  const [pageNumber, setPageNumber] = useState(1)
  const [perPageRows, setPerPageRows] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [totalResults, setTotalResults] = useState(0)

  const { allTransactions, columns, fetchTransactions } = useTransactionsTable({
    pageNumber,
    perPageRows,
    setTotalPage,
    setTotalResults
  })

  const table = useReactTable({
    data: allTransactions,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return {
    control,
    table,
    totalPage,
    pageNumber,
    perPageRows,
    setPerPageRows,
    setPageNumber,
    empty: allTransactions?.length === 0,
    allTransactions,
    totalResults,
    hasFilter: !!getValues().endDate,
    reset
  }
}

const PaymentHistory = () => {
  const {
    control,
    table,
    totalPage,
    pageNumber,
    perPageRows,
    setPerPageRows,
    setPageNumber,
    empty,
    allTransactions,
    hasFilter,
    reset
  } = usePaymentHistory()

  const theme = useTheme()

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false)

  const toggleMobileDrawer = () => {
    setIsMobileDrawerOpen((current) => !current)
  }

  const handlePageChange = useHandlePageChange(totalPage, setPageNumber)

  return (
    <section id="payment-history">
      <Box className="flex justify-between mb-3 md:mb-6" mt={5}>
        <Typography variant="h2">Payment history</Typography>

        <IconButton className="md:hidden float-right" onClick={toggleMobileDrawer}>
          <Badge variant="dot" invisible={!hasFilter} color="primary">
            <TuneIcon className="fill-primary.main" />
          </Badge>
        </IconButton>
      </Box>

      <FilterBar control={control}>
        <TransactionInvoice
          billingSheetsId={allTransactions
            ?.filter((transaction) => transaction.status === 'success')
            .map((transaction) => transaction.billingSheetId)}
          isDisabled={false}
          title="Download all"
        />
      </FilterBar>
      <div className="md:hidden md:mb-8">
        <MobileFilterBar
          onReset={() => {
            reset()
          }}
          open={isMobileDrawerOpen}
          toggleMobileDrawer={toggleMobileDrawer}
          control={control}
        />
      </div>

      {empty ? (
        <Box className="history-list history-section d-flex flex-column align-items-center justify-content-center mt-6 border border-[background.tertiary]">
          <Typography variant="h2" textAlign={'center'}>
            No Transactions Yet
          </Typography>
          <p className="max-w-[340px] text-center">
            After your first transaction, you will be able to view the payment details here.
          </p>
        </Box>
      ) : (
        <>
          <div className="flex gap-4 flex-col md:hidden">
            {allTransactions?.map((transaction) => (
              <MobileTransactionCard key={transaction._id} transaction={transaction} />
            ))}
          </div>
          <Box
            className="mt-2 p-4 md:-mx-4 md:p-6"
            sx={{
              background: theme.palette.background.secondary,
              borderRadius: 6,
              border: `1px solid ${theme.palette.background.tertiary}`
            }}
          >
            <table className="hidden md:table w-full">
              <thead className="my-3">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </Th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className="odd:bg-slate-100">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="py-3 px-2">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="-ml-4 md:mt-2"
            >
              <Stack direction="row" alignItems="center">
                <IconButton
                  color="primary"
                  size="medium"
                  aria-label="prev page"
                  component="label"
                  onClick={() => setPageNumber((current) => Math.max(current - 1, 1))}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <TextField
                  sx={{ maxWidth: 52 }}
                  size="small"
                  value={pageNumber}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: { totalPage }
                    },
                    sx: { input: { textAlign: 'center' } }
                  }}
                  onChange={handlePageChange}
                />
                <Typography variant="subtitle2" className="pl-2">
                  / {totalPage}
                </Typography>
                <IconButton
                  color="primary"
                  size="medium"
                  aria-label="prev page"
                  component="label"
                  onClick={() => setPageNumber((current) => Math.min(current + 1, totalPage))}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
                  Item per page
                </Typography>
                <Select
                  sx={{ width: '100%' }}
                  value={perPageRows}
                  onChange={(event) => setPerPageRows(event.target.value as number)}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Box>
        </>
      )}
    </section>
  )
}

export default PaymentHistory
