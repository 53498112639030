import { SvgIcon } from '@mui/material'
import ArcherChip from './ArcherChip'
import { ReactComponent as WarningIcon } from '@/assets/icons/ic-warning.svg'
import { BaseTooltip } from './Tooltips'
import useIsMobile from '@/utils/isMobile'

const SuspectedEmployerChip = () => {
  const isMobile = useIsMobile()

  const tooltipPlacement = isMobile ? 'top' : 'right'

  return (
    <BaseTooltip
      title="The individual you're searching for appears to have changed jobs, and the company name you provided might not reflect their latest employer. Below, we've provided the contact information for their suspected current company."
      placement={tooltipPlacement}
    >
      <ArcherChip
        className="text-sm ml-2"
        label={'Suspected Employer Switch'}
        color="error"
        variant="outlined"
        size="small"
        icon={
          <SvgIcon viewBox="0 0 25 22" className="ml-2 h-3 w-3">
            <WarningIcon />
          </SvgIcon>
        }
      />
    </BaseTooltip>
  )
}

export default SuspectedEmployerChip
