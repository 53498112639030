import { ReactComponent as SadFaceIcon } from '@/assets/icons/ic-sad-face.svg'
import { Asset } from '@/types/assets'
import { Alert, SvgIcon, Typography } from '@mui/material'
import NewLookup from './NewLookup'
import RetryLookupForm from './RetryLookupForm/RetryLookupForm'

const NoResultCompanyRetry = ({
  onRestart,
  asset
}: {
  onRestart: (newAssetId?: string) => unknown
  asset: Asset
}) => {
  if (!asset.singleEnrichment?.isSingleEnrichment) {
    return null
  }

  return (
    <div className="mt-8 flex flex-col gap-8">
      <div className="flex items-center">
        <Alert
          severity="warning"
          icon={
            <SvgIcon viewBox="0 0 16 16" className="w-5 h-5 mt-0.5">
              <SadFaceIcon />
            </SvgIcon>
          }
          sx={{
            borderRadius: '8px',
            borderColor: 'warning.main',
            borderWidth: '1px',
            borderStyle: 'solid',
            backgroundColor: '#FFEEC3'
          }}
        >
          <Typography fontWeight={500}>
            Sorry, we couldn&apos;t find any contact information for this individual.
          </Typography>
        </Alert>
      </div>
      <div className="flex flex-col gap-3">
        <RetryLookupForm asset={asset} onRestart={onRestart} />
      </div>
      <NewLookup onRestart={onRestart} />
    </div>
  )
}

export default NoResultCompanyRetry
