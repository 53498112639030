import { Box, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import TransactionAmount from '@/components/Payments/TransactionAmount'
import TransactionStatusPill from '@/components/Payments/TransactionStatusPill'
import TransactionInvoice from '@/components/Payments/TransactionInvoice'
import { CardIcon } from '@/pages/settings/billing/CreditCardComp'
import { Transaction } from '@/types/transactions'

const MobileTransactionCard = ({ transaction }: { transaction: Transaction }) => {
  const typeTitle = transaction.type === 'one-time' ? 'One time payment' : 'Monthly payment'
  console.log(transaction)
  const date = new Date(transaction.createdAt)

  return (
    <div className="bg-white rounded-xl border border-background.tertiary">
      <div className="px-6 py-4 flex justify-between items-center">
        <div>
          <Typography
            variant="body1"
            fontWeight={700}
            color="text.primary"
            textTransform="capitalize"
          >
            {typeTitle}
          </Typography>
          <Typography variant="caption" fontWeight={500} color="text.quaternary">
            {DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_SHORT)}
          </Typography>
        </div>
        <div className="flex-1" />
        <div>
          <TransactionInvoice
            billingSheetsId={[transaction.billingSheetId]}
            isDisabled={transaction.status !== 'success'}
            title="Invoice"
          />
        </div>
      </div>

      <div className="w-full border border-t-0 border-r-0 border-l-0 border-background.tertiary"></div>

      <div className="px-6 py-4 flex justify-between items-center">
        <Box display="flex" gap={2} alignItems="center">
          <div>
            <CardIcon size="small" brand={transaction.paymentMethodBrand} />
          </div>
          <Typography variant="caption" fontWeight={700}>
            <span style={{ letterSpacing: 0, fontSize: 20 }}>••••&nbsp;</span>
            {transaction.paymentMethodLast4}
          </Typography>
        </Box>

        <div>
          <TransactionStatusPill status={transaction.status} />
        </div>
        <div>
          <TransactionAmount
            finalAmount={transaction.amountReceived}
            initialAmount={transaction.cost}
          />
        </div>
      </div>
    </div>
  )
}

export default MobileTransactionCard
