/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AxiosError } from 'axios'

export type ServerError = {
  value: string
  msg: string
  param: string
  location: string
}

export const formatError = (
  error?: string | ServerError,
  field: string | undefined = undefined
) => {
  if (typeof error === 'string') {
    if (!field) {
      return error
    }

    return undefined
  }

  if (error && error.msg && error.param && !field) {
    return `${error.msg} at ${error.param}`
  }

  if (error && error.msg && error.param && error.param === field) {
    return `${error.msg} at ${error.param}`
  }

  return undefined
}

export const isUserNotActivatedError = (error: AxiosError) => {
  if (
    error.response?.status === 403 &&
    // @ts-ignore
    Array.isArray(error.response?.data?.errors) &&
    // @ts-ignore
    error.response?.data?.errors.includes('User not activated')
  ) {
    return true
  }

  return false
}
