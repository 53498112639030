import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Typography,
  styled,
  useTheme,
  Alert
} from '@mui/material'
import { ReactComponent as TrashIcon } from '@/assets/icons/ic-delete.svg'
import { useCallback, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import PreventDeleteAlert from '@/components/Alert/PreventDeleteAlert'
import { useBillingTracker } from '@/pages/dashboard/hooks'
import { deleteUser } from '@/services/auth'
import { useAuth } from '@/reducers/user'
import { trackEvent } from '@/analytics'
import { AxiosError } from 'axios'

const DeleteAccountButton = styled(Button)(({ theme }) => ({
  '& svg': {
    fill: theme.palette.error.main
  }
}))

const DeleteAccountModal = ({
  open,
  onClose,
  canCloseAccount
}: {
  canCloseAccount: boolean
  open: boolean
  onClose: () => unknown
}) => {
  const theme = useTheme()
  const { revalidate } = useAuth()
  const [error, setError] = useState<string | null>(null)

  const onConfirm = useCallback(async () => {
    try {
      await deleteUser()

      revalidate(true)
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 500) {
        setError('The user can not be deleted. Please try again later.')
      }
    }
  }, [])

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        className="rounded-none min-[480px]:rounded-3xl"
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'min(480px, 100%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          border: `1px solid ${theme.palette.background.tertiary}`
        }}
      >
        <Box sx={{ pt: 4, px: 3 }}>
          <div className="flex items-start">
            <Typography variant="h2">
              Are you sure you want to permanently delete your account?
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {error && (
            <Alert className="mt-3" severity="error">
              {error}
            </Alert>
          )}
          <Typography sx={{ mt: 3, mb: 4 }} fontWeight={500} color="text.quaternary">
            All of your previous enrichments will be deleted from our system and you will not be
            able to recover them. Additionally, you won&apos;t be able to use this email to sign up
            again in the future. Please download any result files you need first.
          </Typography>
        </Box>
        <Box sx={{ borderTop: `1px solid ${theme.palette.background.tertiary}`, px: 3, py: 1.5 }}>
          {!canCloseAccount && (
            <Box mb={1.5}>
              <PreventDeleteAlert />
            </Box>
          )}

          <div className="flex justify-between">
            <Button onClick={onClose} size="large">
              Cancel
            </Button>
            <Button
              onClick={() => {
                onConfirm()
                trackEvent('Confirms account deletion', { page: 'Profile Settings' })
              }}
              color="error"
              size="large"
              variant="contained"
              disabled={!canCloseAccount}
            >
              Delete account
            </Button>
          </div>
        </Box>
      </Card>
    </Modal>
  )
}

const DeleteAccount = () => {
  const { data: billingTracker, isLoading } = useBillingTracker()

  const canCloseAccount =
    typeof billingTracker?.userBalance === 'number' && billingTracker.userBalance <= 0

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const closeModal = () => setIsConfirmationOpen(false)
  const openModal = () => setIsConfirmationOpen(true)

  return (
    <>
      {!canCloseAccount && isLoading === false && <PreventDeleteAlert />}
      <DeleteAccountButton
        disabled={isLoading}
        startIcon={<TrashIcon className="h-4 text-red-500" />}
        color="error"
        size="large"
        onClick={() => {
          openModal()
          trackEvent('Delete my account', { page: 'Profile Settings' })
        }}
      >
        Delete my account
      </DeleteAccountButton>
      <DeleteAccountModal
        open={isConfirmationOpen}
        canCloseAccount={canCloseAccount}
        onClose={closeModal}
      />
    </>
  )
}

export default DeleteAccount
