import { api } from '@/providers/ApiProvider'
import { PaymentHistoryFilter } from '@/types/billing'
import { Transaction } from '@/types/transactions'

const getTransactions = (page: number, limit: number, filter: PaymentHistoryFilter = {}) => {
  return api.get<{
    message: string
    transactions: Transaction[]
    pagination: {
      page: number
      pages: number
      total: number
    }
  }>('/payments/transactions', {
    params: {
      page,
      limit,
      ...filter
    }
  })
}

export { getTransactions }
