import { LookupResultChip } from '@/components/common/LookupResultChip'
import { AccuracyStatus } from '@/types/accuracy-status.type'
import { LookupStatus } from '@/types/lookup-status.type'

interface Props {
  accuracy?: AccuracyStatus
  status?: LookupStatus
}

const AccuracyChip = ({ accuracy, status }: Props) => {
  return <LookupResultChip accuracy={accuracy} status={status} />
}

export default AccuracyChip
