import { formatMoney } from '@/utils'
import { Tooltip, Typography, TypographyProps, styled } from '@mui/material'
import { useMemo } from 'react'
import { ReactComponent as IcCircleInfo } from '@/assets/icons/ic-circle-info.svg'

const InfoIcon = styled(IcCircleInfo)(({ theme }) => ({
  fill: theme.palette.text.quaternary,
  marginBottom: 2,
  marginLeft: 10,
  height: 16,
  width: 16
}))

export const TransactionAmount = ({
  initialAmount,
  finalAmount,
  variant = 'caption',
  fontWeight = 700
}: {
  initialAmount: number
  finalAmount?: number
  variant?: TypographyProps['variant']
  fontWeight?: TypographyProps['fontWeight']
}) => {
  const showInfo = typeof finalAmount === 'number' && !!(initialAmount > finalAmount)
  const shownAmount = typeof finalAmount == 'number' ? finalAmount : initialAmount
  const isCanceled = typeof finalAmount === 'number' && finalAmount === 0

  const tooltip = useMemo(() => {
    if (!(typeof finalAmount === 'number')) {
      return undefined
    }

    if (isCanceled) {
      return `Initial transaction was for ${formatMoney(
        initialAmount
      )}, but after processing we canceled the transaction. Funds will be returned to customer.`
    }

    return `Initial transaction was for ${formatMoney(
      initialAmount
    )}, but after processing we charged you ${formatMoney(finalAmount)}.`
  }, [finalAmount, initialAmount, isCanceled])

  return (
    <Typography variant={variant} fontWeight={fontWeight}>
      {formatMoney(shownAmount)}
      {showInfo && (
        <Tooltip title={tooltip} placement="top">
          <InfoIcon />
        </Tooltip>
      )}
    </Typography>
  )
}

export default TransactionAmount
