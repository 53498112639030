import { useAuth } from '@/reducers/user'
import { ROLES } from '@/types/user'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { withAdminPanelGuard } from '@/utils/hoc/withAdminPanelGuard'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { useProfileAdminPanel } from '../settings/profile/AdminPanel'

const fakeRow = {
  address: '127 Hanover St',
  city: 'Pemberton',
  firstName: 'barbara',
  lastName: 'lind',
  fullName: 'barbara lind',
  state: 'NJ',
  zipCode: '8068',
  company: 'Meta'
}

const AdminPanel = ({
  onComplete
}: {
  onComplete: (filename: string, rows: any) => Promise<unknown>
}) => {
  const { user } = useAuth()
  const [numberOfRows, setNumberOfRows] = useState(600)
  const {
    mockTotalEnriched,
    setMockTotalEnriched,
    handleSubmit: handleProfileUpdates
  } = useProfileAdminPanel()

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    handleProfileUpdates()

    const rows = []
    for (let i = 0; i < numberOfRows; i += 1) {
      rows.push(fakeRow)
    }
    onComplete(`mock-${numberOfRows}.csv`, rows)
  }

  if (user?.role.name !== ROLES.ADMIN) {
    return null
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h2">Admin panel</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <form onSubmit={onSubmit} className="flex flex-col gap-12">
          <p>Mock file upload size (bypass flatfile and send a file of {numberOfRows} rows)</p>
          <div className="flex gap-2">
            <TextField
              type="number"
              variant="outlined"
              label="Number of records"
              value={numberOfRows}
              onChange={(event) => setNumberOfRows(parseInt(event.target.value, 10))}
            />
          </div>

          <div className="flex gap-2">
            <TextField
              type="number"
              variant="outlined"
              label="Number of matches"
              value={mockTotalEnriched}
              onChange={(event) => setMockTotalEnriched(parseInt(event.target.value, 10))}
            />
          </div>

          <Button variant="contained" size="large" type="submit">
            Submit
          </Button>
        </form>
      </AccordionDetails>
    </Accordion>
  )
}

export default withAdminPanelGuard(AdminPanel)
