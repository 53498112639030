import { stripePromise, useStripeAppearance } from '@/utils/payments'
import { Box, Button, SxProps } from '@mui/material'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useState } from 'react'

const DefaultControls = ({ disabled }: { disabled: boolean }) => {
  return (
    <>
      <Button variant="contained" size="large" type="submit" disabled={disabled}>
        Save
      </Button>
      <Button variant="outlined" size="large" type="reset">
        Cancel
      </Button>
    </>
  )
}

const StripeForm = ({
  onReset,
  onSuccess,
  disabled,
  className,
  renderControls
}: {
  onSuccess: () => unknown
  onReset: () => unknown
  className?: string
  disabled?: boolean
  renderControls?: (props: React.ComponentProps<typeof DefaultControls>) => React.ReactNode
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required'
      //   confirmParams: {
      //     return_url: ''
      //   }
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      if (setupIntent.status === 'succeeded') {
        onSuccess()
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} onReset={onReset} className={className}>
      <PaymentElement className="flex-1" />
      <Box display="flex" mt={2} justifyContent="space-between">
        {renderControls ? (
          renderControls({ disabled: !!disabled })
        ) : (
          <DefaultControls disabled={!stripe || !elements || !!disabled} />
        )}
      </Box>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
}

const StripeAdd = ({
  clientSecret,
  onReset,
  disabled,
  onSuccess,
  renderControls,
  className
}: {
  onSuccess: () => unknown
  disabled?: boolean
  clientSecret: string
  className?: string
  onReset: () => unknown
  sx?: SxProps
  renderControls?: React.ComponentProps<typeof StripeForm>['renderControls']
}) => {
  const appearance = useStripeAppearance()

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: clientSecret,
        appearance: appearance
      }}
    >
      <StripeForm
        onReset={onReset}
        disabled={disabled}
        onSuccess={onSuccess}
        renderControls={renderControls}
        className={className}
      />
    </Elements>
  )
}

export default StripeAdd
