import { ReactComponent as InfoIcon } from '@/assets/icons/ic-circle-info.svg'
import HowPaymentWorks from '@/components/Modal/howPaymentWorksModal'
import ItemsTable from '@/components/Payments/Summary/ItemsTable'
import TotalRow from '@/components/Payments/Summary/TotalRow'
import { useBillingTracker } from '@/pages/dashboard/hooks'
import { BillingSheet } from '@/types/billing'
import { formatDate } from '@/utils'
import ReplayIcon from '@mui/icons-material/Replay'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'

const Header = ({ billingSheet }: { billingSheet: BillingSheet }) => {
  let title = 'Purchase Summary'

  if (billingSheet.transaction?.status === 'failed') {
    title = 'Retry Payment'
  }

  if (billingSheet.transaction?.status === 'canceled') {
    title = 'Transaction canceled'
  }

  return (
    <div className="flex flex-wrap items-center justify-between mb-6 gap-6">
      <Typography variant="h1">{title}</Typography>
    </div>
  )
}

const Subheader = ({ billingSheet }: { billingSheet: BillingSheet }) => {
  const { data: billingTracker } = useBillingTracker()

  const renderAdditionalInfo = () => {
    if (
      billingSheet.transaction?.status === 'success' ||
      billingSheet.transaction?.status === 'canceled'
    ) {
      return null
    }

    if (billingSheet.transaction?.type === 'monthly') {
      return (
        <>
          Type: <b>Monthly payment</b>
        </>
      )
    }

    return <>{/* Payment due: <b>{DateTime.now().toLocaleString(DateTime.DATE_SHORT)}</b> */}</>
  }

  return (
    <div className="flex flex-wrap gap-x-8 md:gap-x-12">
      <Typography variant="body1" color="text.quaternary">
        <b>Billing cycle ending on:</b>
        &nbsp;
        {billingTracker && (
          <span title="title">
            {formatDate(billingTracker.next_billing_cycle, DateTime.DATE_SHORT)}
          </span>
        )}
      </Typography>
      <Typography variant="body1" color="text.quaternary">
        {renderAdditionalInfo()}
      </Typography>
    </div>
  )
}

const InfoBox = ({ billingSheet }: { billingSheet: BillingSheet }) => {
  const theme = useTheme()

  const renderContent = () => {
    if (billingSheet.transaction?.status === 'failed') {
      return (
        <Typography variant="body1" mb={2}>
          <InfoIcon className="mb-1 mr-3" />
          We were unable to process your payment. <br />
          Please retry confirming your payment.
        </Typography>
      )
    }

    if (billingSheet.transaction?.status === 'canceled') {
      return (
        <Typography variant="body1" mb={0}>
          <ReplayIcon className="mb-1 mr-3 fill-background.secondary bg-primary.main rounded-full p-0.5" />
          Transaction canceled. Funds will be returned to customer.
        </Typography>
      )
    }

    return (
      <Typography variant="body1" mb={2} className="flex">
        <Box>
          <InfoIcon className="mb-1 mr-3" />
        </Box>
        <Box>Once payment has been settled, you will get access to your enriched file.</Box>
      </Typography>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.tertiary,
        p: 2,
        borderRadius: 2,
        mt: 4
      }}
    >
      {renderContent()}
      <HowPaymentWorks />
    </Box>
  )
}

const PaymentSummary = ({ billingSheet }: { billingSheet: BillingSheet }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: theme.palette.background.secondary,
        alignSelf: 'flex-start',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 6,
        zIndex: 1,
        flex: 1,
        position: 'relative'
      }}
    >
      <Header billingSheet={billingSheet} />

      <Subheader billingSheet={billingSheet} />

      <Divider sx={{ border: `1px solid ${theme.palette.divider}`, my: 3 }} />

      <TotalRow billingSheet={billingSheet} />

      <Divider sx={{ border: `1px solid ${theme.palette.divider}`, my: 3 }} />

      <ItemsTable billingSheet={billingSheet} />

      <InfoBox billingSheet={billingSheet} />
    </Box>
  )
}

export default PaymentSummary
