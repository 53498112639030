import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import { ReactComponent as IconMinus } from '@/assets/icons/ic-minus.svg'
import NumberInput from '@/components/Input/NumberInput'
import DateInput from '@/components/Input/DateInput'
import { IconButton, SwipeableDrawer } from '@mui/material'
import { ReactComponent as DeleteIcon } from '@/assets/icons/ic-delete.svg'
import CloseIcon from '@mui/icons-material/Close'
import { ComponentProps as BaseComponentProps } from './FilterBar'
import { ResetButton } from '@/pages/history/FilterBar'

interface ComponentProps extends BaseComponentProps {
  open: boolean
  toggleMobileDrawer: () => void
  onReset: () => unknown
}

const MobileFilterBar: React.FC<ComponentProps> = ({
  onReset,
  open,
  toggleMobileDrawer,
  control
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleMobileDrawer}
      onOpen={toggleMobileDrawer}
      sx={{
        width: '100%',
        height: '100%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
          height: '100%',
          boxSizing: 'border-box'
        }
      }}
    >
      <form className="p-6 flex flex-col gap-6" onReset={onReset}>
        <Typography variant="h1" color="primary.main">
          Filters
          <IconButton size="large" className="float-right" onClick={toggleMobileDrawer}>
            <CloseIcon className="fill-primary.main" />
          </IconButton>
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 3 }} mt={2}>
          <Box flex="1 1 0">
            <InputLabel sx={{ mb: 1 }}>DATE OF PAYMENT</InputLabel>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DateInput
                placeholder={`31.12.${new Date().getFullYear()}`}
                sx={{ flexGrow: 1 }}
                control={control}
                name="startDate"
              />
              <Typography color="primary.main" sx={{ textDecoration: 'line-through', mx: '12px' }}>
                <SvgIcon component={IconMinus} inheritViewBox sx={{ fontSize: 16 }} />
              </Typography>
              <DateInput
                placeholder={`31.12.${new Date().getFullYear()}`}
                sx={{ flexGrow: 1 }}
                control={control}
                name="endDate"
              />
            </Box>
          </Box>

          <Box flex="1 1 0">
            <InputLabel sx={{ mb: 1 }}>AMOUNT</InputLabel>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <NumberInput
                control={control}
                placeholder="Min."
                sx={{ flexGrow: 1 }}
                name="minAmount"
              />
              <Typography color="primary.main" sx={{ textDecoration: 'line-through', mx: '12px' }}>
                <SvgIcon component={IconMinus} inheritViewBox sx={{ fontSize: 16 }} />
              </Typography>
              <NumberInput
                control={control}
                placeholder="Max."
                sx={{ flexGrow: 1 }}
                name="maxAmount"
              />
            </Box>
          </Box>
        </Stack>
        <div className="flex justify-between">
          <ResetButton
            type="reset"
            startIcon={<DeleteIcon />}
            // hidden={requestedType.length === 0 && !isDirty}
          >
            Clear filters
          </ResetButton>
          <Button
            variant="contained"
            onClick={() => {
              toggleMobileDrawer()
            }}
            size="large"
          >
            Apply filters
          </Button>
        </div>
      </form>
    </SwipeableDrawer>
  )
}

export default MobileFilterBar
