import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import { ReactComponent as IcDownload } from '@/assets/icons/ic-download.svg'
import { ReactComponent as IcUploadFrom } from '@/assets/icons/ic-upload-from.svg'
import { ReactComponent as IcCircleCheck } from '@/assets/icons/ic-circle-check.svg'
import { ReactComponent as IcPhoneFlip } from '@/assets/icons/ic-phone-flip.svg'
import { ReactComponent as IcEnvelope } from '@/assets/icons/ic-envelope.svg'
import './complete.scss'
import NumberOfResults from './numberOfResults'
import { Box } from '@mui/material'
import { formatMoney, formatNumber } from '@/utils'
import LabelForm from './LabelForm'
import { Asset } from '@/types/assets'
import { useGetAssetDownload } from '@/services/asset'
import { trackEvent, useAuthGuardPageTracker } from '@/analytics'

interface CompleteStepProps {
  asset: Asset
}

const DownloadIcon = styled(IcDownload)(
  ({ theme }) => `
    fill: ${theme.palette.common.white};
  `
)

const CircleCheck = styled(IcCircleCheck)(
  ({ theme }) => `
    fill: ${theme.palette.primary.main};
    margin-bottom: 2rem;
    margin-top: 2rem;
  `
)

const Badge = styled('div')(
  ({ theme }) => `
    background: ${theme.palette.background.tertiary};;
    border-radius: 30px;

    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
  `
)

const Hr = styled('hr')(
  ({ theme }) => `
    color: ${theme.palette.background.tertiary};
    opacity: 1;

  `
)

const ResultsReport = ({
  low,
  medium,
  high,
  icon,
  title
}: {
  low?: number
  medium?: number
  high?: number
  icon: React.ReactNode
  title: string
}) => {
  if (typeof low !== 'number' || typeof medium !== 'number' || typeof high !== 'number') {
    return null
  }

  if (low + medium + high === 0) {
    return null
  }

  return (
    <div className="result-accuracy-show">
      <div className="added_lists">
        <div className="list-title">
          {icon}
          <Typography variant="h3">{title}</Typography>
        </div>
        <div className="list-line">
          <NumberOfResults />
        </div>
        <div className="list-line">
          <div className="list">
            <h2>{low}</h2>
            <Badge>
              <Typography variant="caption" fontWeight={700}>
                Low
              </Typography>
            </Badge>
          </div>
          <div className="list">
            <h2>{medium}</h2>
            <Badge>
              <Typography variant="caption" fontWeight={700}>
                Medium
              </Typography>
            </Badge>
          </div>
          <div className="list">
            <h2>{high}</h2>
            <Badge>
              <Typography variant="caption" fontWeight={700}>
                High
              </Typography>
            </Badge>
          </div>
        </div>
      </div>
    </div>
  )
}

const AssetDownloadButton = ({ asset, children }: { asset: Asset; children: React.ReactNode }) => {
  const swr = useGetAssetDownload(asset._id)

  const event = asset.statistics.totalSubmitted === 1 ? 'Download' : 'Download - Bulk Result'

  return (
    <Button
      startIcon={<DownloadIcon />}
      variant="contained"
      color="primary"
      size="large"
      sx={{ my: 4, color: 'white' }}
      download={true}
      href={swr.data?.url as string}
      disabled={!swr.data?.url}
      onClick={() => {
        trackEvent(event, { page: 'Results' })
      }}
    >
      {children}
    </Button>
  )
}

const trackingPayload = { page: 'Results' } as const

const CompletedStep = ({ asset }: CompleteStepProps) => {
  useAuthGuardPageTracker('Visits Results Page', trackingPayload)

  if (!asset) return null

  return (
    <Container>
      <div className="result-show">
        <div className="result-checked">
          <CircleCheck />
          <Typography variant="h1" color="primary.main">
            We finished processing your data!
          </Typography>
          <AssetDownloadButton asset={asset}>Download</AssetDownloadButton>
        </div>

        <LabelForm assetId={asset._id} defaultLabel={asset.label} />
      </div>
      <Hr />

      <Box justifyContent="center" alignItems="center" flexDirection="column" display="flex" my={5}>
        <Typography variant="h1">Your file summary</Typography>
        <Box display="flex" gap={3} mt={3} mb={1}>
          <Box display="flex" alignItems="baseline">
            <Typography variant="body1" color="text.quaternary" fontWeight={700}>
              Total records submitted:&nbsp;
            </Typography>
            <Typography variant="h2">{formatNumber(asset.statistics?.totalSubmitted)}</Typography>
          </Box>
          <Box display="flex" alignItems="baseline">
            <Typography variant="body1" color="text.quaternary" fontWeight={700}>
              Total records matched:&nbsp;
            </Typography>
            <Typography variant="h2">{formatNumber(asset.statistics?.totalEnriched)}</Typography>
          </Box>
          {asset?.calculatedPrice ? (
            <Box display="flex" alignItems="baseline">
              <Typography variant="body1" color="text.quaternary" fontWeight={700}>
                Total cost:&nbsp;
              </Typography>
              <Typography variant="h2">{formatMoney(asset?.calculatedPrice)}</Typography>
            </Box>
          ) : null}
        </Box>
      </Box>

      <ResultsReport
        low={asset.statistics?.lowAccuracyEmails}
        medium={asset.statistics?.mediumAccuracyEmails}
        high={asset.statistics?.highAccuracyEmails}
        icon={<IcEnvelope />}
        title="Email addresses added"
      />
      <ResultsReport
        low={asset.statistics?.lowAccuracyPhones}
        medium={asset.statistics?.mediumAccuracyPhones}
        high={asset.statistics?.highAccuracyPhones}
        icon={<IcPhoneFlip className="fill-text.primary" />}
        title="Phone numbers added"
      />
      <ResultsReport
        low={asset.statistics?.lowAccuracyWorkEmails}
        medium={asset.statistics?.mediumAccuracyWorkEmails}
        high={asset.statistics?.highAccuracyWorkEmails}
        icon={<IcEnvelope />}
        title="Work email addresses added"
      />
      <div className="detail_features">
        <p>
          All your previous matched are available for download from your <a href="/">History</a>{' '}
          page
        </p>
        <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
          <Button variant="text" size="large" href="/">
            Go to Dashboard
          </Button>
          <Button
            variant="outlined"
            size="large"
            className="upload_list"
            onClick={() => {
              trackEvent('Upload a new list', { page: 'Results' })
            }}
            startIcon={<IcUploadFrom />}
            href="/lookup/address"
          >
            Upload a new list
          </Button>
        </Stack>
      </div>
    </Container>
  )
}

export default CompletedStep
