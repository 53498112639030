import { Typography } from '@mui/material'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { ReactComponent as CircleCheckIcon } from '@/assets/icons/ic-circle-check.svg'
import { Link } from 'react-router-dom'

const ExplorePricing = () => {
  return (
    <div className="flex md:hidden px-4 py-3 flex-col gap-3 rounded-xl bg-background.tertiary mb-4">
      <div className="flex justify-between items-baseline">
        <Typography variant="h3" color="primary.main">
          Explore pricing
        </Typography>

        <Link className="text-sm no-underline font-bold text-primary.main" to="/plans">
          See details
          <ChevronRightRoundedIcon className="mb-0.5" />
        </Link>
      </div>

      <div className="flex flex-col gap-4 items-start">
        <div className="flex flex-col gap-1 items-start">
          <Typography variant="body1" textAlign="center" fontWeight={700}>
            Usage-Based Pricing
          </Typography>

          <Typography variant="body1" textAlign="center" fontWeight={700}>
            Monthly billing | 1 credit = $0.75*
          </Typography>
        </div>

        <div className="flex gap-1 flex-col items-start">
          <div className="flex gap-3">
            <CircleCheckIcon className="mt-1.5 h-3 w-3 flex-shrink-0 fill-secondary.main" />
            <Typography variant="body2" color="text.quaternary" fontWeight={500}>
              You <b>only pay for successful lookups</b> (1 credit)
            </Typography>
          </div>
          <div className="flex gap-3">
            <CircleCheckIcon className="mt-1.5 h-3 w-3 flex-shrink-0 fill-secondary.main" />
            <Typography variant="body2" color="text.quaternary" fontWeight={500}>
              Use Archer as much <b>OR</b> as little as you like
            </Typography>
          </div>
          <div className="flex gap-3">
            <CircleCheckIcon className="mt-1.5 h-3 w-3 flex-shrink-0 fill-secondary.main" />
            <Typography variant="body2" color="text.quaternary" fontWeight={500}>
              <b>No Minimum</b> Usage Required
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExplorePricing
