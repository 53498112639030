import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'
import FormInput from '../../components/Form/Input'
import { forgotPassword } from '@/services/auth'
import { Alert, Box, Button, Divider, Typography, styled } from '@mui/material'
import { useCallback } from 'react'
import BackLink from '@/components/Links/BackLink'
import { ReactComponent as EmailSentIcon } from '@/assets/icons/ic-email-sent.svg'
import { emailValidator } from '@/utils/validators'
import { trackEvent, usePageTracker } from '@/analytics'
import BasicArcherSideScreen from './sideScreen/BasicArcher'
import BaseLayout from './BaseLayout'

const StyledEmailSentIcon = styled(EmailSentIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
  display: 'block',
  margin: '0 auto'
}))

const schema = object({
  email: emailValidator
}).required()

interface FormValues {
  email: string
}

const trackingPayload = { page: 'Reset Password' } as const

const ForgotPasswordPage = () => {
  usePageTracker('Visits Reset Password Page', trackingPayload)

  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema)
  })
  const [completed, setCompleted] = useState(false)
  const { isValid, errors } = formState

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (!isValid) {
        return
      }
      await forgotPassword({ email: data.email })
      setCompleted(true)
    },
    [isValid]
  )

  return (
    <BaseLayout sideScreen={<BasicArcherSideScreen />}>
      <div className="h-full flex flex-col">
        {!completed ? (
          <div>
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="h1">Reset Password</Typography>
              <Divider className="mt-4 mb-8" />
              <FormInput
                control={control}
                name="email"
                label="Email address"
                controlId="fieldEmailAddress"
                rules={{ required: true }}
              />
              {errors?.email?.message ? (
                <Alert severity="error">{errors.email.message}</Alert>
              ) : null}
              <Button
                variant="contained"
                size="large"
                className="w-full mt-10"
                type="submit"
                disabled={completed}
                onClick={() => {
                  trackEvent('Send reset link', { page: 'Reset Password' })
                }}
              >
                Send link to reset the password
              </Button>
            </form>
          </div>
        ) : null}
        {completed ? (
          <>
            <form className="w-100">
              <Typography variant="h1">Reset Password</Typography>
              <Divider className="mt-4 mb-8" />
            </form>
            <div className="flex flex-col justify-center h-full">
              <Box mb={8}>
                <Box ml="auto" mr="auto" mb={3}>
                  <StyledEmailSentIcon />
                </Box>
                <Typography textAlign={'center'} variant="h3" mb={2}>
                  Your Password Reset Link is on its Way!
                </Typography>
                <Typography>
                  A password reset link has been sent to the email address you provided. Please
                  follow the instructions in the email to reset your password. If you don&apos;t
                  receive the email within a few minutes, please check your spam folder.
                </Typography>
              </Box>
            </div>
          </>
        ) : null}
        <div className="flex-1" />
        <div className="flex items-center justify-center mt-10">
          <BackLink>Back to Login</BackLink>
        </div>
      </div>
    </BaseLayout>
  )
}

export default ForgotPasswordPage
