import { useAuth } from '@/reducers/user'
import { User } from '@/types/user'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Box,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Toolbar as MUIToolbar,
  Typography,
  useTheme
} from '@mui/material'
import React from 'react'
import { Link as RLink } from 'react-router-dom'
import { NavMenu } from './NavItems'
import { getInitials } from './utils'

const Toolbar = ({
  handleDrawerToggle,
  noLinks = false
}: {
  handleDrawerToggle: () => unknown
  anchorElUser: null | HTMLElement
  noLinks?: boolean
  setAnchorElUser: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}) => {
  const { user, isLoading } = useAuth()
  const theme = useTheme()

  return (
    <MUIToolbar disableGutters sx={{ minHeight: '48px !important' }}>
      <div className="flex flex-row">
        <div className="md:hidden mr-auto md:mr-0">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{ mr: 2 }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon sx={{ fill: theme.palette.primary.main }} />
          </IconButton>
        </div>
        <RLink to="/" className="mx-auto md:mx-0">
          <img src="/assets/images/logo.svg" alt="Archer" />
        </RLink>
      </div>

      {!noLinks ? (
        <>
          <Box
            className="hidden md:flex items-end"
            sx={{
              ml: 5
            }}
          >
            <NavMenu to="/" label="Dashboard" />
            <NavMenu to="/history" label="History" />
            <NavMenu to="/billing" label="Billing" />
          </Box>
        </>
      ) : null}

      <Box sx={{ ml: 'auto', display: 'flex' }}>
        <Box sx={{ flexGrow: 0 }} className="md:pr-6">
          <div className="hidden md:block">
            <Button sx={{ display: 'flex', alignItems: 'center' }} href="/settings/profile">
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Icon
                  color="primary"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    width: 32,
                    height: 32,
                    background: '#c2c9e1',
                    color: '#04137D',
                    fontSize: 12,
                    lineHeight: '12px',
                    fontWeight: 700,
                    borderRadius: 16,
                    mr: 2
                  }}
                >
                  {user ? getInitials(user as User) : null}
                </Icon>
              )}

              <div className="block">
                <Typography variant="caption" color="text.primary" fontWeight={700}>
                  {user?.fullName}
                </Typography>
              </div>
            </Button>
          </div>
          <div className="md:hidden">
            <IconButton sx={{ display: 'flex', alignItems: 'center' }} href="/settings/profile">
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Icon
                  color="primary"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    width: 32,
                    height: 32,
                    background: '#c2c9e1',
                    color: '#04137D',
                    fontSize: 12,
                    lineHeight: '12px',
                    fontWeight: 700,
                    borderRadius: 16
                  }}
                >
                  {user ? getInitials(user as User) : null}
                </Icon>
              )}
            </IconButton>
          </div>
        </Box>
      </Box>
    </MUIToolbar>
  )
}

export default Toolbar
