export const ucFirst = (value: string) => value.replace(/^\w/, (c) => c.toUpperCase())

export const capitalizeEveryWord = (value: string) => value.replace(/\b\w/g, (c) => c.toUpperCase())

export const isCompanyUrl = (str?: string) => {
  if (!str) {
    return false
  }

  return str.startsWith('http') || str.startsWith('www')
}
