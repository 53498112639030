/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Typography, Box, Button, Grid, RadioGroup } from '@mui/material'
import { ReactComponent as PartyHornIcon } from '@/assets/icons/ic-party-horn.svg'

import { selectLetUsHandleItFlowInquiry } from '@/reducers/inquiry'
import {
  DiscussOverEmailCard,
  DiscussOverPhoneCard,
  KnowYourCost,
  SetupMeetingCard
} from './Components'
import { emailValidator, phoneValidator } from '@/utils/validators'
import { postInquiry } from '@/services/inquiry'
import Stepper from './Stepper'
import { useSelector } from 'react-redux'

const validate = (validationRule: any, obj: any) => {
  try {
    validationRule.validateSync(obj)
    return undefined
  } catch (error) {
    // @ts-ignore
    return error.message
  }
}

const ListTooLarge = () => {
  const navigate = useNavigate()
  const { state }: any = useLocation()
  const [contactMethod, setContactMethod] = useState<string | null>(null)
  const [contactDetail] = useState<string | null>(null)
  const [errors, setErrors] = useState<{ note?: string; phone?: string; email?: string }>({})
  const inquiry = useSelector(selectLetUsHandleItFlowInquiry)

  const validateFile = () => {
    let errorFound = false

    if (contactMethod === 'phone') {
      const phoneError = validate(phoneValidator, inquiry?.phone)
      if (phoneError) {
        setErrors((prev) => ({ ...prev, phone: phoneError }))
        errorFound = true
      }
    }

    if (contactMethod === 'email') {
      const emailError = validate(emailValidator, inquiry?.email)
      if (emailError) {
        setErrors((prev) => ({ ...prev, email: emailError }))
        errorFound = true
      }
    }

    return !errorFound
  }

  const validateStep = () => {
    setErrors({})

    if (state?.file || state?.batchId) {
      return validateFile()
    }

    return true
  }

  const handleClickNext = () => {
    if (!validateStep()) {
      return
    }

    if (!inquiry) {
      return
    }

    if (contactMethod === 'meeting') {
      navigate('/let-us-handle/schedule', {
        state: {
          ...state,
          files: state?.file ? [state.file] : [],
          link: state?.link,
          batchId: state?.batchId
        }
      })
    } else {
      postInquiry({
        ...inquiry,
        phone: contactDetail === 'phone' || contactMethod === 'phone' ? inquiry.phone : '',
        files: state?.file ? [state.file] : [],
        link: state?.link,
        batchId: state?.batchId
      })

      navigate('/let-us-handle/success', {
        state: {
          type: contactDetail || contactMethod,
          step: 2
        }
      })
    }
  }

  return (
    <Container sx={{ py: 5 }}>
      <Stepper stepIdx={1} />
      <div className="flex flex-col gap-2 mt-8">
        <PartyHornIcon className="h-20 w-20 fill-primary.main mx-auto mb-4" />
        <Typography variant="h1" color="primary.main" className="text-center">
          What a big list, we can&apos;t wait to help you process it!
        </Typography>
        <Typography variant="caption" className="text-center">
          We will contact you to provide an invoice for payment, enabling us to proceed with
          enriching your uploaded list.
        </Typography>
      </div>

      <Grid container spacing={4}>
        <Grid item lg={6} md={12} xs={12}>
          <Typography variant="h3" mt={4} mb={3}>
            How can we get back in touch?
          </Typography>

          <RadioGroup
            value={contactMethod}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) =>
              setContactMethod(value)
            }
          >
            <SetupMeetingCard />
            <DiscussOverPhoneCard active={contactMethod === 'phone'} error={errors?.phone} />
            <DiscussOverEmailCard active={contactMethod === 'email'} error={errors?.email} />
          </RadioGroup>
          <Box mt={5} width={'100%'}>
            <KnowYourCost />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 6 }}>
        <Button variant="outlined" size="large" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button
          variant="contained"
          size="large"
          disabled={!contactMethod}
          onClick={handleClickNext}
        >
          Next
        </Button>
      </Box>
    </Container>
  )
}

export default ListTooLarge
