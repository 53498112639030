import { getRequiredStringValidator } from '@/utils/validators'
import * as yup from 'yup'

export interface PersonalFormValues {
  fullName: string
  address: string
  city: string
  state: string
  zipCode: string
}

export const personalFormValuesValidationSchema = yup
  .object({
    fullName: getRequiredStringValidator('Full Name is required'),
    address: getRequiredStringValidator('Address is required'),
    city: getRequiredStringValidator('City is required'),
    state: getRequiredStringValidator('State is required'),
    zipCode: getRequiredStringValidator('Zip Code is required')
  })
  .required()

export interface CompanyFormValues {
  fullName: string
  company: string
  industry?: string
  location?: string
}

export const companyFormValuesValidationSchema = yup
  .object({
    fullName: getRequiredStringValidator('Full Name is required'),
    company: getRequiredStringValidator('Company is required')
  })
  .required()
