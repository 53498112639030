import { Box, styled } from '@mui/material'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'

const StyledReportGmailerrorredIcon = styled(ReportGmailerrorredIcon)(() => ({
  height: '16px',
  width: '16px',
  marginBottom: '2px',
  marginRight: '4px'
}))

const InputError = ({ error }: { error?: string | React.ReactNode }) => {
  return (
    <Box className="text-danger flex items-baseline">
      <Box>
        <StyledReportGmailerrorredIcon />
      </Box>
      <Box className="text-xs">{error}</Box>
    </Box>
  )
}

export default InputError
