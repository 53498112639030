import { Button, Typography, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PaymentWarning from './PaymentWarning'

const InvalidCardWarning = ({
  message = 'Valid payment method is needed to enrich records.',
  href = '/'
}: {
  message?: string
  href?: string
}) => {
  const theme = useTheme()

  return (
    <PaymentWarning>
      <Typography variant="body1">{message}</Typography>
      {href ? (
        <Button
          variant="contained"
          size="large"
          href={href}
          sx={{
            flexShrink: 0,
            whiteSpace: 'nowrap',
            paddingLeft: 3,
            paddingRight: 3,
            backgroundColor: theme.palette.primary.main
          }}
        >
          <AddIcon />
          &nbsp;Add a new card
        </Button>
      ) : null}
    </PaymentWarning>
  )
}

export default InvalidCardWarning
