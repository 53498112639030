import { PAGE, trackEvent } from '@/analytics'
import { ReactComponent as GoogleIcon } from '@/assets/icons/ic-google-logo.svg'
import { Button, SvgIcon } from '@mui/material'

const toHref = (pathname = '/auth/sign-in/google') => {
  const url = new URL(process.env.REACT_APP_BACKEND_API as string)

  url.pathname = pathname

  return url.href
}

const GoogleSignInButton = ({
  className,
  disabled,
  page,
  text = 'Sign up with Google'
}: {
  className: string
  disabled?: boolean
  text?: string
  page: PAGE
}) => {
  return (
    <Button
      startIcon={<SvgIcon component={GoogleIcon}></SvgIcon>}
      variant="outlined"
      className={className}
      disabled={disabled}
      size="large"
      href={toHref()}
      onClick={() => {
        trackEvent('Clicks Google Sign Up', {
          page: page
        })
      }}
    >
      {text}
    </Button>
  )
}

export default GoogleSignInButton
