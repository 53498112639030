import { styled } from '@mui/system'
import { TextareaAutosize } from '@mui/material'

const StyledTextareaAutosize = styled(TextareaAutosize, { shouldForwardProp: () => true })(
  ({ theme }) => `
    background: ${theme.palette.background.secondary};
    border: 1px solid ${theme.palette.divider};
    border-radius: 12px;
    padding: 4px 12px;

    &:focus {
        outline-color: ${theme.palette.primary.main};
    }
`
)

export default StyledTextareaAutosize
