import { useState } from 'react'
import { Typography, Button } from '@mui/material'

import { useAuth } from '@/reducers/user'
import SendEmailVerificationModal from '@/components/Modal/SendEmailVerificationModal'
import { PAGE } from '@/analytics'
import { useVerifyEmail } from '@/pages/settings/profile/hooks'

const NewVerifyEmailBanner = ({
  page,
  title = 'Verify your email address to access your previous lookups!'
}: {
  page: PAGE
  title?: string
}) => {
  const { user } = useAuth()
  const { handleSendEmail, isLoading } = useVerifyEmail(page)
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false)

  const handleOpen: any = (value: boolean) => {
    setSendEmailModalOpen(value)
  }
  return (
    <>
      <Typography variant="h3" className="text-pretty">
        {title}
      </Typography>
      <Typography variant="body1" fontWeight={400} textAlign={'center'}>
        We sent an email to <b>{user?.email}</b>.Once your email got verified,
        <br /> you&apos;ll be able to enjoy all the features Archer has to offer.
      </Typography>
      <div className="flex gap-2 items-center mt-2">
        <Typography variant="body2" fontWeight={400}>
          Don&apos;t see the email?
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={async () => {
            await handleSendEmail()
            setSendEmailModalOpen(true)
          }}
          disabled={isLoading}
        >
          Resend
        </Button>
      </div>
      <SendEmailVerificationModal open={sendEmailModalOpen} setOpen={handleOpen} page={page} />
    </>
  )
}

export default NewVerifyEmailBanner
