import { useForm } from 'react-hook-form'
import { Link, useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'
import FormInput from '../../components/Form/Input'
import { resetPassword } from '@/services/auth'
import { useState } from 'react'
import { Alert, Box, Button, Divider, Typography, styled } from '@mui/material'
import { ReactComponent as CheckCircleIcon } from '@/assets/icons/ic-circle-check.svg'
import { ReactComponent as LeftArrowIcon } from '@/assets/icons/ic-left-arrow-2.svg'
import { passwordErrorText, passwordValidator } from '@/utils/validators'
import BaseLayout from './BaseLayout'

interface FormValues {
  password: string
}

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
  display: 'block',
  margin: '0 auto',
  width: '64px',
  height: '64px'
}))

const BackIcon = styled(LeftArrowIcon)(({ theme }) => ({
  marginRight: '8px',
  fill: theme.palette.background.secondary,
  paddingBottom: '2px',
  height: '12px'
}))

const schema = object({
  password: passwordValidator
}).required()

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [completed, setCompleted] = useState(false)

  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      password: ''
    },
    resolver: yupResolver(schema)
  })
  const { isValid, errors } = formState

  const onSubmit = async (values: FormValues) => {
    if (!isValid || !token) {
      return
    }
    await resetPassword({ token, password: values.password })
    setCompleted(true)
  }

  return (
    <BaseLayout>
      {!completed ? (
        <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1">New password</Typography>
          <Divider className="mt-4 mb-8" />
          <FormInput
            control={control}
            name="password"
            label="New password"
            type="password"
            controlId="fieldPassword"
            helperText={passwordErrorText}
            rules={{ required: true }}
          />
          {errors?.password?.message ? (
            <Alert severity="error">{errors.password.message}</Alert>
          ) : null}
          <Button variant="contained" size="large" className="w-full mt-10" type="submit">
            Reset password
          </Button>
        </form>
      ) : null}
      {completed ? (
        <div className="h-100 d-flex justify-content-between flex-column">
          <form className="w-100">
            <Typography variant="h1">New password</Typography>
            <Divider className="mt-4 mb-8" />
          </form>
          <Box mb={8}>
            <Box ml="auto" mr="auto" mb={3}>
              <StyledCheckCircleIcon />
            </Box>
            <Typography textAlign={'center'} variant="h3" mb={2}>
              Password changed!
            </Typography>
            <Typography>
              Your password has been successfully reset. Please use your new password to log in to
              your account.
            </Typography>
          </Box>
          <Link to="/auth/sign-in">
            <Button className="w-100 round">
              <BackIcon />
              Back to Login
            </Button>
          </Link>
        </div>
      ) : null}
    </BaseLayout>
  )
}

export default ResetPasswordPage
