import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Form } from 'react-bootstrap'
import { useForm, SubmitHandler } from 'react-hook-form'

type Inputs = {
  firstname: string
  lastname: string
  address: string
  city: string
  state: string
  zipcode: number
}

interface ComponentProps {
  visible: boolean
  onCloseModal: () => void
}
const UploadModal: React.FC<ComponentProps> = ({ visible, onCloseModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(data)
    onCloseModal()
  }

  return (
    <Modal show={visible} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="firstname">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              {...register('firstname', { required: true, maxLength: 10 })}
            />
            {errors.firstname && <Form.Text className="text-danger">FN is required</Form.Text>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="lastname">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              {...register('lastname', { required: true, maxLength: 10 })}
            />
            {errors.lastname && <Form.Text className="text-danger">LN is required</Form.Text>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              {...register('address', { required: true, maxLength: 10 })}
            />
            {errors.address && (
              <Form.Text className="text-danger">Street Address is required</Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter City"
              {...register('city', { required: true, maxLength: 10 })}
            />
            {errors.city && <Form.Text className="text-danger">City is required</Form.Text>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="state">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter State"
              {...register('state', { required: true, maxLength: 10 })}
            />
            {errors.state && <Form.Text className="text-danger">State is required</Form.Text>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="zipcode">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Zip Code"
              {...register('zipcode', { required: true, maxLength: 10 })}
            />
            {errors.zipcode && <Form.Text className="text-danger">ZipCode is required</Form.Text>}
          </Form.Group>
          <div className="d-flex justify-content-end gap-3">
            <Button variant="secondary" onClick={onCloseModal}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Next
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default UploadModal
