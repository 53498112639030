import { Box, Button, Divider, IconButton, Typography, Stack, Alert } from '@mui/material'
import { object, string } from 'yup'

import { ReactComponent as CircleCheckIcon } from '@/assets/icons/ic-circle-check.svg'
import { ArcherModal } from '@/components/Modal/ArcherModal'
import { useState } from 'react'
import FormTextarea from '@/components/Form/Textarea/Textarea'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { getRequiredStringValidator } from '@/utils/validators'
import FormInput from '@/components/Form/Input/Input'
import { useAuth } from '@/reducers/user'
import { submitContactUs } from '@/services/feedback'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'

export type ChatWithUsFormValues = {
  email: string
  company: string
  message: string
}

const schema = object({
  email: getRequiredStringValidator('Email is required'),
  company: getRequiredStringValidator('Company is required'),
  message: string().optional()
}).required()

interface ContactUsFormProps {
  onSubmitError: (error: unknown) => void
  onSubmitSuccess: () => void
}

const ContactUsForm = ({ onSubmitError, onSubmitSuccess }: ContactUsFormProps) => {
  const { user } = useAuth()

  const { control, handleSubmit, formState } = useForm<ChatWithUsFormValues>({
    defaultValues: {
      email: user?.email || '',
      company: '',
      message: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: ChatWithUsFormValues) => {
    try {
      await submitContactUs(data)
      onSubmitSuccess()
    } catch (error) {
      onSubmitError(error)
    }
  }

  return (
    <form className="flex flex-col -mx-3 md:-mx-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="px-3 md:px-6">
        <FormInput
          control={control}
          formState={formState}
          name="email"
          type="email"
          label="EMAIL ADDRESS(ES)"
          controlId="fieldEmailAddress"
          rules={{ required: true }}
        />
        <FormInput
          control={control}
          name="company"
          type="text"
          label="YOUR COMPANY"
          formState={formState}
          controlId="fieldCompany"
          rules={{ required: true }}
          placeholder="Company name"
        />
        <FormTextarea
          control={control}
          name="message"
          label={
            <>
              MESSAGE <span className="text-text.quaternary">(OPTIONAL)</span>
            </>
          }
          controlId="fieldMessage"
          placeholder="Let us know, if you have any questions"
        />
      </div>
      <Divider className="opacity-100 mb-4 mt-2" />
      <div className="flex gap-2 mx-8 flex-row-reverse">
        <LoadingButton
          loading={formState.isSubmitting}
          size="large"
          type="submit"
          variant="contained"
        >
          Let&apos;s connect
        </LoadingButton>
      </div>
    </form>
  )
}

const ContactUsModal = ({
  open,
  setOpen
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [formSent, setFormSent] = useState(false)
  const [formSentError, setFormSentError] = useState(false)

  const handleShowSuccessMessage = () => {
    setFormSent(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setFormSent(false)
    setFormSentError(false)
  }

  const handleAlertError = (error: unknown) => {
    console.error(`Error submitting the form: ${error}`)
    setFormSentError(true)
  }

  return (
    <ArcherModal open={open} setOpen={setOpen} cardSx={{ width: 520 }}>
      <div className="flex flex-col gap-2">
        <Stack justifyContent="space-between" direction="row" alignItems="center">
          <Box>
            <Typography variant="h1" className="pr-16 my-4 text-2xl">
              Let&apos;s chat
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleCloseModal} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Stack>
        {formSent ? (
          <>
            <Box className="flex justify-center gap-6 flex-col items-center">
              <CircleCheckIcon className="h-16 w-16 fill-primary.main" />
              <Typography className="text-primary.main text-xl" variant="h2">
                Thank you for contacting us
              </Typography>
              <Typography
                className="w-[236px] text-center"
                variant="caption"
                color="text.quaternary"
              >
                Your message has been received. We’ll get in touch with you soon.
              </Typography>
            </Box>
            <Divider className="opacity-100 mb-4 mt-2 ml-[-24px] mr-[-24px]" />
            <Box className="flex flex-row-reverse">
              <Button type="submit" variant="contained" size="large" onClick={handleCloseModal}>
                Back to site
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="body1" color="text.quaternary">
              We&apos;ll get in touch with you soon at the email address associated with your
              account. If needed, feel free to update it.
            </Typography>
            <div className="mt-5">
              {formSentError && (
                <Alert className="mb-4" severity="error" onClose={() => setFormSentError(false)}>
                  The message could not be sent. Please try again.
                </Alert>
              )}
              <ContactUsForm
                onSubmitError={handleAlertError}
                onSubmitSuccess={handleShowSuccessMessage}
              />
            </div>
          </>
        )}
      </div>
    </ArcherModal>
  )
}

export default ContactUsModal
