import { Box, Card, CardActionArea, Typography, useTheme } from '@mui/material'
import { ReactComponent as TriangleIcon } from '@/assets/icons/ic-triangle.svg'
import EmailActivationTooltip from '@/components/Auth/EmailActivationTooltip'

export const Tab = ({
  icon,
  text,
  active,
  onClick,
  children,
  disabled
}: {
  icon: React.ReactElement
  text: string
  active?: boolean
  disabled?: boolean
  tooltip?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  children?: React.ReactNode | React.ReactElement
}) => {
  const theme = useTheme()

  return (
    <Card
      sx={{
        flex: 1,
        borderRadius: 4,
        backgroundColor: disabled ? '#F3F3F6' : theme.palette.background.secondary,
        color: disabled ? '#858798' : undefined,

        position: 'relative',
        overflow: 'unset',

        boxShadow: '0px 2px 4px 0px rgba(31, 31, 35, 0.1)',
        borderWidth: active ? 2 : 2,
        borderStyle: 'solid',
        borderColor: active ? theme.palette.primary.main : 'transparent'
      }}
    >
      <EmailActivationTooltip>
        <CardActionArea
          sx={{
            borderRadius: 3,

            py: 1.5,
            display: 'flex',

            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
          }}
          disableRipple
          onClick={onClick}
          disabled={disabled}
        >
          {icon}
          <Typography variant="body1" fontWeight={700} sx={{ ml: 1.5 }}>
            {text}
          </Typography>
        </CardActionArea>
      </EmailActivationTooltip>
      {children}
    </Card>
  )
}

export const ActiveTabArrow = ({ activeTab }: { activeTab: number }) => {
  return (
    <TriangleIcon
      className="hidden md:block absolute top-5 w-10 h-10 fill-white -translate-y-full"
      style={{
        left: activeTab === 0 ? '1.5rem' : undefined,
        right: activeTab === 1 ? '1.5rem' : undefined
      }}
    />
  )
}

interface TabArrowProps {
  position: 'left' | 'right' | (string & NonNullable<unknown>)
}

export const TabArrow = ({ position }: TabArrowProps) => {
  const xPosition = position === 'left' ? ' left-7' : position === 'right' ? ' right-7' : ''

  return (
    <Box className={`absolute top-[3.3rem] bottom-[-15px] ${xPosition}`}>
      <TriangleIcon className="w-10 h-10 fill-white" />
    </Box>
  )
}
