import { Button, Typography } from '@mui/material'
import { ArcherModal, ArcherModalBottomActions } from './ArcherModal'

const AccountVerifiedModal = ({
  open,
  setOpen
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <ArcherModal open={open} setOpen={setOpen}>
      <Typography variant="h2" marginRight={2}>
        Your account has been verified!
      </Typography>
      <Typography variant="body1" mt={3} mb={4}>
        Great news, you&apos;re all set! Now you can enjoy all the features that Archer has to
        offer.
      </Typography>
      <ArcherModalBottomActions>
        <div className="flex items-center justify-between gap-2">
          <Button variant="text" size="large" onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button variant="contained" size="large" onClick={() => setOpen(false)}>
            Let&apos;s do a lookup
          </Button>
        </div>
      </ArcherModalBottomActions>
    </ArcherModal>
  )
}

export default AccountVerifiedModal
