import { ReactComponent as WorkEmailIcon } from '@/assets/icons/ic-briefcase.svg'
import { ReactComponent as CheckedIcon } from '@/assets/icons/ic-check-gradient.svg'
import { ReactComponent as EmailIcon } from '@/assets/icons/ic-email-circle.svg'
import { ReactComponent as PhoneIcon } from '@/assets/icons/ic-phone-circle.svg'
import { formatNumber } from '@/utils'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Typography,
  styled,
  useTheme
} from '@mui/material'
import { useBillingTracker } from '../hooks'
import { useIsCurrentUserActivated } from '@/reducers/user'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .label-icon': {
    height: '27px',
    width: '27px',
    fill: theme.palette.primary.main
  },
  '&.Mui-disabled .label-icon': {
    fill: theme.palette.text.disabled
  }
}))

const MatchTypeCheckbox = ({
  value,
  flipValue,
  icon,
  label,
  disabled
}: {
  value: boolean
  flipValue: React.Dispatch<React.SetStateAction<boolean>>
  icon: React.ReactNode
  label: string
  disabled?: boolean
}) => {
  return (
    <Box>
      <StyledFormControlLabel
        disabled={disabled}
        title={disabled ? 'Coming soon' : undefined}
        control={
          <Checkbox
            disabled={disabled}
            checked={disabled ? false : value}
            onChange={() => flipValue((prev) => !prev)}
          />
        }
        label={
          <Stack direction="row" alignItems="center" gap={1}>
            {icon}
            <Typography variant="body1" fontWeight="700">
              {label}
            </Typography>
          </Stack>
        }
      />
    </Box>
  )
}

const MatchTypeChecked = ({ icon, label }: { icon: React.ReactNode; label: string }) => {
  return (
    <Box>
      <StyledFormControlLabel
        control={<CheckedIcon className="m-2" fill="#2721AE" width={27} height={27} />}
        label={
          <Stack direction="row" alignItems="center" gap={1}>
            {icon}
            <Typography variant="body1" fontWeight="700">
              {label}
            </Typography>
          </Stack>
        }
      />
    </Box>
  )
}

const SubmitArea = ({
  lookupType,
  isLoading,
  requestPhones,
  requestEmails,
  requestWorkEmails,
  hideSelector,
  requestPhoneLabel = 'Personal mobile',
  requestEmailLabel = 'Personal email',
  requestWorkEmailLabel = 'Work Emails',
  requestPhoneIcon = <PhoneIcon className="label-icon" />,
  requestEmailIcon = <EmailIcon className="label-icon" />,
  requestWorkEmailIcon = <WorkEmailIcon className="label-icon p-1" />,
  disablePhones,
  setRequestPhones,
  setRequestEmails
}: {
  lookupType: 'company' | 'address'
  isLoading?: boolean
  disabled?: boolean
  requestPhones: boolean
  requestEmails: boolean
  requestWorkEmails: boolean
  hideSelector?: boolean
  disablePhones?: boolean
  requestPhoneLabel?: string
  requestEmailLabel?: string
  requestWorkEmailLabel?: string
  requestPhoneIcon?: React.ReactNode
  requestEmailIcon?: React.ReactNode
  requestWorkEmailIcon?: React.ReactNode
  setRequestPhones: React.Dispatch<React.SetStateAction<boolean>>
  setRequestEmails: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const theme = useTheme()
  const isActivated = useIsCurrentUserActivated()
  const { data: billingTracker } = useBillingTracker()
  const freeCredits = billingTracker?.free_credits ?? 0

  const getMatchesInfo = () => {
    if (freeCredits === 0) return 'No free matches available'
    if (freeCredits === 1) return 'You have 1 free match'
    return `You have ${formatNumber(freeCredits)} free matches`
  }

  const disabled =
    lookupType === 'company'
      ? !requestWorkEmails && !requestEmails && !requestPhones
      : !requestEmails && !requestPhones

  return (
    <Box py={2} borderRadius={4} mt={2}>
      <Stack direction="row" gap={2} flexWrap="wrap" className="items-center">
        {hideSelector ? null : (
          <div className="flex flex-col">
            <Typography color="text.quaternary" variant="caption" fontWeight={600} className="mb-1">
              I&apos;M LOOKING FOR ...
            </Typography>
            {lookupType === 'company' ? (
              <MatchTypeChecked icon={requestWorkEmailIcon} label={requestWorkEmailLabel} />
            ) : (
              <>
                <MatchTypeCheckbox
                  disabled={disablePhones}
                  value={requestPhones}
                  flipValue={setRequestPhones}
                  icon={requestPhoneIcon}
                  label={requestPhoneLabel}
                />
                <MatchTypeCheckbox
                  value={requestEmails}
                  flipValue={setRequestEmails}
                  icon={requestEmailIcon}
                  label={requestEmailLabel}
                />
              </>
            )}
          </div>
        )}

        <div className="flex-shrink-0 flex-grow">
          <div className="float-right flex flex-col items-center">
            <Box display="flex" alignItems="center" justifyContent={'flex-end'} gap={2}>
              <Button
                variant="contained"
                size="large"
                type="submit"
                disabled={isLoading || disabled || !isActivated}
                sx={{
                  flexShrink: 0,
                  whiteSpace: 'nowrap',
                  paddingLeft: 3,
                  paddingRight: 3,
                  backgroundColor: theme.palette.primary.main
                }}
              >
                Get contact info
              </Button>
              {isLoading ? <CircularProgress size={20} /> : null}
            </Box>
            <Typography variant="body2" fontWeight={700} fontSize={12} color="text.quaternary">
              {getMatchesInfo()}
            </Typography>
          </div>
        </div>
      </Stack>
    </Box>
  )
}

export default SubmitArea
export { MatchTypeChecked }
