import Typography from '@mui/material/Typography'

import { INFO_EMAIL_ADDRESS } from '@/pages/static/TermsOfUse'

const Footer = () => {
  return (
    <footer
      id="footer"
      className="flex gap-8 right-10 left-10 items-center bottom-0 fixed border-t border-solid border-0 pt-4 border-background.tertiary"
      style={{ pageBreakAfter: 'always' }}
    >
      <Typography>
        <b>Archer LLC</b>, 8 The Green, Suite B, Dover DE 19901
      </Typography>
      <Typography>
        Email: <b>{INFO_EMAIL_ADDRESS}</b>
      </Typography>
    </footer>
  )
}

export default Footer
