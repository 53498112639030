import { Box } from '@mui/material'
import QuickPersonalForm from './QuickPersonalForm'
import QuickCompanyForm from './QuickCompanyForm'

const QuickMatch = ({
  matchType,
  setActiveAssetId
}: {
  matchType: 'personal' | 'company'
  setActiveAssetId: (assetId: string) => unknown
}) => {
  return (
    <Box>
      {matchType === 'personal' && <QuickPersonalForm setActiveAssetId={setActiveAssetId} />}
      {matchType === 'company' && <QuickCompanyForm setActiveAssetId={setActiveAssetId} />}
    </Box>
  )
}

export default QuickMatch
