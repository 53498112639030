import * as yup from 'yup'

import YupPassword from 'yup-password'
YupPassword(yup)

export const getRequiredStringValidator = (errorMessage: string) =>
  yup.string().required(errorMessage)

export const fullNameValidator = getRequiredStringValidator('Full name is required')

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()\-_=+{};:'",.<>?/\\]).{8,}$/

export const passwordErrorText =
  'Passwords must contain 8+ characters, at least one uppercase letter, one number or one special character'

export const passwordValidator = yup
  .string()
  .matches(passwordRegex, passwordErrorText)
  .required(passwordErrorText)

export const emailValidator = yup
  .string()
  .email('Email must be a valid email')
  .required('Email is required')

export const agreedValidator = yup
  .boolean()
  .isTrue('You must agree to terms and conditions')
  .required('You must agree to terms and conditions')

export const phoneValidator = yup
  .string()
  .min(10, 'Phone number must be at least 10 digits')
  .matches(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/, 'Phone number is invalid')
  .required('Phone number is required')
