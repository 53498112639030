import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'
import { useForm } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { getRequiredStringValidator } from '@/utils/validators'
import { updateAsset } from '@/services/asset'
import { useDispatch } from 'react-redux'
import { setCurrentAsset } from '@/reducers/assets'
import { trackEvent } from '@/analytics'

interface FormProps {
  label: string
}

const schema = object({
  label: getRequiredStringValidator('Label is required')
}).required()

const LabelForm = ({ assetId, defaultLabel }: { assetId: string; defaultLabel?: string }) => {
  const dispatch = useDispatch()
  const {
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit
  } = useForm<FormProps>({
    defaultValues: {
      label: defaultLabel || ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: FormProps) => {
    trackEvent('Labels file', { page: 'Results' })
    const asset = await updateAsset(assetId, { label: data.label })
    dispatch(setCurrentAsset(asset.data.asset))
  }

  return (
    <form className="add-label" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body1" fontWeight={700}>
        Add a label to find your results easily:
      </Typography>
      <div className="add-label__form">
        <TextField
          {...register('label', { required: true })}
          name="label"
          fullWidth
          disabled={isSubmitting}
          error={!!errors.label}
          helperText={errors.label?.message}
        />
        <div className="add-label_actions">
          <IconButton aria-label="cancel" type="reset" disabled={isSubmitting}>
            <CloseIcon />
          </IconButton>

          <IconButton aria-label="check" color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitSuccessful ? <CheckIcon color="success" /> : <CheckIcon />}
          </IconButton>
        </div>
      </div>
    </form>
  )
}

export default LabelForm
