/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import FormInput from '../../components/Form/Input'

import { Typography, Link as MuiLink } from '@mui/material'
import { trackEvent, usePageTracker } from '@/analytics'
import { SignUpPageLayout } from './BaseLayout'
import { useSignUpPage } from './Signup'
import { useEffect } from 'react'
import GoogleSignInButton from '@/components/Button/GoogleSignInButton'

const trackingPayload = { page: 'Quick Sign Up', variant: 'quick-sign-up' } as const

const QuickSignUpPage = ({ onComplete }: { onComplete: (defaultEmail: string) => unknown }) => {
  usePageTracker('Visits Quick Sign Up Page', trackingPayload)

  const { control, getValues } = useSignUpPage()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <SignUpPageLayout>
      <div className="bg-white -mx-10 py-10 px-6 rounded-t-2xl border border-background.tertiary shadow-lg">
        <Typography variant="h1" className="text-center mb-6">
          Quick Sign Up
        </Typography>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            event.stopPropagation()

            onComplete(getValues().email)
          }}
        >
          <FormInput
            control={control}
            name="email"
            type="email"
            label="Email address"
            controlId="fieldEmailAddress"
            rules={{ required: true }}
          />
          <Button
            variant="contained"
            size="large"
            type="submit"
            className="w-full mt-3 md:mt-7"
            onClick={() => {
              trackEvent('Clicks Continue', {
                page: 'Quick Sign Up'
              })
            }}
          >
            Continue
          </Button>
          <GoogleSignInButton className="w-full mt-7" page={'Quick Sign Up'} />
          <div className="mx-auto text-center text-sm font-medium mt-8">
            Already have an account?&nbsp;
            <Link to="/auth/sign-in" className="no-underline mt-6 lg:mt-10 text-primary.main">
              Log in
            </Link>
          </div>
        </form>

        <div className="mt-8 mb-48 flex justify-center">
          <MuiLink
            className="text-sm text-primary.main font-bold no-underline"
            href="https://helloarcher.io/"
          >
            Learn more about Archer
          </MuiLink>
        </div>
      </div>
    </SignUpPageLayout>
  )
}

export default QuickSignUpPage
