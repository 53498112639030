import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Typography, Box, Button, CircularProgress } from '@mui/material'
import { postInquiry } from '@/services/inquiry'
import Stepper from './Stepper'

const LetUsHandleContactPage = () => {
  const navigate = useNavigate()
  const { state }: any = useLocation()

  const [disabled, setDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [bookingDetails, setBookingDetails] = useState<any | null>(null)
  console.log(state)

  const onSuccess = async (meeting: Date) => {
    setLoading(true)
    await postInquiry({
      meeting,
      files: state?.file ? [state.file] : [],
      link: state?.link,
      note: state?.note,
      phone: '',
      email: '',
      batchId: state?.batchId
    })

    setDisabled(false)
    setLoading(false)
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    document.body.appendChild(script)

    const handleHubspotMessage = (event: any) => {
      if (event.data.meetingBookSucceeded) {
        setBookingDetails(event.data.meetingsPayload?.bookingResponse)
        if (event.data.meetingsPayload?.bookingResponse?.event?.dateTime) {
          onSuccess(new Date(event.data.meetingsPayload?.bookingResponse?.event?.dateTime))
        }
      }
    }
    window.addEventListener('message', handleHubspotMessage)

    return () => {
      document.body.removeChild(script)
      window.removeEventListener('message', handleHubspotMessage)
    }
  }, [])

  return (
    <Container sx={{ py: 5 }}>
      <Stepper stepIdx={1} className="mb-8" />
      <Typography variant="h1" color="primary.main" mb={1}>
        Have a quick chat with us!
      </Typography>

      <Typography variant="body1" fontWeight={400} mt={1}>
        We can discuss your needs and goals to personalize your results.
      </Typography>

      {/* <Typography variant="body1" fontWeight={700} mt={5} textAlign="center">
        Schedule a time that works for you
      </Typography> */}

      <Box mt={5} minHeight={516}>
        <div
          className="meetings-iframe-container"
          data-src="https://meetings.hubspot.com/joshua-lorah/sterlingai?embed=true"
        ></div>
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0 }}>
        <Button variant="outlined" size="large" onClick={() => navigate(-1)}>
          Back
        </Button>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {loading ? <CircularProgress size={18} sx={{ mr: 2 }} /> : null}
          <Button
            variant="contained"
            size="large"
            disabled={disabled || !bookingDetails}
            onClick={() =>
              navigate('/let-us-handle/success', {
                state: {
                  type: 'meeting',
                  event: bookingDetails?.event,
                  contact: bookingDetails?.postResponse?.contact
                }
              })
            }
          >
            Next
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default LetUsHandleContactPage
