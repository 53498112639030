import { api } from '@/providers/ApiProvider'
import { BillingSheet } from '@/types/billing'
import { Box, Container } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import PaymentSummary from '../upload/step-components/payment-method/PaymentSummary'
import PaymentUI from '../upload/step-components/payment-method/PaymentUI'
import ContactUsForm from './ContactUsForm'

const useGuards = () => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  return isReady
}

const fetcher = async (url: string) => {
  const result = await api.get<BillingSheet>(url)

  return result.data
}

export const useBillingSheet = (paramId?: string) => {
  const { id } = useParams()

  return useSWR(`/payments/billing/sheet/${paramId || id}`, fetcher)
}

const PaymentPage = () => {
  const billingSheet = useBillingSheet()

  const isReady = useGuards()

  if (!isReady || !billingSheet.data) {
    return null
  }

  return (
    <Container maxWidth={'xl'} className="flex flex-col items-start mb-10">
      <Box className="w-full flex flex-row flex-wrap gap-16 justify-stretch" my={4}>
        <PaymentSummary billingSheet={billingSheet.data} />
        <PaymentUI billingSheet={billingSheet.data} />
      </Box>
      <ContactUsForm />
    </Container>
  )
}

export default PaymentPage
