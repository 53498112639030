import { useNavigate, useSearchParams } from 'react-router-dom'
import { verifyMagicLink } from '@/services/auth'
import { CircularProgress, Divider, Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useSignIn } from '@/reducers/user'
import { PAGE, usePageTracker } from '@/analytics'
import BaseLayout from './BaseLayout'

const MagicLinkVerifyPage = () => {
  const signIn = useSignIn()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tokenParam = searchParams.get('token')

  const trackingPayload = useMemo(
    () => ({ isTokenPresent: !!tokenParam, page: 'Login' as PAGE }),
    [tokenParam]
  )

  usePageTracker('Magic link login', trackingPayload)

  const verifyToken = async (token: string) => {
    try {
      const response = await verifyMagicLink(token)

      signIn(response.data)
      navigate('/')
      window.location.reload()
    } catch (error) {
      navigate('/')
    }
  }

  useEffect(() => {
    if (!tokenParam) {
      return
    }

    verifyToken(tokenParam)
  }, [])

  return (
    <BaseLayout>
      <Typography variant="h1">
        {tokenParam ? 'Verifying your magic link...' : 'No token present'}
      </Typography>
      {tokenParam ? (
        <>
          <Divider className="mt-4 mb-8" />
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        </>
      ) : null}
    </BaseLayout>
  )
}

export default MagicLinkVerifyPage
