import { trackEvent } from '@/analytics'
import {
  ReactComponent as IcCircleInfo,
  ReactComponent as InfoIcon
} from '@/assets/icons/ic-circle-info.svg'
import { ReactComponent as CopyIcon } from '@/assets/icons/ic-copy.svg'
import { ReactComponent as EmailSentIcon } from '@/assets/icons/ic-email-sent.svg'
import { ReactComponent as PersonalEmailIcon } from '@/assets/icons/ic-personal-email.svg'
import { ReactComponent as PersonalPhoneIcon } from '@/assets/icons/ic-personal-phone.svg'
import { ReactComponent as WorkEmailIcon } from '@/assets/icons/ic-work-email.svg'

import { AcceptAllChip, DeliverableChip } from '@/components/Chip'
import { ArcherModal, ArcherModalBottomActions } from '@/components/Modal/ArcherModal'
import AccuracyChip from '@/components/common/AccuracyChip/AccuracyChip'
import useLookupHasResults from '@/hooks/useLookupHasResults'
import { selectIsRetry } from '@/reducers/lookup'
import { AccuracyStatus } from '@/types/accuracy-status.type'
import { Asset } from '@/types/assets'
import { LookupStatus } from '@/types/lookup-status.type'
import { formatNumber } from '@/utils'
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  SvgIcon,
  SxProps,
  Typography,
  useTheme
} from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useBillingTracker } from '../hooks'
import EmptyCompanyWorkContactResults from './EmptyCompanyWorkContactResults'
import NoResultCompanyRetry from './NoResultCompanyRetry'

const EntryIcon = ({
  workOrPersonal,
  phoneOrEmail
}: {
  workOrPersonal: 'personal' | 'work'
  phoneOrEmail: 'phone' | 'email'
}) => {
  const theme = useTheme()
  const sxProps: SxProps = { width: 30, fill: theme.palette.text.quaternary }

  if (workOrPersonal === 'work' && phoneOrEmail === 'email') {
    return (
      <SvgIcon viewBox="0 0 30 20" sx={sxProps}>
        <WorkEmailIcon />
      </SvgIcon>
    )
  }

  if (workOrPersonal === 'personal' && phoneOrEmail === 'email') {
    return (
      <SvgIcon viewBox="0 0 26 17" sx={sxProps}>
        <PersonalEmailIcon />
      </SvgIcon>
    )
  }

  if (workOrPersonal === 'personal' && phoneOrEmail === 'phone') {
    return (
      <SvgIcon viewBox="0 0 25 20" sx={sxProps}>
        <PersonalPhoneIcon />
      </SvgIcon>
    )
  }

  return null
}

const ChipInfo = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton size="small" className="md:hidden h-5 w-5" onClick={() => setOpen(true)}>
        <SvgIcon viewBox="0 0 16 16" className="h-4 w-4">
          <InfoIcon />
        </SvgIcon>
      </IconButton>

      <ArcherModal open={open} setOpen={setOpen}>
        <Typography variant="h2" pl={1}>
          Accuracy & Deliverability
        </Typography>
        <ul className="list-disc mt-5">
          <li className="mb-2">
            <Typography variant="body1">
              <b>Accuracy</b>: We assign match likelihood scores to contact information to display
              our confidence in the results. Even a &quot;low&quot; or &quot;medium&quot; rating has
              a good chance of belonging to the individual.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <b>Deliverability</b>: The deliverability status reflects our confidence in the
              validity of an email address.
              <DeliverableChip /> means the email address is valid and should accept emails. An{' '}
              <AcceptAllChip />
              email address is linked to an accept-all domain therefore its validity cannot be
              determined. While it is less certain for individual deliverability, it still offers a
              reasonable opportunity for successful email communication.
            </Typography>
          </li>
        </ul>
        <ArcherModalBottomActions>
          <div className="flex items-center justify-end gap-2">
            <Button variant="contained" size="large" onClick={() => setOpen(false)}>
              Got it!
            </Button>
          </div>
        </ArcherModalBottomActions>
      </ArcherModal>
    </>
  )
}

const ResultChips = ({
  accuracy,
  status
}: {
  accuracy?: AccuracyStatus
  status?: LookupStatus
}) => {
  return (
    <div className="flex flex-wrap items-center gap-1">
      <AccuracyChip accuracy={accuracy} status={status} />
      <ChipInfo />
    </div>
  )
}

interface ResultControlsProps {
  result: string
  isEmail?: boolean
}

export const ResultControls = ({ result, isEmail }: ResultControlsProps) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(result)
  }

  return (
    <>
      <div className="flex flex-row md:w-16 md:h-7 items-center">
        <IconButton size="small" onClick={handleClick} title="Copy to clipboard" color="primary">
          <SvgIcon viewBox="0 0 20 20">
            <CopyIcon />
          </SvgIcon>
        </IconButton>
        {isEmail && (
          <IconButton
            className="hidden md:block"
            size="small"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              window.open(`mailto:${result}`)
            }}
            title={`mailto:${result}`}
            color="primary"
          >
            <SvgIcon viewBox="0 0 100 72">
              <EmailSentIcon />
            </SvgIcon>
          </IconButton>
        )}
      </div>
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  )
}

const ResultEntry = ({
  workOrPersonal,
  phoneOrEmail,
  accuracy,
  status,
  result
}: {
  workOrPersonal: 'personal' | 'work'
  phoneOrEmail: 'phone' | 'email'
  accuracy?: AccuracyStatus
  status?: LookupStatus
  result: string
}) => {
  return (
    <div className="flex gap-4 flex-row items-center">
      <EntryIcon workOrPersonal={workOrPersonal} phoneOrEmail={phoneOrEmail} />
      <div className="flex flex-col flex-1 gap-1">
        <Typography fontWeight={700}>{result}</Typography>
        <ResultChips accuracy={accuracy} status={status} />
      </div>
      <ResultControls result={result} isEmail={phoneOrEmail === 'email'} />
    </div>
  )
}

const ResultsEntry = ({ children }: { children: React.ReactNode }) => {
  return <div className="my-6 flex flex-col gap-8 flex-shrink-0">{children}</div>
}

export interface WorkEmailResultsProps {
  asset: Asset
  onRestart: () => void
  setActiveAssetId: (assetId: string) => unknown
}

const WorkEmailResults = ({ asset, setActiveAssetId, onRestart }: WorkEmailResultsProps) => {
  const {
    enrichedWorkEmail,
    enrichedWorkEmail2,
    enrichedWorkEmail2Accuracy,
    enrichedWorkEmail2DeliverableStatus,
    enrichedWorkEmailAccuracy,
    enrichedWorkEmailDeliverableStatus,
    companyWebsite
  } = asset.singleEnrichment

  const companyUrl = companyWebsite ?? ''

  if (!enrichedWorkEmail && !enrichedWorkEmail2)
    return (
      <EmptyCompanyWorkContactResults
        asset={asset}
        domain={companyUrl}
        onRestart={onRestart}
        setActiveAssetId={setActiveAssetId}
      />
    )

  return (
    <div className="flex-1">
      <WorkContactsTitle />
      <ResultsEntry>
        {enrichedWorkEmail && (
          <ResultEntry
            workOrPersonal="work"
            phoneOrEmail="email"
            accuracy={enrichedWorkEmailAccuracy}
            result={enrichedWorkEmail}
            status={enrichedWorkEmailDeliverableStatus}
          />
        )}

        {enrichedWorkEmail2 ? (
          <ResultEntry
            workOrPersonal="work"
            phoneOrEmail="email"
            accuracy={enrichedWorkEmail2Accuracy}
            result={enrichedWorkEmail2}
            status={enrichedWorkEmail2DeliverableStatus}
          />
        ) : null}
      </ResultsEntry>
    </div>
  )
}

const WorkContactsTitle = () => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-2">
        <Typography variant="body1" fontWeight={700} color="primary.main">
          Work contacts
        </Typography>
      </div>
    </div>
  )
}

const CompanyAssetResult = ({
  asset,
  onRestart,
  setActiveAssetId
}: {
  asset: Asset
  onRestart: (newAssetId?: string) => unknown | undefined
  setActiveAssetId: (assetId: string) => unknown
}) => {
  const isRetryLookup = useSelector(selectIsRetry)
  const { data: billingTracker } = useBillingTracker()
  const { lookupResult } = useLookupHasResults(asset)

  const credits = billingTracker?.free_credits || undefined

  if (!asset.singleEnrichment?.isSingleEnrichment) {
    return null
  }

  const retryWithNoResults =
    isRetryLookup &&
    !asset.singleEnrichment.enrichedWorkEmail &&
    !asset.singleEnrichment.enrichedWorkEmail2

  if (retryWithNoResults) {
    return (
      <EmptyCompanyWorkContactResults
        asset={asset}
        domain={asset.singleEnrichment.company}
        onRestart={onRestart}
        setActiveAssetId={setActiveAssetId}
      />
    )
  }

  if (!lookupResult) {
    return <NoResultCompanyRetry asset={asset} onRestart={onRestart} />
  }

  return (
    <Box className="mt-8 flex flex-col gap-8">
      <Box className="md:px-6">
        <Box
          className="flex flex-col md:flex-row flex-wrap"
          sx={{
            gap: {
              md: '2.5rem'
            }
          }}
        >
          <Box className="flex-1">
            <WorkEmailResults
              setActiveAssetId={setActiveAssetId}
              asset={asset}
              onRestart={onRestart}
            />
          </Box>
          <Box className="flex-1"></Box>
        </Box>
      </Box>
      <Box className="flex justify-between">
        <Box className="flex items-center gap-2">
          <IcCircleInfo className="flex-shrink-0" />
          <Typography variant="body1" fontWeight={500}>
            You can later find all your enrichments on the{' '}
            <Link to="/history">
              <Box component="span" className="text-primary.main" fontWeight={700}>
                History
              </Box>
            </Link>{' '}
            page.
          </Typography>
        </Box>
        <Box className="flex flex-col items-center">
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              trackEvent('New Lookup', { page: 'Dashboard' })
              if (onRestart) {
                return onRestart()
              }
            }}
          >
            New Lookup
          </Button>
          {credits ? (
            <Typography mt={1}>You have {formatNumber(credits)} free matches left</Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default CompanyAssetResult
