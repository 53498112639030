import { createTheme } from '@mui/material/styles'
import LinkBehavior from './MuiLink'
import HkGrotesk_LightOtf from '../assets/fonts/HKGrotesk-Light.otf'
import HkGrotesk_RegularOtf from '../assets/fonts/HKGrotesk-Regular.otf'
import HkGrotesk_MediumOtf from '../assets/fonts/HKGrotesk-Medium.otf'
import HkGrotesk_SemiBoldOtf from '../assets/fonts/HKGrotesk-SemiBold.otf'
import HkGrotesk_BoldOtf from '../assets/fonts/HKGrotesk-Bold.otf'
import HkGrotesk_BlackOtf from '../assets/fonts/HKGrotesk-Black.otf'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties
  }

  interface TypeBackground {
    secondary: string
    tertiary: string
  }

  interface TypeText {
    tertiary: string
    quaternary: string
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: 'HKGrotesk',
    title: {
      fontSize: 48,
      lineHeight: '64px',
      letterSpacing: '0.5%'
    },
    h1: {
      fontSize: 36,
      fontWeight: 900
    },
    h2: {
      fontSize: 24,
      lineHeight: 1.25,
      fontWeight: 900
    },
    h3: {
      fontSize: 20,
      lineHeight: 1.4,
      fontWeight: 800
    },
    h4: {
      fontSize: 20,
      lineHeight: 1.4,
      fontWeight: 800
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 500
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.71,
      fontWeight: 500
    },
    caption: {
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 500
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'HKGrotesk';
        src: url(${HkGrotesk_LightOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
      }
      
      @font-face {
        font-family: 'HKGrotesk';
        src: url(${HkGrotesk_RegularOtf}) format('opentype');
        font-weight: 400;
        font-style: normal;
      }
      
      @font-face {
        font-family: 'HKGrotesk';
        src: url(${HkGrotesk_MediumOtf}) format('opentype');
        font-weight: 500;
        font-style: normal;
      }
      
      @font-face {
        font-family: 'HKGrotesk';
        src: url(${HkGrotesk_SemiBoldOtf}) format('opentype');
        font-weight: 600;
        font-style: normal;
      }
      
      @font-face {
        font-family: 'HKGrotesk';
        src: url(${HkGrotesk_BoldOtf}) format('opentype');
        font-weight: 700;
        font-style: normal;
      }
      
      @font-face {
        font-family: 'HKGrotesk';
        src: url(${HkGrotesk_BlackOtf}) format('opentype');
        font-weight: 900;
        font-style: normal;
      }

      html, body {
        font-feature-settings: 'salt' on;
        color: #010417;
      }

      .MuiButtonBase-root.MuiButton-root.Mui-disabled {
        background: #E8E8ED;
        border-color: #E8E8ED;
      }
      `
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: {
          maxWidth: 1440
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        sizeMedium: {
          borderRadius: 18,
          fontSize: 14,
          lineHeight: '24px',
          fontWeight: 700
        },
        sizeLarge: {
          borderRadius: 20,
          fontSize: 14,
          lineHeight: '24px',
          fontWeight: 700
        },
        sizeSmall: {
          borderRadius: 16,
          fontSize: 14,
          lineHeight: '24px',
          fontWeight: 700,
          padding: '4px 12px'
        },
        // TODO: Annotate contained argument type
        contained: ({ disabled }) => ({
          color: disabled ? '#C4C5D1' : '#FFFFFF'
        })
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <h1> by default
          title: 'h1'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 40,
          background: '#fff',
          fontSize: 14,
          lineHeight: '24px',

          '&.Mui-disabled': {
            background: '#F3F3F6',

            input: {
              '-webkit-text-fill-color': 'rgba(1, 4, 23, 0.7)'
            }
          }
        },
        sizeSmall: {
          height: 32
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          paddingLeft: 12,
          paddingRight: 12,
          fieldset: {
            borderColor: '#dde3f6'
          },
          input: {
            paddingRight: 0,
            paddingLeft: 0
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 600,
          lineHeight: '12px',
          color: '#515468',
          textTransform: 'uppercase'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: 0
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: '#fff',
          borderRadius: 12,
          boxShadow: '0px 2px 4px rgba(31, 31, 35, 0.1)'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#dde3f6'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid #dde3f6',
          borderRadius: 12,
          boxShadow: 'none',
          ':before': {
            display: 'none'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '8px 0 0 0',
          fontSize: 12,
          fontWeight: 500,
          lineHeight: 1,
          color: '#515468'
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440
    }
  },
  palette: {
    background: {
      default: '#f0f7fe',
      secondary: '#fff',
      tertiary: '#dde3f6'
    },
    primary: {
      main: '#2721ae',
      light: '#4052F0'
    },
    secondary: {
      main: '#11c14d'
    },
    text: {
      primary: '#010417',
      secondary: '#858798',
      tertiary: '#2B2E36',
      quaternary: '#515468'
    },
    divider: '#dde3f6',
    error: {
      main: '#ff5555'
    },
    warning: {
      main: '#FFB800'
    }
  }
})

export const internalPDFTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    background: {
      ...theme.palette.background,
      default: '#ffffff',
      secondary: '#f0f7fe'
    }
  }
})
