import { DateTime } from 'luxon'
import { useBillingTracker } from '../dashboard/hooks'
import { useEffect, useState } from 'react'
import { atom, useAtom } from 'jotai'

export const useLimitedTimeOfferExpiry = () => {
  const { data: billingTracker } = useBillingTracker()
  const expiringAtString = billingTracker?.profile_completion_limited_time_offer_expired_at

  if (!expiringAtString) {
    return null
  }

  return DateTime.fromISO(expiringAtString)
}

const wasCompleted = atom(false)

export const useLimitedTimeOfferCompleted = () => {
  const [completed, setCompleted] = useAtom(wasCompleted)

  return [completed, setCompleted] as const
}

export const useLimitedTimeOfferAvailable = (expiringAt: DateTime | null): boolean => {
  const hasNoCreditCard = !useBillingTracker().data?.hasPaymentMethod
  const [completed] = useAtom(wasCompleted)

  return !!hasNoCreditCard && !!expiringAt && !completed && DateTime.now() < expiringAt
}

export const getTimeDiff = (now: DateTime, expiringAt: DateTime, format = 'hh:mm:ss') => {
  return expiringAt?.diff(now)?.toFormat(format)
}

export const useLimitedTimeOfferCountdown = (expiringAt: DateTime) => {
  const [remainingTime, setRemainingTime] = useState<string>(
    getTimeDiff(DateTime.now(), expiringAt)
  )

  useEffect(() => {
    const recompute = () => {
      const diff = getTimeDiff(DateTime.now(), expiringAt)

      setRemainingTime(diff)
    }

    const interval = setInterval(recompute, 1000)

    return () => {
      recompute()
      clearInterval(interval)
    }
  }, [expiringAt])

  return remainingTime
}
