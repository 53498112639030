import { Box, Typography } from '@mui/material'

const Heading = () => {
  return (
    <div className="self-stretch">
      <Box mt={3} className="flex justify-between">
        <Typography variant="h1" color="primary.main">
          Confirm & Pay
        </Typography>
      </Box>
    </div>
  )
}

export default Heading
