import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { RouterProvider } from 'react-router-dom'
import { initAnalytics } from './analytics'
import { theme } from './styles/theme'

import router from '@/router/routes'
import { useIntercom } from './services/intercom'

initAnalytics()

function App() {
  useIntercom()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
