import React, { useEffect } from 'react'
import {
  Typography,
  Box,
  TextField,
  InputLabel,
  Radio,
  FormControlLabel,
  SvgIcon,
  Card
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { ReactComponent as CalendarIcon } from '@/assets/icons/ic-calendar-circle.svg'
import { ReactComponent as PhoneIcon } from '@/assets/icons/ic-phone-circle.svg'
import { ReactComponent as EmailIcon } from '@/assets/icons/ic-email-circle.svg'
import PhoneInput from '@/components/Input/PhoneInput'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectLetUsHandleItFlowInquiry,
  setInquiryEmail,
  setInquiryPhone
} from '@/reducers/inquiry'
import { useAuth } from '@/reducers/user'

export const SetupMeetingCard = () => {
  return (
    <Card sx={{ padding: 2, mb: 2 }}>
      <FormControlLabel
        value="meeting"
        control={<Radio />}
        label={
          <Typography variant="body1" fontWeight={700}>
            <SvgIcon component={CalendarIcon} inheritViewBox sx={{ ml: 1, mr: 2, fontSize: 40 }} />
            Set up a meeting!
          </Typography>
        }
      />
    </Card>
  )
}

export const DiscussOverPhoneCard = ({ active, error }: any) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const inquiry = useSelector(selectLetUsHandleItFlowInquiry)
  const { user } = useAuth()

  const handleUpdate = React.useCallback(
    (phone: string) => {
      dispatch(setInquiryPhone(phone))
    },
    [dispatch, setInquiryPhone]
  )

  useEffect(() => {
    if (user?.phone) {
      handleUpdate(user?.phone)
    }
  }, [user?.phone, handleUpdate])

  return (
    <>
      <Card sx={{ padding: 2, mb: 2 }}>
        <FormControlLabel
          value="phone"
          control={<Radio />}
          label={
            <Typography variant="body1" fontWeight={700}>
              <SvgIcon
                component={PhoneIcon}
                inheritViewBox
                sx={{
                  ml: 1,
                  mr: 2,
                  fontSize: 40,
                  borderRadius: '50%',
                  backgroundColor: theme.palette.background.default,
                  fill: theme.palette.primary.main
                }}
              />
              Discuss it over phone!
            </Typography>
          }
        />
        {active && (
          <Box mt={3} width={'100%'}>
            <InputLabel sx={{ color: theme.palette.text.quaternary }}>PHONE NUMBER</InputLabel>
            <PhoneInput
              sx={{ mt: 1 }}
              value={inquiry?.phone}
              defaultValue={user?.phone}
              onChange={(event) => {
                handleUpdate(event.target.value)
              }}
              error={!!error}
            />
            <Typography
              fontSize={12}
              lineHeight={1}
              fontWeight={500}
              color={error ? theme.palette.error.main : theme.palette.text.quaternary}
              mt={1}
            >
              Please check if the phone number above is accurate
            </Typography>
          </Box>
        )}
      </Card>
    </>
  )
}

export const DiscussOverEmailCard = ({ active, error }: any) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const inquiry = useSelector(selectLetUsHandleItFlowInquiry)
  const { user } = useAuth()

  const handleUpdate = React.useCallback(
    (email: string) => {
      dispatch(setInquiryEmail(email))
    },
    [dispatch]
  )

  useEffect(() => {
    if (user?.email) {
      handleUpdate(user?.email)
    }
  }, [user?.email, handleUpdate])

  return (
    <>
      <Card sx={{ padding: 2 }}>
        <FormControlLabel
          value="email"
          control={<Radio />}
          label={
            <Typography variant="body1" fontWeight={700}>
              <SvgIcon
                component={EmailIcon}
                inheritViewBox
                sx={{
                  ml: 1,
                  mr: 2,
                  fontSize: 40,
                  borderRadius: '50%',
                  backgroundColor: theme.palette.background.default
                }}
              />
              Discuss it over email!
            </Typography>
          }
        />
        {active && (
          <Box mt={3} width={'100%'}>
            <InputLabel sx={{ color: theme.palette.text.quaternary }}>EMAIL ADDRESS</InputLabel>
            <TextField
              helperText="Please check if the email address above is accurate"
              variant="outlined"
              fullWidth
              type="email"
              error={!!error}
              value={inquiry?.email}
              onChange={(event) => {
                handleUpdate(event.target.value)
              }}
              sx={{ mt: 1 }}
            />
          </Box>
        )}
      </Card>
    </>
  )
}

export const KnowYourCost = () => {
  return (
    <>
      <Typography variant="h3">Know your costs</Typography>
      <Typography mt={1} textAlign={'justify'}>
        After we process your uploaded file, we&apos;ll provide you with a detailed price estimate
        so you can make an informed decision. Our transparent pricing approach ensures that
        you&apos;ll have a clear understanding of any costs or fees associated with your request
        before we proceed. While we try to get back to you as soon as possible, please note that
        this process usually takes up to 1-2 days.
      </Typography>
    </>
  )
}
