import { AccuracyMatchChip } from '@/components/common/AccuracyMatchChip'
import { AccuracyTooltip } from './Tooltips'

const MediumAccuracyMatchChip = () => (
  <AccuracyMatchChip
    color="info"
    label="Medium Accuracy Match"
    wrappWithTooltip={AccuracyTooltip}
  />
)

export default MediumAccuracyMatchChip
