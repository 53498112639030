import { useFormContext } from 'react-hook-form'

const useFormContextProvider = () => {
  const form = useFormContext()

  if (!form) throw new Error('The form context can not be used outside of the FormProvider')

  return form
}

export default useFormContextProvider
