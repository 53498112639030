import { Button, Grid, IconButton, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { ReactComponent as CircleQuestionIcon } from '@/assets/icons/ic-circle-question.svg'
import { CONTACT_US_URL } from '@/constants/mix'
import { useState } from 'react'
import { ArcherModal } from '@/components/Modal/ArcherModal'
import CloseIcon from '@mui/icons-material/Close'

const InternalError = () => {
  const [open, setOpen] = useState(false)
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        textAlign: 'center'
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: '4rem', sm: '72px' },
          fontWeight: 800
        }}
        color="primary.main"
      >
        Oops! Something <br /> Went Wrong
      </Typography>
      <Typography variant="h3" marginTop={'24px'}>
        Your browser is using outdated data, which is preventing the latest <br /> version of our
        site from loading correctly. Clearing your cache should <br /> get everything working
        smoothly again.
      </Typography>
      <Button
        sx={{
          marginTop: '40px'
        }}
        size="large"
        startIcon={<CircleQuestionIcon />}
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        How to clear your cache
      </Button>
      <p className="mt-10">
        Still having issues?{' '}
        <Link to={CONTACT_US_URL} className="no-underline text-primary.main">
          {' '}
          Contact Us
        </Link>
      </p>
      <ArcherModal
        hiddenCloseButton
        open={open}
        setOpen={setOpen}
        cardSx={{
          maxWidth: '560px',
          padding: '32px'
        }}
      >
        <div className="flex justify-between items-center mt-3">
          <Typography variant="h2">How to Clear Your Browser Cache</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon
              sx={{
                color: '#04137D'
              }}
            />
          </IconButton>
        </div>

        <Grid
          sx={{
            padding: '8px 8px 24px 8px'
          }}
        >
          <ul className="list-disc px-6 py-4 mb-0">
            <li className="pb-4 font-bold">
              Chrome:{' '}
              <Typography>
                Go to <b>Settings</b> &gt; <b>Clear browsing data</b>. <br />
                Check &quot;Cookies and other site data&quot; and &quot;Cached images and
                files,&quot; then click <b>Clear data</b>.
              </Typography>
            </li>
            <li className="pb-4 font-bold">
              Firefox:{' '}
              <Typography>
                Go to <b>Options</b> &gt; <b>Privacy & Security</b> &gt;{' '}
                <b>Cookies and Site Data</b>, then click <b>Clear Data</b>.
              </Typography>
            </li>
            <li className="pb-4 font-bold">
              Safari:{' '}
              <Typography>
                Go to <b>Preferences</b> &gt; <b>Privacy</b>, click <b>Manage Website Data</b>, and
                select <b> Remove All</b>.
              </Typography>
            </li>
            <li className="pb-2 font-bold">
              Edge:{' '}
              <Typography>
                Go to <b>Settings</b> &gt; <b>Privacy, search, and services, under </b> &quot;Clear
                browsing data,&quot; select <b>Choose what to clear</b>,check &quot;Cached images
                and files,&quot; then click <b>Clear now</b>.
              </Typography>
            </li>
          </ul>
          <Typography>
            After clearing your cache, please <b>refresh the page</b> to load the latest version of
            our site.
          </Typography>
        </Grid>
      </ArcherModal>
    </Grid>
  )
}

export default InternalError
