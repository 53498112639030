/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      colors: {
        'background.default': '#f0f7fe',
        'background.secondary': '#fff',
        'background.tertiary': '#dde3f6',
        'primary.main': '#2721ae',
        'secondary.main': '#11c14d',
        'text.primary': '#010417',
        'text.secondary': '#858798',
        'text.quaternary': '#515468',
        danger: '#FF5555',
        warning: '#FFB800'
      }
    }
  },
  plugins: [],
  corePlugins: {
    preflight: false
  },
  important: true
}
