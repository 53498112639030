import { Button } from '@mui/material'
import { useCallback, useState } from 'react'
import { getBillingSheetPdf } from '@/services/payments'
import { ReactComponent as IcDownload } from '@/assets/icons/ic-download.svg'

export const TransactionInvoice = ({
  billingSheetsId,
  isDisabled,
  title
}: {
  billingSheetsId: string[] | undefined
  isDisabled: boolean
  title: string
}) => {
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<unknown>(undefined)

  const onDownload = useCallback(async () => {
    console.log('Clicked on ' + title)
    setIsLoading(true)
    if (!billingSheetsId) {
      return
    }
    for (const billingSheetId of billingSheetsId) {
      console.log('Downloading invoice with billingSheetId = ', billingSheetId)
      try {
        const response = await getBillingSheetPdf(billingSheetId)

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(response.data)
        link.download = `archer-receipt-${billingSheetId}.pdf`
        link.click()
      } catch (error) {
        setError(error)
      }
    }
    setIsLoading(false)
  }, [billingSheetsId])

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<IcDownload className="fill-text.secondary" />}
      onClick={onDownload}
      disabled={isLoading || isDisabled}
    >
      {title}
    </Button>
  )
}

export default TransactionInvoice
