import { BillingSheet } from '@/types/billing'
import { formatNumber } from '@/utils'
import { Box, Typography, useTheme } from '@mui/material'
import { useMemo } from 'react'
import TransactionAmount from '../TransactionAmount'

const TotalRow = ({
  billingSheet,
  pdfTemplate = false
}: {
  billingSheet: BillingSheet
  pdfTemplate?: boolean
}) => {
  const theme = useTheme()

  const initialAmount = billingSheet.amount
  const finalAmount = billingSheet.transaction?.amountReceived

  const total = useMemo(() => {
    return billingSheet.items.reduce((acc, item) => {
      const price =
        item.assetDoc[0].usedCredits > 0
          ? item.assetDoc[0].usedCredits
          : item.assetDoc[0].statistics.totalEnriched

      return acc + price
    }, 0)
  }, [billingSheet.items])

  return (
    <>
      <Typography variant="body1" color="text.quaternary">
        <b>Total:</b>
      </Typography>
      <Box
        className="flex justify-between items-center"
        sx={{
          backgroundColor: pdfTemplate
            ? theme.palette.background.secondary
            : theme.palette.background.default,
          p: 2,
          borderRadius: 2,
          mt: 1
        }}
      >
        <Typography variant="body1">
          <b>{formatNumber(total)} credits used</b>
        </Typography>
        <TransactionAmount
          variant="h2"
          finalAmount={finalAmount}
          initialAmount={initialAmount}
          fontWeight={900}
        />
      </Box>
    </>
  )
}
export default TotalRow
