import { useTrackAuth } from '@/analytics/hooks'
import CenteredCircularProgress from '@/components/Progress/CenteredCircularProgress'
import useRedirectToQueryParam from '@/hooks/useRedirectToQueryParam'
import { useAuth } from '@/reducers/user'
import { Navigate, Outlet } from 'react-router-dom'

const SIGN_IN = '/auth/sign-in'

const PrivateRoutes = () => {
  const { user, isLoading } = useAuth()
  const { redirectToQueryParam } = useRedirectToQueryParam()

  useTrackAuth()

  if (isLoading) return <CenteredCircularProgress />

  if (!user) {
    return <Navigate to={`${SIGN_IN}${redirectToQueryParam}`} />
  }

  return <Outlet />
}

export default PrivateRoutes
