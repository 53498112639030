import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Typography
} from '@mui/material'
import { useAuth } from '@/reducers/user'
import { ROLES } from '@/types/user'
import { useState } from 'react'
import { updateProfile } from '@/services/user'

export const useProfileAdminPanel = () => {
  const { user, revalidate } = useAuth()
  const [role, setRole] = useState(user?.role.name)

  const [mockTotalEnriched, setMockTotalEnriched] = useState(user?.adminConfigs?.mockTotalEnriched)
  const updatePayload = { role, adminConfigs: { mockTotalEnriched } }

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault()
    }
    await updateProfile(updatePayload)
    await revalidate()
  }

  return { handleSubmit, role, setRole, mockTotalEnriched, updatePayload, setMockTotalEnriched }
}

const AdminPanel = () => {
  const { user } = useAuth()
  const { handleSubmit, updatePayload, role, setRole, mockTotalEnriched, setMockTotalEnriched } =
    useProfileAdminPanel()

  if (!process.env.REACT_APP_ALLOW_PROFILE_ADMIN && user?.role.name !== ROLES.ADMIN) {
    return null
  }

  return (
    <div>
      <Typography variant="h1">Admin panel</Typography>
      <form onSubmit={handleSubmit} className="flex flex-col items-start">
        <Accordion>
          <AccordionSummary>
            <Typography>Current user info payload</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{JSON.stringify(user, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded>
          <AccordionSummary>
            <Typography>Update payload</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{JSON.stringify(updatePayload, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>

        <select
          name="role"
          id="cars"
          value={role}
          onChange={(event) => {
            setRole(event.target.value as ROLES)
          }}
        >
          {Object.values(ROLES).map((role) => (
            <option value={role} key={role}>
              {role}
            </option>
          ))}
        </select>
        <Card sx={{ p: 2 }}>
          <p>Mock total enriched - this number will be used to mock the enrichments.</p>
          <input
            type="number"
            value={mockTotalEnriched}
            onChange={(event) => {
              setMockTotalEnriched(event.target.value as unknown as number)
            }}
          />
          <p>
            Server uses:&nbsp;
            <code>totalEnriched = min(totalSubmitted, mockTotalEnriched)</code>
          </p>
          <p>! If mockTotalEnriched is 0, match accuracy will be 100%</p>
          <p>Server will yield other statistic randomly</p>
        </Card>
        <Button type="submit">Submit</Button>
      </form>
    </div>
  )
}

export default AdminPanel
