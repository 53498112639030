import { Asset } from '@/types/assets'
import { ReactComponent as SadFaceIcon } from '@/assets/icons/ic-sad-face.svg'
import { Button, Typography } from '@mui/material'

const AssetError = ({
  onRestart
}: {
  onRestart: (newAssetId?: string) => unknown | undefined
  asset: Asset
}) => {
  return (
    <div className="flex flex-col gap-6 items-center">
      <div className="flex flex-col gap-2 items-center">
        <SadFaceIcon className="h-10 w-10 fill-primary.main" />
        <Typography variant="h3" color="primary.main">
          Oops! Something went wrong.
        </Typography>
      </div>
      <div className="flex flex-col items-center">
        <Typography variant="caption" fontWeight={700} className="text-center text-pretty">
          We&apos;re experiencing technical difficulties in our system, but our team is working to
          fix it. Please try again later.
        </Typography>
        <Typography variant="caption" className="md:w-3/5 text-center text-pretty">
          Don&apos;t worry - you won&apos;t be charged for anything and all paid funds will be
          returned back. If the issue persists, feel free to contact our team for assistance. We
          apologize for any inconvenience.
        </Typography>
      </div>
      <div className="flex gap-4 items-center">
        <Button size="large" variant="outlined">
          Contact Us
        </Button>
        <Button size="large" variant="contained" onClick={() => onRestart()}>
          New Lookup
        </Button>
      </div>
    </div>
  )
}

export default AssetError
