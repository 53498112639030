import React from 'react'
import {
  TextField,
  StandardTextFieldProps,
  InputAdornment,
  IconButton,
  SvgIcon
} from '@mui/material'
import { Search } from '@mui/icons-material'
import { SxProps, Theme } from '@mui/material/styles'

import { ReactComponent as CrossIcon } from '@/assets/icons/ic-cross.svg'
import { Control, Controller, FieldValues } from 'react-hook-form'

interface ComponentProps extends StandardTextFieldProps {
  placeholder?: string
  sx?: SxProps<Theme>
  onClear?: () => void
  control?: Control<FieldValues> | undefined
  name: string
}

export const SearchInput: React.FC<ComponentProps> = ({
  placeholder = 'Search',
  value,
  onClear,
  control,
  ...otherProps
}) => {
  return (
    <Controller
      name={otherProps.name}
      control={control}
      render={({ field }) => (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
            endAdornment: value ? (
              <IconButton aria-label="Clear" onClick={() => onClear && onClear()} size="small">
                <SvgIcon component={CrossIcon} inheritViewBox sx={{ fontSize: 16 }} />
              </IconButton>
            ) : (
              <></>
            )
          }}
          variant="outlined"
          type="text"
          placeholder={placeholder}
          {...field}
          {...otherProps}
        />
      )}
    />
  )
}

export default SearchInput
