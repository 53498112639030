import { AlertColor } from '@mui/material'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { orderBy } from 'lodash'

import type { RootState } from './'

export interface NotificationState {
  read?: boolean
  type?: AlertColor
  title?: string
  message?: string
  createdAt?: number | null
  fileUrl?: string
}

export interface NotificationsState {
  notifications: Array<NotificationState>
}

export const notificationInitialState: NotificationsState = {
  notifications: []
}

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    addNotification: (_state, action: PayloadAction<NotificationState>) => {
      _state.notifications.push({ ...action.payload, read: false })
    },
    clearNotification: (state) => {
      state.notifications[state.notifications.length - 1].read = true
    },
    clearAllNotifications: (state) => {
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        read: true
      }))
    }
  }
})

export const { addNotification, clearNotification, clearAllNotifications } =
  NotificationSlice.actions

export const selectNotifications = (state: RootState) =>
  orderBy(state.notification.notifications, 'createdAt', 'desc')

export const selectUnreadNotifications = (state: RootState) =>
  orderBy(
    state.notification.notifications.filter((notification) => !notification.read),
    'createdAt',
    'desc'
  )

export const selectLastNotification = (state: RootState) =>
  state.notification.notifications[state.notification.notifications.length - 1]

export default NotificationSlice.reducer
