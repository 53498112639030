import MuiStepper from '@mui/material/Stepper'
import { ReactComponent as CheckIcon } from '@/assets/icons/ic-check.svg'
import {
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  SvgIcon,
  stepConnectorClasses,
  stepLabelClasses,
  styled
} from '@mui/material'

const defaultSteps = [
  { name: 'Upload & map file' },
  { name: 'Select contact method' },
  { name: 'Confirmation' }
]

interface StepProgressBarProps {
  stepIdx: number
  className?: string
  steps?: { name: string }[]
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 15px)',
    right: 'calc(50% + 15px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#9BB0C9',
    borderTopWidth: 3,
    borderRadius: 1
  }
}))

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    // color: theme.palette.grey[700],
    color: 'red',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.primary.main
    }),
    '& .CustomStepIcon-completedIcon': {
      color: theme.palette.background.secondary,
      background: theme.palette.primary.main,
      borderRadius: '50%',
      zIndex: 1,
      fontSize: 18,
      width: 32,
      height: 32,
      padding: 6
    },
    '& .CustomStepIcon-activeIcon': {
      color: theme.palette.primary.main,
      textAlign: 'center',
      borderRadius: '30px',
      border: `2px solid ${theme.palette.primary.main}`,
      zIndex: 1,
      width: 32,
      height: 32,
      paddingTop: '3px',
      fontWeight: 700,
      fontSize: '14px'
    },
    '& .CustomStepIcon-futureIcon': {
      color: '#9BB0C9',
      textAlign: 'center',
      borderRadius: '30px',
      border: `2px solid #9BB0C9`,
      zIndex: 1,
      width: 32,
      height: 32,
      paddingTop: '3px',
      fontWeight: 700,
      fontSize: '14px'
    }
  })
)

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props

  return (
    <CustomStepIconRoot ownerState={{ active }} className={className}>
      {!!completed && (
        <SvgIcon viewBox="0 0 16 16" className="CustomStepIcon-completedIcon">
          <CheckIcon />
        </SvgIcon>
      )}
      {!completed && !!active && <div className="CustomStepIcon-activeIcon">{icon}</div>}
      {!completed && !active && <div className="CustomStepIcon-futureIcon">{icon}</div>}
    </CustomStepIconRoot>
  )
}

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.active}`]: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: '700 !important'
  },
  [`& .${stepLabelClasses.completed}`]: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: '500 !important'
  },
  [`& .${stepLabelClasses.disabled}`]: {
    color: '#9BB0C9 !important',
    fontWeight: '500 !important'
  }
}))

const Stepper = ({ stepIdx, className, steps = defaultSteps }: StepProgressBarProps) => {
  return (
    <MuiStepper
      className={className}
      activeStep={stepIdx}
      alternativeLabel
      connector={<CustomConnector />}
    >
      {steps.map((step) => (
        <Step key={step.name}>
          <CustomStepLabel StepIconComponent={CustomStepIcon}>{step.name}</CustomStepLabel>
        </Step>
      ))}
    </MuiStepper>
  )
}

export default Stepper
