import { Box, Button, Container, Tooltip, Typography } from '@mui/material'
import SettingsTabs from '../tabs'
import { useAuth } from '@/reducers/user'
import { ReactComponent as PencilIcon } from '@/assets/icons/ic-pencil.svg'
import { ReactComponent as WarningIcon } from '@/assets/icons/ic-warning-octagon.svg'
import DeleteAccount from './DeleteAccount'
import AdminPanel from './AdminPanel'
import { useAuthGuardPageTracker } from '@/analytics'
import { useVerifyEmail } from './hooks'
import Banners from '@/pages/dashboard/Banners'

const PersonalInfo = ({
  label,
  value,
  children
}: {
  label: string
  value: string
  children?: React.ReactElement | null
}) => {
  return (
    <Box>
      <Typography
        variant="caption"
        fontSize={12}
        color="text.quaternary"
        fontWeight={600}
        lineHeight={1}
      >
        {label}
      </Typography>
      <div className="flex gap-1 items-center">
        <Typography variant="body1" fontWeight={700}>
          {value}
        </Typography>
        {children}
      </div>
    </Box>
  )
}

const VerifyEmail = () => {
  const { canSend, isLoading, countdown, handleSendEmail } = useVerifyEmail('Profile Settings')

  return (
    <div className="flex gap-4 items-center">
      <Tooltip title="Activate your account by verifying your email">
        <WarningIcon className="fill-warning h-3 w-3" />
      </Tooltip>
      <Button
        size="small"
        variant="contained"
        onClick={handleSendEmail}
        disabled={!canSend || isLoading}
      >
        Verify email
      </Button>
      {!canSend ? (
        <Typography variant="caption" color="text.quaternary">
          You can resend in {countdown} seconds
        </Typography>
      ) : null}
    </div>
  )
}

const trackingPayload = { page: 'Profile Settings' } as const

const SettingsProfilePage = () => {
  useAuthGuardPageTracker('Visits Profile Settings Page', trackingPayload)
  const { user } = useAuth()

  const isUserRequiringActivation = user?.activatedAt === null

  return (
    <>
      <Banners page="Profile Settings" />
      <div className="flex-1 flex flex-col">
        <SettingsTabs />

        <Container sx={{ py: 5 }} className="flex-1 flex flex-col">
          <Typography variant="h1" color="primary.main">
            Profile settings
          </Typography>
          {!!user && (
            <Box mt={5} className="flex-1 flex flex-col items-start" gap={3}>
              <Typography variant="h2">Personal information</Typography>

              <PersonalInfo label="FULL NAME" value={user?.fullName} />
              <PersonalInfo label="EMAIL" value={user?.email}>
                {isUserRequiringActivation ? <VerifyEmail /> : null}
              </PersonalInfo>
              <PersonalInfo label="PHONE NUMBER" value={user?.phone} />

              <Button
                startIcon={<PencilIcon className="h-4" />}
                variant="outlined"
                size="large"
                href="/settings/profile/change-password"
              >
                Change password
              </Button>

              <div className="flex-1" />

              <DeleteAccount />
            </Box>
          )}
          <AdminPanel />
        </Container>
      </div>
    </>
  )
}

export default SettingsProfilePage
