import { formatNumber } from '@/utils'
import { twMerge } from 'tailwind-merge'

import { Box, Divider, Stack, Typography } from '@mui/material'
import { useBillingTracker } from '../dashboard/hooks'
import { LimitedTimeOfferCTA } from './CTA'
import { useLimitedTimeOfferAvailable, useLimitedTimeOfferExpiry } from './hooks'

export const TopOuterContainer = ({
  className,
  children
}: {
  className?: string
  children: React.ReactNode
}) => {
  return (
    <div
      className={twMerge(
        'right-1/2 rounded-full bg-primary.main absolute top-0 px-3 py-1  translate-x-1/2 -translate-y-1/2',
        className
      )}
    >
      {children}
    </div>
  )
}

const UsageBasedPricing = ({ onModalOpen }: { onModalOpen: () => unknown }) => {
  const expiringAt = useLimitedTimeOfferExpiry()
  const isLimitedTimeOfferAvailable = useLimitedTimeOfferAvailable(expiringAt)
  const { data: billingTracker } = useBillingTracker()
  const freeCredits = billingTracker?.free_credits ?? 0

  return (
    <div className="relative flex-grow md:flex-grow-0">
      {freeCredits > 0 ? (
        <TopOuterContainer>
          <div className="text-white text-sm whitespace-nowrap">
            {formatNumber(freeCredits)} Free Lookups Left
          </div>
        </TopOuterContainer>
      ) : null}
      <div className="flex flex-col rounded-2xl overflow-hidden bg-white flex-shrink shadow-md md:max-w-[475px]">
        <div className="px-6 py-6 flex flex-col">
          <Stack gap={2}>
            <Box>
              <Box fontWeight="bold">With Archer, you only pay for successful searches!</Box>
              Our system operates on credits. You can pay as you go, or get billed automatically at
              the end of each billing cycle or when your balance exceeds $375.
            </Box>
          </Stack>
          <Divider className="my-6 opacity-100" />
          <Stack gap={2}>
            <Box>
              <Typography className="text-base" variant="h4" color="primary.main" fontWeight={700}>
                1 Lookup = 1 Credit
              </Typography>
              <Box className="text-xs">
                You only pay for successful lookups when we find a personal email, phone number, or
                both.
              </Box>
            </Box>
            <Box>
              <Typography className="text-base" variant="h4" color="primary.main" fontWeight={700}>
                1 Credit = $0.75
              </Typography>
              <Box className="text-xs">for your first 500 credits used per billing cycle</Box>
            </Box>
          </Stack>
        </div>

        {isLimitedTimeOfferAvailable && expiringAt ? (
          <LimitedTimeOfferCTA expiringAt={expiringAt} onAction={onModalOpen} />
        ) : null}
        {/* <CompleteAccountCTA /> */}
      </div>
    </div>
  )
}

export default UsageBasedPricing
