import { Asset } from '@/types/assets'

export const getNameOfAsset = (asset: Asset) => {
  const { firstName, lastName, fullName } = asset.singleEnrichment || {}

  return fullName
    ? fullName
    : firstName === undefined || lastName === undefined
    ? undefined
    : `${firstName} ${lastName}`
}
