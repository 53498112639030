import { formatNumber } from '@/utils'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useBillingTracker } from './hooks'

const FreeTrialBadge = ({ size }: { size?: 'large' | 'small' | undefined }) => {
  const { data: billingTracker } = useBillingTracker()

  const textClasses = useMemo(() => {
    if (size === 'large') {
      return 'text-sm md:text-md leading-6'
    }

    return 'text-xs md:text-sm leading-4'
  }, [size])

  const containerClasses = useMemo(() => {
    if (size === 'large') {
      return 'px-2 py-1 md:px-4 md:py-2'
    }

    return 'px-2 py-1 md:px-3 md:py-1.5'
  }, [size])

  if (!billingTracker?.free_credits) {
    return null
  }

  if (billingTracker.free_credits <= 0) {
    return null
  }

  return (
    <Box
      className={clsx('rounded-full flex items-center ', containerClasses)}
      sx={{
        background:
          'linear-gradient(74.37deg, #A7A4EE 6.07%, #352ED8 42.6%, #4052F0 84.16%, #04137D 126.99%)'
      }}
    >
      <Typography color="white" className={textClasses}>
        You have {formatNumber(billingTracker.free_credits)} free matches
      </Typography>
    </Box>
  )
}

export default FreeTrialBadge
