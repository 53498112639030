import { Alert } from '@mui/material'
import { useAuth } from '@/reducers/user'
import { ROLES } from '@/types/user'

const AdminMode = () => {
  const { user } = useAuth()

  if (user?.role.name !== ROLES.ADMIN) {
    return null
  }

  return (
    <div className="fixed bottom-0 right-0 pointer-events-none">
      <Alert variant="filled" severity="info">
        Admin mode
      </Alert>
    </div>
  )
}

export default AdminMode
