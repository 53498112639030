import { yupResolver } from '@hookform/resolvers/yup'
import ReplayIcon from '@mui/icons-material/Replay'
import { LoadingButton } from '@mui/lab'
import { Box, Stack, SvgIcon, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as WorkEmailIcon } from '@/assets/icons/ic-briefcase.svg'
import { ReactComponent as CheckedIcon } from '@/assets/icons/ic-check-gradient.svg'
import { ReactComponent as LightBulbIcon } from '@/assets/icons/ic-light-bulb.svg'

import { trackEvent } from '@/analytics'
import FormInput from '@/components/Form/Input'
import { Apis } from '@/providers/ApiProvider'
import { processAsset } from '@/services/asset'
import { Asset } from '@/types/assets'
import { CompanyFormValues, companyFormValuesValidationSchema } from '@/types/form-data'

const capitalizeEveryWord = (str?: string) => {
  if (!str) {
    return str
  }

  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const isCompanyUrl = (str?: string) => {
  if (!str) {
    return false
  }

  return str.startsWith('http') || str.startsWith('www')
}

const RetryForm = ({
  onRestart,
  asset,
  component: MessageComponent
}: {
  onRestart: (newAssetId?: string) => unknown
  asset: Asset
  component: React.ComponentType<{ asset: Asset }>
}) => {
  const isCompanyNameAsUrl = isCompanyUrl(asset.singleEnrichment?.company)

  const { control, handleSubmit, formState } = useForm<CompanyFormValues>({
    defaultValues: {
      fullName: isCompanyNameAsUrl ? '' : capitalizeEveryWord(asset.singleEnrichment?.fullName),
      company: ''
    },
    resolver: yupResolver(companyFormValuesValidationSchema)
  })
  const navigate = useNavigate()

  const handleCompanyFormSubmit = async (formData: CompanyFormValues) => {
    trackEvent('Single Search - Get Contact Info', {
      page: 'Dashboard',
      type: 'company',
      requestWorkEmails: asset.requestWorkEmails,
      formPayload: formData
    })

    const { data } = await Apis.postAsset({
      filename: `${formData.fullName}`,
      requestWorkEmails: asset.requestWorkEmails,
      requestedType: 'company',
      rows: [formData]
    })

    await processAsset({ assetId: data.asset._id, navigate: navigate })

    return data.asset._id
  }

  const handleFormSubmit = async (values: CompanyFormValues) => {
    const id = await handleCompanyFormSubmit(values)

    onRestart(id)
  }

  const boxWidth =
    !asset.singleEnrichment.enrichedPhone && !asset.singleEnrichment.enrichedEmail
      ? 'max-w-[80%]'
      : 'max-w-[100%]'

  const { isSubmitting } = formState

  return (
    <>
      <div className="flex gap-2 items-center max-w-[80%]">
        <Typography className="flex" variant="body1" fontWeight={700}>
          <Box>
            <LightBulbIcon className="fill-primary.main flex-shrink-0 mb-1" />
          </Box>
          <Box className="mx-3">
            <MessageComponent asset={asset} />
          </Box>
        </Typography>
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box className={boxWidth}>
          <Stack
            className="mt-4"
            direction="row"
            spacing={{ lg: 0, xl: 2, sm: 0 }}
            justifyContent="center"
            alignContent="center"
            display={{
              lg: 'block',
              xl: 'flex',
              sm: 'block',
              xs: 'block'
            }}
            sx={{
              label: {
                fontWeight: 500,
                color: '#515468'
              }
            }}
          >
            <Box className="flex-grow">
              <FormInput
                formState={formState}
                control={control}
                name="fullName"
                label="FULL NAME"
                controlId="fieldFullName"
                rules={{ required: true }}
              />
            </Box>
            <Box className="flex-grow">
              <FormInput
                control={control}
                formState={formState}
                name="company"
                label="COMPANY WEBSITE"
                controlId="fieldCompany"
                rules={{ required: true }}
              />
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              display: 'flex'
            }}
          >
            <Box>
              {/* TODO: Create a reusable component */}
              <Stack direction="row" alignItems="center" gap={0.3}>
                <SvgIcon
                  className="mt-2"
                  component={CheckedIcon}
                  color="primary"
                  sx={{
                    width: '2.5rem',
                    height: '2.5rem'
                  }}
                />
                <SvgIcon className="mt-1" component={WorkEmailIcon} color="primary" />
                <Typography variant="body1" fontWeight="700">
                  Work Emails
                </Typography>
              </Stack>
            </Box>
            <Box>
              <LoadingButton
                loading={isSubmitting}
                className="w-full"
                type="submit"
                variant="outlined"
                startIcon={<ReplayIcon />}
                loadingPosition="start"
                disabled={isSubmitting}
              >
                Retry
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </form>
    </>
  )
}

export default RetryForm
