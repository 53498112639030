import { api, Apis } from '@/providers/ApiProvider'
import { Asset, AssetFilter, AssetResponse } from '@/types/assets'
import { isArray } from 'lodash'
import useSWR, { useSWRConfig } from 'swr'
import { Order } from '../history/AssetsTable'

type UseAssetsConfig = {
  pageNumber?: number
  perPage?: number
  filters?: AssetFilter
  isSingle?: boolean
  acceptEmpty?: boolean
  order?: Order
  orderBy?: Required<keyof Asset>
}

export const ASSETS_QUERY_KEY = 'assets'

const DEFAULT_REFRESH_INTERVAL = 5000

const fetcher = async (url: string) => {
  const result = await api.get<AssetResponse>(url)

  return result.data
}

export const useRefetchAssets = () => {
  const { mutate } = useSWRConfig()

  return () =>
    mutate((key) => isArray(key) && key[0] === 'assets', undefined, {
      revalidate: true
    })
}

export const fetchAssets = async ({
  pageNumber = 1,
  perPage = 5,
  filters = {},
  acceptEmpty = false,
  isSingle,
  order = 'desc',
  orderBy = 'createdAt'
}: UseAssetsConfig) => {
  if (isSingle) filters.single = isSingle

  const result = await Apis.getAllAssets(
    pageNumber,
    perPage,
    acceptEmpty,
    { ...filters },
    order,
    orderBy
  )

  return result.data
}

export const useAsset = (
  id: string,
  config: { refreshInterval: number } = {
    refreshInterval: DEFAULT_REFRESH_INTERVAL
  }
) => {
  return useSWR(id ? `/asset/${id}` : null, fetcher, config)
}

export const useAssets = ({
  pageNumber = 1,
  perPage = 5,
  filters = {},
  isSingle = undefined,
  acceptEmpty = false,
  order = 'desc',
  orderBy = 'createdAt'
}: UseAssetsConfig = {}) => {
  const swr = useSWR(
    [ASSETS_QUERY_KEY, pageNumber, perPage, filters, isSingle, acceptEmpty, order, orderBy],
    ([
      _,
      argPageNumber,
      argPerPage,
      argFilters,
      argIsSingle,
      argAcceptEmpty,
      argOrder,
      argOrderBy
    ]) =>
      fetchAssets({
        pageNumber: argPageNumber,
        perPage: argPerPage,
        filters: argFilters,
        isSingle: argIsSingle,
        acceptEmpty: argAcceptEmpty,
        order: argOrder,
        orderBy: argOrderBy
      }),
    {
      revalidateOnFocus: false
    }
  )

  const { data, isLoading, mutate, isValidating } = swr

  const refetch = mutate

  return { data, isLoading, refetch, isValidating }
}

export const useVerifyAndReplaceAssets = () =>
  useAssets({ filters: { requestedType: 'verify-replace-work', single: true } })
