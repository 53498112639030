/* eslint-disable @typescript-eslint/no-unused-vars */
import { lookupTypes } from '@/types/assets'
import { BillingSheet, BillingSheetItem } from '@/types/billing'
import { formatMoney, formatNumber } from '@/utils'
import { normalizeDate } from '@/utils/date'
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    padding: '4px 10px'
  },
  '&:nth-of-type(odd) td': {
    backgroundColor: `${theme.palette.background.default}`
  },
  '&:nth-of-type(even) td': {
    backgroundColor: `${theme.palette.background.secondary}`
  },
  '& td:first-of-type': {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8
  },
  '& td:last-child': {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8
  }
}))

const ItemsTable = ({ billingSheet }: { billingSheet: BillingSheet }) => {
  const creditUsed = (item: BillingSheetItem) => {
    const { usedCredits } = item.assetDoc[0]
    const value = usedCredits > 0 ? usedCredits : item.assetDoc[0].statistics.totalEnriched

    return formatNumber(value)
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            className="uppercase"
            sx={{
              '& th': {
                fontSize: 12
              }
            }}
          >
            <TableCell>Type</TableCell>
            <TableCell>Credit Used</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Date Performed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {billingSheet.items.map((item) => (
            <StyledTableRow key={item._id}>
              <TableCell className="font-bold">
                {lookupTypes[item.assetDoc[0].requestedType]}
              </TableCell>
              <TableCell align="right" className="font-bold">
                {creditUsed(item)}
              </TableCell>
              <TableCell align="right" className="font-bold">
                {formatMoney(item.cost)}
              </TableCell>
              <TableCell align="right">{normalizeDate(item.createdAt)}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ItemsTable

// https://www.totaltypescript.com/books/total-typescript-essentials/objects#reducing-duplication-with-utility-types
