import { Button, Typography } from '@mui/material'
import { ArcherModal, ArcherModalBottomActions } from './ArcherModal'
import { useAuth } from '@/reducers/user'
import { atom, useAtom } from 'jotai'

const isOpen = atom(false)

export const useEmailSentModal = () => {
  const [open, setOpen] = useAtom(isOpen)

  return [open, setOpen] as const
}

const EmailSentModal = () => {
  const [open, setOpen] = useEmailSentModal()

  const { user } = useAuth()

  return (
    <ArcherModal open={open} setOpen={setOpen}>
      <Typography variant="h2" marginRight={2}>
        Email verification sent
      </Typography>
      <Typography variant="body1" mt={3} mb={4}>
        Please check the email we sent to <b>{user?.email}</b> to verify your account.
        <br />
        If you don&apos;t receive the email within a few minutes, check your spam folder.
      </Typography>
      <ArcherModalBottomActions>
        <div className="flex justify-end">
          <Button variant="contained" size="large" onClick={() => setOpen(false)}>
            Got it!
          </Button>
        </div>
      </ArcherModalBottomActions>
    </ArcherModal>
  )
}

export default EmailSentModal
