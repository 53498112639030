import { Box, SxProps, useTheme } from '@mui/material'
import { ReactComponent as AlertIcon } from '@/assets/icons/ic-alert.svg'

const ErrorAlert = ({
  sx,
  children
}: {
  sx?: SxProps
  children: React.ReactNode | React.ReactElement
}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: `#FFD5D5`,
        borderRadius: '8px',
        border: `1px solid ${theme.palette.error.main}`,
        padding: '8px 16px 8px 16px',
        ...sx
      }}
    >
      <div className="flex">
        <AlertIcon className="fill-danger flex-shrink-0 h-4 w-4 mr-4 my-2" />
        {children}
      </div>
    </Box>
  )
}

export default ErrorAlert
