import { SvgIcon, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePrevious } from 'react-use'

import { ReactComponent as LightBulbIcon } from '@/assets/icons/ic-light-bulb.svg'
import CircularProgress from '@/components/Progress/CircularProgress'
import { useAsset, useRefetchAssets } from '@/pages/asset/hooks'
import { useAuth } from '@/reducers/user'
import { processAsset } from '@/services/asset'
import { AssetStatus } from '@/types/assets'
import { useClearSearchParam } from '@/utils'
import AssetError from './AssetError'
import AssetResult from './AssetResult'
import AssetSummary from './AssetSummary'

const LoadingAsset = ({
  assetId,
  startProcessing
}: {
  assetId: string
  startProcessing?: boolean
}) => {
  const navigate = useNavigate()

  const { data } = useAsset(assetId, { refreshInterval: 5000 })

  useEffect(() => {
    if (!startProcessing || !assetId) {
      return
    }

    processAsset({ assetId: assetId, navigate: navigate })
  }, [startProcessing, assetId, navigate])

  return (
    <div className="flex flex-col items-center mb-40 mt-32 gap-8">
      <CircularProgress size={64} />
      <div className="flex flex-col items-center">
        <Typography variant="h2">Working on it!</Typography>
        <Typography variant="body1" className="mt-2" fontWeight={400}>
          This could take a minute or two.
        </Typography>
        {data?.asset.requestedType === 'company' && (
          <Typography variant="body1" className="mt-6 text-center" color="primary.main">
            <SvgIcon viewBox="0 0 20 16" className="pb-1">
              <LightBulbIcon />
            </SvgIcon>{' '}
            <b>Did you know?</b> Providing the company URL instead of the name can greatly enhance
            the accuracy & speed of your search.
          </Typography>
        )}
      </div>
    </div>
  )
}

const useRefreshTable = (assetStatus?: AssetStatus) => {
  const refetchAssets = useRefetchAssets()
  const previousAssetStatus = usePrevious(assetStatus)

  useEffect(() => {
    if (previousAssetStatus !== assetStatus) {
      refetchAssets()
    }
  }, [assetStatus, previousAssetStatus, refetchAssets])
}

const ProcessAsset = ({
  assetId,
  setActiveAssetId
}: {
  assetId: string
  setActiveAssetId: (assetId: string | null) => unknown
}) => {
  const { data } = useAsset(assetId, { refreshInterval: 5000 })
  const { revalidate } = useAuth()
  const wasSet = useClearSearchParam('action', 'next')

  const asset = data?.asset

  useRefreshTable(asset?.status)

  useEffect(() => {
    if (asset?.status === AssetStatus.READY) {
      revalidate()
    }
  }, [asset?.status, revalidate])

  if (!asset || asset.status === AssetStatus.UPLOADED) {
    return <LoadingAsset assetId={assetId} startProcessing={wasSet} />
  }

  if (!asset || asset.status === AssetStatus.PROCESSING) {
    return <LoadingAsset assetId={assetId} />
  }

  if (asset.singleEnrichment?.isSingleEnrichment && asset.status === AssetStatus.READY) {
    return (
      <AssetResult
        asset={asset}
        onRestart={(id) => {
          setActiveAssetId(id || null)
        }}
        setActiveAssetId={setActiveAssetId}
      />
    )
  }

  return (
    <div className="mt-8 mb-4">
      <AssetError
        onRestart={(id) => {
          setActiveAssetId(id || null)
        }}
        asset={asset}
      />
    </div>
  )
}

const DashboardAsset = ({
  assetId,
  setActiveAssetId,
  matchType
}: {
  assetId: string
  setActiveAssetId: (assetId: string | null) => unknown
  matchType: 'personal' | 'company'
}) => {
  const { data } = useAsset(assetId, { refreshInterval: 5000 })

  return (
    <div>
      {matchType === 'company' && <AssetSummary asset={data?.asset} />}
      <ProcessAsset assetId={assetId} setActiveAssetId={setActiveAssetId} />
    </div>
  )
}

export default DashboardAsset
