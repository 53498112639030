import { DateTime } from 'luxon'
import { BillingSheet } from '@/types/billing'
import Typography from '@mui/material/Typography'
import { CardIcon } from '@/pages/settings/billing/CreditCardComp'

const Sender = ({ billingSheet }: { billingSheet: BillingSheet }) => {
  return (
    <div className="flex flex-col items-end gap-2">
      <Typography variant="body1">
        Billing cycle of{' '}
        <b>
          {DateTime.fromISO(billingSheet.transaction?.createdAt as string).toLocaleString(
            DateTime.DATE_MED
          )}
        </b>
      </Typography>
      <Typography variant="body1">
        Paid on{' '}
        <b>
          {DateTime.fromISO(billingSheet.transaction?.createdAt as string).toLocaleString(
            DateTime.DATE_SHORT
          )}
        </b>
      </Typography>
      <div className="flex items-center gap-2">
        <CardIcon size="small" brand={billingSheet.transaction?.paymentMethodBrand} />
        <Typography variant="body1" fontWeight={700}>
          <span style={{ letterSpacing: 1, fontSize: 20 }}>•••• •••• •••• </span>
          {billingSheet.transaction?.paymentMethodLast4}
        </Typography>
      </div>
    </div>
  )
}

export default Sender
