const useSearchType = () => {
  const isDomainURL = (value: string) => {
    const regex =
      /^(https?:\/\/.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/

    return regex.test(value)
  }

  return { isDomainURL }
}

export default useSearchType
