import { DateTime } from 'luxon'
import { Checkbox, IconButton, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Asset } from '@/types/assets'
import { PAGE, trackEvent } from '@/analytics'
import { ReactComponent as IcPhoneFlip } from '@/assets/icons/ic-phone-flip.svg'
import { ReactComponent as IcEnvelope } from '@/assets/icons/ic-envelope.svg'
import { ReactComponent as IcEmailSent } from '@/assets/icons/ic-email-sent.svg'
import { ReactComponent as IcPhoneOutbound } from '@/assets/icons/ic-phone-outbound.svg'
import { RowSelectionState } from '@tanstack/react-table'
import DownloadSelectedRows from '@/components/Tables/DownloadSelectedRows'
import CloseIcon from '@mui/icons-material/Close'
import { getHasAssetAnyResult } from './utils'
import { getNameOfAsset } from '@/utils/assets'
import formatPhoneNumber from '@/utils/phoneNumber'

const ResultsRow = ({
  mainIcon,
  actionIcon,
  row1Title,
  row1Content,
  row1href,
  row2Title,
  row2Content,
  row2href
}: {
  mainIcon: React.ReactNode
  actionIcon: React.ReactNode
  row1Title?: string
  row1Content?: string
  row1href?: string
  row2Title?: string
  row2Content?: string
  row2href?: string
}) => {
  const notFound = row1Content === undefined && row2Content === undefined

  return (
    <div className="py-4 pr-4 flex gap-4 items-center">
      <div className="w-10 text-center flex-shrink-0">{mainIcon}</div>
      {notFound ? (
        <Typography variant="caption" fontWeight={700}>
          No results found
        </Typography>
      ) : null}
      <div className="flex self-stretch flex-col gap-4">
        {row1Content ? (
          <Typography variant="caption" fontWeight={500}>
            {row1Title}
          </Typography>
        ) : null}

        {row2Content ? (
          <Typography variant="caption" fontWeight={500}>
            {row2Title}
          </Typography>
        ) : null}
      </div>
      <div className="flex-1 flex self-stretch flex-col">
        {row1Content ? (
          <Typography variant="caption" fontWeight={500} sx={{ overflowWrap: 'anywhere' }}>
            {row1Content}
          </Typography>
        ) : null}
        {row2Content ? (
          <Typography variant="caption" fontWeight={500} sx={{ overflowWrap: 'anywhere' }}>
            {row2Content}
          </Typography>
        ) : null}
      </div>
      <div className="flex self-stretch flex-col">
        {row1Content && row1href ? (
          <a target="_blank" rel="noreferrer" href={row1href}>
            {actionIcon}
          </a>
        ) : null}

        {row2Content && row2href ? (
          <a target="_blank" rel="noreferrer" href={row2href}>
            {actionIcon}
          </a>
        ) : null}
      </div>
    </div>
  )
}

const MobileSingleAssetCard = ({
  isSelected,
  onToggleSelected,
  asset,
  page
}: {
  isSelected: boolean
  onToggleSelected: (checked: boolean) => unknown
  asset: Asset
  page: PAGE
}) => {
  const toLink = `/asset/${asset._id}`

  const personalEmail = asset.singleEnrichment?.enrichedEmail
  const workEmail = asset.singleEnrichment?.enrichedWorkEmail
  const personalPhone = asset.singleEnrichment?.enrichedPhone

  const name = getNameOfAsset(asset)

  const trackClick = () => {
    trackEvent('Select history record', { page })
  }

  return (
    <div className="bg-white rounded-xl border border-background.tertiary">
      <div className="py-4 pr-4 flex items-center">
        <Checkbox
          className="w-14"
          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          checked={isSelected}
          disabled={getHasAssetAnyResult(asset) === false}
          onChange={(e) => {
            onToggleSelected(e.target.checked)
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />

        <div>
          <Link to={toLink} className="no-underline" onClick={trackClick}>
            <Typography
              variant="body1"
              fontWeight={700}
              color="text.primary"
              textTransform="capitalize"
            >
              {name ? name : <i>(No label)</i>}
            </Typography>
          </Link>
          <Typography variant="caption" fontWeight={500} color="text.quaternary">
            {DateTime.fromISO(asset.createdAt).toLocaleString(DateTime.DATE_SHORT)}
          </Typography>
        </div>
      </div>

      <div className="w-full border border-t-0 border-r-0 border-l-0 border-background.tertiary"></div>

      <ResultsRow
        mainIcon={<IcEnvelope className="ml-5 fill-text.quaternary h-4 w-4" />}
        row1Title="Personal"
        row1Content={personalEmail}
        row1href={`mailto:${personalEmail}`}
        row2Title="Work"
        row2Content={workEmail}
        row2href={`mailto:${workEmail}`}
        actionIcon={
          <IcEmailSent
            className="fill-primary.main pointer-events-none"
            style={{ height: '20px', width: '20px' }}
          />
        }
      />

      <div className="w-full border border-t-0 border-r-0 border-l-0 border-background.tertiary"></div>

      <ResultsRow
        mainIcon={<IcPhoneFlip className="ml-5 fill-text.quaternary h-4 w-4" />}
        row1Title="Personal"
        row1Content={personalPhone ? formatPhoneNumber(personalPhone) : undefined}
        row1href={`tel:${personalPhone}`}
        actionIcon={
          <IcPhoneOutbound
            className="fill-primary.main pointer-events-none"
            style={{ height: '20px', width: '20px' }}
          />
        }
      />
    </div>
  )
}

const MobileSingleAssetsTable = ({
  allMatches,
  page,
  rowSelection,
  setRowSelection
}: {
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  rowSelection: RowSelectionState
  allMatches: Asset[]
  page: PAGE
}) => {
  const selectedRowsNumber = Object.keys(rowSelection).length

  return (
    <div className="flex flex-col gap-4 mb-4">
      {allMatches.map((asset) => (
        <MobileSingleAssetCard
          isSelected={!!rowSelection?.[asset._id]}
          onToggleSelected={(checked) => {
            setRowSelection((prev) => {
              if (checked) {
                return { ...prev, [asset._id]: true }
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [asset._id]: _, ...rest } = prev
                return rest
              }
            })
          }}
          key={asset._id}
          asset={asset}
          page={page}
        />
      ))}
      <div
        className={`${
          selectedRowsNumber === 0 ? '-bottom-32' : 'block bottom-0'
        } fixed z-10 right-0 left-0 flex py-4 shadow-2xl shadow-black bg-background.secondary transition-all justify-center`}
      >
        <div className="flex-1"></div>
        <DownloadSelectedRows rowSelection={rowSelection} mobile />
        <div className="flex-1 flex justify-end">
          <IconButton
            size="small"
            onClick={() => {
              setRowSelection({})
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default MobileSingleAssetsTable
