import { Container } from '@mui/material'
import BillingHeader from './Header'
import PaymentPlan from './billingPlans/PaymentPlan'
import PaymentHistory from './paymentHistory/PaymentHistory'
import { getBillingCheck } from '@/services/payments'
import axios from 'axios'
import { useCallback, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { handleBillingError } from '@/utils/errors/billing'
import { useAuthGuardPageTracker } from '@/analytics'
import Banners from '../dashboard/Banners'

const fetchBillingCheck = async (navigate: NavigateFunction) => {
  try {
    const result = await getBillingCheck()

    console.log(result)

    return result.data
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error
    }

    handleBillingError({ error, navigate })
  }
}

export const useBillingCheck = () => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const checkBilling = useCallback(async () => {
    setIsLoading(true)

    await fetchBillingCheck(navigate)
    setIsLoading(false)
  }, [])

  return { checkBilling, isLoading }
}

const trackingPayload = { page: 'Billing' } as const

const BillingPage = () => {
  useAuthGuardPageTracker('Visits Billing Page', trackingPayload)

  return (
    <>
      <Banners page="Billing" />
      <Container sx={{ py: 5 }}>
        <BillingHeader />
        <PaymentPlan />
        <PaymentHistory />
      </Container>
    </>
  )
}

export default BillingPage
