import { Transaction } from '@/types/transactions'
import { ChipProps, SxProps } from '@mui/material'
import { Chip } from '@mui/material'

export const TransactionStatusPill = ({ status }: { status: Transaction['status'] }) => {
  const sx: SxProps = {
    fontWeight: 700
  }

  const commonProps: ChipProps = {
    sx,
    size: 'small'
  }

  switch (status) {
    case 'pending':
      return <Chip label="Pending" color="warning" {...commonProps} />
    case 'success':
      return <Chip label="Paid" color="primary" {...commonProps} />
    case 'requires_capture':
      return <Chip label="Uncaptured" color="info" {...commonProps} />
    case 'canceled':
      return <Chip label="Canceled" color="warning" {...commonProps} />
    default:
      return <Chip label="Failed" color="error" {...commonProps} />
  }
}

export default TransactionStatusPill
