import { Location, useLocation } from 'react-router-dom'

import useStatusAlert, { type Status } from '@/hooks/useStatusAlert'
import StateAlert from './StateAlert'

const AUTO_CLOSE_TIMEOUT = 5000

type State = {
  status: Status
}

type Info = {
  title: string
  message: string
}

type StatusAlertProps = {
  onStatus: {
    success?: Info
    error?: Info
  }
  autoClose?: number
}

const StatusAlert = ({ autoClose = AUTO_CLOSE_TIMEOUT, onStatus: onState }: StatusAlertProps) => {
  const location: Location<State> = useLocation()
  const [closeAlert, status] = useStatusAlert(autoClose, location.state?.status)

  const isSuccess = status === 'success'
  const { title = '', message = '' } = isSuccess ? onState?.success ?? {} : onState?.error ?? {}

  if (!status || (!title && !message)) return null

  const className = isSuccess ? 'bg-[#C3EFD3] border-secondary.main' : 'bg-[#FFD5D5] border-danger'

  return (
    <StateAlert
      className={className}
      message={message}
      onClose={closeAlert}
      severity={status}
      title={title}
    />
  )
}

export default StatusAlert
