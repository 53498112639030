import { DateTime } from 'luxon'
import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Typography, Box, Button, Card, styled } from '@mui/material'

import { ReactComponent as CheckIcon } from '@/assets/icons/ic-circle-check.svg'
import Stepper from './Stepper'

const CheckMarkIcon = styled(CheckIcon)(({ theme }) => ({
  fill: theme.palette.primary.main
}))

const LetUsHandleContactPage = () => {
  const { state }: any = useLocation()
  const emailRef: React.Ref<HTMLInputElement> = useRef<HTMLInputElement>(null)
  const [disabled] = useState<boolean>(true)

  useEffect(() => {
    if (!disabled) {
      emailRef.current?.focus()
    }
  }, [disabled])

  return (
    <>
      <Container sx={{ py: 5 }}>
        {state?.step ? <Stepper className="self-stretch" stepIdx={state?.step} /> : null}
      </Container>
      <Container
        sx={{ textAlign: 'center', py: 5 }}
        className={`flex-1 flex flex-col justify-center items-center ${
          state?.type !== 'meeting' ? 'justify-center' : ''
        }`}
      >
        <CheckMarkIcon sx={{ fontSize: 80 }} />

        <Typography variant="h1" lineHeight={'48px'} fontWeight={900} color="primary.main" mt={3}>
          All good!
        </Typography>

        {state?.type === 'phone' && (
          <>
            <Typography variant="body1" fontWeight={400} color="text.tertiary" mt={2} mb={3}>
              We will call you after reviewing your note!
            </Typography>

            <Button variant="contained" size="large" href="/">
              Back to Dashboard
            </Button>
          </>
        )}

        {state?.type === 'email' && (
          <>
            <Typography variant="body1" fontWeight={400} color="text.tertiary" mt={2} mb={3}>
              We will send you an email after reviewing your note!
            </Typography>

            <Button variant="contained" size="large" href="/">
              Back to Dashboard
            </Button>
          </>
        )}

        {state?.type === 'meeting' && (
          <>
            <Typography variant="h3" color="text.tertiary" mt={3} mb={1}>
              {`Let's meet at`}
            </Typography>

            <Card sx={{ maxWidth: 660, padding: 3, mx: 'auto' }}>
              <Typography variant="h2">
                {DateTime.fromMillis(state.event.dateTime).toFormat('hh:mm')} -{' '}
                {DateTime.fromMillis(state.event.dateTime + state.event.duration).toFormat(
                  'hh:mm a'
                )}
              </Typography>
              <Typography mt={1} variant="body1" fontWeight={400}>
                on {DateTime.fromMillis(state.event.dateTime).toFormat('cccc, LLLL d')}
              </Typography>
            </Card>

            <Typography
              variant="body1"
              fontWeight={400}
              color="text.tertiary"
              maxWidth={660}
              mx={'auto'}
              mt={3}
              textAlign="left"
            >
              You will receive a meeting invitation momentarily from our team. We look forward to
              speaking with you. Please reach out to&nbsp;
              <a href="mailto:info@archer.ai">info@archer.ai</a> if you do not receive confirmation
              within a few minutes.
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button variant="outlined" size="large" sx={{ mr: 2 }}>
                Add to my calendar
              </Button>
              <Button variant="contained" size="large" href="/">
                Back to Dashboard
              </Button>
            </Box>
          </>
        )}
      </Container>
    </>
  )
}

export default LetUsHandleContactPage
