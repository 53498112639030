import { ReactComponent as ChecklistIcon } from '@/assets/icons/ic-checklist.svg'
import { ReactComponent as IcCircleInfo } from '@/assets/icons/ic-circle-info.svg'
import { Apis } from '@/providers/ApiProvider'
import { LookupRequestType } from '@/types/assets'
import { Flatfile } from '@flatfile/sdk'
import CloseIcon from '@mui/icons-material/Close'
import { Card, IconButton, Modal, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useEffect, useRef, useState } from 'react'
import { useLocalStorage } from 'react-use'
import './upload.scss'

const HowToImportData = ({ type }: { type: LookupRequestType }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const imgSrc =
    type === 'company'
      ? '/assets/images/img-company-sheet.svg'
      : '/assets/images/img-example-sheet.svg'

  return (
    <>
      <Button
        startIcon={<ChecklistIcon fill="currentColor" className="w-4 h-4" />}
        variant="outlined"
        size="large"
        onClick={handleOpen}
      >
        How to import your data?
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'min(464px, 100%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', right: '1rem', top: '1rem' }}
          >
            <CloseIcon />
          </IconButton>

          <Typography sx={{ mt: 0 }} variant="h2">
            How to import your data
          </Typography>

          <ol className="list-decimal pl-5 my-6">
            <li>Upload your file</li>
            <li>Confirm header row selection</li>
            <li>Link your spreadsheet fields to the inputs Archer expects (mapping)</li>
            <li>Finalize and submit to Archer</li>
          </ol>

          <Typography sx={{ mb: 1.5 }} variant="body1" fontWeight={700}>
            How to set up your file
          </Typography>
          <img src={imgSrc} alt="Sample" />

          <Stack direction="row" alignItems="flex-start" gap={1} mt={1.5}>
            <IcCircleInfo className="h-3 w-3 flex-shrink-0 mt-1.5" />
            <div>
              <Typography variant="caption" lineHeight={'16px'} fontWeight={500} fontSize={'12px'}>
                Above columns are mandatory for your file
                <br />
                Allowed file formats: <code>.xlsx, .xls, .csv</code> and other similar file types
              </Typography>
            </div>
          </Stack>

          <div className="flex items-baseline gap-2">
            <Typography mt={1.5} mb={1} variant="caption" fontWeight={700}>
              Use our template to format your data.
            </Typography>
            <Button
              variant="outlined"
              size="small"
              href={
                type === 'address' ? '/assets/example-address.xlsx' : '/assets/example-company.xlsx'
              }
              LinkComponent="a"
              download={
                type === 'address'
                  ? 'Archer Template - Full Address Enrichment'
                  : 'Archer Template - Full Company Enrichment'
              }
            >
              Download template
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  )
}

interface UploadFileStepProps {
  onNext: (data: any) => Promise<void>
  onComplete: (newFilename: string, records: any, batchId: string) => void
  type: LookupRequestType
}

const UploadFileStep = ({ onNext, onComplete, type }: UploadFileStepProps) => {
  const initialLoaded = useRef(false)
  const flatfileRef = useRef<{ close: () => void }>()
  const flatfileTokenRef = useRef('')
  const flatfileBatchIdRef = useRef('')
  const [, setOpenFlatfileGuide] = useLocalStorage('open-flatfile-guide', true)

  const loadFlatFileView = async () => {
    flatfileRef.current = await Flatfile.requestDataFromUser({
      // Development mode = embedId, user, org.
      token: async () => {
        const { data } = await Apis.getFlatfileAuthToken(type)
        flatfileTokenRef.current = data.token
        return data.token
      },
      mountOn: '#flatfile-view',
      theme: {
        displayName: 'Archer'
      },
      // handle submitted data
      onInit: ({ meta, ...rest }) => {
        console.log(rest)
        console.log(meta)
        flatfileBatchIdRef.current = meta.batchId
      },
      onData: async (chunk, next) => {
        // do something with the data
        console.log(chunk)
        const data = chunk.records
          .map((record) => ({
            ...record.data,
            zipCode: record.data.zipCode ? record.data.zipCode : undefined
          }))
          .slice()
        await onNext(data)

        // call next() to proceed with the import
        next()
      },
      onComplete: async (x) => {
        console.log(x)

        const records = (await x.data()).rows
          .map((record) => ({
            ...record.data,
            zipCode: record.data.zipCode ? record.data.zipCode : undefined
          }))
          .slice()

        const { data } = await Apis.getFileMetadata(flatfileBatchIdRef.current)

        onComplete(data.originalFile, records, x.batchId)
      },
      onError(error) {
        console.error(error)
      }
    })
  }

  useEffect(() => {
    if (!initialLoaded.current) {
      initialLoaded.current = true
      loadFlatFileView()
    }

    setOpenFlatfileGuide(false)

    return () => {
      if (flatfileRef.current) {
        flatfileRef.current.close()
      }
    }
  }, [])

  return (
    <Box className="flex flex-col" mt={10}>
      <div className="flex justify-between items-center">
        <Typography variant="h1" color="primary.main" mb={3}>
          Upload your file
        </Typography>

        <HowToImportData type={type} />
      </div>
      {/* {matches && (
        <StyledAccordion disableGutters elevation={0} defaultExpanded={openFlatfileGuide}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            sx={{
              px: 3,
              '& .MuiAccordionSummary-content': {
                my: 2
              }
            }}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <IcBulbBlack />
              <Typography variant="h2">Importing your data</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '8px 24px 16px 24px' }}>
            <Grid container>
              <Grid item xs={6} pr={4} sx={{ borderRight: 1, borderColor: 'divider' }}>
                <Typography variant="h3" mb={1.5}>
                  How to import your data
                </Typography>
                <ol>
                  <li>Upload your file</li>
                  <li>Confirm header row selection</li>
                  <li>Link your spreadsheet fields to the inputs Archer expects (mapping)</li>
                  <li>Finalize and Submit to Archer</li>
                </ol>
              </Grid>
              <Grid item xs={6} px={4} sx={{ borderRight: 1, borderColor: 'divider' }}>
                <Typography sx={{ mb: 1.5 }} variant="h3">
                  How to setup your file
                </Typography>
                <img src={imgSrc} alt="Sample" />
                <Typography mt={1.5} mb={1} variant="subtitle1" fontWeight={700}>
                  Use our template to format your data.
                </Typography>
                <Button
                  variant="outlined"
                  size="large"
                  href="/assets/example.xlsx"
                  LinkComponent="a"
                  download="Archer Template - Full Address Enrichment"
                >
                  Download template
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      )} */}
      <div id="flatfile-view" className="flex-shrink-0 min-w-[901px] h-[1000px] w-full" />
      {/* {!matches && (
          <div className="instruction-view">
            <Typography variant="h3" mb={1.5}>
              How to import your data
            </Typography>
            <ol>
              <li>Upload your file</li>
              <li>Confirm header row selection</li>
              <li>Link your spreadsheet fields to the inputs Archer expects (mapping)</li>
              <li>Finalize and Submit to Archer</li>
            </ol>

            <Typography sx={{ mt: 4, mb: 1.5 }} variant="h3">
              How to setup your file
            </Typography>
            <img src={imgSrc} alt="Sample" />

            <Stack direction="row" alignItems="center" gap={1} mt={1.5}>
              <IcCircleInfo />
              <Typography variant="caption">Above columns are mandatory for your file</Typography>
            </Stack>
            <Typography mt={1.5} mb={1} variant="subtitle1" fontWeight={700}>
              Use our template to format your data.
            </Typography>
            <Button
              variant="outlined"
              size="large"
              href="/assets/example.xlsx"
              LinkComponent="a"
              download="Archer Template - Full Address Enrichment"
            >
              Download template
            </Button>
          </div>
        )} */}
    </Box>
  )
}

export default UploadFileStep
