import { useCallback, useEffect, useState } from 'react'

export type Status = 'success' | 'error' | undefined

const useStatusAlert = (autoClose: number, initialState: Status) => {
  const [status, setStatus] = useState(initialState)

  const closeAlert = useCallback(() => {
    setStatus(undefined)
    window.history.replaceState(null, '')
  }, [])

  useEffect(() => {
    if (!status || !autoClose) return

    const timer = setTimeout(() => {
      closeAlert()
    }, autoClose)

    return () => clearTimeout(timer)
  }, [status, autoClose, closeAlert])

  return [closeAlert, status] as const
}

export default useStatusAlert
