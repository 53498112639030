import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', process.env.REACT_APP_BACKEND_API as string]
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: 0 // No
})
