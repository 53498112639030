import { CircularProgress, CircularProgressProps, Box } from '@mui/material'
import { BoxProps } from '@mui/system'

interface CenteredCircularProgressProps {
  boxProps?: BoxProps
  circularProgressProps?: CircularProgressProps
}

const CenteredCircularProgress = ({
  boxProps,
  circularProgressProps
}: CenteredCircularProgressProps) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="calc(100vh - 100px)"
    {...boxProps}
  >
    <CircularProgress size={60} {...circularProgressProps} />
  </Box>
)

export default CenteredCircularProgress
