import { Asset } from '@/types/assets'

const useLookupHasResults = (asset?: Asset) => {
  const phoneResult = !!asset?.singleEnrichment?.enrichedPhone

  const emailResult =
    !!asset?.singleEnrichment?.enrichedEmail ||
    !!asset?.singleEnrichment?.enrichedWorkEmail ||
    !!asset?.singleEnrichment?.enrichedWorkEmail2

  return {
    phoneResult,
    emailResult,
    lookupResult: phoneResult || !!emailResult
  }
}

export default useLookupHasResults
