import { Fragment, ElementType, ReactNode } from 'react'

// TODO: Do not use index as a key!!!
const useWrapItemsWithComponent = () => {
  const wrapItemsWithComponent = <T extends unknown[]>(
    arr: T,
    Component: ElementType = 'strong',
    separator: ReactNode | null = ', ',
    className = 'text-[#2721ae] underline'
  ): React.ReactNode => (
    <>
      {arr.map((item, index) => (
        <Fragment key={index}>
          <Component className={className}>{item}</Component>
          {separator && index < arr.length - 1 && separator}
        </Fragment>
      ))}
    </>
  )

  return { wrapItemsWithComponent }
}

export default useWrapItemsWithComponent
