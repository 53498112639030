import { Button, Card, IconButton, Modal, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { ReactComponent as BookIcon } from '@/assets/icons/ic-book.svg'
import CloseIcon from '@mui/icons-material/Close'
import PaymentsIcon from '@mui/icons-material/Payments'
import { useBillingTracker } from '@/pages/dashboard/hooks'
import { formatMoney } from '@/utils'

const HowPaymentWorks = () => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const billingTracker = useBillingTracker()

  return (
    <>
      <Button
        onClick={handleOpen}
        startIcon={<BookIcon />}
        sx={{ color: 'primary.main', fill: theme.palette.primary.main, fontWeight: 700, px: 2 }}
      >
        How payment works
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'min(500px, 100%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', right: '1rem', top: '1rem' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h2">
            <PaymentsIcon sx={{ mr: 1 }} />
            Payment Basics
          </Typography>
          <Typography sx={{ mt: 4 }} variant="body1">
            Archer bills you automatically each month, or whenever your balance exceeds&nbsp;
            <b>
              {formatMoney(billingTracker.data?.payment_required_threshold, {
                notation: 'compact'
              })}
            </b>
            .
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            For transactions that have estimated costs, we run a pre-auth on your credit card and
            then we bill you based on the number of matches we find for your request.
          </Typography>
          {/* <Typography sx={{ mt: 2 }} variant="body1">
            When you request more than <b>{formatNumber(1000)}</b> matches with full name, full
            address inputs, billing is by the <b>record</b> instead of by the match.
          </Typography> */}
        </Card>
      </Modal>
    </>
  )
}

export default HowPaymentWorks
