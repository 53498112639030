import {
  Button,
  buttonClasses,
  ButtonProps,
  IconButton,
  Link,
  SvgIcon,
  Typography
} from '@mui/material'

import { ReactComponent as BuildingIcon } from '@/assets/icons/ic-building.svg'
import { ReactComponent as CopyIcon } from '@/assets/icons/ic-copy.svg'
import { ReactComponent as EnvelopeIcon } from '@/assets/icons/ic-envelope.svg'
import { ReactComponent as PhoneIcon } from '@/assets/icons/ic-phone.svg'
import { ReactComponent as SmsIcon } from '@/assets/icons/ic-sms.svg'
import SuspectedEmployerChip from '@/components/Chip/SuspectedEmployerChip'
import { TooltipFullText } from '@/components/common/TooltipFullText'
import useAppendDomainProtocolToURL from '@/hooks/useAppendDomainProtocolToURL'
import { Asset, AssetStatus } from '@/types/assets'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

const NameAndCompany = ({
  name,
  companyName,
  companyUrl,
  linkedInUrl,
  jobChange
}: {
  name: string
  companyName: string
  companyUrl?: string
  linkedInUrl?: string
  jobChange?: boolean
}) => {
  const appendProtocolToURL = useAppendDomainProtocolToURL()

  return (
    <div className="flex flex-col justify-start gap-2">
      <div className="flex flex-row items-center">
        <Typography variant="h2" fontWeight={800} className="capitalize">
          {name}
        </Typography>
        {jobChange && <SuspectedEmployerChip />}
      </div>
      <div className="flex flex-row gap-10">
        <div className="flex flex-row">
          <BuildingIcon className="my-auto" />
          <Typography variant="body1" fontWeight={500} className="my-auto pl-1">
            {companyName}
            {!!companyUrl && (
              <span>
                {' | '}
                <Link
                  href={appendProtocolToURL(companyUrl)}
                  target="_blank"
                  rel="noreferrer"
                  className="no-underline font-bold"
                >
                  {companyUrl}
                </Link>
              </span>
            )}
          </Typography>
        </div>
        {!!linkedInUrl && (
          <div className="hidden md:flex flex-row items-center gap-1.5">
            <LinkedInIcon className="h-[21px] w-[21px] fill-[#0068C9]" />
            <Link
              href={linkedInUrl}
              target="_blank"
              rel="noreferrer"
              className="no-underline font-bold"
            >
              <TooltipFullText limit={50}>{linkedInUrl}</TooltipFullText>
            </Link>
            <IconButton
              color="primary"
              onClick={() => {
                navigator.clipboard.writeText(linkedInUrl)
              }}
            >
              <SvgIcon viewBox="0 0 16 16" className="h-5 w-5">
                <CopyIcon />
              </SvgIcon>
            </IconButton>
          </div>
        )}
      </div>
    </div>
  )
}

const CTAButton = ({ children, ...rest }: ButtonProps) => {
  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      sx={{
        borderRadius: '8px',
        [`${buttonClasses.disabled}`]: {
          color: 'red'
        }
      }}
      {...rest}
    >
      <div className="flex flex-col items-center">{children}</div>
    </Button>
  )
}

const EmailCTA = ({ asset }: { asset?: Asset }) => {
  const foundEmail =
    asset?.singleEnrichment?.enrichedWorkEmail ||
    asset?.singleEnrichment?.enrichedWorkEmail2 ||
    asset?.singleEnrichment?.enrichedEmail

  const canSendEmail = !!foundEmail

  const mailToHref = `mailto:${foundEmail}`

  return (
    <CTAButton
      disabled={!canSendEmail}
      href={mailToHref}
      onClick={(e) => {
        e.preventDefault()
        window.open(mailToHref, '_blank')
      }}
    >
      <SvgIcon viewBox="0 0 24 24" className="w-4">
        <EnvelopeIcon />
      </SvgIcon>
      Email
    </CTAButton>
  )
}

const CallCTA = ({ asset }: { asset?: Asset }) => {
  const foundPhone = asset?.singleEnrichment?.enrichedPhone

  const canPhoneCall = !!foundPhone

  const telHref = `tel:${foundPhone}`

  return (
    <CTAButton
      disabled={!canPhoneCall}
      href={telHref}
      onClick={(e) => {
        e.preventDefault()
        window.open(telHref, '_blank')
      }}
    >
      <SvgIcon viewBox="0 0 16 16" className="w-4">
        <PhoneIcon />
      </SvgIcon>
      Call
    </CTAButton>
  )
}

const TextCTA = ({ asset }: { asset?: Asset }) => {
  const foundPhone = asset?.singleEnrichment?.enrichedPhone

  const canSendText = !!foundPhone

  const textHref = `sms:${foundPhone}`

  return (
    <CTAButton
      disabled={!canSendText}
      href={textHref}
      onClick={(e) => {
        e.preventDefault()
        window.open(textHref, '_blank')
      }}
    >
      <SvgIcon viewBox="0 0 17 16" className="w-4">
        <SmsIcon />
      </SvgIcon>
      Text
    </CTAButton>
  )
}

const MobileCTA = ({ asset }: { asset?: Asset }) => {
  return (
    <div className="flex md:hidden flex-row justify-between gap-4">
      <EmailCTA asset={asset} />
      <CallCTA asset={asset} />
      <TextCTA asset={asset} />
    </div>
  )
}

const AssetSummary = ({ asset }: { asset?: Asset }) => {
  const {
    fullName = '',
    company = '',
    companyWebsite = '',
    linkedin = '',
    jobChange = false
  } = asset?.singleEnrichment || {}
  const showMobileCTA = asset?.status === AssetStatus.READY

  return (
    <div className="bg-background.default px-4 md:px-3 py-4 rounded-2xl flex flex-col gap-4">
      <NameAndCompany
        name={fullName}
        companyName={company}
        companyUrl={companyWebsite}
        linkedInUrl={linkedin}
        jobChange={jobChange}
      />
      {!!showMobileCTA && <MobileCTA asset={asset} />}
    </div>
  )
}

export default AssetSummary
