import { useMemo } from 'react'
import { ProgressBar, Step, StepChildProps } from 'react-step-progress-bar'
import { ReactComponent as CheckIcon } from '../../assets/icons/ic-check.svg'
import './StepProgressBar.scss'

const steps = [
  { progress: 0, name: 'Upload & map file' },
  { progress: 25, name: 'Match types & estimation' },
  { progress: 50, name: 'Payment method' },
  { progress: 75, name: 'Process matches' },
  { progress: 100, name: 'Download matches' }
]

interface StepProgressBarProps {
  stepIdx: number
}

const StepProgressBar = ({ stepIdx }: StepProgressBarProps) => {
  const percent = useMemo(() => {
    return steps[stepIdx].progress
  }, [stepIdx])

  return (
    <ProgressBar percent={percent} stepPositions={[0, 25, 50, 75, 100]}>
      {steps.map((step, idx) => (
        <Step key={step.progress}>
          {({ accomplished }: StepChildProps) => (
            <div
              className={`step-wrapper ${accomplished ? 'accomplished' : 'future'} ${
                stepIdx === idx ? 'current' : ''
              }`}
              role="button"
              tabIndex={0}
            >
              <div className="indexedStep">
                {accomplished && stepIdx > idx ? <CheckIcon style={{ fill: 'white' }} /> : idx + 1}
              </div>
              <span className="step-name">{step.name}</span>
            </div>
          )}
        </Step>
      ))}
    </ProgressBar>
  )
}

export default StepProgressBar
