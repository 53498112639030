import { PAGE, trackEvent } from '@/analytics'
import { ReactComponent as AppleIcon } from '@/assets/icons/ic-apple-logo.svg'
import { Button, SvgIcon } from '@mui/material'

const toHref = (pathname = '/auth/sign-in/apple') => {
  const url = new URL(process.env.REACT_APP_BACKEND_API as string)

  url.pathname = pathname

  return url.href
}

const AppleSignInButton = ({
  className,
  disabled,
  page,
  text = 'Sign up with Apple'
}: {
  className: string
  disabled?: boolean
  text?: string
  page: PAGE
}) => {
  return (
    <Button
      startIcon={<SvgIcon component={AppleIcon}></SvgIcon>}
      variant="outlined"
      className={className}
      disabled={disabled}
      size="large"
      href={toHref()}
      onClick={() => {
        trackEvent('Clicks Apple Sign Up', {
          page: page
        })
      }}
    >
      {text}
    </Button>
  )
}

export default AppleSignInButton
