import { useEffect, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import { ReactComponent as AlertIcon } from '@/assets/icons/ic-alert.svg'
import { formatMoney, formatNumber } from '@/utils'
import { Tooltip } from '@mui/material'
import { useBillingTracker } from '@/pages/dashboard/hooks'

const MatchSliderBoxWrapper = styled('div')({
  width: '100%',
  // padding: 24,
  marginTop: 30,
  backgroundColor: '#fff',
  borderRadius: 12
})

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.background.secondary,
    opacity: 1,
    boxShadow: '0px 2px 4px rgba(31, 31, 35, 0.1)',
    border: 'none',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0px 2px 4px rgba(31, 31, 35, 0.1)',
      '@media (hover: none)': {
        boxShadow: '0px 2px 4px rgba(31, 31, 35, 0.1)'
      }
    },
    '::before': {
      boxShadow: '0px 2px 4px rgba(31, 31, 35, 0.1)'
    },
    input: {
      border: 0
    }
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    top: -6,
    backgroundColor: 'unset',
    color: '#16161B',
    '&:before': {
      display: 'none'
    },
    '& *': {
      background: 'transparent'
    }
  },
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: theme.palette.primary.light
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    backgroundColor: theme.palette.background.tertiary
  }
}))

interface MatchSliderBoxProps {
  maxValue: number
  onValueChange: (val: number) => void
  setMatchNum: React.Dispatch<React.SetStateAction<number>>
  matchNum: number
  estimatedMaxCost: number
}

const MatchSliderBox = ({
  maxValue,
  onValueChange,
  matchNum,
  setMatchNum,
  estimatedMaxCost
}: MatchSliderBoxProps) => {
  const theme = useTheme()
  const { data: billingTracker } = useBillingTracker()

  const currentBalance = billingTracker?.userBalance || 0
  const threshold = billingTracker?.payment_required_threshold || 0
  const willExceedThreshold = currentBalance + estimatedMaxCost >= threshold

  const { min, max } = useMemo(() => {
    const min = 0
    const max = Math.ceil(maxValue)

    return { min, max }
  }, [maxValue])

  const formatMatches = (count: number) => {
    return `${formatNumber(count)} match` + (count === 1 ? '' : 'es')
  }

  useEffect(() => {
    onValueChange(matchNum)
  }, [matchNum, onValueChange])

  const handleChange = (_event: Event, newValue: number | number[]) => {
    setMatchNum(newValue as number)
    onValueChange(newValue as number)
  }

  return (
    <MatchSliderBoxWrapper>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="body2"
          fontWeight={500}
          color={theme.palette.text.quaternary}
          className="flex-shrink-0"
          sx={{ mt: 0.5, mr: 2 }}
        >
          {formatMatches(min)}
        </Typography>
        <StyledSlider
          aria-label="Always visible"
          value={matchNum}
          onChange={handleChange}
          min={min}
          max={max}
          valueLabelDisplay="on"
          valueLabelFormat={() => formatMatches(matchNum)}
        />
        <Typography
          variant="body2"
          fontWeight={500}
          color={theme.palette.text.quaternary}
          className="flex-shrink-0"
          sx={{ mt: 0.5, ml: 2 }}
        >
          {formatMatches(max)}
        </Typography>
        {willExceedThreshold ? (
          <Tooltip
            title={`If the total amount due could exceed ${formatMoney(
              threshold
            )}, we will place a pre-authorization on your credit card for the maximum total cost. If your balance after match rates are determined exceeds ${formatMoney(
              threshold
            )}, Archer will require a payment.`}
            arrow
          >
            <AlertIcon className="fill-warning w-4 h-4 flex-shrink-0 mt-1.5 ml-1.5" />
          </Tooltip>
        ) : null}
      </Stack>
    </MatchSliderBoxWrapper>
  )
}

export default MatchSliderBox
