import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'

import { Notification } from '@/components/Notification/Notification'
import Navbar from '@/components/Navbar/Navbar'
import ScrollToTop from '@/components/ScrollToTop'

import './layout.scss'
import AdminMode from './AdminMode'
import Footer from './Footer'
import { memo } from 'react'

const MainLayout = () => {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Box
        sx={{
          minHeight: 'calc(100vh - 96px)',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Outlet />
      </Box>
      <Footer />
      <AdminMode />
      <Notification />
    </>
  )
}

export default memo(MainLayout)
