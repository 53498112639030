import InvalidCardWarning from '@/components/Alert/InvalidCardWarning'
import { BILLING_ERROR_CODES } from '@/utils/errors/billing'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'

const BillingErrors = ({ hasPaymentMethod }: { hasPaymentMethod: boolean }) => {
  const location = useLocation()

  const error = location.state?.error

  if (error === BILLING_ERROR_CODES.NO_PAYMENT_METHOD && !hasPaymentMethod) {
    return (
      <Box mb={3}>
        <div className="flex">
          <InvalidCardWarning href={''} message="A payment method is required to enrich records" />
        </div>
      </Box>
    )
  }

  return null
}

export default BillingErrors
