import { Box, Typography } from '@mui/material'

const NoRecentMatches = () => {
  return (
    <Box className="history-list flex items-center justify-center">
      <Box className="w-[380px] flex flex-col items-center justify-center -mx-4 gap-4 text-center">
        <Typography variant="h3">There are no previous contacts enriched</Typography>
        <Typography variant="body1" fontWeight={400}>
          Here you will see all of your new contact information.
        </Typography>
      </Box>
    </Box>
  )
}

export default NoRecentMatches
