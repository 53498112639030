import { ContactUsFormValues } from '@/pages/payment/ContactUsForm'
import { ChatWithUsFormValues } from '@/pages/plans/ContactUsModal'
import { api } from '@/providers/ApiProvider'
import { AxiosRequestConfig } from 'axios'

const submitFeedback = (data: ContactUsFormValues, options?: AxiosRequestConfig<unknown>) => {
  return api.post<{ message: string }>(`/feedback/submit`, data, options)
}
const submitContactUs = (data: ChatWithUsFormValues, options?: AxiosRequestConfig<unknown>) => {
  return api.post<{ message: string }>(`/feedback/contact-us`, data, options)
}

export { submitFeedback, submitContactUs }
