import { BILLING_PLAN } from '@/types/billing'
import { Box, LinearProgress } from '@mui/material'
import { useState } from 'react'
import StandardPlan from './StandardPlan'

const useUserBillingPlan = () => {
  const [plan] = useState<BILLING_PLAN | null>(BILLING_PLAN.STANDARD)
  const [isLoading] = useState(false)

  return {
    isLoading,
    plan
  }
}

const PaymentPlan = () => {
  const { isLoading, plan } = useUserBillingPlan()

  return (
    <section className="flex">
      <Box gap={3} flexDirection="column" display="flex" alignItems="flex-start" className="w-full">
        {isLoading ? (
          <div className="w-full">
            <LinearProgress />
          </div>
        ) : null}
        <div className="self-stretch">
          {!isLoading && plan === BILLING_PLAN.STANDARD ? <StandardPlan /> : null}
        </div>
      </Box>
    </section>
  )
}

export default PaymentPlan
