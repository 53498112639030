import { withAuthGuard } from '@/utils/hoc/withAuthGuard'
import { useAuth, useIsVerifiedUpdates } from '@/reducers/user'
import { useCallback, useEffect, useState } from 'react'
import AccountVerifiedModal from './AccountVerifiedModal'
import { usePrevious } from 'react-use'
import EmailSentModal from './EmailSentModal'
import LimitedTimeOfferModal from '@/pages/plans/LimitedTimeOfferModal'

const useActivationTrigger = (callback: () => unknown) => {
  const { user, revalidate } = useAuth()

  const isVerified = useIsVerifiedUpdates()
  const prevIsVerified = usePrevious(isVerified)

  useEffect(() => {
    if (isVerified === true && prevIsVerified === false) {
      revalidate()
      callback()
      return
    }

    if (typeof user?.activatedAt === 'string') {
      const diff = Date.now() - new Date(user.activatedAt).getTime()

      if (diff < 5000) {
        callback()
      }

      return
    }
  }, [user?.activatedAt, isVerified, prevIsVerified, callback, revalidate])
}

const PersistentModals = () => {
  const [open, setOpen] = useState(false)
  useActivationTrigger(useCallback(() => setOpen(true), []))

  return (
    <>
      <AccountVerifiedModal open={open} setOpen={setOpen} />
      <LimitedTimeOfferModal />
      <EmailSentModal />
    </>
  )
}

export default withAuthGuard(PersistentModals)
