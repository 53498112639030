import { Box, Button, Container, Typography, styled } from '@mui/material'
import FreeTrialBadge from './FreeTrialBadge'
import { useBillingTracker } from './hooks'
import { ReactComponent as PartyHornIcon } from '@/assets/icons/ic-party-horn.svg'
import { ReactComponent as GiftIcon } from '@/assets/icons/ic-gift.svg'
import { ReactComponent as CreditCardIcon } from '@/assets/icons/ic-credit-card.svg'
import { ReactComponent as EmailCheckIcon } from '@/assets/icons/ic-envelop-check-purple.svg'
import {
  useLimitedTimeOfferAvailable,
  useLimitedTimeOfferCountdown,
  useLimitedTimeOfferExpiry
} from '../plans/hooks'
import { DateTime } from 'luxon'
import { useLTOModal } from '../plans/LimitedTimeOfferModal'
import { ReactNode, useState } from 'react'
import { useAuth, useCanCurrentUserEnrich, useIsCurrentUserActivated } from '@/reducers/user'
import SendEmailVerificationModal from '@/components/Modal/SendEmailVerificationModal'
import { useSessionStorage } from 'react-use'
import { PAGE } from '@/analytics'

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.tertiary
}))

const defaultRenderContainer = (children: React.ReactNode) => {
  return (
    <Container className="py-2 px-6 mb-0 md:pr-12" disableGutters maxWidth="xl">
      {children}
    </Container>
  )
}

const Wrapper = ({
  children,
  renderContainer = defaultRenderContainer
}: {
  children: ReactNode
  renderContainer?: (children: React.ReactNode) => React.ReactNode
}) => {
  return <div className="-ml-12 pl-12 bg-background.tertiary ">{renderContainer(children)}</div>
}

const VerifyEmailBanner = ({
  page,
  renderContainer
}: {
  page: PAGE
  renderContainer?: (children: React.ReactNode) => React.ReactNode
}) => {
  const { user } = useAuth()
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false)

  const onSent = () => {
    setWasVerifyEmailSent(true)
  }

  const [, setWasVerifyEmailSent] = useSessionStorage('verifyEmailSent', false)

  return (
    <Wrapper renderContainer={renderContainer}>
      <StyledBox className="flex items-center gap-2">
        <EmailCheckIcon className="h-5 w-5 flex-shrink-0 fill-primary.main mr-1" />
        <div className="flex items-baseline gap-1 flex-wrap">
          <Typography
            variant="body1"
            color="primary.main"
            fontWeight={700}
            className="inline-block"
          >
            Verify your email address to start using Archer!
          </Typography>{' '}
          <Typography variant="body1" fontWeight={500} className="hidden md:inline-block mr-3">
            Don&apos;t see the email? Send a new verification email to <b>{user?.email}</b>.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="inline-block"
            onClick={() => {
              setSendEmailModalOpen(true)
            }}
          >
            Resend
          </Button>
        </div>
      </StyledBox>
      <SendEmailVerificationModal
        open={sendEmailModalOpen}
        setOpen={setSendEmailModalOpen}
        onSent={onSent}
        page={page}
      />
    </Wrapper>
  )
}

const FreeTrialGreeting = ({
  credits,
  renderContainer
}: {
  credits?: number
  renderContainer?: (children: React.ReactNode) => React.ReactNode
}) => {
  const isUserActivated = useIsCurrentUserActivated()

  const tryMessage = isUserActivated
    ? `Let's try Single Search or Bulk Lookup for free!`
    : `Let's try Single Search for free!`

  const tryMessageMobile = `Let's try it for free!`

  return (
    <Wrapper renderContainer={renderContainer}>
      <StyledBox className="flex items-center gap-2">
        <PartyHornIcon className="h-4 w-4 flex-shrink-0 fill-primary.main mr-1" />
        <div className="flex flex-wrap items-baseline gap-1">
          <Typography
            variant="body1"
            color="primary.main"
            fontWeight={700}
            className="inline-block"
          >
            Archer gets you the best contact information!
          </Typography>
          <Typography variant="body1" fontWeight={500} className="hidden md:inline-block mr-3">
            {tryMessage}
          </Typography>
          <Typography variant="body1" fontWeight={500} className="inline-block md:hidden mr-3">
            {tryMessageMobile}
          </Typography>
          {credits && credits > 0 ? (
            <span className="inline-block">
              <FreeTrialBadge size="large" />
            </span>
          ) : null}
        </div>
      </StyledBox>
    </Wrapper>
  )
}

const LimitedTimeOfferAlert = ({
  expiringAt,
  page,
  renderContainer
}: {
  expiringAt: DateTime
  page: PAGE
  renderContainer?: (children: React.ReactNode) => React.ReactNode
}) => {
  const countdown = useLimitedTimeOfferCountdown(expiringAt)

  const [, setOpen, , setAdditionalProps] = useLTOModal()

  return (
    <Wrapper renderContainer={renderContainer}>
      <StyledBox className="flex items-center gap-2">
        <GiftIcon className="h-4 w-4 flex-shrink-0 fill-primary.main mr-1 mb-1" />
        <div className="flex items-baseline flex-wrap gap-1">
          <Typography
            variant="body1"
            color="primary.main"
            fontWeight={700}
            className="inline-block"
          >
            Limited Time Offer:
          </Typography>
          <Typography variant="body1" fontWeight={500} className="inline-block mr-3">
            Get 25 free credits by entering your credit card within the next 48 hours
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className="inline-block flex-shrink-0"
            size="large"
            onClick={() => {
              setAdditionalProps({ largeWithLink: true, page: page })
              setOpen(true)
            }}
          >
            Unlock 25 Free Credits ({countdown})
          </Button>
        </div>
      </StyledBox>
    </Wrapper>
  )
}

const NoPaymentMethodGreeting = ({
  renderContainer
}: {
  renderContainer?: (children: React.ReactNode) => React.ReactNode
}) => {
  const [, setOpen, , setAdditionalProps] = useLTOModal()

  return (
    <Wrapper renderContainer={renderContainer}>
      <StyledBox className="flex items-center gap-2 flex-wrap">
        <CreditCardIcon className="h-4 w-4 flex-shrink-0 fill-primary.main mr-1" />
        <div className="flex items-baseline gap-1">
          <Typography
            variant="body1"
            color="primary.main"
            fontWeight={700}
            className="inline-block"
          >
            You have run out of free credits.
          </Typography>
          <Typography variant="body1" fontWeight={500} className="inline-block mr-3">
            Add your payment information for uninterrupted access!
          </Typography>
        </div>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            setAdditionalProps({ largeWithLink: true, page: 'Dashboard', type: 'add-payment-info' })
            setOpen(true)
          }}
        >
          Enter Payment Information
        </Button>
      </StyledBox>
    </Wrapper>
  )
}

// Useful for debugging
// const AllBanners = ({
//   renderContainer,
//   page
// }: {
//   renderContainer?: (children: React.ReactNode) => React.ReactNode
//   page: PAGE
// }) => {
//   const { data: billingTracker } = useBillingTracker()

//   return (
//     <div className="flex flex-col gap-4">
//       <VerifyEmailBanner renderContainer={renderContainer} page={page} />
//       <FreeTrialGreeting credits={billingTracker?.free_credits} renderContainer={renderContainer} />
//       <LimitedTimeOfferAlert
//         page={page}
//         expiringAt={DateTime.now().plus({ days: 2 })}
//         renderContainer={renderContainer}
//       />
//       <NoPaymentMethodGreeting renderContainer={renderContainer} />
//     </div>
//   )
// }

// export default AllBanners

const Banners = ({
  renderContainer,
  page
}: {
  renderContainer?: (children: React.ReactNode) => React.ReactNode
  page: PAGE
}) => {
  const { data: billingTracker } = useBillingTracker()

  const expiringAt = useLimitedTimeOfferExpiry()
  const isLimitedTimeOfferAvailable = useLimitedTimeOfferAvailable(expiringAt)
  const canEnrich = useCanCurrentUserEnrich()
  const isUserActivated = useIsCurrentUserActivated()
  if (!billingTracker) {
    return null
  }

  if (!isUserActivated) {
    return <VerifyEmailBanner renderContainer={renderContainer} page={page} />
  }

  if (canEnrich && isLimitedTimeOfferAvailable && expiringAt) {
    return (
      <LimitedTimeOfferAlert
        page={page}
        expiringAt={expiringAt}
        renderContainer={renderContainer}
      />
    )
  }

  if (canEnrich && billingTracker?.free_credits && billingTracker?.free_credits > 0) {
    return (
      <FreeTrialGreeting credits={billingTracker?.free_credits} renderContainer={renderContainer} />
    )
  }

  if (
    typeof billingTracker?.free_credits === 'number' &&
    billingTracker?.free_credits === 0 &&
    billingTracker.hasPaymentMethod === false
  ) {
    return <NoPaymentMethodGreeting renderContainer={renderContainer} />
  }

  return null
}

export default Banners
