import { Box, Typography, CircularProgress, useTheme } from '@mui/material'
import { StripeCustomer } from '@/types/user'
import BillingAddressForm from './BillingAddressForm'
import { useAddresses } from './hooks'
import { CardBox } from './CardBox'
import { trackEvent } from '@/analytics'

const Addresses = ({ userInfo }: { userInfo?: StripeCustomer }) => {
  const { isLoading, onSubmit, formState, control } = useAddresses()
  const theme = useTheme()

  const disabled = isLoading || !userInfo
  const hasError =
    formState.errors && Object.keys(formState.errors) && Object.keys(formState.errors)?.length > 0

  return (
    <Box flex={1} display="flex" flexDirection="column" minWidth={'300px'}>
      <Typography variant="h2" mb={3}>
        My billing address
        {disabled ? <CircularProgress size={20} sx={{ marginLeft: 2 }} /> : null}
      </Typography>

      <CardBox
        className="py-6 px-6 md:px-8"
        sx={{
          borderRadius: '12px',
          boxShadow: '0px 8px 24px rgba(31, 31, 35, 0.1)',
          minWidth: '250px',
          border: hasError ? `2px solid ${theme.palette.error.main}` : undefined
        }}
      >
        <BillingAddressForm
          onSubmit={(e) => {
            trackEvent('Updates billing address', { page: 'Payment Settings' })
            return onSubmit(e)
          }}
          formState={formState}
          control={control}
          disabled={disabled}
        />
      </CardBox>
    </Box>
  )
}

export default Addresses
