import { fullConfig } from '@/utils/isMobile'
import { useWindowSize } from 'react-use'
import { TooltipFullText } from '../common/TooltipFullText'

const WrapWithTooltip = ({ children, limit = 50 }: { children: string; limit: number }) => {
  const { width } = useWindowSize()

  const extraWidth = parseInt(fullConfig.theme?.screens.xl, 10)
  const isLargeScreen = width > extraWidth
  if (isLargeScreen || !children) return <>{children}</>

  return (
    <TooltipFullText className="text-black" limit={limit}>
      {children}
    </TooltipFullText>
  )
}

export default WrapWithTooltip
