import { useCallback, useState } from 'react'
import { RowSelectionState } from '@tanstack/react-table'
import { downloadMultipleAssets } from '@/services/asset'
import { IconButton, Tooltip } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import DownloadIcon from '@mui/icons-material/Download'
import { trackEvent } from '../../analytics/track'
import { TYPE } from '@/analytics'

const DownloadSelectedRows = ({
  rowSelection,
  event,
  mobile = false
}: {
  rowSelection: RowSelectionState
  event?: TYPE
  mobile?: boolean
}) => {
  const selectedRowsNumber = Object.keys(rowSelection).length
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<unknown>(undefined)

  const onDownload = useCallback(async () => {
    try {
      setIsLoading(true)

      const assetIds = Object.keys(rowSelection)

      const response = await downloadMultipleAssets(assetIds)

      const blob = new Blob([response.data], { type: 'text/csv' })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `multi-selection-${selectedRowsNumber}.csv`
      link.click()

      event && trackEvent(event, { selectedRows: selectedRowsNumber, fileName: link.download })
    } catch (error) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }, [event, rowSelection, selectedRowsNumber])

  if (mobile) {
    return (
      <LoadingButton
        disabled={isLoading || selectedRowsNumber === 0}
        size="small"
        onClick={onDownload}
        className="ml-0.5"
        variant="contained"
        startIcon={<DownloadIcon />}
      >
        Download selected ({selectedRowsNumber})
      </LoadingButton>
    )
  }

  return (
    <Tooltip
      title={
        selectedRowsNumber === 0 ? undefined : `Download the ${selectedRowsNumber} selected records`
      }
    >
      <IconButton
        disabled={isLoading || selectedRowsNumber === 0}
        size="medium"
        onClick={onDownload}
        className="ml-0.5"
        color="primary"
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  )
}

export default DownloadSelectedRows
