import { useEffect, useState } from 'react'
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import { PaymentIntent, PaymentRequest, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js'

const QuickPayButton = ({
  amount,
  createPaymentIntent
}: {
  amount: number
  createPaymentIntent: (paymentMethodId: string) => Promise<PaymentIntent>
}) => {
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null | undefined>(undefined)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Cost for processing enrichments',
        amount: Math.round(amount * 100)
      },
      requestPayerName: true,
      requestPayerEmail: true
    })

    pr.canMakePayment()
      .then((result) => {
        if (!result) {
          setPaymentRequest(null)
          return
        }

        setPaymentRequest(pr)
      })
      .catch((err) => {
        console.error(err)

        setPaymentRequest(null)
      })
  }, [stripe])

  useEffect(() => {
    if (!paymentRequest || !stripe) {
      return
    }
    console.log('attaching event')

    const handlePaymentMethod = async (ev: PaymentRequestPaymentMethodEvent) => {
      const paymentMethodId = ev.paymentMethod.id
      const { client_secret } = await createPaymentIntent(paymentMethodId)

      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        client_secret as string,
        { payment_method: paymentMethodId },
        { handleActions: false }
      )

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail')
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success')
        // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(client_secret as string)
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
          } else {
            // The payment has succeeded.
          }
        } else {
          // The payment has succeeded.
        }
      }
    }

    paymentRequest.on('paymentmethod', handlePaymentMethod)

    return () => {
      if (paymentRequest) {
        paymentRequest.off('paymentmethod', handlePaymentMethod)
      }
    }
  }, [paymentRequest, stripe])

  if (paymentRequest) {
    return <PaymentRequestButtonElement className="min-w-[15rem]" options={{ paymentRequest }} />
  }

  return <div />
}

export default QuickPayButton
