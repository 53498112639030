import { Typography, Grid, Card } from '@mui/material'
import { styled } from '@mui/system'

import { formatNumber } from '@/utils'
import { useState } from 'react'
import PricingTable from './PricingTable'
import Slider from './Slider'
import CurrentBalanceCard from './CurrentBalanceCard'
import BillingCycleProgress from './BillingCycleProgress'
import NotificationEmail from '@/pages/upload/step-components/process-matches/NotificationEmail'

const StyledCard = styled(Card)(
  ({ theme }) => `
    background: ${theme.palette.background.secondary};
    border-radius: 12px;
    box-shadow: none;
  `
)

const PriceCalculator = ({
  totalNum,
  total,
  estimatedMaxCost,
  handleSliderChange
}: {
  totalNum: number
  total: number
  estimatedMaxCost: number
  handleSliderChange: (val: number) => unknown
}) => {
  const [matchNum, setMatchNum] = useState(Math.ceil(totalNum * 0.5))

  return (
    <>
      <Typography variant="h1" color="primary.main" mb={1}>
        Price estimation
      </Typography>
      <Typography variant="h3" fontWeight={700} lineHeight="28px">
        Price calculator
      </Typography>
      <Grid container rowSpacing={4} columnSpacing={3}>
        <Grid item lg={7} xs={12}>
          <Typography variant="body1" mt={1}>
            Your file contains <b>{formatNumber(totalNum)} records</b>. Our average match rate is
            between 50-100%.
            <br />
            <b>You can adjust the slider below to see how the estimated price changes</b> depending
            on the number of matches.
          </Typography>
        </Grid>
        <Grid item lg={7} xs={12}>
          <StyledCard className="flex flex-col gap-6 p-6">
            <Slider
              handleSliderChange={handleSliderChange}
              matchNum={matchNum}
              totalNum={totalNum}
              setMatchNum={setMatchNum}
              estimatedMaxCost={estimatedMaxCost}
            />

            <PricingTable
              matchNum={matchNum}
              moneyTotal={total}
              totalRecords={totalNum}
              estimatedMaxCost={estimatedMaxCost}
            />
          </StyledCard>
        </Grid>
        <Grid item xs={12} lg={5} gap={2}>
          <CurrentBalanceCard dynamicBalance={total} />

          <BillingCycleProgress />
        </Grid>
        <Grid item lg={7} xs={12}>
          <NotificationEmail page="Select Matches" />
        </Grid>
      </Grid>
    </>
  )
}

export default PriceCalculator
