/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'

import ScrollTopButton from '../../components/Button/ScrollTopButton'

import { ReactComponent as LeftIcon } from '@/assets/icons/ic-left-arrow.svg'
import { usePageTracker } from '@/analytics'

export const INFO_EMAIL_ADDRESS = 'info@helloarcher.io'

const trackingPayload = { page: 'Terms of Service' } as const

const TermsOfUsePage = () => {
  const navigate = useNavigate()
  usePageTracker('Visits Terms of Service Page', trackingPayload)

  return (
    <Container fixed sx={{ mt: 5, mb: 5 }}>
      <Button onClick={() => navigate(-1)} variant="outlined" size="small" startIcon={<LeftIcon />}>
        Back
      </Button>
      <Typography variant="title" color="primary.main" mt={2} mb={5}>
        Terms and Conditions of Use
      </Typography>
      <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 6 }}>
        <i>Last Updated: February 14, 2023</i>

        <Typography variant="subtitle1" fontWeight={700} mt={2} mb={2} align="center">
          THESE TERMS ARE IMPORTANT AND AFFECT YOUR LEGAL RIGHTS. PLEASE CAREFULLY READ THIS TERMS
          AND CONDITIONS OF USE & PRIVACY POLICY PRIOR TO USING OUR WEBSITE. THESE TERMS CONTAIN A
          BINDING ARBITRATION CLAUSE. PLEASE READ THESE TERMS AND THE ARBITRATION PROVISION SO THAT
          YOU ARE AWARE OF YOUR LEGAL RIGHTS.
        </Typography>

        <Typography
          variant="h6"
          fontWeight={700}
          mb={2}
          align="center"
          sx={[{ textDecoration: 'underline' }]}
        >
          TERMS AND CONDITIONS OF USE
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} mb={2}>
          Section 1 – Introduction & Consent to Terms
        </Typography>

        <Typography variant="body1">
          Welcome to Archer LLC a Delaware limited liability company (referred to herein as “Archer,
          “we”, “us”, or “our”) Terms & Conditions of Use (referred to herein as the “Terms”).
          Archer is a company engaged in assisting campaigns, causes, and companies with contact
          information enrichment efforts, with a variety of products and services including data
          enrichment services (referred to herein as our “Services”).
          <br />
          <br />
          These Terms apply to all users of our website{' '}
          <Link href="https://www.sterlingstrategies.co/" target="_blank" rel="noreferrer">
            https://www.sterlingstrategies.co/
          </Link>
          ,{' '}
          <Link href="https://sterlingdatacompany.com/" target="_blank" rel="noreferrer">
            https://sterlingdatacompany.com/
          </Link>
          ,{' '}
          <Link href="https://www.sterling.ai/" target="_blank" rel="noreferrer">
            https://www.sterling.ai/
          </Link>
          ,{' '}
          <Link href="https://www.helloarcher.io/" target="_blank" rel="noreferrer">
            https://www.helloarcher.io/
          </Link>
          , and{' '}
          <Link href="https://app.helloarcher.io" target="_blank" rel="noreferrer">
            https://app.helloarcher.io
          </Link>{' '}
          (each, a “Website”) and users who sign up for our Services.
          <br />
          <br />
          <b>
            If you do not agree to these Terms, we ask that you please not use our Website or sign
            up for our Services. Use of our Website and signing up for our Services constitutes
            explicit acceptance of these Terms.
          </b>
          <br />
          <br />
          We reserve the right to modify the contents of these Terms at any time. You agree that it
          is your responsibility to monitor changes to these Terms. Any modification to these Terms
          will be notated at the top of these Terms.
          <br />
          <br />
          If you are entering into these Terms on behalf of an entity, you represent and warrant
          that you are authorized to accept these Terms on such entity’s behalf and that such entity
          agrees to be responsible to us if you or the entity violates these Terms.
          <br />
          <br />
          Thank you and we look forward to assisting you with your contact information enrichment
          needs!
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 2 – Eligibility, User Account, & Technological Requirements
        </Typography>

        <Typography variant="body1">
          Access to and use of our Website and Services is not meant to be used by anyone under the
          age of eighteen (18) and we do not target persons under this age. By using our Website or
          our Services, you represent and warrant that (a) you are at least eighteen (18) years old;
          (b) have a working computer with internet access; and (c) meet all technological
          requirements to use our Services.
          <br />
          <br />
          Your use of our Services involves the use of hardware, software, and access to the
          internet. These system requirements may change from time to time. You acknowledge and
          agree that all system or internet requirements for our Services is/are your sole
          responsibility, and you acknowledge and agree that failure to comply with all hardware,
          software, and internet requirements will be borne solely by you.
          <br />
          <br />
          To register for our Services or the Services of one of our affiliates or related
          companies, you are required to create a user account through our Website (a “User
          Account”) or directly with us or our affiliates or related companies via a separate
          written contractual agreement, and provide all information as requested when prompted to
          create a user account (referred to herein as becoming a “User”). The specific elements of
          our Services can be accessed through your User Account.
          <br />
          <br />
          By registering for our Services and becoming a User, you further represent and warrant
          that (a) all information submitted to us on your User Account is true and correct to the
          best of your knowledge; (b) if there are any errors or inaccuracies on your User Account,
          you will update this information as soon as you discover the inaccuracy; (c) you will
          maintain and continue to update your User Account to ensure that at all times it is
          current and accurate; and (d) you are only authorized to register a third-party if you
          have unambiguous consent from that person, preferably in writing, to do so.
          <br />
          <br />
          By signing up and becoming a User or by providing your contact information through our
          Website, you explicitly consent to receive marketing and other communications related to
          our Services, products, and future product offerings. You are able to terminate this
          consent in accordance with our Privacy Policy.
          <br />
          <br />
          You are solely responsible for maintaining confidentiality of your password and account
          information and are responsible for all activity that takes place on your account. We are
          not responsible for any activity through your User Account.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 3 – Free Trial
        </Typography>

        <Typography variant="body1">
          <i>This Section only applies to Users that we have offered a Free Trial.</i>
          <br />
          <br />
          We may offer you a risk-free trial period to use our Services (“Trial Period”) in which
          you are able to use our Services on a trial basis and without long-term commitment or
          being charged for our Services. Your Trial Period is 100% free. The specific terms of your
          Trial Period will be as offered to you. Only one (1) Trial Period is permitted per client.
          This may also include affiliates, parents, subsidiaries, or related companies of the
          person or entity who uses our Services during a Trial Period.
          <br />
          <br />
          If you are not totally happy with our Services during your Trial Period, you are not
          required to continue using our Services.
          <br />
          <br />
          OUR TRIAL PERIOD IS PROVIDED 'AS IS' AND, TO THE EXTENT PERMITTED BY APPLICABLE LAW,
          ARCHER AND ITS OWNERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS,
          THIRD PARTY PROVIDERS AND AFFILIATES, EXCLUDE ALL REPRESENTATIONS OR WARRANTIES OF ANY
          KIND, EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
          MERCHANTABILITY, SATISFACTORY QUALITY, AND FITNESS FOR A PARTICULAR PURPOSE.
          <br />
          <br />
          If you are offered a Trial Period, your Trial Period is subject to all of the terms and
          conditions contained herein.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 4 – How Our Services Work
        </Typography>

        <Typography variant="body1">
          Our Services are purchased on an as-use basis, meaning that you only pay for Services that
          are ordered by you. <br />
          <br />
          Our Services are based on a flat-rate per acquisition and enrichment.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 5 – Additional Terms of Sale
        </Typography>

        <Typography variant="body1">
          We offer three (3) methods for payment: (a) a monthly auto-draw on the first (1st) of each
          calendar month; (b) payment of Services when ordered; or (c) payment by a user prior to
          the auto-draw on the first (1st) of each calendar month. If you do not pay for Services,
          when ordered, you have the option to pay later, until the first (1st) of each calendar
          month when your card on file will be automatically charged for all Services ordered.{' '}
          <br />
          <br />
          If you have enrolled and purchased our Services, you expressly acknowledge and agree that
          Sterling (or our third-party payment processor) is authorized to charge you on a monthly
          basis for all unpaid Service-charges.
          <br />
          <br />
          No new Services can be ordered nor fulfilled by Archer until all prior Service-related
          charges have been paid.
          <br />
          <br />
          You are required to keep at least (1) active credit card on your User Account at all times
          unless you have a $0.00 account balance. If you would like to add a new credit card, you
          are required to add the new credit card before deleting the old credit card, unless you
          have a $0.00 account balance.
          <br />
          <br />
          All prices shown for our Services are shown in U.S. Dollars. We reserve the right to
          change our Services at any time, provided that if we change the amounts or other charges
          associated with your Services, we will provide you thirty (30) days advance written notice
          of such changes. Any price changes will be looking forward and will not alter any
          prior-ordered Services.
          <br />
          <br />
          We reserve the right to reject our Services to anyone and for any reason. We also reserve
          the right to discontinue certain Services at any time.
          <br />
          <br />
          When you provide payment information, you represent and warrant that the information you
          provide is accurate, that you are authorized to use the payment method provided, and that
          you will notify us of changes to the payment information. We reserve the right to utilize
          third party payment card updating services to obtain current expiration dates on credit
          cards and debit cards.
          <br />
          <br />
          By providing a credit card, you represent and warrant that you authorize us to charge your
          payment method for all charges you incur for your purchase of our Services. By providing a
          credit card, you represent and warrant that you have a sufficient balance on the provided
          credit card to pay for all Services incurred. This statement is a blanket authorization
          for all charges you incur in connection with our Services, and you expressly permit us to
          run your card for all charges you incur in connection with our Services.
          <br />
          <br />
          Once you have incurred charges for our Services, you are obligated to pay for those
          Services.
          <br />
          <br />
          Failure to pay for Services used, in full, will cause that all past-due amounts to accrue
          interest at three percent (3%) per month until paid in full. If you fail to pay any monies
          due, Archer reserves the right to terminate your access to the Services. Archer reserves
          the right to transfer your invoice, account, and any past-due payments to a collection
          agency or attorney. If your invoice, account, and any past-due payments is transferred to
          a collection agency or attorney, Archer shall be entitled to recover all costs and fees in
          collecting your past-due balance, including all reasonable attorneys’ fees.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 6 – Cancellation and Refunds
        </Typography>

        <Typography variant="body1">
          You may cancel your Services at any time by terminating these Terms or simply not using
          our Services moving forward.
          <br />
          <br />
          Cancellation does not alter any payment obligations incurred prior to termination.
          <br />
          <br />
          We do not offer refunds for our Services. If you are unhappy with your Services, please
          contact us, detailing the reason why you are unhappy with the Services. We will do our
          best to ensure that you are satisfied with our Services, within reason.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 7 - Archer Intellectual Property
        </Typography>

        <Typography variant="body1">
          The contents of our Website are protected by United States and international copyright
          laws. The contents of our Website are owned exclusively by Archer or licensed to us.
          <br />
          <br />
          You may not, and may not cause or encourage others to, reproduce, distribute, display,
          sell, transfer, assign, license or use for commercial purposes any copyrighted material on
          our Website (the “Intellectual Property”) without our prior written consent. All rights
          not expressly granted in these Terms are reserved to Archer.
          <br />
          <br />
          Archer and its name, logos, slogans, or otherwise are trademarks or service marks
          ("Marks") of Archer. All rights in these Marks are reserved by Archer. You may not use any
          Archer-provided Marks or other logos or graphics, without our prior written consent.
          <br />
          <br />
          We grant you a limited, revocable, non-transferable and non-exclusive license to access
          and make personal use of our Website and our Services. This limited license does not
          include the right to: (a) republish, redistribute, transmit, sell, license or download
          anything on our Website, except as is necessary to view and/or use our Website; (b) make
          any use of our Website other than uses consistent with our Services or exploring the
          Services; (c) modify, reverse engineer or create any derivative works based upon our
          Website; (d) collect account information for the benefit of yourself or another party; or
          (e) use software robots, spiders, crawlers, or similar data gathering and extraction
          tools, or take any other action that may impose an unreasonable burden or load on our
          infrastructure.
          <br />
          <br />
          Any unauthorized use by you of our Website automatically terminates this license without
          prejudice to any other remedy provided by applicable law or these Terms.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 8 – Your Materials & Submissions
        </Typography>

        <Typography variant="body1">
          You are solely responsible for your use or misuse of our Services, including anything you
          do with the data we have enriched for you. We will not be responsible for your use or
          misuse of our Services, for example, for using our Services or anything derived therefrom
          in connection with anything that is illegal, immoral, unethical, or reprehensible. See
          Sections 10, 14, 15, and 16 regarding your misuse of our Services, all of which are
          incorporated into this Section as if fully written herein.
          <br />
          <br />
          Using our Services may include the uploading of certain data to our Website or platform.
          Any errors of any kind in uploading data to our Website or platform will be borne solely
          by you.
          <br />
          <br />
          If you send submissions of any kind, with or without a request from us, including ideas,
          suggestions, or otherwise, whether online, by email, or otherwise (“Submissions”), you
          agree that we may, at any time, without restriction, edit, copy, publish, distribute,
          translate, and otherwise use in any medium any Submissions that you forward to us. This
          does not include any confidential or personal information that you send to us (such as
          when signing up for our Services).
          <br />
          <br />
          We are and shall be under no obligation (1) to maintain any Submissions in confidence; (2)
          to pay compensation to you for any Submissions; or (3) to respond to any Submissions.
          <br />
          <br />
          By you submitting Submissions to us, you hereby transfer and assign any claim to any
          rights that you may have had in those Submissions and do so with full acknowledgment of
          the same. You agree that your Submissions will not violate any right of any third-party,
          including copyright, trademark, privacy, personality or other personal or proprietary
          right. We take no responsibility and assume no liability for any Submissions submitted by
          you.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 9 – Third Party Content and Third-Party Waiver
        </Typography>

        <Typography variant="body1">
          Our Website may contain links to third-party websites or services that are not owned or
          controlled by us. Archer has no control over, and assumes no responsibility for, the
          content, privacy policies, or business practices of any third-party companies or persons.
          We strongly encourage you to read the terms and conditions and privacy policies of any
          third-party websites or services that you visit.
          <br />
          <br />
          You understand that Archer may use licensed materials or content on our Website and that
          there is no agency relationship between Archer and any of these third-party persons or
          entities, all of whom are separate from Archer.
          <br />
          <br />
          By using our Website, you expressly waive Archer, our employees, agents, owners, and
          principals from all liability arising from your use of any content, plans, or services
          provided by third-parties.
          <br />
          <br />
          YOU AGREE THAT ARCHER WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE,
          DIRECTLY OR INDIRECTLY, FOR ANY CONTENT OF ANY KIND OR FOR ANY HARM RELATED THERETO, OR
          FOR ANY DAMAGES OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH YOUR USE
          OR RELIANCE ON THE CONTENT OF ALL CONTENT PROVIDED BY A THIRD-PARTY.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 10 – Community Guidelines
        </Typography>

        <Typography variant="body1">
          You are prohibited from using our Website or Services:
          <br />
          <br />
          <List>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                a)
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="for any unlawful purpose;"
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                b)
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="to solicit others to perform or participate in any unlawful acts;"
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                c)
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="to violate any international, federal, provincial or state regulations,
                rules, laws, or local ordinances; or"
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                d)
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="for any obscene or immoral purpose."
              ></ListItemText>
            </ListItem>
          </List>
          <br />
          We reserve the right to terminate your use of our Website or Services for violating any of
          these community guidelines.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 11 - Copyright Infringement
        </Typography>

        <Typography variant="body1">
          If you believe that your copyright has been infringed by Archer, please immediately send
          us a notice to {INFO_EMAIL_ADDRESS} with the following information:
          <br />
          <br />
          <List>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                1.
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="A physical or electronic signature of a person authorized to act on behalf
          of the owner of an exclusive right that is allegedly infringed."
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  typography: 'body1',
                  color: 'text.primary'
                }}
              >
                2.
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="Identification of the copyrighted work claimed to have been infringed, or,
                if multiple copyrighted works at a single online site are covered by a single
                notification, a representative list of such works at that site."
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  typography: 'body1',
                  color: 'text.primary'
                }}
              >
                3.
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="Identification of the material that is claimed to be infringing or to be
                the subject of infringing activity and that is to be removed or access to which is to be
                disabled, and information reasonably sufficient to permit the service provider to locate
                the material."
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  typography: 'body1',
                  color: 'text.primary'
                }}
              >
                4.
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="Information reasonably sufficient to permit the service provider to contact
                the complaining party, such as an address, telephone number, and, if available, an
                electronic mail address at which the complaining party may be contacted."
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  typography: 'body1',
                  color: 'text.primary'
                }}
              >
                5.
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="A statement that the complaining party has a good faith belief that use of
                the material in the manner complained of is not authorized by the copyright owner, its
                agent, or the law."
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  typography: 'body1',
                  color: 'text.primary'
                }}
              >
                6.
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="A statement that the information in the notification is accurate, and under
                penalty of perjury, that the complaining party is authorized to act on behalf of the owner
                of an exclusive right that is allegedly infringed."
              ></ListItemText>
            </ListItem>
          </List>
          <br />
          We respond to notices of copyright infringement immediately and take any such allegations
          seriously. The reported content will be taken down within seventy-two (72) hours of
          written notice unless it is clear that your notice is spam or if we have evidence that
          your claim is false.
          <br />
          <br />
          Archer prohibits any infringement of intellectual property rights by any user of our
          Website and Services.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 12 – Term & Errors
        </Typography>

        <Typography variant="body1">
          These Terms will remain active and in full force and effect so long as they are posted on
          our Website.
          <br />
          <br />
          Occasionally there may be information on our Website that contains typographical errors,
          inaccuracies or omissions that may relate to descriptions, pricing, promotions, offers,
          and availability. We reserve the right to correct any errors, inaccuracies, or omissions,
          at any time.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 13 – ADA Compliance
        </Typography>

        <Typography variant="body1">
          If you believe that you are or may be covered by the Americans with Disabilities Act of
          1990, as amended (the “ADA Act”), please reach out to us with any issues or concerns you
          have governing our Website and/or these Terms. We will be more than happy to assist you to
          ensure that you are able to access our Website and our Services.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 14 - Disclaimer
        </Typography>

        <Typography variant="body1">
          OUR WEBSITE AND OUR SERVICES ARE PROVIDED 'AS IS' AND, TO THE EXTENT PERMITTED BY
          APPLICABLE LAW, ARCHER AND ITS OWNERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
          REPRESENTATIVES, LICENSORS, THIRD PARTY PROVIDERS AND AFFILIATES, EXCLUDE ALL
          REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED
          TO, IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, AND FITNESS FOR A
          PARTICULAR PURPOSE.
          <br />
          <br />
          WE DO NOT AND CANNOT GUARANTEE PERFECT AVAILABILITY OF OUR WEBSITE OR OUR SERVICES, WHICH
          MAY BE INACCESSIBLE, IN FULL OR IN PART, FROM TIME TO TIME. IN ADDITION, SOFTWARE UPDATES,
          BUGS, OR OTHER REASONS WHY OUR WEBSITE OR SERVICES MAY BE TEMPORARILY UNAVAILABLE COULD BE
          A TIME-TO-TIME OCCURRENCE. BY USING OUR WEBSITE OR OUR SERVICES, YOU ACKNOWLEDGE AND AGREE
          THAT OPERATING AN ONLINE PLATFORM IS LIKE OWNING A LUXURY VEHICLE – UNEXPECTED ISSUES DO
          ARISE FROM TIME TO TIME AND SHOULD BE EXPECTED. YOU WILL NOT HAVE UNINTERRUPTED SERVICE OF
          OUR WEBSITE AT ALL TIMES.
          <br />
          <br />
          WE DO NOT AND CANNOT GUARANTEE THAT OUR ENRICHMENT SERVICES ARE CERTAIN, MEANING THAT WE
          CANNOT GUARANTEE THAT ALL RECORDS OR DATA YOU SEND US CAN BE ENRICHED. OUR SERVICES ARE
          LIMITED BY OUR ABILITY TO ENRICH THE DATA OR RECORDS YOU HAVE SENT US.
          <br />
          <br />
          WE DO NOT PERMIT ANY UNLAWFUL ACTIVITY VIA USE OF OUR SERVICES.
          <br />
          <br />
          WE EXPRESSLY DISCLAIM ANY LIABILITY FOR LOSS OR DAMAGE SUSTAINED BY YOU AS A RESULT OF
          USING OUR SERVICES.
          <br />
          <br />
          WE DO NOT AND CANNOT GUARANTEE ANY RESULTS FROM YOUR USE OF OUR SERVICES. WHILE WE HAVE A
          PROVEN TRACK RECORD OF ASSISTING CLIENTS WITH A WIDE VARIETY OF CONTACT INFORMATION
          ENRICHMENT NEEDS, THE UNDERLYING SUCCESS OF OUR CLIENTS ULTIMATELY DEPENDS ON THEIR
          BUSINESS. NO AMOUNT OF COMPENSATION PAID BY YOU IS TIED TO A SPECIFIC RESULT OF THESE
          SERVICES OR ANYTHING IN CONNECTION WITH THEM.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 15– Limitation of Liability
        </Typography>

        <Typography variant="body1">
          WE SHALL NOT BE LIABLE TO YOU FOR INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES,
          INCLUDING LOST PROFITS, EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, NOR
          SHALL WE BE HELD LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND
          OUR REASONABLE CONTROL. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES,
          LOSSES AND CAUSES OF ACTION EXCEED THE TOTAL AMOUNT ARCHER RECEIVED IN CONNECTION WITH
          YOUR SERVICES, OR IF YOU HAVE NOT USED OUR SERVICES, TEN DOLLARS ($10.00).
          <br />
          <br />
          WE SHALL NOT BE LIABLE TO YOU NOR ANY THIRD-PARTY FOR YOUR MISUSE OF OUR SERVICES OR YOUR
          BREACH OF APPLICABLE LAW OR THESE TERMS. THIS SHALL SPECIFICALLY INCLUDE ANY ACTIONS YOU
          TAKE WITH THE DATA WE ENRICH FOR YOU, NONE OF WHICH IS THE RESPONSIBILITY OF STERLING.
          <br />
          <br />
          STERLING SHALL NOT BE LIABLE TO ANY THIRD-PARTIES ARISING OUT YOUR USE OR MISUSE OF OUR
          SERVICES. AS AN INDEPENDENTLY CONTRACTING THIRD-PARTY, YOU ARE SOLELY RESPONSIBLE FOR ALL
          USES OF THE DATA WE ENRICH FOR YOU. YOU CANNOT BIND STERLING TO ANY ACTION OR INACTION
          ARISING OUT OF YOUR USES OF THE DATA WE ENRICH FOR YOU.
          <br />
          <br />
          YOU AGREE THAT NO CLAIMS OR ACTION IN CONTRACT, WARRANTY, OR IN TORT (INCLUDING
          NEGLIGENCE) ARISING OUT OF, OR RELATED TO, THE USE OF OUR WEBSITE, OUR SERVICES, OR THESE
          TERMS MAY BE BROUGHT MORE THAN ONE (1) YEAR AFTER THE CAUSE OF ACTION RELATING TO SUCH
          CLAIM OR ACTION AROSE. IF YOU ARE DISSATISFIED WITH OUR WEBSITE OR SERVICES, YOU ARE NOT
          REQUIRED TO CONTINUE TO USE OUR WEBSITE OR SERVICES. WE HAVE NO OTHER OBLIGATION,
          LIABILITY, OR RESPONSIBILITY TO YOU.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 16 – Indemnification
        </Typography>

        <Typography variant="body1">
          You agree to defend, indemnify and hold Archer, its agents, employees, directors,
          officers, owners, etc. harmless for any loss, damages or costs, including reasonable
          attorneys' fees, resulting from any claim, action, or demand arising from (i) your use of
          our Website or Services; (ii) your breach of these Terms; or (iii) breach of applicable
          law.{' '}
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 17 – FTC Disclosures
        </Typography>

        <Typography variant="body1">
          In connection with our Services (i) we may run advertisements on our Website concerning
          third-parties who may provide us compensation to be advertised on our Website; and (ii) we
          may receive compensation if you click one of the links on our Website.{' '}
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 18 – General Provisions
        </Typography>

        <Typography variant="body1">
          <i>Entire Agreement.</i> These Terms contain the entire agreement between you and Archer
          except for any Services-related contracts you have entered into with Archer.
          <br />
          <br />
          <i>Waiver.</i> The failure by Archer to enforce any provision of these Terms shall not be
          construed as a waiver or limitation of our right to subsequently enforce and compel strict
          compliance with every provision of this Agreement.
          <br />
          <br />
          <i>Assignment.</i> You may not assign, pledge, delegate, or otherwise transfer any of your
          rights or obligations under this Agreement without Archer prior written consent.
          <br />
          <br />
          <i>Relationship.</i> Nothing in these Terms shall create, or is intended to create an
          agency, employment, franchise, joint venture, or partnership relationship between you and
          Archer.
          <br />
          <br />
          <i>Applicable Law/Dispute Resolution.</i> This Agreement shall be governed by the laws of
          the State of Delaware. Any dispute arising from this Agreement shall be subject to binding
          arbitration in Delaware. The prevailing Party in any dispute shall be entitled to recover
          its/his/her reasonable attorney’s fees and costs. The governing rules shall be the rules,
          then-implemented, by the American Arbitration Association commercial division. Archer and
          you agree that any such final decisions may be presented to a court of competent
          jurisdiction for purposes of being confirmed as a judgment enforceable under the law in
          which that party is domiciled or where their headquarters are located. Should either party
          forego arbitration, that party shall be barred from recovering their attorneys’ fees or
          costs.
          <br />
          <br />
          <i>Severability.</i> If any provision of this Agreement shall be held to be invalid or
          unenforceable for any reason, the remaining provisions shall continue to be valid and
          enforceable. If an arbitrator or panel of arbitrators finds that any provision of this
          Agreement is invalid or unenforceable, but that by limiting such provision it would become
          valid and enforceable, then such provision shall be deemed to be written, construed, and
          enforced as so limited.
          <br />
          <br />
          <i>Updates.</i> We recommend that you check the Terms periodically for updates.
        </Typography>
      </Box>

      <ScrollTopButton />
    </Container>
  )
}

export default TermsOfUsePage
