import { useCallback, useState } from 'react'
import { Typography, Button } from '@mui/material'

import { useAuth } from '@/reducers/user'
import SendEmailVerificationModal from '@/components/Modal/SendEmailVerificationModal'
import { useEmailSentModal } from '@/components/Modal/EmailSentModal'
import { PAGE } from '@/analytics'

const VerifyEmailBanner = ({
  page,
  title = 'Verify your email address to access your previous lookups!'
}: {
  page: PAGE
  title?: string
}) => {
  const { user } = useAuth()
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false)
  const [, setEmailSentModalOpen] = useEmailSentModal()

  const onSent = useCallback(() => {
    setSendEmailModalOpen(false)
    setEmailSentModalOpen(true)
  }, [setEmailSentModalOpen])

  return (
    <>
      <Typography variant="h3" className="text-pretty">
        {title}
      </Typography>
      <Typography variant="body1" fontWeight={400}>
        We&apos;ll send an email to <b>{user?.email}</b>.
      </Typography>
      <Button
        variant="contained"
        size="large"
        onClick={() => {
          setSendEmailModalOpen(true)
        }}
        className="mt-2"
      >
        Verify Email
      </Button>
      <SendEmailVerificationModal
        open={sendEmailModalOpen}
        setOpen={setSendEmailModalOpen}
        onSent={onSent}
        page={page}
      />
    </>
  )
}

export default VerifyEmailBanner
