import ReplayIcon from '@mui/icons-material/Replay'

import { ReactComponent as WorkEmailIcon } from '@/assets/icons/ic-briefcase.svg'
import { ReactComponent as CheckedIcon } from '@/assets/icons/ic-check-gradient.svg'

import FormInput from '@/components/Form/Input'
import { Apis } from '@/providers/ApiProvider'
import { setRetry } from '@/reducers/lookup'
import { processAsset } from '@/services/asset'
import { Asset } from '@/types/assets'
import { CompanyFormValues, companyFormValuesValidationSchema } from '@/types/form-data'
import { capitalizeEveryWord, isCompanyUrl } from '@/utils/str'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Stack, SvgIcon, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import NewLookup from '../NewLookup'

// TODO: Duplicate RetryForm.tsx
const RetryCompanyForm = ({
  asset,
  onRestart,
  setActiveAssetId
}: {
  asset: Asset
  onRestart: () => void
  setActiveAssetId: (assetId: string) => unknown
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isCompanyNameAsUrl = isCompanyUrl(asset?.singleEnrichment?.company)

  const { control, handleSubmit, formState, setValue } = useForm<CompanyFormValues>({
    resolver: yupResolver(companyFormValuesValidationSchema)
  })

  useEffect(() => {
    const fullName = !isCompanyNameAsUrl
      ? capitalizeEveryWord(asset?.singleEnrichment?.fullName)
      : ''

    if (!fullName) return

    setValue('fullName', fullName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset?.singleEnrichment.fullName])

  const handleCompanyFormSubmit = async (formValues: CompanyFormValues) => {
    const { data } = await Apis.postAsset({
      filename: `${formValues.fullName}`,
      requestWorkEmails: true,
      requestedType: 'company',
      rows: [formValues]
    })

    const assetId = data.asset._id
    await processAsset({ assetId, navigate })

    return assetId
  }

  const handleFormSubmit = async (formValues: CompanyFormValues) => {
    try {
      const id = await handleCompanyFormSubmit(formValues)

      setActiveAssetId(id)

      dispatch(setRetry(false))
    } catch (error) {
      console.log('Error', error)
    }
  }

  const { isSubmitting } = formState

  const boxWidth =
    !asset.singleEnrichment.enrichedPhone && !asset.singleEnrichment.enrichedEmail
      ? 'max-w-[80%]'
      : 'max-w-[100%]'

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box className={boxWidth}>
        <Stack
          className="mt-4"
          direction="row"
          spacing={{ lg: 0, xl: 2, sm: 0 }}
          justifyContent="center"
          alignContent="center"
          display={{
            lg: 'block',
            xl: 'flex',
            sm: 'block',
            xs: 'block'
          }}
          sx={{
            label: {
              fontWeight: 500,
              color: '#515468'
            }
          }}
        >
          <Box className="flex-grow">
            <FormInput
              formState={formState}
              control={control}
              name="fullName"
              label="FULL NAME"
              controlId="fieldFullName"
              rules={{ required: true }}
            />
          </Box>
          <Box className="flex-grow">
            <FormInput
              control={control}
              formState={formState}
              name="company"
              label="COMPANY WEBSITE"
              controlId="fieldCompany"
              rules={{ required: true }}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            display: 'flex'
          }}
        >
          <Box>
            {/* TODO: Create a reusable component */}
            <Stack direction="row" alignItems="center" gap={0.3}>
              <SvgIcon
                className="mt-2"
                component={CheckedIcon}
                color="primary"
                sx={{
                  width: '2.5rem',
                  height: '2.5rem'
                }}
              />
              <SvgIcon className="mt-1" component={WorkEmailIcon} color="primary" />
              <Typography variant="body1" fontWeight="700">
                Work Emails
              </Typography>
            </Stack>
          </Box>
          <Box>
            <LoadingButton
              loading={isSubmitting}
              className="w-full"
              type="submit"
              variant="outlined"
              startIcon={<ReplayIcon />}
              loadingPosition="start"
              disabled={isSubmitting}
            >
              Retry
            </LoadingButton>
          </Box>
        </Stack>
      </Box>
      {!asset.singleEnrichment.enrichedPhone && !asset.singleEnrichment.enrichedEmail && (
        <Box className="mt-10">
          <NewLookup onRestart={onRestart} />
        </Box>
      )}
    </form>
  )
}
export default RetryCompanyForm
