import { Form } from 'react-bootstrap'

import { useController, UseControllerProps, FieldValues } from 'react-hook-form'
import './check.scss'
import InputError from '../Input/InputError'

type FormCheckProps<FieldsType extends FieldValues> = UseControllerProps<FieldsType> & {
  controlId?: string
  label?: string | React.ReactNode
  type?: string
  placeholder?: string
  error?: string
}

const FormCheck = <FieldValuesType extends FieldValues>({
  control,
  name,
  rules,
  controlId,
  error,
  label
}: FormCheckProps<FieldValuesType>) => {
  const { field } = useController({ name, control, rules })

  return (
    <Form.Group className="mb-3" controlId={controlId}>
      <Form.Check type="checkbox" label={label} {...field} />
      {!!error && <InputError error={error} />}
    </Form.Group>
  )
}

export default FormCheck
