import { useEffect } from 'react'
import { useAuth } from '@/reducers/user'
import { useLocation } from 'react-router-dom'

import { Payload, TYPE } from './types'
import { mixpanelTrack, mixpanelTrackUserProfile } from './track'

export const useTrackAuth = () => {
  const { user } = useAuth()

  useEffect(() => {
    if (!user) {
      return
    }

    mixpanelTrackUserProfile({
      userId: user._id,
      name: user.fullName,
      email: user.email
    })
  }, [user])
}

export const usePageTracker = (event: TYPE, payload?: Payload | undefined) => {
  const location = useLocation()

  useEffect(() => {
    mixpanelTrack(event, payload)
  }, [event, location.pathname, payload])
}

export const useAuthGuardPageTracker = (event: TYPE, payload?: Payload | undefined) => {
  const location = useLocation()
  const { user, isLoading } = useAuth()

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (!user) {
      return
    }
    mixpanelTrack(event, payload)
  }, [user, isLoading, location.pathname, event, payload])
}
