import { ReactComponent as WelcomeImg } from '@/assets/images/welcome.svg'

const WelcomeSideScreen = () => {
  return (
    <div className="w-full bg-light px-8 flex flex-col items-center justify-center">
      <WelcomeImg />
    </div>
  )
}

export default WelcomeSideScreen
