/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'

import { Notification } from '@/components/Notification/Notification'
import NavSidebar from '@/components/Navbar/NavSidebar'
import ScrollToTop from '@/components/ScrollToTop'

import './layout.scss'
import AdminMode from './AdminMode'
import Footer from './Footer'
import MainLayout from './main'
import useIsMobile from '@/utils/isMobile'
import PersistentModals from '@/components/Modal/PersistentModals'

const MainSidebarLayout = () => {
  const isMobile = useIsMobile()

  return (
    <>
      {isMobile ? (
        <div className="block md:hidden">
          <MainLayout />
        </div>
      ) : (
        <>
          <div className="hidden md:flex h-full">
            <ScrollToTop />
            <NavSidebar />
            <Box
              sx={{
                minHeight: 'calc(100vh - 96px)'
              }}
              className="mt-12 flex flex-col relative flex-1 mx-auto"
            >
              <Outlet />
              <AdminMode />
              <Notification />
            </Box>
          </div>
          <Footer className="pl-[224px]" />
        </>
      )}
      <PersistentModals />
    </>
  )
}

export default MainSidebarLayout
