import { Alert, Button, Typography } from '@mui/material'
import { object } from 'yup'
import { useForm } from 'react-hook-form'
import FormTextarea from '@/components/Form/Textarea/Textarea'
import FormSelect from '@/components/Form/Input/Select'
import { ReactComponent as CircleCheckIcon } from '@/assets/icons/ic-circle-check.svg'
import './payment.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import { getRequiredStringValidator } from '@/utils/validators'
import { useState } from 'react'
import { ArrayElement } from 'type-fest/source/internal'
import { submitFeedback } from '@/services/feedback'

const options = [
  { value: 'Incorrect charges', label: 'Incorrect charges' },
  { value: 'Payment issues', label: 'Payment issues' },
  { value: 'Issue with enrichment', label: 'Issue with enrichment' },
  { value: 'Subscription issues', label: 'Subscription issues' }
]

const schema = object({
  feedback: getRequiredStringValidator('Feedback is required').min(
    5,
    'Feedback must be at least 5 characters'
  ),
  type: getRequiredStringValidator('Type is required').oneOf(options.map((o) => o.value))
}).required()

export interface ContactUsFormValues {
  feedback: string
  type: ArrayElement<typeof options>['value']
}

const ContactUsForm = () => {
  const { control, handleSubmit, formState } = useForm<ContactUsFormValues>({
    defaultValues: {
      type: '',
      feedback: ''
    },
    resolver: yupResolver(schema)
  })

  const [serverError, setServerError] = useState<string | undefined>(undefined)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const onSubmit = async (data: ContactUsFormValues) => {
    try {
      setServerError(undefined)

      await submitFeedback(data)

      setIsSubmitted(true)
    } catch (error) {
      setServerError('Something went wrong')
    }
  }

  return (
    <div className="p-6 bg-background.tertiary rounded-xl self-stretch flex justify-center flex-wrap items-start">
      <div className="flex-1 flex flex-col gap-2">
        <Typography variant="h3">Contact us!</Typography>
        <Typography variant="body1">Having problems with payment? We are here to help!</Typography>
      </div>
      <form
        className="contact-us-form flex-1 flex flex-col gap-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormSelect
          control={control}
          name="type"
          label="PROBLEM TYPE"
          controlId="fieldType"
          rules={{ required: true }}
          formState={formState}
        >
          <option>Select an option</option>
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </FormSelect>
        <FormTextarea
          control={control}
          name="feedback"
          label="YOUR FEEDBACK"
          controlId="fieldFeedback"
          rules={{ required: true }}
          placeholder="How can we help?"
        />
        <div className="flex items-center gap-4">
          <Button
            variant="outlined"
            disabled={formState.isSubmitting || isSubmitted}
            type="submit"
            size="large"
            className="self-start"
          >
            Send message
          </Button>

          {isSubmitted ? <CircleCheckIcon className="w-5 h-5 fill-secondary.main" /> : null}
        </div>
        {serverError ? (
          <Alert className="rounded-xl mt-4" severity="error">
            {serverError}
          </Alert>
        ) : null}
      </form>
    </div>
  )
}

export default ContactUsForm
