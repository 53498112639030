import SettingsTabs from '../tabs'
import { Container } from '@mui/material'
import Addresses from './Addresses'
import PaymentSettings from './PaymentSettings'
import { useUserPaymentInfo } from './usePaymentMethods'
import { useAuthGuardPageTracker } from '@/analytics'
import Banners from '@/pages/dashboard/Banners'

const trackingPayload = { page: 'Payment Settings' } as const

const SettingsBillingPage = () => {
  useAuthGuardPageTracker('Visits Payment Settings Page', trackingPayload)
  const { payload: userInfo, refetch: refreshUser } = useUserPaymentInfo()

  return (
    <>
      <Banners page="Payment Settings" />
      <div className="flex-1 flex flex-col">
        <SettingsTabs />
        <Container maxWidth="xl" sx={{ py: 5 }} className="flex-1 flex gap-8 flex-wrap">
          <Addresses userInfo={userInfo} />
          <PaymentSettings userInfo={userInfo} refreshUser={refreshUser} />
        </Container>
      </div>
    </>
  )
}

export default SettingsBillingPage
