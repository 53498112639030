import { useAssets } from '@/pages/asset/hooks'
import { Apis } from '@/providers/ApiProvider'
import { Asset, AssetStatus } from '@/types/assets'
import { IconButton, IconButtonProps, styled, SvgIcon } from '@mui/material'

type Direction = 'up' | 'down'

interface StyledIconButtonProps extends IconButtonProps {
  direction?: Direction
}

interface LikeButtonProps {
  asset: Asset
  direction?: Direction
  icon: React.FC
  assets: ReturnType<typeof useAssets>
}

const StyledIconButton = styled(IconButton)<StyledIconButtonProps>(({ direction }) => ({
  transform: direction === 'down' ? 'scaleY(-1)' : 'scaleY(1)'
}))

export const LIKE_VALUE = 5
export const DISLIKE_VALUE = 1

const LikeButton = ({ asset, direction = 'up', icon: Icon, assets }: LikeButtonProps) => {
  const like = direction === 'up'
  const dislike = direction === 'down'

  const isLiked = asset.rating === LIKE_VALUE && like
  const isDisliked = asset.rating === DISLIKE_VALUE && dislike

  const handleClickRating = async () => {
    if (isLiked || isDisliked) return

    try {
      const rating = like ? LIKE_VALUE : DISLIKE_VALUE

      await Apis.updateRating(asset._id, { rating })

      assets.refetch()
    } catch (err) {
      console.log('==> Error updating ratings')
    }
  }

  const disabled = asset.status === AssetStatus.PROCESSING

  // TODO: Use the theme to get the colors
  let color = disabled ? '#C4C5D1' : '#515468'
  if (isLiked || isDisliked) color = '#2721AE'

  return (
    <StyledIconButton direction={direction} disabled={disabled} onClick={handleClickRating}>
      <SvgIcon sx={{ color }}>
        <Icon />
      </SvgIcon>
    </StyledIconButton>
  )
}

export default LikeButton
