import { useAuthGuardPageTracker } from '@/analytics'
import { Grid } from '@mui/material'
import DashboardLayout from './DashboardLayout'
import QuickEnrich from './QuickMatching/QuickEnrich'
import RecentFiles from './RecentFiles'

const trackingPayload = { page: 'Dashboard', lookup: 'address' } as const

const DashboardAddressPage = () => {
  useAuthGuardPageTracker('Visits Dashboard Page', trackingPayload)

  return (
    <DashboardLayout
      title="Find people by their personal address"
      shortTitle="Find people by personal address"
    >
      <Grid item xs={12}>
        <QuickEnrich matchType="personal" />
      </Grid>
      <Grid item xs={12} className="hidden md:block">
        <RecentFiles />
      </Grid>
    </DashboardLayout>
  )
}

export default DashboardAddressPage
