import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  styled,
  AccordionSummaryProps,
  AccordionProps,
  Link
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import ContactUsModal from './ContactUsModal'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.60)',
  color: theme.palette.text.quaternary,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0px 2px 4px 0px rgba(31, 31, 35, 0.10)',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2)
}))

const Entry = ({
  title,
  content,
  expanded,
  handleChange
}: {
  title: string
  content: string | React.ReactNode
  expanded: string | null
  handleChange: (title: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void
}) => {
  return (
    <Accordion
      expanded={title === expanded}
      onChange={handleChange(title)}
      // TODO: Add to the theme
      className="bg-[#F9FCFF] border border-[#DDE3F6] text-text.quaternary shadow-none"
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography className="text-sm">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="text-sm">{content}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

const FAQ = () => {
  const [expanded, setExpanded] = useState<string | null>(null)
  const [open, setOpen] = useState(false)

  const handleChange = (title: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? title : null)
  }

  return (
    <div className="flex flex-col gap-2">
      <Entry
        title="What happens after I run out of free credits?"
        expanded={expanded}
        handleChange={handleChange}
        content={
          <>
            Your credits expire in 7 days. Following that, if you haven&apos;t already done so, you
            will have to provide your payment information to continue using Archer.
            <br />
            <br />
            Other than that, you won&apos;t have to do anything else, Archer will bill you
            automatically each month for your usage, or whenever your balance exceeds five hundred
            credits used.
          </>
        }
      />

      <Entry
        title="Do I need to add a credit card?"
        expanded={expanded}
        handleChange={handleChange}
        content={
          <>
            After using your free credits, you must provide your payment information to continue
            using Archer.
            <br />
            <span className="text-primary.main font-bold">
              Don&apos;t miss out on our current deal. Enter it now, and get 25 more free credits!
            </span>
          </>
        }
      />

      <Entry
        title="When do I have to pay?"
        expanded={expanded}
        handleChange={handleChange}
        content={
          <>
            Archer bills you automatically each month for your usage, or whenever your balance
            exceeds five hundred credits used.
          </>
        }
      />

      <Entry
        title="What happens if I go over 500 credits in a billing cycle?"
        expanded={expanded}
        handleChange={handleChange}
        content={
          <>
            Above 500 credits, the price drops to $0.50/ credit until the end of the billing cycle.
            <br />
            <br />
            You will also have to make a payment every time your balance exceeds five hundred
            credits used.
          </>
        }
      />

      <Entry
        title="How can I get an enterprise plan?"
        expanded={expanded}
        handleChange={handleChange}
        content={
          <>
            Contact us at{' '}
            <a
              href="mailto:success@helloarcher.io"
              className="no-underline font-bold text-primary.main"
            >
              success@helloarcher.io
            </a>
            , or fill out our{' '}
            <Link
              className="text-primary.main font-bold"
              onClick={() => setOpen(true)}
              href="#contact-us"
            >
              form
            </Link>
            , and let&apos;s talk about the possibilities.
          </>
        }
      />
      <Entry
        title="I have another question"
        expanded={expanded}
        handleChange={handleChange}
        content={
          <>
            Contact us at{' '}
            <a
              href="mailto:success@helloarcher.io"
              className="no-underline font-bold text-primary.main"
            >
              success@helloarcher.io
            </a>
            , or fill out our{' '}
            <Link
              className="text-primary.main font-bold"
              onClick={() => setOpen(true)}
              href="#contact-us"
            >
              form
            </Link>
            , and let&apos;s talk about the possibilities.
          </>
        }
      />

      <ContactUsModal open={open} setOpen={setOpen} />
    </div>
  )
}

export default FAQ
