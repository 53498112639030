import { useWindowSize } from 'react-use'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/styles/tailwind.config'
import { useEffect } from 'react'

export const fullConfig = resolveConfig(tailwindConfig)

const getMobileSize = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mdSizeAsString = fullConfig?.theme?.screens?.md as string

  if (mdSizeAsString.endsWith('px')) {
    return parseInt(mdSizeAsString.replace('px', ''), 10)
  }

  return 768
}

const mobileSize = getMobileSize()

const useIsMobile = (onChange?: (isMobile: boolean) => unknown, breakpoint = mobileSize) => {
  const { width } = useWindowSize()

  const isMobile = breakpoint > width

  useEffect(() => {
    if (!onChange) {
      return
    }

    onChange(isMobile)
  }, [isMobile, onChange])

  return isMobile
}

export default useIsMobile
