import { Snackbar, Alert, SnackbarCloseReason, Box, IconButton, Link, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { clearNotification, selectLastNotification } from '@/reducers/notifications'

export const Notification = (): JSX.Element => {
  const dispatch = useDispatch()
  const notification = useSelector(selectLastNotification)
  const [closeReason, setCloseReason] = useState<SnackbarCloseReason | undefined>(undefined)

  const handleClose = (_: unknown, reason?: SnackbarCloseReason) => {
    setCloseReason(reason)
    reason !== 'clickaway' && reason !== 'timeout' && dispatch(clearNotification())
  }

  useEffect(() => {
    setCloseReason(undefined)
  }, [notification])

  if (!notification) {
    return <></>
  }

  return (
    <Snackbar
      open={!notification.read && closeReason !== 'timeout'}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={notification.type}
        action={
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 0 }}>
            <Link
              color="inherit"
              href={notification.fileUrl}
              download
              sx={{ '&:hover': { color: 'inherit' } }}
            >
              <Button color="inherit" size="small" onClick={handleClose}>
                Download
              </Button>
            </Link>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        }
      >
        {notification.message}
      </Alert>
    </Snackbar>
  )
}
