export enum STEPS {
  UPLOAD = 'upload',
  SELECT_MATCHES = 'select-matches',
  PAYMENT = 'payment',
  PROCESS_MATCHES = 'process-matches',
  DOWNLOAD = 'download'
}

export const STEPS_TO_INDEX = {
  [STEPS.UPLOAD]: 0,
  [STEPS.SELECT_MATCHES]: 1,
  [STEPS.PAYMENT]: 2,
  [STEPS.PROCESS_MATCHES]: 3,
  [STEPS.DOWNLOAD]: 4
}
