import { CONTACT_US_URL } from '@/constants/mix'
import { Button, Grid, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

const Notfound = () => {
  const navigate = useNavigate()
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        textAlign: 'center'
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: '4rem', sm: '80px' },
          fontWeight: 800
        }}
        color="primary.main"
      >
        404 - Page Not Found
      </Typography>
      <Typography variant="h3" marginTop={'24px'}>
        Sorry, but the page you&apos;re looking for could not be found. Either the URL
        <br /> entered was typed incorrectly, or the page is broken/does not exist.
      </Typography>
      <div className="flex gap-4 mt-10">
        {' '}
        <Button variant="contained" size="large" onClick={() => navigate('/lookup/address')}>
          Single Address Lookup
        </Button>{' '}
        <Button variant="outlined" size="large" onClick={() => navigate('/lookup/address')}>
          Bulk Address Lookup
        </Button>
      </div>
      <p className="mt-10">
        Do you have a question?{' '}
        <Link to={CONTACT_US_URL} className="no-underline text-primary.main">
          {' '}
          Contact Us
        </Link>
      </p>
    </Grid>
  )
}

export default Notfound
