/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { forwardRef, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Button, ButtonProps, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'

const NavButton = styled(Button)({
  padding: '11px 16px 8px',
  color: '#515468',
  borderBottom: 'none',
  fontSize: 14,
  textDecoration: 'none',
  lineHeight: '24px',
  fontWeight: 700,
  '&:hover': {
    '+ .nav-border-bottom': {
      visibility: 'visible'
    }
  }
})

const NavBorderBottom = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive'
})((props: { isActive: boolean }) => ({
  background: '#2721AE',
  borderRadius: '4px 4px 0 0',
  height: 4,
  width: '100%',
  visibility: props.isActive ? 'visible' : 'hidden',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
}))

interface NavMenuProps {
  to?: string
  label?: string
  onClick?: React.MouseEventHandler<HTMLElement> | undefined
}

export const NavMenu: React.FC<NavMenuProps> = ({ to = '', label }) => {
  return (
    <NavLink to={to} style={{ textDecoration: 'none' }}>
      {({ isActive }) => (
        <>
          <NavButton>{label}</NavButton>
          <NavBorderBottom className="nav-border-bottom" isActive={isActive} />
        </>
      )}
    </NavLink>
  )
}

export const MobileNavMenu2: React.FC<NavMenuProps & { icon?: React.ReactNode }> = ({
  icon,
  to = '',
  label,
  onClick
}) => {
  return (
    <NavLink
      to={to}
      style={{ textDecoration: 'none' }}
      className="block px-6 py-3"
      onClick={onClick}
    >
      {({ isActive }) => (
        <>
          <Typography variant="h3" className={`text-text.primary ${isActive ? 'underline' : ''}`}>
            {icon}

            {label}
          </Typography>
          {/* <NavBorderBottom isActive={isActive} /> */}
        </>
      )}
    </NavLink>
  )
}

const useIsActiveByLocation = (to?: string) => {
  const location = useLocation()

  return useMemo(() => {
    if (location.pathname === to) {
      return true
    }
    if (to && location.pathname.startsWith(to + '/')) {
      return true
    }
    return false
  }, [location.pathname, to])
}

export const SidebarIconButton = forwardRef<HTMLAnchorElement, ButtonProps>(
  ({ children, title, ...props }, ref) => {
    const theme = useTheme()
    const isActive = useIsActiveByLocation(props?.href)

    const withTooltip = !!title && props.disabled !== true

    const component = (
      // @ts-ignore
      <IconButton
        {...props}
        ref={ref}
        className={`py-2.5 text-left items-center justify-center  rounded-lg`}
        sx={{
          backgroundColor: isActive ? theme.palette.background.tertiary : undefined,
          '& .MuiTouchRipple-child': {
            backgroundColor: isActive ? theme.palette.primary.light : undefined
          },
          '&.Mui-disabled': {
            backgroundColor: 'unset !important',
            borderColor: 'unset !important'
          },
          '&:hover': {
            backgroundColor: isActive ? theme.palette.background.tertiary : undefined
          }
        }}
      >
        {children}
      </IconButton>
    )

    if (withTooltip) {
      return (
        <Tooltip title={title} placement="right">
          {component}
        </Tooltip>
      )
    }

    return component
  }
)

SidebarIconButton.displayName = 'SidebarIconButton'

export const MobileNavMenu: React.FC<
  NavMenuProps & {
    icon?: React.ReactNode
    comingSoon?: boolean
    inAccordion?: boolean
    iconOnly?: boolean
  }
> = ({ icon, to = '', label, inAccordion = false, onClick, comingSoon, iconOnly }) => {
  const theme = useTheme()
  const isActive = useIsActiveByLocation(to)

  const fontClass = useMemo(() => {
    if (isActive) {
      return 'font-bold'
    }
    if (inAccordion) {
      return 'font-medium'
    }
    return 'font-bold'
  }, [isActive, inAccordion])

  const colorClass = useMemo(() => {
    if (comingSoon) {
      return 'text-[#858798]'
    }
    if (isActive) {
      return 'text-primary.main'
    }
    return 'text-text.primary'
  }, [comingSoon, isActive])

  if (iconOnly) {
    return (
      <SidebarIconButton href={to} onClick={onClick} disabled={comingSoon} title={label}>
        {icon}
      </SidebarIconButton>
    )
  }

  return (
    <Button
      href={to}
      onClick={onClick}
      className={`${
        inAccordion ? 'px-3' : 'px-3'
      } py-2 text-left justify-start rounded-lg ${colorClass} ${fontClass}`}
      disabled={comingSoon}
      sx={{
        backgroundColor: isActive ? theme.palette.background.tertiary : undefined,
        '& .MuiTouchRipple-child': {
          backgroundColor: isActive ? theme.palette.primary.light : undefined
        },
        '&.Mui-disabled': {
          backgroundColor: 'unset !important',
          borderColor: 'unset !important'
        },
        '&:hover': {
          backgroundColor: isActive ? theme.palette.background.tertiary : undefined
        }
      }}
      startIcon={icon}
    >
      {label}
      {comingSoon ? (
        <span className="ml-auto text-primary.main font-medium text-[10px] mt-0.5">
          Coming soon
        </span>
      ) : null}
    </Button>
  )
}
