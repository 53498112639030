import StepProgressBar from '@/components/StepProgressBar'
import AdminPanel from '@/pages/upload/AdminPanel'
import { Container } from '@mui/material'

const BulkUploadLayout = ({
  children,
  handleComplete
}: {
  children: React.ReactNode
  handleComplete: (newFilename: string, records?: any) => Promise<void>
}) => {
  return (
    <Container className="my-10">
      <StepProgressBar stepIdx={0} />
      <AdminPanel onComplete={handleComplete} />
      {children}
    </Container>
  )
}

export default BulkUploadLayout
