import { Asset } from '@/types/assets'

import { ReactComponent as LikeOnIcon } from '@/assets/icons/ic-thumb-on.svg'
import { ReactComponent as LikeOffIcon } from '@/assets/icons/ic-thumb-off.svg'

import React from 'react'
import LikeButton from './LikeButton'
import { useAssets } from '@/pages/asset/hooks'
import { Box } from '@mui/material'

interface RatingButtonsProps {
  asset: Asset
  assets: ReturnType<typeof useAssets>
}

const RatingButtons = ({ asset, assets }: RatingButtonsProps) => {
  let LeftIcon = LikeOffIcon
  let RightIcon = LikeOffIcon

  if (asset.rating === 5) {
    LeftIcon = LikeOnIcon
  } else if (asset.rating === 1) {
    RightIcon = LikeOnIcon
  }

  return (
    <Box className="flex">
      <LikeButton asset={asset} icon={LeftIcon} assets={assets} />
      <LikeButton asset={asset} direction="down" icon={RightIcon} assets={assets} />
    </Box>
  )
}

// TODO: Do we really need to memoize this component
// given that it's just generating two buttons?
export default React.memo(RatingButtons)
