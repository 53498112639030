import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Button } from '@mui/material'
import { stripePromise } from '@/utils/payments'

const StripeForm = ({ onSuccess }: { onSuccess: () => unknown }) => {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      return null
    }

    const { error } = await stripe.confirmPayment({
      elements: elements,
      redirect: 'if_required'
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      //   setErrorMessage(error.message)
      console.log(error)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      onSuccess()
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button
          variant="contained"
          size="large"
          type="submit"
          disabled={!stripe || !elements}
          sx={{ mt: 3 }}
        >
          Confirm
        </Button>
      </form>
    </div>
  )
}

const StripeCheckout = ({
  clientSecret,
  onSuccess
}: {
  clientSecret: string
  onSuccess: () => unknown
}) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret
      }}
    >
      <StripeForm onSuccess={onSuccess} />
    </Elements>
  )
}
export default StripeCheckout
