import { Typography } from '@mui/material'
import { StripeCustomer } from '@/types/user'

const AddressLine = ({ top, bottom }: { top: string; bottom: string }) => {
  return (
    <>
      <Typography fontSize={12} color="text.quaternary" fontWeight={600}>
        {top}
      </Typography>
      <Typography variant="body1" fontWeight={700}>
        {bottom}
      </Typography>
    </>
  )
}

const getAddressAsString = (userInfo: StripeCustomer) => {
  if (!userInfo?.address) {
    return 'No address specified'
  }

  return `${userInfo.address.line1}, ${userInfo.address.city} ${userInfo.address.postal_code}, ${userInfo.address.country}`
}

const AddressSummary = ({ userInfo }: { userInfo: StripeCustomer }) => {
  return (
    <div className="flex gap-4 flex-wrap">
      <div className="flex-1 min-w-[200px]">
        <AddressLine top="FULL NAME" bottom={userInfo.name} />
      </div>
      <div className="flex-1 min-w-[200px]">
        <AddressLine top="ADDRESS" bottom={getAddressAsString(userInfo)} />
      </div>
    </div>
  )
}

export default AddressSummary
