import { configureStore } from '@reduxjs/toolkit'
import assetsReducer from './assets'
import notificationReducer from './notifications'
import inquiryReducer from './inquiry'
import lookupReducer from './lookup'

export const store = configureStore({
  reducer: {
    assets: assetsReducer,
    inquiries: inquiryReducer,
    lookup: lookupReducer,
    notification: notificationReducer
  }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
