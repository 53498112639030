import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'

import ScrollTopButton from '../../components/Button/ScrollTopButton'

import { ReactComponent as LeftIcon } from '@/assets/icons/ic-left-arrow.svg'
import { useEffect, useRef } from 'react'

const CcpaPage = () => {
  const navigate = useNavigate()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  useEffect(() => {
    const handleResizeMessage = (e: MessageEvent) => {
      if (
        e.source &&
        e.origin === 'https://my.datasubject.com' &&
        iframeRef.current &&
        e.source === iframeRef.current.contentWindow
      ) {
        iframeRef.current.style.height = `${e.data}px`
        iframeRef.current.style.minWidth = '100%'
      }
    }

    window.addEventListener('message', handleResizeMessage, false)

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('message', handleResizeMessage)
    }
  }, [])
  return (
    <Container fixed sx={{ mt: 5, pb: 5 }}>
      <Button onClick={() => navigate(-1)} variant="outlined" size="small" startIcon={<LeftIcon />}>
        Back
      </Button>
      <Typography variant="h1" color="primary.main" mt={2} mb={5}>
        Opt Out
      </Typography>
      <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 6 }}>
        <iframe
          ref={iframeRef}
          frameBorder="0"
          src="https://my.datasubject.com/6obVKUGpYNBfEQsU/39593"
          width="100%"
          height="0"
          title="Iframe Resizer"
        ></iframe>
      </Box>

      <ScrollTopButton />
    </Container>
  )
}

export default CcpaPage
