import React from 'react'
import { Form } from 'react-bootstrap'
import { useController, UseControllerProps, FieldValues, FormState } from 'react-hook-form'
import './input.scss'
import InputError from './InputError'
import formatPhoneNumber from '@/utils/phoneNumber'

type FormInputProps<
  FieldValuesType extends FieldValues,
  T extends FieldValues = FieldValues
> = UseControllerProps<FieldValuesType> & {
  controlId?: string
  label?: string
  type?: string
  placeholder?: string
  helperText?: string
  variant?: 'phone'
  error?: string | React.ReactNode
  formState?: FormState<T>
  children?: React.ReactNode | React.ReactNode[]
  disabled?: boolean
}

export const FormSelect = <FieldValuesType extends FieldValues>({
  control,
  name,
  rules,
  controlId,
  label,
  placeholder,
  helperText,
  error,
  variant,
  formState,
  disabled,
  children
}: FormInputProps<FieldValuesType>) => {
  const errorMessage = error ?? (formState?.errors?.[name]?.message as string)

  const { field } = useController({ name, control, rules })

  const handleInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let value = e.target.value
    if (variant === 'phone') {
      // this is where we'll call the phoneNumberFormatter function
      value = formatPhoneNumber(e.target.value)
    }
    field.onChange(value)
  }

  return (
    <Form.Group className="mb-3" controlId={controlId}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="c-form-input">
        <Form.Select
          {...field}
          isInvalid={!!errorMessage}
          placeholder={placeholder}
          onChange={handleInput}
          disabled={disabled}
        >
          {children}
        </Form.Select>
      </div>
      {!!errorMessage && <InputError error={errorMessage} />}
      {helperText && <Form.Text className="text-muted">{helperText}</Form.Text>}
    </Form.Group>
  )
}

export default FormSelect
