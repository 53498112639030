import React, { useCallback } from 'react'
import { redirect } from 'react-router-dom'
import {
  AppBar as MuiAppBar,
  CSSObject,
  Drawer as MuiDrawer,
  IconButton,
  Toolbar as MUIToolbar,
  Theme,
  styled,
  AppBarProps as MuiAppBarProps
} from '@mui/material'
import { useSignOut } from '@/reducers/user'
import { signOut } from '@/services/auth'
import { trackEvent } from '@/analytics'
import MobileSidebar from './MobileSidebar'
import Toolbar from './Toolbar'
import SidebarContent from './SidebarContent'
import KeyboardDoubleArrowRightRounded from '@mui/icons-material/KeyboardDoubleArrowRightRounded'
import KeyboardDoubleArrowLeftRounded from '@mui/icons-material/KeyboardDoubleArrowLeftRounded'
import { useLocalStorage } from 'react-use'

const drawerWidth = 224
const NAVBAR_HEIGHT = 48

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `68px`
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'content-box',
    boxShadow: '0px 8px 24px 0px rgba(31, 31, 35, 0.10)',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  width: `100%`,
  background: '#f0f7fe',
  height: NAVBAR_HEIGHT,
  borderBottom: '1px solid #DDE3F6',
  boxShadow: 'none',
  left: 0,
  paddingLeft: '5rem',
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'padding'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    paddingLeft: '0rem',
    transition: theme.transitions.create(['width', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const DesktopSidebar = ({
  handleDrawerToggle,
  anchorElUser,
  setAnchorElUser
}: {
  handleDrawerToggle: () => unknown
  anchorElUser: null | HTMLElement
  setAnchorElUser: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}) => {
  const signOutAtom = useSignOut()
  const [open, setOpen] = useLocalStorage('sideBar-open', true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleSignOut = async () => {
    trackEvent('Log out', { page: 'Profile' })
    await signOut()
    signOutAtom()
    setAnchorElUser(null)
    redirect('/auth/sign-in')
    sessionStorage.clear()
  }

  return (
    <>
      <Drawer variant="permanent" open={open} className="mr-12">
        <MUIToolbar
          disableGutters
          sx={{
            borderBottom: '1px solid #DDE3F6',
            minHeight: '48px !important'
          }}
        >
          {open ? <img src="/assets/images/logo.svg" alt="Archer" className="ml-6" /> : null}
          <IconButton
            className={open ? `ml-auto mr-1` : 'mx-auto'}
            color="primary"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
          >
            {open ? <KeyboardDoubleArrowLeftRounded /> : <KeyboardDoubleArrowRightRounded />}
          </IconButton>
        </MUIToolbar>

        <div className="flex-1 flex flex-col justify-stretch px-3 py-6">
          <SidebarContent noToolbar handleSignOut={handleSignOut} iconOnly={!open} />
        </div>
      </Drawer>
      <AppBar position="fixed" open={open}>
        <Toolbar
          handleDrawerToggle={handleDrawerToggle}
          anchorElUser={anchorElUser}
          setAnchorElUser={setAnchorElUser}
          noLinks
        />
      </AppBar>
    </>
  )
}

export const NavSidebar = () => {
  const signOutAtom = useSignOut()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const [mobileSidebarOpen, setMobileSidebarOpen] = React.useState(false)

  const handleDrawerToggle = useCallback(() => {
    setMobileSidebarOpen((prev) => !prev)
  }, [])

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleSignOut = async () => {
    trackEvent('Log out', { page: 'Profile' })
    await signOut()
    signOutAtom()
    handleCloseUserMenu()
    redirect('/auth/sign-in')
    sessionStorage.clear()
  }

  return (
    <>
      <MobileSidebar
        handleDrawerToggle={handleDrawerToggle}
        isOpen={mobileSidebarOpen}
        handleSignOut={handleSignOut}
      />
      <DesktopSidebar
        handleDrawerToggle={handleDrawerToggle}
        anchorElUser={anchorElUser}
        setAnchorElUser={setAnchorElUser}
      />
    </>
  )
}

export default NavSidebar
