import MatchSliderBox from '../MatchSliderBox/MatchSliderBox'

const Slider = ({
  totalNum,
  matchNum,
  setMatchNum,
  handleSliderChange,
  estimatedMaxCost
}: {
  totalNum: number
  matchNum: number
  setMatchNum: React.Dispatch<React.SetStateAction<number>>
  handleSliderChange: (val: number) => unknown
  estimatedMaxCost: number
}) => {
  return (
    <MatchSliderBox
      maxValue={totalNum}
      onValueChange={handleSliderChange}
      matchNum={matchNum}
      setMatchNum={setMatchNum}
      estimatedMaxCost={estimatedMaxCost}
    />
  )
}

export default Slider
