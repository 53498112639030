import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'

import { MatchSelectConfig } from '@/types/match'

import { trackEvent } from '@/analytics'
import { Asset } from '@/types/assets'
import MatchSelectBoxList from './MatchSelectBoxList'

const MatchTypeSelection = ({
  matchSelectConfig,
  setMatchSelectConfig,
  saved,
  setSaved,
  asset
}: {
  matchSelectConfig: MatchSelectConfig
  saved: boolean
  asset: Asset
  setSaved: React.Dispatch<React.SetStateAction<boolean>>
  setMatchSelectConfig: React.Dispatch<React.SetStateAction<MatchSelectConfig>>
}) => {
  let savedDisabled = !matchSelectConfig.email.active && !matchSelectConfig.phone.active
  if (asset.requestedType === 'company') {
    savedDisabled = savedDisabled && !matchSelectConfig.workEmail.active
  }

  return (
    <>
      <Stack direction="row" gap={2} flexWrap="wrap">
        <MatchSelectBoxList
          matchSelectConfig={matchSelectConfig}
          requestedType={asset.requestedType}
          setMatchSelectConfig={setMatchSelectConfig}
        />
      </Stack>
      <FormControlLabel
        control={
          <Checkbox
            checked={!savedDisabled && saved}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              trackEvent('Save match preferences', {
                page: 'Select Matches',
                checked: evt.target.checked
              })
              setSaved(evt.target.checked)
            }}
          />
        }
        label="Save this selection as my preference"
        disabled={savedDisabled}
        sx={{ mt: 4, fontSize: 14, fontWeight: 500, mb: 6 }}
      />
    </>
  )
}

export default MatchTypeSelection
