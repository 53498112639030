import { useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { BaseTooltip } from '@/components/Chip/Tooltips'
import { ArcherModal, ArcherModalBottomActions } from '@/components/Modal/ArcherModal'
import { ReactComponent as IcCircleInfo } from '@/assets/icons/ic-circle-info.svg'
import { Button, IconButton, Typography } from '@mui/material'
import useIsMobile from '@/utils/isMobile'
import FormInput from './Input'
import { FormInputProps } from './Input'

const FormInputInfo = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton size="small" className="md:hidden h-5 w-5" onClick={() => setOpen(true)}>
        <IcCircleInfo className="fill-[#4052F0] flex-shrink-0 ml-1" />
      </IconButton>

      <ArcherModal open={open} setOpen={setOpen} cardSx={{ borderRadius: '30px' }}>
        <Typography variant="h2" pl={1}>
          Advanced Search
        </Typography>
        <ul className="list-disc mt-5">
          <li className="mb-2">
            <Typography variant="body1" color="text.quaternary">
              <b>Company Industry</b>: Add industry info as you would in a Google search
            </Typography>
          </li>
          <li>
            <Typography variant="body1" color="text.quaternary">
              <b>Company Location</b>: Enter the name of a Country, State, Region, City, or Town for
              better results. Please do not enter a direct address or zip code.
            </Typography>
          </li>
        </ul>
        <ArcherModalBottomActions>
          <div className="flex items-center justify-end gap-2">
            <Button variant="contained" size="large" onClick={() => setOpen(false)}>
              Got it!
            </Button>
          </div>
        </ArcherModalBottomActions>
      </ArcherModal>
    </>
  )
}

const FormInputHover = ({ tooltipText }: { tooltipText: string }) => {
  return (
    <BaseTooltip title={tooltipText} placement="right">
      <IcCircleInfo className="fill-[#4052F0] flex-shrink-0 ml-1" />
    </BaseTooltip>
  )
}

const FormInputForAdvancedCompanySearch = <FieldValuesType extends FieldValues>({
  control,
  name,
  rules,
  controlId,
  label,
  type = 'text',
  placeholder,
  helperText,
  error,
  variant,
  formState,
  disabled,
  autoComplete,
  className,
  tooltipText
}: FormInputProps<FieldValuesType>) => {
  const isMobile = useIsMobile()
  return (
    <FormInput
      {...{
        control,
        name,
        rules,
        controlId,
        label,
        type,
        placeholder,
        helperText,
        error,
        variant,
        formState,
        disabled,
        autoComplete,
        className
      }}
      childComponent={
        isMobile ? <FormInputInfo /> : <FormInputHover tooltipText={tooltipText || ''} />
      }
    />
  )
}
export default FormInputForAdvancedCompanySearch
