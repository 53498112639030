import { PartialDeep } from 'type-fest'
import { AccuracyMode } from './match'

export interface User {
  _id: string
  fullName: string
  email: string
  phone: string
  initialExpectations: string
  createdAt: string
  updatedAt: string
  activatedAt: string
  magicLinksAllowedFrom: string
  preferences: {
    emailAccuracy: AccuracyMode
    emailMatchesSelected: boolean
    phoneAccuracy: AccuracyMode
    phoneMatchesSelected: boolean
    workEmailMatchesSelected: boolean
    notificationsEmail: string
  }
  adminConfigs?: {
    mockTotalEnriched: number
  }
  role: {
    name: ROLES
  }
  statistics: {
    totalSubmitted: number
    totalEnriched: number
  }
}

export interface DashboardStats {
  totalPhoneNumberMatches: number
  totalEmailAddressesMatches: number
}

// Reduced typing of stripe's customer object
export interface StripeCustomer {
  name: string
  address?: {
    city: string
    country: string
    line1: string
    postal_code: string
  }

  invoice_settings: {
    default_payment_method: string | null
  }
}

export interface BillingTracker {
  userId: string
  payment_required_threshold: number
  createdAt: string
  updatedAt: string
  hasPaymentMethod: boolean
  next_billing_cycle: string
  profile_completion_limited_time_offer_expired_at: string | null
  userBalance: number
  accrued_credits: number
  free_credits: number
}

// To be used only for submitting valid fields to API endpoints
export type AllowedUserUpdateFields = PartialDeep<
  Pick<User, 'fullName' | 'preferences' | 'adminConfigs'>
> & {
  role?: ROLES
}

export enum ROLES {
  USER = 'USER',
  ADMIN = 'ADMIN'
}
