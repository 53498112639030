import { Typography, Box, useTheme, LinearProgress } from '@mui/material'
import { formatMoney } from '@/utils'
import { useBillingTracker } from '@/pages/dashboard/hooks'

export const BalanceProgress = ({ balance, threshold }: { balance: number; threshold: number }) => {
  const percentageThreshold = balance / threshold || 0
  const exceeded = percentageThreshold >= 1

  return (
    <div className="flex flex-col">
      <div className="flex items-baseline">
        <Typography variant="body1" fontWeight={900} fontSize={24}>
          {formatMoney(balance, {
            notation: 'compact'
          })}
        </Typography>
        <Typography variant="body1" fontWeight={500} fontSize={20} sx={{ mx: 1 }}>
          /
        </Typography>
        <Typography variant="body1" fontWeight={500} fontSize={20}>
          {formatMoney(threshold, {
            notation: 'compact'
          })}
        </Typography>
      </div>
      <div>
        <LinearProgress
          variant="determinate"
          value={Math.min(1, percentageThreshold) * 100}
          sx={{
            borderRadius: 2,
            my: 1,
            '& .MuiLinearProgress-bar': {
              transition: 'transform .1s linear'
            }
          }}
          color={exceeded ? 'warning' : 'primary'}
        />
      </div>
    </div>
  )
}

const CurrentBalanceCard = ({ dynamicBalance }: { dynamicBalance?: number }) => {
  const theme = useTheme()
  const { data: billingTracker } = useBillingTracker()

  const userBalance = billingTracker?.userBalance || 0

  return (
    <Box
      sx={{
        borderRadius: 4,
        border: `1px solid ${theme.palette.background.tertiary}`,
        px: 3,
        py: 2,
        mb: 3
      }}
    >
      <div className="flex gap-4 items-center">
        <div className="flex flex-col gap-4">
          <Typography variant="body1" fontWeight={700} mb={2}>
            Balance
          </Typography>
          <BalanceProgress
            balance={dynamicBalance ? dynamicBalance + userBalance : userBalance}
            threshold={billingTracker?.payment_required_threshold || 0}
          />
          <Typography variant="body1" fontWeight={700} mb={2}>
            current: {formatMoney(userBalance)}
          </Typography>
        </div>
        <Typography variant="caption">
          If your balance exceeds the &nbsp;
          <b>
            {formatMoney(billingTracker?.payment_required_threshold, {
              notation: 'compact'
            })}
          </b>
          &nbsp; threshold during the enrichment, we will place a pre-authorization and require a
          payment on your selected credit card.
        </Typography>
      </div>
    </Box>
  )
}

export default CurrentBalanceCard
