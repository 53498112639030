import { Alert, Box, Container, Grid, LinearProgress, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { useRef } from 'react'
import CheckoutWithPaymentIdGuard from './CheckoutWithPaymentIdGuard'
import { useAuth } from '@/reducers/user'

const AuthGuard = ({ children }: { children: React.ReactElement }) => {
  const { isLoading, user } = useAuth()

  if (isLoading) {
    return <LinearProgress />
  }

  if (!user) {
    return (
      <Alert variant="filled" severity="error">
        Permission denied
      </Alert>
    )
  }

  return children
}

const CheckoutPage = () => {
  const [searchParams] = useSearchParams()
  const infoBoxRef = useRef<HTMLDivElement>(null)
  const internalPaymentIntentId = searchParams.get('payment')

  return (
    <Container sx={{ py: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box>
            <Typography variant="h1" mb={2}>
              Checkout
            </Typography>
            <AuthGuard>
              {internalPaymentIntentId ? (
                <CheckoutWithPaymentIdGuard
                  internalPaymentIntentId={internalPaymentIntentId}
                  infoBoxRef={infoBoxRef}
                />
              ) : (
                <Alert variant="outlined" severity="error">
                  No payment id provided (missing <code>payment</code> parameter)
                </Alert>
              )}
            </AuthGuard>
          </Box>
        </Grid>
        <Grid item xs={4} ref={infoBoxRef}></Grid>
      </Grid>
    </Container>
  )
}

export default CheckoutPage
