import React, { useCallback } from 'react'
import { redirect } from 'react-router-dom'
import { Container, AppBar } from '@mui/material'
import { useSignOut } from '@/reducers/user'
import { signOut } from '@/services/auth'
import { trackEvent } from '@/analytics'
import MobileSidebar from './MobileSidebar'
import Toolbar from './Toolbar'

export const Navbar = () => {
  const signOutAtom = useSignOut()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const [mobileSidebarOpen, setMobileSidebarOpen] = React.useState(false)

  const handleDrawerToggle = useCallback(() => {
    setMobileSidebarOpen((prev) => !prev)
  }, [])

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleSignOut = async () => {
    trackEvent('Log out', { page: 'Profile' })
    await signOut()
    signOutAtom()
    handleCloseUserMenu()
    redirect('/auth/sign-in')
    sessionStorage.clear()
  }

  return (
    <>
      <MobileSidebar
        handleDrawerToggle={handleDrawerToggle}
        isOpen={mobileSidebarOpen}
        handleSignOut={handleSignOut}
      />
      <AppBar
        position="static"
        sx={{
          background: '#f0f7fe',
          height: 48,
          borderBottom: '1px solid #DDE3F6',
          boxShadow: 'none'
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            handleDrawerToggle={handleDrawerToggle}
            anchorElUser={anchorElUser}
            setAnchorElUser={setAnchorElUser}
          />
        </Container>
      </AppBar>
    </>
  )
}

export default Navbar
