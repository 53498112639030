import { Chip as MuiChip, styled, chipClasses, AlertColor } from '@mui/material'

export interface ArcherChipProps {
  color: AlertColor
  overrideStyle?: {
    [K in AlertColor]?: {
      backgroundColor: string
      color: string
      iconColor: string
    }
  }
}

const ArcherChip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'overrideStyle'
})<ArcherChipProps>(({ theme, color, overrideStyle }) => ({
  fontWeight: 600,

  [`&.${chipClasses.outlined}`]: {
    color: theme.palette.text.primary
  },

  [`&.${chipClasses.colorSuccess}`]: {
    backgroundColor: '#C3EFD3',
    borderColor: '#11C14D'
  },

  [`&.${chipClasses.colorError}`]: {
    backgroundColor: overrideStyle?.[color]?.backgroundColor ?? '#FFD5D5',
    color: overrideStyle?.error?.color ?? '#FF5555',
    borderColor: '#FF5555'
  },

  [`&.${chipClasses.colorInfo}`]: {
    backgroundColor: '#DEDDF8',
    borderColor: '#5A56DC'
  },

  [`&.${chipClasses.colorWarning}`]: {
    backgroundColor: '#FFEEC3',
    borderColor: '#FFB800'
  }
}))

export default ArcherChip
