import { trackEvent } from '@/analytics'
import { ReactComponent as BriefCaseIcon } from '@/assets/icons/ic-briefcase.svg'
import { ReactComponent as IcCircleInfo } from '@/assets/icons/ic-circle-info.svg'
import { ReactComponent as CopyIcon } from '@/assets/icons/ic-copy.svg'
import { ReactComponent as EmailIcon } from '@/assets/icons/ic-email-circle.svg'
import { ReactComponent as PhoneIcon } from '@/assets/icons/ic-phone-circle.svg'
import { ReactComponent as UserIcon } from '@/assets/icons/ic-user.svg'
import formatPhoneNumber from '@/utils/phoneNumber'

import { Asset } from '@/types/assets'
import { formatNumber } from '@/utils'
import { getNameOfAsset } from '@/utils/assets'
import { Alert, Box, Button, Chip, IconButton, Snackbar, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useBillingTracker } from '../hooks'
import CompanyAssetResult from './CompanyAssetResults'

const ResultEntry = ({
  type,
  accuracy,
  result
}: {
  type: 'personal' | 'work'
  accuracy?: string
  result: string
}) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(result)
  }

  const noResult = !result

  return (
    <div className="rounded-2xl border border-background.tertiary py-4 px-6 flex gap-y-4 flex-wrap justify-between flex-shrink-0">
      <div className="flex items-center gap-2">
        {type === 'personal' ? (
          <UserIcon className="h-4 w-4 fill-text.primary" />
        ) : (
          <BriefCaseIcon className="h-4 w-4 fill-text.primary" />
        )}
        <Typography fontWeight={700} textTransform="capitalize">
          {type}
        </Typography>
      </div>

      {noResult ? (
        <Typography fontWeight={700} sx={{ my: 1.5 }}>
          No result found
        </Typography>
      ) : null}

      {!noResult ? (
        <div className="flex items-center gap-2 ml-auto">
          <div className="flex flex-col items-end gap-2">
            <Typography fontWeight={700}>{result}</Typography>
            <Chip
              label={`${accuracy} Accuracy`}
              size="small"
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                backgroundColor: 'background.tertiary',
                textTransform: 'capitalize',
                height: '1.25rem'
              }}
            />
          </div>
          <div className="flex flex-col items-center">
            <IconButton size="small" onClick={handleClick} title="Copy to clipboard">
              <CopyIcon className="h-4 w-4 fill-primary.main" />
            </IconButton>

            <IconButton
              className="invisible"
              size="small"
              onClick={handleClick}
              title="Copy to clipboard"
            >
              <CopyIcon className="h-4 w-4 fill-primary.main" />
            </IconButton>
          </div>
        </div>
      ) : null}
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </div>
  )
}

const ResultsEntry = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col gap-4 w-[min(30rem,100%)] flex-shrink-0">{children}</div>
}

const PhoneResults = ({
  enrichedPhone,
  enrichedPhoneAccuracy
}: {
  enrichedPhone: string
  enrichedPhoneAccuracy: string
}) => {
  if (!enrichedPhone) {
    return null
  }

  return (
    <ResultsEntry>
      <div className="flex items-center gap-2">
        <PhoneIcon className="bg-background.tertiary fill-primary.main h-9 w-9 rounded-full" />
        <Typography fontWeight={700}>Phone numbers</Typography>
      </div>

      {enrichedPhone ? (
        <ResultEntry
          type="personal"
          accuracy={enrichedPhoneAccuracy}
          result={formatPhoneNumber(enrichedPhone)}
        />
      ) : null}

      {/* <ResultEntry
        type="work"
        accuracy={enrichedPhoneAccuracy}
        result={formatPhoneNumber(enrichedPhone)}
      /> */}
    </ResultsEntry>
  )
}

const EmailResults = ({
  enrichedEmail,
  enrichedEmailAccuracy,
  enrichedWorkEmail,
  enrichedWorkEmailAccuracy,
  enrichedWorkEmail2,
  enrichedWorkEmail2Accuracy
}: {
  enrichedEmail: string
  enrichedEmailAccuracy: string
  enrichedWorkEmail: string
  enrichedWorkEmailAccuracy: string
  enrichedWorkEmail2?: string
  enrichedWorkEmail2Accuracy?: string
}) => {
  if (!enrichedEmail && !enrichedWorkEmail && !enrichedWorkEmail2) {
    return null
  }

  return (
    <ResultsEntry>
      <div className="flex items-center gap-4">
        <EmailIcon className="bg-background.tertiary h-9 w-9 rounded-full" />
        <Typography fontWeight={700}>Email addresses</Typography>
      </div>

      {enrichedEmail ? (
        <ResultEntry type="personal" accuracy={enrichedEmailAccuracy} result={enrichedEmail} />
      ) : null}

      {enrichedWorkEmail ? (
        <ResultEntry type="work" accuracy={enrichedWorkEmailAccuracy} result={enrichedWorkEmail} />
      ) : null}

      {enrichedWorkEmail2 ? (
        <ResultEntry
          type="work"
          accuracy={enrichedWorkEmail2Accuracy}
          result={enrichedWorkEmail2}
        />
      ) : null}
    </ResultsEntry>
  )
}

const AssetResult = ({
  onRestart,
  asset,
  setActiveAssetId
}: {
  onRestart: (newAssetId?: string) => unknown | undefined
  asset: Asset
  setActiveAssetId: (assetId: string) => unknown
}) => {
  const { data: billingTracker } = useBillingTracker()
  const credits = billingTracker?.free_credits || undefined

  if (!asset.singleEnrichment?.isSingleEnrichment) {
    return null
  }

  const name = getNameOfAsset(asset)

  const anyResult =
    !!asset.singleEnrichment.enrichedPhone ||
    !!asset.singleEnrichment.enrichedEmail ||
    !!asset.singleEnrichment.enrichedWorkEmail

  if (asset.requestedType === 'company') {
    return (
      <CompanyAssetResult asset={asset} onRestart={onRestart} setActiveAssetId={setActiveAssetId} />
    )
  }

  return (
    <div className="mt-8 flex flex-col gap-8">
      <div className="flex items-center gap-2">
        <Typography variant="body1" fontWeight={500}>
          Contact info for&nbsp;
          <b>{name}</b>
        </Typography>
        <Tooltip
          placement="right"
          arrow
          title={`We assign match likelihood scores to contact information to display our confidence in the results. Even a "low" or "medium" rating has a good chance of belonging to the individual.`}
        >
          <IcCircleInfo />
        </Tooltip>
      </div>
      {anyResult ? (
        <div className="md:px-6 flex gap-10 flex-wrap">
          <PhoneResults
            enrichedPhone={asset.singleEnrichment.enrichedPhone}
            enrichedPhoneAccuracy={asset.singleEnrichment.enrichedPhoneAccuracy}
          />
          <EmailResults
            enrichedEmail={asset.singleEnrichment.enrichedEmail}
            enrichedEmailAccuracy={asset.singleEnrichment.enrichedEmailAccuracy}
            enrichedWorkEmail={asset.singleEnrichment.enrichedWorkEmail}
            enrichedWorkEmailAccuracy={asset.singleEnrichment.enrichedWorkEmailAccuracy}
            enrichedWorkEmail2={asset.singleEnrichment.enrichedWorkEmail2}
            enrichedWorkEmail2Accuracy={asset.singleEnrichment.enrichedWorkEmail2Accuracy}
          />
        </div>
      ) : (
        <div className="flex">
          <Alert severity="warning">
            <Typography fontWeight={700}>
              We couldn&apos;t find any contact information for this person.
            </Typography>
          </Alert>
        </div>
      )}
      <div>
        <div className="flex items-center gap-2">
          <IcCircleInfo className="flex-shrink-0" />
          <Typography variant="body1" fontWeight={500}>
            You can later find all your enrichments on the{' '}
            <Link to="/history">
              <Box component="span" className="text-primary.main" fontWeight={700}>
                History
              </Box>
            </Link>{' '}
            page.
          </Typography>
        </div>
      </div>
      <div className="flex justify-between">
        <div />
        <div className="flex flex-col items-center">
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              trackEvent('New Lookup', { page: 'Dashboard' })
              if (onRestart) {
                return onRestart()
              }
            }}
          >
            New Lookup
          </Button>
          {credits ? (
            <Typography mt={1}>You have {formatNumber(credits)} free matches left</Typography>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default AssetResult
