import { Button, SxProps, Typography } from '@mui/material'
import { ArcherModal } from '@/components/Modal/ArcherModal'
import { TopOuterContainer } from './UsageBasedPricing'
import { ReactComponent as CircleCheckIcon } from '@/assets/icons/ic-circle-check.svg'
import { formatMoney } from '@/utils'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { Link } from 'react-router-dom'
import { useCards } from '../settings/billing/useCards'
import { useUserPaymentInfo } from '../settings/billing/usePaymentMethods'
import StripeAdd from '../settings/billing/stripeAdd'
import { useCallback, useEffect, useState } from 'react'
import {
  useLimitedTimeOfferCompleted,
  useLimitedTimeOfferCountdown,
  useLimitedTimeOfferExpiry
} from './hooks'
import { DateTime } from 'luxon'
import { useIsCurrentUserActivated } from '@/reducers/user'
import LTOSuccessModal from '@/components/Modal/LTOSuccessModal'
import { atom, useAtom } from 'jotai'
import VerifyEmailBanner from '@/components/Auth/VerifyEmailBanner'
import { PAGE } from '@/analytics'
import CreditCardAddedModal from '@/components/Modal/CreditCardAddedModal'

const LinkToPlans = () => {
  return (
    <div className="relative hidden md:block">
      <TopOuterContainer className="bg-white border border-background.tertiary">
        <div className="text-text.primary  text-sm">Monthly billing</div>
      </TopOuterContainer>
      <div className="flex flex-col rounded-xl overflow-hidden bg-background.default border border-background.tertiary flex-shrink w-[264px]">
        <div className="px-4 py-6 flex flex-col gap-10">
          <div className="flex flex-col gap-2">
            <Typography variant="body1" textAlign="center" color="primary.main" fontWeight={700}>
              Usage-Based Pricing
            </Typography>
            <Typography variant="body2" textAlign="center" fontWeight={700}>
              1 credit = {formatMoney(0.75)}*
            </Typography>
          </div>
          <div className="flex gap-4 flex-col mx-auto md:items-stretch">
            <div className="flex gap-3">
              <CircleCheckIcon className="mt-1.5 h-3 w-3 flex-shrink-0 fill-secondary.main" />
              <Typography variant="body2" color="text.quaternary" fontWeight={500}>
                You <b>only pay for successful lookups</b> (1 credit)
              </Typography>
            </div>
            <div className="flex gap-3">
              <CircleCheckIcon className="mt-1.5 h-3 w-3 flex-shrink-0 fill-secondary.main" />
              <Typography variant="body2" color="text.quaternary" fontWeight={500}>
                Use Archer as much <b>OR</b> as little as you like
              </Typography>
            </div>
            <div className="flex gap-3">
              <CircleCheckIcon className="mt-1.5 h-3 w-3 flex-shrink-0 fill-secondary.main" />
              <Typography variant="body2" color="text.quaternary" fontWeight={500}>
                <b>No Minimum</b> Usage Required
              </Typography>
            </div>
          </div>
          <div className="flex justify-center">
            <Link to="/plans" className="no-underline text-text.quaternary text-xs font-bold">
              *See details
              <ChevronRightRoundedIcon className="h-4 w-4 ml-1 mb-[1px] fill-text.quaternary" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const SaveButton = ({ disabled }: { disabled?: boolean }) => {
  const expiringAt = useLimitedTimeOfferExpiry()
  const countdown = useLimitedTimeOfferCountdown(expiringAt as DateTime)
  const [{ type = 'limited-time-offer' }] = useAtom(additionalPropsAtom)

  return (
    <Button disabled={disabled} variant="contained" size="large" type="submit" className="mx-auto">
      {type === 'limited-time-offer'
        ? `Save Payment Info & Get 25 Free Credits (${countdown})`
        : 'Save Payment Info'}
    </Button>
  )
}

const AddCard = ({ onSuccess, page }: { page: PAGE; onSuccess: () => unknown }) => {
  const { payload: userInfo, refetch: refreshUser } = useUserPaymentInfo()
  const isUserVerified = useIsCurrentUserActivated()

  const { isLoading, onAdd, onAddCancel, onAddSuccess, showAdd, clientSecret } = useCards({
    userInfo,
    refreshUser,
    page
  })

  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (isUserVerified) {
      onAdd()
    }
  }, [isUserVerified])

  const renderControls = useCallback(
    ({ disabled }: { disabled?: boolean }) => <SaveButton disabled={disabled || isLoading} />,
    [isLoading]
  )

  const onSuccessInternal = useCallback(async () => {
    await onAddSuccess()
    setSuccess(true)
    onSuccess()
  }, [onAddSuccess, onSuccess])

  return (
    <div className="flex-1 flex flex-col">
      {!isUserVerified ? (
        <div className="flex flex-col gap-4 items-stretch">
          <VerifyEmailBanner title="Verify your account before adding a credit card." page={page} />
        </div>
      ) : null}
      {!success && showAdd && clientSecret ? (
        <StripeAdd
          className="flex flex-col flex-1"
          onReset={onAddCancel}
          clientSecret={clientSecret}
          disabled={isLoading}
          onSuccess={onSuccessInternal}
          renderControls={renderControls}
        />
      ) : null}
    </div>
  )
}

const isOpen = atom(false)
const additionalPropsAtom = atom(
  {} as {
    largeWithLink: boolean
    page: PAGE
    type?: 'limited-time-offer' | 'add-payment-info'
  }
)

export const useLTOModal = () => {
  const [open, setOpen] = useAtom(isOpen)
  const [additionalProps, setAdditionalProps] = useAtom(additionalPropsAtom)

  return [open, setOpen, additionalProps, setAdditionalProps] as const
}

const LimitedTimeOfferModal = () => {
  const [open, setOpen, { largeWithLink, page, type = 'limited-time-offer' }] = useLTOModal()

  const modalCardSxProps: SxProps | undefined = largeWithLink
    ? {
        width: 'min(736px, 100%)'
      }
    : undefined

  const [, setCompleted] = useLimitedTimeOfferCompleted()
  const [successModalOpen, setSuccessModalOpen] = useState(false)

  return (
    <>
      <ArcherModal open={open} setOpen={setOpen} cardSx={modalCardSxProps}>
        <div className="flex flex-col gap-2">
          <Typography variant="h2" className="pr-16">
            {type === 'limited-time-offer'
              ? 'Enter your payment info within the next 48 hours and'
              : 'Complete Your Account Now For'}{' '}
            <span className="text-primary.main">
              {type === 'limited-time-offer' ? 'unlock 25 free credits!' : 'Uninterrupted Access!'}
            </span>
          </Typography>

          {!largeWithLink && (
            <Typography variant="body1" color="text.quaternary">
              No charges will be made until you&apos;ve used your free lookups.
            </Typography>
          )}
          {!!largeWithLink && (
            <>
              <Typography variant="body1" color="text.quaternary" className="hidden md:block">
                No charges will be made until you&apos;ve used your free credits.{' '}
                <strong>We will notify you in advance before charging for any lookups</strong>,
                ensuring there are no unexpected surprises. Until then, you can freely enjoy the
                benefits of Archer without incurring any costs.
              </Typography>
              <Typography variant="body1" color="text.quaternary" className="block md:hidden">
                No charges will be made until you&apos;ve used your free credits.
              </Typography>
            </>
          )}
        </div>
        <div className="flex gap-6 mt-8">
          {largeWithLink ? <LinkToPlans /> : null}
          <AddCard
            page={page}
            onSuccess={useCallback(() => {
              setOpen(false)
              setCompleted(true)

              setSuccessModalOpen(true)
            }, [setCompleted, setOpen])}
          />
        </div>
      </ArcherModal>
      {type === 'add-payment-info' && (
        <CreditCardAddedModal open={successModalOpen} setOpen={setSuccessModalOpen} />
      )}
      {type === 'limited-time-offer' && (
        <LTOSuccessModal open={successModalOpen} setOpen={setSuccessModalOpen} />
      )}
    </>
  )
}

export default LimitedTimeOfferModal
