import {
  AcceptAllChip,
  DeliverableChip,
  HighAccuracyMatchChip,
  LowAccuracyMatchChip,
  MediumAccuracyMatchChip
} from '@/components/Chip'
import { AccuracyChipsMap, AccuracyStatus } from '@/types/accuracy-status.type'
import { LookupStatus, LookupStatusMap } from '@/types/lookup-status.type'

const accuracyChipsMap: AccuracyChipsMap = {
  low: LowAccuracyMatchChip,
  medium: MediumAccuracyMatchChip,
  high: HighAccuracyMatchChip
} as const

const lookupStatusChipsMap: LookupStatusMap = {
  deliverable: DeliverableChip,
  'accept-all': AcceptAllChip
} as const

interface Props {
  accuracy?: AccuracyStatus
  status?: LookupStatus
}

const AccuracyMatchChip = ({ accuracy }: { accuracy: AccuracyStatus }) => {
  const AccuracyMatchChip = accuracyChipsMap[accuracy]

  return <AccuracyMatchChip />
}

const StatusChip = ({ status }: { status: LookupStatus }) => {
  const StatusChip = lookupStatusChipsMap[status]

  return <StatusChip />
}

const LookupResultChip = ({ accuracy, status }: Props) => {
  if (!accuracy && !status) return null

  if (accuracy && !accuracyChipsMap[accuracy]) {
    console.error('Invalid accuracy', accuracy)
    return null
  }

  if (status && !lookupStatusChipsMap[status]) {
    console.error('Invalid status', status)
    return null
  }

  return (
    <>
      {accuracy && <AccuracyMatchChip accuracy={accuracy} />}
      {status && <StatusChip status={status} />}
    </>
  )
}

export default LookupResultChip
