import StatusAlert from '@/components/Alert/StatusAlert'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { Box, Button, Typography } from '@mui/material'

const BillingHeader = () => {
  return (
    <>
      <Box className="max-w-[500px] absolute m-auto right-0 left-0 top-2 z-10">
        <StatusAlert
          onStatus={{
            success: {
              title: 'Payment Successful!',
              message: 'Your payment has been successfully processed.'
            }
          }}
        />
      </Box>
      <Box className="flex justify-between" mb={5}>
        <Typography variant="h1" color="primary.main">
          Billing
        </Typography>
        <Button
          variant="outlined"
          size="large"
          startIcon={<SettingsOutlinedIcon />}
          href="/settings/billing"
        >
          Payment settings
        </Button>
      </Box>
    </>
  )
}

export default BillingHeader
