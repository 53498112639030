import { BillingSheet } from '@/types/billing'
import Typography from '@mui/material/Typography'

const Header = ({ billingSheet }: { billingSheet: BillingSheet }) => {
  return (
    <div className="flex items-center justify-between">
      <img src="/assets/images/logo.svg" alt="logo" className="h-16" />
      <div className="flex flex-col items-end mt-4">
        <Typography variant="h3" fontWeight={800}>
          Invoice
        </Typography>
        <Typography variant="caption" fontWeight={400}>
          Id: {billingSheet._id}
        </Typography>
      </div>
    </div>
  )
}

export default Header
