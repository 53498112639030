import React from 'react'
import { TextField, StandardTextFieldProps, IconButton, SvgIcon } from '@mui/material'

import { ReactComponent as CrossIcon } from '@/assets/icons/ic-cross.svg'
import { Control, Controller, FieldValues } from 'react-hook-form'

interface ComponentProps extends StandardTextFieldProps {
  onClear?: () => void
  name: string
  control?: Control<FieldValues> | undefined
}

export const NumberInput: React.FC<ComponentProps> = ({
  onClear,
  value,
  control,
  ...otherProps
}) => {
  return (
    <Controller
      name={otherProps.name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          {...otherProps}
          value={value}
          type="number"
          InputProps={{
            endAdornment: value ? (
              <IconButton aria-label="Clear" onClick={() => onClear && onClear()} size="small">
                <SvgIcon component={CrossIcon} inheritViewBox sx={{ fontSize: 16 }} />
              </IconButton>
            ) : (
              <></>
            )
          }}
        />
      )}
    />
  )
}

export default NumberInput
