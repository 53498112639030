import { BillingSheet } from '@/types/billing'
import Typography from '@mui/material/Typography'

const Recipient = ({ billingSheet }: { billingSheet: BillingSheet }) => {
  return (
    <div className="flex flex-col gap-2">
      <Typography fontWeight={700} variant="body1">
        John Doe
      </Typography>
      <Typography fontWeight={700} variant="body1">
        {billingSheet.address?.line1}
      </Typography>
      <Typography fontWeight={700} variant="body1">
        {billingSheet.address?.city}, {billingSheet.address?.postal_code}
      </Typography>
      <Typography fontWeight={700} variant="body1">
        {billingSheet.address?.country}
      </Typography>
    </div>
  )
}
export default Recipient
