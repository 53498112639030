import React from 'react'
import { AlertColor, ChipProps, SvgIcon, TooltipProps } from '@mui/material'

import { ReactComponent as UserCheckIcon } from '@/assets/icons/ic-user-checkmark.svg'
import { ArcherChip } from '@/components/Chip'
import { PartialBy } from '@/utils/types'

interface Props extends Omit<ChipProps, 'icon'> {
  color: AlertColor
  chipIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  label: string
  wrappWithTooltip?: React.ComponentType<PartialBy<TooltipProps, 'title'>>
}

const AccuracyMatchChip = ({
  label,
  color,
  chipIcon: ChipIcon = UserCheckIcon,
  wrappWithTooltip: TooltipWrapper = React.Fragment,
  ...restProps
}: Props) => {
  return (
    <TooltipWrapper>
      <ArcherChip
        label={label}
        color={color}
        variant="outlined"
        size="small"
        icon={
          <SvgIcon viewBox="0 0 14 10" className="ml-2">
            <ChipIcon />
          </SvgIcon>
        }
        {...restProps}
      />
    </TooltipWrapper>
  )
}

export default AccuracyMatchChip
