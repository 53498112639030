import { useIsCurrentUserActivated } from '@/reducers/user'
import { Tooltip } from '@mui/material'
import { useMemo } from 'react'

const EmailActivationTooltip = ({
  title,
  children
}: {
  title?: string
  children: React.ReactElement
}) => {
  const isUserActivated = useIsCurrentUserActivated()

  const displayedTitle = useMemo(() => {
    if (isUserActivated) {
      return undefined
    }

    if (title) {
      return title
    }

    return 'Verify your email to unlock the Bulk Lookup feature'
  }, [isUserActivated, title])

  return (
    <Tooltip title={displayedTitle} arrow placement="top">
      <span>{children}</span>
    </Tooltip>
  )
}

export default EmailActivationTooltip
