import { Typography } from '@mui/material'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HowItWorks = (_: { matchType: 'personal' | 'company' }) => {
  return (
    <div className="md:hidden">
      {/* <IconButton size="small" onClick={() => setIsOpen(true)}>
        <InfoIcon className="fill-primary.main" />
      </IconButton> */}
      <Typography fontSize={12} fontWeight={700} color="text.quaternary" textAlign="right">
        Looking for Bulk Lookup?
      </Typography>
      <Typography fontSize={12} fontWeight={700} color="text.quaternary" textAlign="right">
        Switch to desktop!
      </Typography>
      {/* <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Card
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'min(500px, 100%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}
        >
          <div className="absolute top-3 right-3">
            <IconButton size="large" onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography variant="h2">How does it work?</Typography>
          <HowItWorksContent matchType={matchType} />
        </Card>
      </Modal> */}
    </div>
  )
}

export default HowItWorks
