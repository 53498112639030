import { Button, Typography } from '@mui/material'
import { useLimitedTimeOfferCountdown } from './hooks'
import { DateTime } from 'luxon'
import './CTA.scss'

export const CompleteAccountCTA = ({ onAction }: { onAction: () => unknown }) => {
  return (
    <div className="bg-background.tertiary px-6 pb-10 pt-6 flex flex-col items-center gap-4">
      <Typography
        variant="body1"
        fontWeight={800}
        color="primary.main"
        textAlign="center"
        className="uppercase"
      >
        For Uninterrupted access
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className="gradient-background"
        onClick={onAction}
      >
        Complete Your Account
      </Button>
    </div>
  )
}

export const LimitedTimeOfferCTA = ({
  onAction,
  expiringAt
}: {
  onAction: () => unknown
  expiringAt: DateTime
}) => {
  const countdown = useLimitedTimeOfferCountdown(expiringAt)

  return (
    <div className="bg-background.tertiary px-6 pb-10 pt-6 flex flex-col items-center gap-4">
      <Typography
        variant="body1"
        fontWeight={800}
        color="primary.main"
        textAlign="center"
        className="uppercase"
      >
        Unlock 25 free credits
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className="gradient-background"
        onClick={onAction}
      >
        Complete Your Account ({countdown})
      </Button>
    </div>
  )
}
