import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Typography, Box, Button, TextField, InputLabel, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import * as Yup from 'yup'

import DragDropFile from '@/components/Upload/DragDropFile'
import { useDispatch } from 'react-redux'
import { newInquiry } from '@/reducers/inquiry'

const urlSchema = Yup.string().url().required()

const LetUsHandleUploadPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [url, setUrl] = useState<string>('')
  const [file, setFile] = useState<any | null>(null)

  useEffect(() => {
    dispatch(newInquiry())
  }, [])

  return (
    <Container sx={{ py: 5 }}>
      <Typography variant="h1" color="primary.main" mb={5}>
        Let us take care of it
      </Typography>

      <DragDropFile
        onUploaded={(file: any) => {
          setFile(file)
        }}
      />

      <Divider sx={{ color: theme.palette.text.secondary, my: 2 }}>
        <Typography variant="caption" fontWeight={700}>
          OR
        </Typography>
      </Divider>

      <Box sx={{ width: '100%' }}>
        <InputLabel
          sx={{
            color: theme.palette.text.primary,
            fontSize: 16,
            lineHeight: 1.5,
            textTransform: 'none',
            mb: 1
          }}
        >
          Do you have a link?
        </InputLabel>
        <TextField
          placeholder="Paste your URL here"
          fullWidth
          value={url}
          onInput={(event: React.ChangeEvent<HTMLInputElement>) => setUrl(event.target.value)}
        />
      </Box>

      <Box mt={6}>
        <Typography variant="h3">
          {`Can't upload your list or need to discuss with an expert?`}
        </Typography>

        <Button
          onClick={() => navigate('/let-us-handle/contact')}
          variant="outlined"
          size="large"
          sx={{ mt: 2 }}
        >{`Let's find a solution`}</Button>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 6 }}>
        <Button variant="outlined" size="large" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          disabled={!file && !urlSchema.isValidSync(url)}
          onClick={() =>
            navigate('/let-us-handle/contact', {
              state: {
                file: file,
                link: url
              }
            })
          }
        >
          Next
        </Button>
      </Box>
    </Container>
  )
}

export default LetUsHandleUploadPage
