import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './'
import { Inquiry } from '@/types/inquiry'

export interface InquiryState {
  letUsHandleItFlow: Inquiry | null
}

const initialState: InquiryState = {
  letUsHandleItFlow: null
}

export const InquiriesSlice = createSlice({
  name: 'inquiries',
  initialState,
  reducers: {
    setInquiryFiles: (state, action: PayloadAction<File[]>) => {
      if (state.letUsHandleItFlow) {
        state.letUsHandleItFlow.files = action.payload
      }
    },
    setInquiryNote: (state, action: PayloadAction<string>) => {
      if (state.letUsHandleItFlow) {
        state.letUsHandleItFlow.note = action.payload
      }
    },
    setInquiryPhone: (state, action: PayloadAction<string>) => {
      if (state.letUsHandleItFlow) {
        state.letUsHandleItFlow.phone = action.payload
      }
    },
    setInquiryEmail: (state, action: PayloadAction<string>) => {
      if (state.letUsHandleItFlow) {
        state.letUsHandleItFlow.email = action.payload
      }
    },
    newInquiry: (state) => {
      state.letUsHandleItFlow = {
        files: [],
        link: '',
        meeting: null,
        note: '',
        phone: '',
        email: ''
      }
    }
  }
})

export const { setInquiryFiles, setInquiryPhone, setInquiryEmail, setInquiryNote, newInquiry } =
  InquiriesSlice.actions

export const selectLetUsHandleItFlowInquiry = (state: RootState) =>
  state.inquiries.letUsHandleItFlow

export default InquiriesSlice.reducer
