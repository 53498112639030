import { Button, Typography } from '@mui/material'
import { ArcherModal, ArcherModalBottomActions } from './ArcherModal'

const LTOSuccessModal = ({
  open,
  setOpen
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <>
      <ArcherModal open={open} setOpen={setOpen}>
        <Typography variant="h2" marginRight={2}>
          Congratulations! You&apos;ve just earned 25 free credits!
        </Typography>
        <Typography variant="body1" mt={3} mb={4}>
          You have 7 days to use them for Single Searches or Bulk Lookups.
        </Typography>
        <ArcherModalBottomActions>
          <div className="flex items-center justify-between gap-2">
            <Button variant="text" size="large" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" size="large" onClick={() => setOpen(false)}>
              Let&apos;s do a lookup
            </Button>
          </div>
        </ArcherModalBottomActions>
      </ArcherModal>
    </>
  )
}

export default LTOSuccessModal
