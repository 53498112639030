import { Asset, LookupRequestType } from './assets'

export enum MatchMode {
  phone = 'phone',
  email = 'email',
  workEmail = 'workEmail'
}

export enum AccuracyMode {
  ALL = 'all',
  HIGH_ACCURACY = 'high_accuracy'
}

export type KeyOfMatchMode = keyof typeof MatchMode

export type MatchSelectConfig = {
  [key in KeyOfMatchMode]: {
    active: boolean
    accuracy: AccuracyMode
  }
}

// TODO: This must be refactored as follows:
/**
   * const defaultMatchSelectConfig = {
      company: {
        workEmail: { ... },
        requestPersonalData: { ... }
      },
      address: {
        email: { ... },
        phone: { ... }
      }
    } 
   */
export const matchSelectConfigToAssetFields = (
  requestType: LookupRequestType,
  config: MatchSelectConfig
): Partial<Pick<Asset, 'requestEmails' | 'requestPhones' | 'requestWorkEmails'>> => {
  if (requestType === 'address') {
    return {
      requestEmails: config.email.active || false,
      requestPhones: config.phone.active || false,
      requestWorkEmails: false
    }
  }

  return {
    requestWorkEmails: config.workEmail.active || false
  }
}
