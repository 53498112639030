import { trackEvent } from '@/analytics'
import { ReactComponent as RocketIcon } from '@/assets/icons/ic-rocket.svg'
import { MAX_BILLING_BALANCE, MAX_CREDITS, MIN_CREDIT_COST } from '@/constants/billing'
import { useBillingTracker } from '@/pages/dashboard/hooks'
import { createBillingSettlement } from '@/services/payments'
import { formatDate, formatMoney, formatNumber, minCreditCost } from '@/utils'
import { Box, Button, Grid, LinearProgress, Skeleton, Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as InfoIcon } from '@/assets/icons/ic-info.svg'

const PlanListItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <li>
      <Box mb={1} className="flex items-center">
        <Typography fontWeight={500}>{children}</Typography>
      </Box>
    </li>
  )
}

const useBillingSettle = () => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  return {
    handleSubmit: async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      setIsLoading(true)
      event.stopPropagation()
      event.preventDefault()

      try {
        const billingSheet = await createBillingSettlement()

        console.log(billingSheet.data)
        navigate(`/payment/${billingSheet.data._id}`)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    disabled: isLoading
  }
}

const calculateProgress = (balance: number) => {
  return (balance / MAX_BILLING_BALANCE) * 100
}

const StandardPlan = () => {
  const { data: billingTracker, isLoading } = useBillingTracker()
  const { handleSubmit, disabled } = useBillingSettle()

  const { accrued_credits, userBalance = 0 } = billingTracker || {}

  const tierFloor = accrued_credits && accrued_credits >= MAX_CREDITS
  const tierNumber = tierFloor ? 2 : 1
  const tierPrice = tierFloor ? '0.5¢' : '75¢'

  const creditUsed = formatNumber(accrued_credits)
  const lowBalance = userBalance > 0 && userBalance < MIN_CREDIT_COST
  const buttonDisabled = disabled || userBalance === 0 || lowBalance
  const minCost = minCreditCost()

  return (
    <Stack direction="column" gap={5}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <Box className="md:rounded-lg md:bg-background.secondary shadow-lg flex flex-col p-7">
              <Box className="flex justify-between items-center">
                <Box>
                  <Skeleton variant="text" width={180} height={60} />
                </Box>
                <Box>
                  <Skeleton variant="text" width={230} height={60} />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="text" width="100%" height={120} />
              </Box>
              <>
                <Box className="flex justify-between items-center">
                  <Box>
                    <Skeleton variant="text" width={120} height={60} />
                  </Box>
                  <Box>
                    <Skeleton
                      className="rounded-[20px]"
                      variant="rounded"
                      width={155}
                      height={45}
                    />
                  </Box>
                </Box>
                {lowBalance && (
                  <Box>
                    <Skeleton variant="text" width="100%" height={70} />
                  </Box>
                )}
              </>
            </Box>
          ) : (
            <Box className="md:rounded-lg md:bg-background.secondary shadow-lg flex flex-col gap-5 p-7 pb-10">
              <Box className="flex justify-between items-center">
                <Typography className="text-2xl font-black" variant="h2">
                  Current Balance
                </Typography>
                <Typography className="text-base" color="text.primary" variant="caption">
                  Next payment due:&nbsp;
                  {billingTracker && (
                    <b title={billingTracker.next_billing_cycle}>
                      {formatDate(billingTracker.next_billing_cycle, DateTime.DATE_SHORT)}
                    </b>
                  )}
                </Typography>
              </Box>
              <Box className="text-left">
                Archer will bill you automatically at the end of each billing cycle based on your
                balance for credits used. You will be automatically charged whenever your balance
                exceeds five hundred credits used.
              </Box>

              <>
                <Box className="flex justify-between items-center">
                  <Box className="text-3xl font-black">{formatMoney(userBalance)}</Box>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={(e) => {
                      trackEvent('Review & Pay now', {
                        page: 'Billing',
                        amount: userBalance
                      })
                      return handleSubmit(e)
                    }}
                    disabled={buttonDisabled}
                    href={'/payment'}
                  >
                    Review & Pay now
                  </Button>
                </Box>
                {lowBalance && (
                  <Typography
                    className="flex text-xs leading-4 text-justify gap-1"
                    variant="caption"
                    color="text.secondary"
                  >
                    <Box>
                      <InfoIcon className="w-3 h-3" />
                    </Box>
                    <Box>
                      Your current balance is below {minCost}. You’ll be able to make a payment once
                      your balance reaches {minCost} or higher.
                    </Box>
                  </Typography>
                )}
              </>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Box className="md:rounded-lg flex flex-col border-solid border background.tertiary p-3 pb-0 gap-1">
                <Typography className="text-base" variant="h3">
                  Pricing Tier {tierNumber}: {tierPrice} per credit
                </Typography>
                <Typography className="text-base" variant="caption">
                  Credits used this billing cycle: {creditUsed}
                </Typography>
                <Typography variant="body1" mb={2} className="flex">
                  <Box>
                    <RocketIcon className="mb-1 mr-3" />
                  </Box>
                  <Box className="text-primary.main font-bold">
                    Only {MAX_CREDITS - Number(creditUsed)} more until you unlock 50¢ pricing!
                  </Box>
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box className="md:rounded-lg flex flex-col gap-0 border-solid border background.tertiary p-3">
                <Typography className="text-base" variant="h3">
                  Pre-authorization and outstanding payments
                </Typography>
                <Grid container spacing={2} className="flex justify-center items-center">
                  <Grid item xs={12} md={6} className="flex flex-col flex-initial gap-2">
                    <Grid className="flex text-xl" item>
                      <Box className="font-black">{formatMoney(userBalance)}</Box>
                      <Box className="mx-1">/</Box>
                      <Box>$375</Box>
                    </Grid>
                    <Grid item>
                      <LinearProgress
                        className="my-1"
                        variant="determinate"
                        value={calculateProgress(userBalance)}
                        sx={{
                          borderRadius: 5
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className="flex-1">
                    <Typography variant="caption">
                      If your maximum balance exceeds $375 during an enrichment, we will place a
                      pre-authorization and require a payment on your credit card.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box className="md:rounded-3xl md:p-6 md:bg-background.secondary shadow-lg hidden">
        <div className="flex mb-4 gap-2 flex-wrap">
          <Typography variant="h4" fontWeight={500}>
            <b>Usage-Based Pricing</b>
          </Typography>
          <span className="text-xl font-medium mx-2">|</span>
          <Typography variant="h4" fontWeight={500}>
            Monthly Plan
          </Typography>
          <span className="text-xl font-medium mx-2">|</span>
          <Typography variant="h4" fontWeight={800} color="primary.main">
            1 credit = $0.75*
          </Typography>
        </div>
        <Box>
          <ul className="list-none p-0 m-0">
            <PlanListItem>
              You <b className="text-primary.main">only</b> pay for successful lookups (1 credit)!
            </PlanListItem>
            <PlanListItem>
              You can use Archer as much <b className="text-primary.main">OR</b> as little as you
              like. No commitments.
            </PlanListItem>
            <PlanListItem>
              *After five hundred credits used in a billing cycle, each additional credit costs
              $0.50.
            </PlanListItem>
          </ul>
        </Box>
      </Box>
    </Stack>
  )
}

export default StandardPlan
