import { Asset } from '@/types/assets'
import RetryForm from '../RetryForm'
import RetryLookupCompanyMessage from './RetryLookupCompanyMessage'

type Props = {
  asset: Asset
  onRestart: (newAssetId?: string) => unknown
}

const RetryLookupForm = ({ asset, onRestart }: Props) => {
  return <RetryForm onRestart={onRestart} asset={asset} component={RetryLookupCompanyMessage} />
}

export default RetryLookupForm
