import { api } from '@/providers/ApiProvider'
import { User } from '@/types/user'
import { AxiosRequestConfig } from 'axios'

const getInfo = (options?: AxiosRequestConfig<unknown>) => {
  return api.get<User>('/auth/info', options)
}

const signIn = (
  data: { email: string; password: string },
  options?: AxiosRequestConfig<unknown>
) => {
  return api.post<User>('/auth/sign-in', data, options)
}

const signUp = (
  data: {
    fullName: string
    email: string
    password: string
  },
  options?: AxiosRequestConfig<unknown>
) => {
  return api.post<undefined>('/auth/sign-up', data, options)
}

const signOut = (options?: AxiosRequestConfig<unknown>) => {
  return api.post<undefined>('/auth/sign-out', undefined, options)
}

const forgotPassword = (data: { email: string }, options?: AxiosRequestConfig<unknown>) => {
  return api.post<undefined>('/auth/forgot-password', data, options)
}

const resetPassword = (
  data: { token?: string; password: string; oldPassword?: string },
  options?: AxiosRequestConfig<unknown>
) => {
  return api.post<User>('/auth/reset-password', data, options)
}

const requestMagicLink = (email: string, options?: AxiosRequestConfig<unknown>) => {
  return api.post<User>(`/auth/email-link`, { email }, options)
}

const requestVerificationEmail = requestMagicLink

const verifyMagicLink = (token: string, options?: AxiosRequestConfig<unknown>) => {
  return api.get<User>(`/auth/email-link/check?token=${token}`, options)
}

const deleteUser = (options?: AxiosRequestConfig<unknown>) => {
  return api.delete<null>(`/auth`, options)
}

export {
  getInfo,
  signIn,
  signUp,
  signOut,
  forgotPassword,
  resetPassword,
  requestMagicLink,
  requestVerificationEmail,
  verifyMagicLink,
  deleteUser
}
