import { useEffect } from 'react'
import { useAuth } from '@/reducers/user'

export const useIntercom = () => {
  const { user } = useAuth()

  useEffect(() => {
    if (!user) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window?.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID
      })

      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      name: user.fullName,
      email: user.email,
      user_id: user._id
    })
  }, [user])
}
