import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Asset } from '@/types/assets'
import { MdOutlineUpload } from 'react-icons/md'

import { Box, Button, CircularProgress, Typography } from '@mui/material'
import NotificationEmail from '../upload/step-components/process-matches/NotificationEmail'
import { trackEvent, useAuthGuardPageTracker } from '@/analytics'

let timer: NodeJS.Timer | null = null

interface ProcessMatchesProps {
  asset: Asset
  refetch: () => unknown
}

const trackingPayload = { page: 'Working on your data' } as const

const ProcessMatches = ({ asset, refetch }: ProcessMatchesProps) => {
  useAuthGuardPageTracker('Visits Working on your data', trackingPayload)

  useEffect(() => {
    if (!timer) {
      timer = setInterval(() => {
        refetch()
      }, 10000)
    }

    return () => {
      if (timer) {
        clearInterval(timer)
        timer = null
      }
    }
  }, [refetch])

  useEffect(() => {
    console.log('asset?.status', asset?.status)
  }, [asset?.status])

  return (
    <Container className="text-center">
      <div className="mb-12">
        <div className="mt-12">
          <Box mb={4} mt={10}>
            <CircularProgress size={60} />
          </Box>
          <h1 className="font-black	text-4xl text-primary">We are working on your data</h1>
          <p>
            This usually takes a few minutes to a few hours. Feel free to look around in the
            meantime!
          </p>
          <Box my={5} className="max-w-xl mx-auto">
            <NotificationEmail disabled />
          </Box>
        </div>
      </div>
      <div className="flex gap-4 items-center justify-center">
        <Typography variant="h3" fontWeight={800}>
          While you wait
        </Typography>
        <Button
          variant="contained"
          href={`/lookup/address`}
          size="small"
          startIcon={<MdOutlineUpload />}
          onClick={() => {
            trackEvent('Upload a new list', { page: 'Working on your data' })
          }}
        >
          Upload a new list
        </Button>
      </div>
    </Container>
  )
}

export default ProcessMatches
