import { useForm } from 'react-hook-form'
import { object } from 'yup'
import { useEffect, useState } from 'react'
import { BsCheck2 } from 'react-icons/bs'
import { IoMdClose } from 'react-icons/io'

import { ReactComponent as PencilIcon } from '@/assets/icons/ic-pencil.svg'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateProfile } from '@/services/user'
import { useSignIn, useAuth } from '@/reducers/user'
import { emailValidator } from '@/utils/validators'
import FormInput from '@/components/Form/Input/Input'
import { IconButton, Typography } from '@mui/material'
import { PAGE, trackEvent } from '@/analytics'

interface SchemaType {
  email: string
}

const schema = object({
  email: emailValidator
}).required()

export const useNotificationEmailForm = () => {
  const [editing, setEditing] = useState(false)
  const refresh = useSignIn()
  const { user } = useAuth()

  const { handleSubmit, formState, control, setValue } = useForm<SchemaType>({
    resolver: yupResolver(schema)
  })

  const defaultEmail = user?.preferences?.notificationsEmail || user?.email

  useEffect(() => {
    if (defaultEmail) {
      setValue('email', defaultEmail)
    }
  }, [defaultEmail])

  const onSubmit = async ({ email }: SchemaType) => {
    const result = await updateProfile({
      preferences: {
        notificationsEmail: email
      }
    })

    refresh(result.data)
    setEditing(false)
  }

  return {
    editing,
    setEditing,
    formState,
    control,
    handleSubmit,
    onSubmit: handleSubmit(onSubmit)
  }
}

const NotificationEmail = ({ disabled, page }: { disabled?: boolean; page?: PAGE }) => {
  const { editing, setEditing, control, formState, onSubmit } = useNotificationEmailForm()

  const renderControls = () => {
    if (disabled) {
      return null
    }

    if (editing) {
      return (
        <div>
          <IconButton type="button" onClick={() => setEditing(false)}>
            <IoMdClose className="close" />
          </IconButton>
          <IconButton type="submit">
            <BsCheck2 className="check" />
          </IconButton>
        </div>
      )
    }

    return (
      <IconButton
        type="button"
        onClick={() => {
          if (page) {
            trackEvent('Edits notification email', { page })
          }
          setEditing(true)
        }}
        disabled={disabled}
      >
        <PencilIcon />
      </IconButton>
    )
  }

  return (
    <div className="p-6 bg-background.primary rounded-xl flex flex-col items-start border border-border.primary">
      <div>
        <Typography fontWeight={700} variant="body1" mb={2} textAlign={'left'}>
          When it’s ready we will notify you on:
        </Typography>
        {!disabled ? (
          <Typography fontWeight={500} variant="body1" mb={2} textAlign={'left'}>
            If you&apos;d prefer to be notified at a different address, feel free to update your
            email settings here:
          </Typography>
        ) : null}
        <form onSubmit={onSubmit} className="flex flex-row gap-4">
          {/** A component is changing an uncontrolled input to be controlled */}
          <FormInput
            formState={formState}
            control={control}
            name="email"
            type="email"
            disabled={disabled || !editing}
            controlId="email"
            className="w-80"
          />
          {formState.errors?.email?.message && <span>{formState.errors.email.message}</span>}
          <div className="mb-4">{renderControls()}</div>
        </form>
      </div>
      <span>
        You can also access it anytime from your{' '}
        <a href="/history" className="text-primary no-underline	">
          History
        </a>{' '}
        page.
      </span>
    </div>
  )
}

export default NotificationEmail
