import { PaymentIntent } from '@stripe/stripe-js'
import { Asset, LookupRequestType } from './assets'
import { Transaction } from './transactions'
import { StripeCustomer } from './user'

export enum BILLING_PLAN {
  STANDARD = 'standard'
}

export interface PaymentHistoryFilter {
  minAmount?: string
  maxAmount?: string
  startDate?: string
  endDate?: string
}

type TransactionWithPayment = Transaction & {
  payment: PaymentIntent
}

export type BillingSheetItem = {
  _id: string
  type: LookupRequestType
  cost: number
  createdAt: string
  assetDoc: [
    {
      usedCredits: number
      requestedType: LookupRequestType
      statistics: Asset['statistics']
    }
  ]
}

export type BillingSheet = {
  _id: string
  userId: string
  amount: number
  address?: StripeCustomer['address']
  totalCreditsUsed: number
  items: BillingSheetItem[]
  transaction?: TransactionWithPayment
}
