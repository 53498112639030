import { ReactComponent as BillingIcon } from '@/assets/icons/ic-file-invoice-dollar.svg'
import { ReactComponent as LogoutIcon } from '@/assets/icons/ic-logout.svg'
import { ReactComponent as PlansIcon } from '@/assets/icons/ic-plans.svg'
import { ReactComponent as HistoryIcon } from '@/assets/icons/ic-sidebar-history.svg'
import { ReactComponent as UserGearIcon } from '@/assets/icons/ic-user-gear.svg'
import { ReactComponent as UserSearchIcon } from '@/assets/icons/ic-user-search.svg'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  Link,
  Popover,
  styled,
  Toolbar,
  useTheme
} from '@mui/material'
import { useRef, useState } from 'react'
import { MobileNavMenu, SidebarIconButton } from './NavItems'

const Accordion = styled(MuiAccordion)(() => ({
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

export const SidebarContent = ({
  handleDrawerToggle,
  handleSignOut,
  noToolbar = false,
  iconOnly
}: {
  handleDrawerToggle?: () => unknown
  handleSignOut: () => unknown
  noToolbar?: boolean
  iconOnly?: boolean
}) => {
  const theme = useTheme()
  const menuRef = useRef<HTMLAnchorElement>(null)

  const [menuOpen, setMenuOpen] = useState(false)

  const onLinkClick = () => {
    if (handleDrawerToggle) {
      handleDrawerToggle()
    }
  }

  return (
    <>
      {noToolbar ? null : (
        <Toolbar disableGutters sx={{ minHeight: '48px !important' }} className="p-6">
          <Link href="/" className="mr-auto">
            <img src="/assets/images/logo.svg" alt="Archer" />
          </Link>
          <div className="ml-auto">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <CloseIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </div>
        </Toolbar>
      )}
      {iconOnly ? (
        <>
          <SidebarIconButton
            title="Lookups"
            ref={menuRef}
            href="/lookup"
            onClick={(event) => {
              event.preventDefault()
              setMenuOpen(true)
            }}
          >
            <UserSearchIcon className="w-5 h-5" />
          </SidebarIconButton>
          {menuRef.current ? (
            <Popover
              open={menuOpen}
              anchorEl={menuRef.current}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              onClose={() => {
                setMenuOpen(false)
              }}
              sx={{
                left: '16px',

                '& .MuiPopover-paper': {
                  borderRadius: '12px'
                }
              }}
            >
              <Accordion defaultExpanded disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="lookups-content"
                  className="font-bold text-sm px-3 text-primary.main"
                  id="lookups-header"
                  sx={{
                    minHeight: '40px',
                    '& .MuiAccordionSummary-content': {
                      marginTop: '8px',
                      marginBottom: '8px'
                    }
                  }}
                >
                  Lookups
                </AccordionSummary>
                <Divider className="bg-background.tertiary opacity-100 " />
                <AccordionDetails className="p-0 flex flex-col items-stretch gap-1">
                  <MobileNavMenu
                    inAccordion
                    to="/lookup/company"
                    label="Find by company"
                    onClick={() => {
                      setMenuOpen(false)
                    }}
                  />
                  <MobileNavMenu
                    inAccordion
                    to="/lookup/address"
                    label="Find by address"
                    onClick={() => {
                      setMenuOpen(false)
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Popover>
          ) : null}
        </>
      ) : (
        <MobileNavMenu
          to="/lookup/address"
          label="Find by address"
          onClick={onLinkClick}
          icon={<UserSearchIcon className="w-5 h-5 fill-primary.main" />}
        />
      )}

      <MobileNavMenu
        to="/history"
        label="History"
        onClick={onLinkClick}
        iconOnly={iconOnly}
        icon={<HistoryIcon className="w-5 h-5 fill-primary.main" />}
      />
      <MobileNavMenu
        to="/plans"
        label="Pricing"
        onClick={onLinkClick}
        iconOnly={iconOnly}
        icon={<PlansIcon className="w-5 h-5 fill-primary.main" />}
      />

      <div className="flex-1 min-h-[1rem]" />
      <MobileNavMenu
        to="/settings/profile"
        label="Profile settings"
        onClick={onLinkClick}
        iconOnly={iconOnly}
        icon={<UserGearIcon className="w-5 h-5" />}
      />
      <MobileNavMenu
        to="/billing"
        label="Billing"
        onClick={onLinkClick}
        iconOnly={iconOnly}
        icon={<BillingIcon className="w-5 h-5" />}
      />
      <div className="mt-6" />
      <MobileNavMenu
        to="/logout"
        onClick={(e) => {
          e.preventDefault()
          handleSignOut()
        }}
        label="Logout"
        iconOnly={iconOnly}
        icon={<LogoutIcon className="w-5 h-5" />}
      />
    </>
  )
}

export default SidebarContent
