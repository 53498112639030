import { ReactComponent as EmailCheckIcon } from '@/assets/icons/ic-email-check.svg'
import { AccuracyMatchChip } from '../common/AccuracyMatchChip'
import { EmailTooltip } from './Tooltips'

const DeliverableChip = () => (
  <AccuracyMatchChip
    color="success"
    label="Deliverable"
    wrappWithTooltip={EmailTooltip}
    chipIcon={EmailCheckIcon}
  />
)

export default DeliverableChip
