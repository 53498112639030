import { useLocation, useSearchParams } from 'react-router-dom'

const REDIRECT_TO_KEY = 'redirectTo'

type RedirectToQueryParam = {
  redirectToQueryParam: string
  getRedirectToQueryParam: string
}

/**
 * Custom hook to manage redirection using URL redirectTo query parameter.
 * - `redirectToQueryParam` is used to create a URL query string for redirection.
 * - `getRedirectToQueryParam` retrieves the target URL for redirection from the current URL's query parameters.
 *
 * @returns {RedirectToQueryParam} Object containing `redirectToQueryParam` and `getRedirectToQueryParam`.
 */
export const useRedirectToQueryParam = (
  replacePath?: (path: string) => void
): RedirectToQueryParam => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  const getPath = searchParams.get(REDIRECT_TO_KEY) ?? '/'
  const getRedirectToQueryParam = replacePath?.(getPath) ?? getPath

  const encodedPathName = encodeURIComponent(pathname)
  const redirectToQueryParam = pathname !== '/' ? `?${REDIRECT_TO_KEY}=${encodedPathName}` : ''

  return {
    redirectToQueryParam,
    getRedirectToQueryParam
  }
}

export default useRedirectToQueryParam
