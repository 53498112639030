/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReactComponent as StopwatchIcon } from '@/assets/icons/ic-stopwatch.svg'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import DashboardAsset from './DashboardAsset'
import HowItWorks from './HowItWorks'
import QuickMatch from './QuickMatch'

const SingleEnrichmentDashboard = ({
  active,
  defaultAssetId,
  matchType
}: {
  active: boolean
  defaultAssetId?: string | null
  matchType: 'personal' | 'company'
}) => {
  const [activeAssetId, setActiveAssetId] = useState<string | null>(null)

  useEffect(() => {
    if (defaultAssetId) {
      setActiveAssetId(defaultAssetId)
    }
  }, [defaultAssetId])

  return (
    <Box sx={{ display: active ? undefined : 'none' }} className="py-3">
      {activeAssetId && (
        <div>
          <DashboardAsset
            assetId={activeAssetId}
            setActiveAssetId={setActiveAssetId}
            matchType={matchType}
          />
        </div>
      )}
      {!activeAssetId ? (
        <div>
          <span className="flex justify-between gap-2 mt-1">
            <div className="flex justify-between gap-2">
              <StopwatchIcon className="hidden md:block h-6 w-6" />
              <Typography variant="h3" className="hidden md:block">
                Look up Someone!
              </Typography>
              <Typography variant="h3" className="md:hidden">
                Single Search
              </Typography>
            </div>
            <HowItWorks matchType={matchType} />
          </span>
          <div className="flex mt-4">
            <div className="w-full">
              <QuickMatch matchType={matchType} setActiveAssetId={setActiveAssetId} />
            </div>
          </div>
        </div>
      ) : null}
    </Box>
  )
}

export default SingleEnrichmentDashboard
