import { Box, BoxProps, Button, Divider, SvgIcon, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useState } from 'react'

import CircularProgressWithLabel from '@/components/Progress/CircularProgressWithLabel'
import { humanFileSize } from '@/utils'

import { ReactComponent as CircleCheckIcon } from '@/assets/icons/ic-circle-check.svg'

export const DragDropCaption = () => {
  return (
    <Typography variant="caption" color="text.secondary" fontWeight={500}>
      Allowed file formats: .xlsx, .xls, .csv and other similiar file types
    </Typography>
  )
}

export const DragDropFile = ({
  onUploaded,
  padding = 7.75
}: {
  onUploaded: (file: File) => void
  padding?: number
}) => {
  const theme = useTheme()
  const [dragActive, setDragActive] = useState(false)
  const [progress] = useState<number>(0)
  const [status, setStatus] = useState<string>('initial')
  const [file, setFile] = useState<File | null>(null)

  // handle drag events
  const handleDrag = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploadFile(e.dataTransfer.files[0])
    }
  }

  // triggers when file is selected with click
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      uploadFile(e.target.files[0])
    }
  }

  const uploadFile = (file: File) => {
    setStatus('uploading')
    setFile(file)
    setStatus('completed')
    onUploaded(file)
  }

  const FileDetails = (props: BoxProps) => {
    return (
      file && (
        <Box
          px={2}
          py={1.5}
          width={'100%'}
          borderRadius={3}
          textAlign="left"
          sx={{ background: theme.palette.background.default }}
          {...props}
        >
          <Typography variant="caption" fontWeight={700}>
            {file.name}
          </Typography>
          <Typography fontSize={12} lineHeight={1} color="text.quaternary" fontWeight={500}>
            {humanFileSize(file.size, true)}
          </Typography>
        </Box>
      )
    )
  }

  return (
    <Box>
      <Box
        textAlign="center"
        sx={{
          background: dragActive
            ? theme.palette.background.tertiary
            : theme.palette.background.secondary,
          border: `1.5px dashed ${theme.palette.divider}`,
          borderRadius: 6
        }}
      >
        {status === 'initial' && (
          <Box
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            py={padding}
          >
            <Typography variant="h3" color={'text.tertiary'} px={3}>
              Drag & drop your existing contact list here
            </Typography>

            <Divider sx={{ color: theme.palette.text.secondary, my: 2, maxWidth: 256, mx: 'auto' }}>
              <Typography variant="caption" fontWeight={700}>
                OR
              </Typography>
            </Divider>

            <Button variant="outlined" size="large" component="label">
              Browse
              <input
                hidden
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                type="file"
                onChange={handleChange}
              />
            </Button>
          </Box>
        )}
        {status === 'uploading' && file && (
          <Box px={1.5} pt={5} pb={1.5} alignItems="center" display="flex" flexDirection="column">
            <CircularProgressWithLabel
              value={progress}
              size={64}
              sx={{ color: theme.palette.primary.light }}
              thickness={2.5}
            />
            <Typography variant="caption" fontWeight={500} mt={0.5}>
              Uploading
            </Typography>

            <FileDetails mt={5} />
          </Box>
        )}
        {status === 'completed' && (
          <Box px={1.5}>
            <SvgIcon
              component={CircleCheckIcon}
              color="secondary"
              inheritViewBox
              sx={{ mt: 7, fontSize: 24 }}
            />

            <Typography variant="h3" mt={1}>
              Successfully uploaded
            </Typography>

            <FileDetails mt={7} mb={1.5} />
          </Box>
        )}
      </Box>

      <Box mt={1}>
        <DragDropCaption />
      </Box>
    </Box>
  )
}

export default DragDropFile
