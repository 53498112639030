import BasicArcherSideScreen from './sideScreen/BasicArcher'
import MarketingSideScreen, { MobileMarketingSideScreen2 } from './sideScreen/Marketing'

const BaseLayout = ({
  children,
  sideScreen = <BasicArcherSideScreen />
}: {
  children: React.ReactNode
  sideScreen?: React.ReactNode
}) => {
  return (
    <div className="max-w-4xl h-full flex mx-auto rounded-3xl overflow-hidden lg:min-h-[780px]">
      <div className="hidden lg:flex lg:w-1/2">{sideScreen}</div>
      <div className="w-full max-w-md mx-auto lg:max-w-none lg:w-1/2 flex-1 lg:bg-white px-10 lg:py-10">
        {children}
      </div>
    </div>
  )
}

export const SignUpPageLayout = ({
  children,
  sideScreen = <MarketingSideScreen />,
  mobileSideScreen = <MobileMarketingSideScreen2 />
}: {
  children: React.ReactNode
  sideScreen?: React.ReactNode
  mobileSideScreen?: React.ReactNode
}) => {
  return (
    <div className="max-w-5xl h-full flex mx-auto rounded-3xl overflow-hidden lg:min-h-[780px]">
      <div className="hidden lg:flex lg:w-[554px]">{sideScreen}</div>
      <div className="w-full max-w-md mx-auto lg:max-w-none lg:w-1/2 flex-1 lg:bg-white px-10 lg:py-10">
        <div className="flex lg:hidden">{mobileSideScreen}</div>
        {children}
      </div>
    </div>
  )
}

export default BaseLayout
