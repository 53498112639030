import { Control, FormState, UseFormRegister } from 'react-hook-form'
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material'
import { COUNTRIES } from '@/utils/countries'
import FormInput from '@/components/Form/Input/Input'
import FormSelect from '@/components/Form/Input/Select'
import { BillingFormType } from './hooks'

const BillingAddressForm = ({
  onSubmit,
  formState,
  control,
  disabled,
  register,
  header
}: {
  onSubmit?: (e?: React.BaseSyntheticEvent<object> | undefined) => Promise<void>
  formState: FormState<BillingFormType>
  control: Control<BillingFormType>
  disabled: boolean
  register?: UseFormRegister<BillingFormType>
  header?: React.ReactNode
}) => {
  return (
    <>
      {header}
      <form onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column" gap={2}>
          <FormInput
            formState={formState}
            control={control}
            name="fullName"
            label="Full Name"
            controlId="fieldFullName"
            rules={{ required: true }}
            disabled
          />
          <div className="flex flex-col sm:flex-row gap-4">
            <Box flex={1}>
              <FormSelect
                formState={formState}
                control={control}
                name="country"
                type="select"
                label="Country or region"
                controlId="fieldCountry"
                rules={{ required: true }}
                disabled={disabled}
              >
                <option></option>
                {COUNTRIES.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </FormSelect>
            </Box>
            <Box flex={1}>
              <FormInput
                formState={formState}
                control={control}
                name="city"
                label="City"
                controlId="fieldCity"
                rules={{ required: true }}
                disabled={disabled}
              />
            </Box>
          </div>
          <FormInput
            formState={formState}
            control={control}
            name="address"
            label="Address"
            controlId="fieldAddress"
            rules={{ required: true }}
            disabled={disabled}
          />
          <FormInput
            formState={formState}
            control={control}
            name="zipCode"
            label="Zip Code"
            controlId="fieldzipcode"
            rules={{ required: true }}
            disabled={disabled}
          />

          <Box alignSelf="flex-start">
            {onSubmit ? (
              <Button variant="contained" size="large" type="submit" disabled={disabled}>
                Update
              </Button>
            ) : (
              <FormControlLabel
                control={<Checkbox {...register?.('update')} defaultChecked />}
                label="Update your billing address"
              />
            )}
          </Box>
        </Box>
      </form>
    </>
  )
}

export default BillingAddressForm
