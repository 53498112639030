import { useNavigate, useParams } from 'react-router-dom'
import { Asset, AssetStatus } from '@/types/assets'
import { Container, LinearProgress } from '@mui/material'
import CompletedStep from '../upload/step-components/complete-matches/complete'
import StepProgressBar from '@/components/StepProgressBar/StepProgressBar'
import { STEPS, STEPS_TO_INDEX } from '../upload'
import { useAsset } from './hooks'
import { useCallback, useEffect } from 'react'
import SelectMatches from './selectMatches'
import { processAsset, updateAsset } from '@/services/asset'
import { MatchSelectConfig, matchSelectConfigToAssetFields } from '@/types/match'
import ProcessMatches from './ProcessMatches'
import AdminPanel from './AdminPanel'
import { useClearSearchParam } from '@/utils'

const useActionNext = ({ asset, refetch }: { asset: Asset; refetch: () => unknown }) => {
  const navigate = useNavigate()

  const wasSet = useClearSearchParam('action', 'next')
  const shouldStartProcessing = asset.status === AssetStatus.UPLOADED

  useEffect(() => {
    const handler = async () => {
      await processAsset({ assetId: asset._id, navigate: navigate })
      refetch()
    }

    if (wasSet && shouldStartProcessing) {
      handler()
    }
  }, [wasSet, asset._id, shouldStartProcessing])
}

const AssetLifecycle = ({ asset, refetch }: { asset: Asset; refetch: () => unknown }) => {
  const navigate = useNavigate()
  useActionNext({ asset: asset, refetch })

  const onStartProcessing = useCallback(
    async (config: MatchSelectConfig) => {
      await updateAsset(asset._id, matchSelectConfigToAssetFields(asset.requestedType, config))
      await processAsset({ assetId: asset._id, navigate: navigate })
      refetch()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [asset._id, refetch, navigate]
  )

  if (asset.status === AssetStatus.UPLOADED) {
    return (
      <>
        <StepProgressBar stepIdx={STEPS_TO_INDEX[STEPS.SELECT_MATCHES]} />
        <SelectMatches asset={asset} onNext={onStartProcessing} />
      </>
    )
  }

  if (asset.status === AssetStatus.PROCESSING) {
    return (
      <>
        <StepProgressBar stepIdx={STEPS_TO_INDEX[STEPS.PROCESS_MATCHES]} />
        <ProcessMatches asset={asset} refetch={refetch} />
      </>
    )
  }

  if (asset.status === AssetStatus.READY) {
    return (
      <>
        <StepProgressBar stepIdx={STEPS_TO_INDEX[STEPS.DOWNLOAD]} />
        <CompletedStep asset={asset} />
      </>
    )
  }

  return null
}

const AssetPage = () => {
  const { id } = useParams()

  const { data, isLoading, mutate } = useAsset(id as string)

  if (data) {
    return (
      <Container sx={{ my: 5 }}>
        <AssetLifecycle asset={data.asset} refetch={mutate} />
        <AdminPanel asset={data.asset} />
      </Container>
    )
  }

  if (isLoading) {
    return (
      <>
        <LinearProgress />
      </>
    )
  }

  return (
    <>
      <div>Error loading the asset</div>
    </>
  )
}

export default AssetPage
