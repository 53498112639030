import { AlertColor, Box, BoxProps, styled } from '@mui/material'

export type Reliability = AlertColor

interface ReliabilityProps {
  reliability: Reliability
}

interface StyledBoxProps extends ReliabilityProps, BoxProps {}

interface ReliabilityLabelProps extends ReliabilityProps {
  label?: string
}

type Color = `${number}, ${number}, ${number}`

export const reliabilities: Record<AlertColor, Color> = {
  error: '255, 85, 85',
  info: '39, 33, 174',
  success: '18, 135, 80',
  warning: '255, 85, 85'
}

const StyledBox = styled(Box)<StyledBoxProps>(({ reliability }) => {
  const color = reliabilities[reliability]

  return {
    backgroundColor: 'white',
    border: `1px solid rgba(${color}, 0.3)`,
    borderRadius: '30px',
    color: `rgb(${color})`,
    padding: '3px 12px',
    textAlign: 'center',
    display: 'inline'
  }
})

const labels: Record<Reliability, string> = {
  info: 'Probable',
  warning: 'Invalid', // TODO: Check on the BE side?
  error: 'Invalid',
  success: 'Replacement'
}

const ReliabilityLabel = ({ label, reliability }: ReliabilityLabelProps) => {
  const text = label ?? labels[reliability]
  return <StyledBox reliability={reliability}>{text}</StyledBox>
}

export default ReliabilityLabel
