/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, { AxiosError } from 'axios'
import { Apis } from '@/providers/ApiProvider'
import { PostAssetRequest } from '@/types/request'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useAuth } from '@/reducers/user'

export const isLimitExceededError = (error: any) => {
  if (axios.isAxiosError(error)) {
    if (
      (error as AxiosError).response?.status === 400 &&
      // @ts-ignore
      (error as AxiosError).response?.data.error &&
      // @ts-ignore
      /Upper limit of .* reached/.test((error as AxiosError).response?.data.error)
    ) {
      return true
    }
  }

  return false
}

export const handleFlatfileUploadComplete = async ({
  newFilename,
  records,
  requestPayload,
  navigate
}: {
  newFilename: string
  records?: any
  batchId?: string
  requestPayload: Omit<PostAssetRequest, 'filename' | 'rows'>
  navigate: NavigateFunction
}) => {
  try {
    const { data } = await Apis.postAsset({
      filename: newFilename,
      rows: records,
      ...requestPayload
    })

    await new Promise((r) => setTimeout(r, 1000))
    navigate(`/asset/${data.asset._id}`)
  } catch (error) {
    if (isLimitExceededError(error)) {
      throw error
    }
  }
}

export const useRedirectIfInactive = (link = '/lookup/address') => {
  const navigate = useNavigate()
  const { user } = useAuth()

  useEffect(() => {
    if (user?.activatedAt === null) {
      navigate(link)
    }
  }, [user, link, navigate])
}
