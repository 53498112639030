import { Box, Button, Typography, CircularProgress, Paper } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import StripeAdd from './stripeAdd'
import { StripeCustomer } from '@/types/user'
import BillingErrors from './BillingErrors'
import { useCards } from './useCards'
import EditCreditCardComp from './EditCreditCardComp'
import PreventDeleteAlert from '@/components/Alert/PreventDeleteAlert'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { BILLING_ERROR_CODES } from '@/utils/errors/billing'
import { PaymentMethod } from '@stripe/stripe-js'
import { useAsset } from '@/pages/asset/hooks'
import { PAGE, trackEvent } from '@/analytics'
import { useIsCurrentUserActivated } from '@/reducers/user'
import VerifyEmailBanner from '@/components/Auth/VerifyEmailBanner'

const useRedirectToAsset = (paymentMethods: PaymentMethod[] | undefined) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const asset = useAsset(state?.assetId)

  const isSingleEnrichment = asset?.data?.asset?.statistics?.totalSubmitted === 1

  useEffect(() => {
    const shouldProcess =
      state?.assetId && state?.error === BILLING_ERROR_CODES.NO_PAYMENT_METHOD && paymentMethods

    if (!shouldProcess) {
      return
    }

    if (paymentMethods.length > 0 && !isSingleEnrichment) {
      navigate(`/asset/${state.assetId}?action=next`)

      return
    }

    if (paymentMethods.length > 0 && isSingleEnrichment) {
      navigate(`/?assetView=${state.assetId}&action=next`)
    }
  }, [state?.assetId, state?.error, paymentMethods?.length])
}

const PaymentSettings = ({
  userInfo,
  refreshUser,
  page = 'Payment Settings'
}: {
  userInfo?: StripeCustomer
  refreshUser: () => unknown
  page?: PAGE
}) => {
  const isUserVerified = useIsCurrentUserActivated()
  const {
    isLoading,
    paymentMethods,
    onPaymentMethodDelete,
    onAdd,
    onMarkDefault,
    onAddCancel,
    onAddSuccess,
    showAdd,
    clientSecret,
    defaultPaymentMethodId,
    canDeletePaymentMethod,
    showCantDeletePaymentMethod
  } = useCards({ userInfo, refreshUser, page })

  useRedirectToAsset(paymentMethods)

  const message = useMemo(() => {
    if (!isUserVerified) {
      return (
        <div className="bg-background.secondary px-6 py-4 rounded-xl shadow-md flex flex-col gap-4 items-start">
          <VerifyEmailBanner
            title="Verify your account before adding a credit card."
            page="Payment Settings"
          />
        </div>
      )
    }

    if (paymentMethods?.length === 0) {
      return <Typography>You have no credit/debit card added.</Typography>
    }
  }, [isUserVerified, paymentMethods?.length])

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Typography variant="h2" mb={3}>
        My credit cards
        {isLoading ? <CircularProgress size={20} sx={{ marginLeft: 2 }} /> : null}
      </Typography>
      {!isLoading && (
        <BillingErrors hasPaymentMethod={!!paymentMethods?.length && paymentMethods.length > 0} />
      )}

      <Box display="flex" gap={3} flexDirection="column" alignItems="flex-start">
        {paymentMethods?.map((paymentMethod) => (
          <EditCreditCardComp
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            onMarkDefault={(id) => {
              trackEvent('Set card as default', { page })
              return onMarkDefault(id)
            }}
            onDelete={(id) => {
              trackEvent('Remove card', { page })
              return onPaymentMethodDelete(id)
            }}
            canDelete={canDeletePaymentMethod}
            disabled={isLoading}
            isDefault={defaultPaymentMethodId === paymentMethod.id}
          />
        ))}
        {showCantDeletePaymentMethod && (
          <PreventDeleteAlert text="You must add an additional card or pay your outstanding balance before removing this card." />
        )}

        {message}

        {!showAdd && isUserVerified && (
          <Button
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            disabled={isLoading}
            onClick={() => {
              return onAdd()
            }}
          >
            Add new card
          </Button>
        )}
        {showAdd && clientSecret ? (
          <Paper sx={{ borderRadius: 3 }} elevation={3} className="p-6 md:p-8">
            <StripeAdd
              onReset={onAddCancel}
              clientSecret={clientSecret}
              disabled={isLoading}
              onSuccess={onAddSuccess}
            />
          </Paper>
        ) : null}
      </Box>
    </Box>
  )
}

export default PaymentSettings
