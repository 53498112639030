import { api } from '@/providers/ApiProvider'
import { AllowedUserUpdateFields, DashboardStats, User } from '@/types/user'
import { AxiosRequestConfig } from 'axios'

const updateProfile = (data: AllowedUserUpdateFields, options?: AxiosRequestConfig<unknown>) => {
  return api.patch<User>('/user/update-profile', data, options)
}

const dashboardStats = (options?: AxiosRequestConfig<unknown>) => {
  return api.get<DashboardStats>('/user/dashboard-stats', options)
}

const isVerified = (options?: AxiosRequestConfig<unknown>) => {
  return api.get<{ isVerified: boolean }>('/user/is-verified', options)
}

export { updateProfile, dashboardStats, isVerified }
