import { Card, Divider, IconButton, Modal, SxProps } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type ArcherModalProps = {
  open: boolean
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
  cardSx?: SxProps
  closeIcon?: () => void
  hiddenCloseButton?: boolean
}

export const ArcherModal = ({
  open,
  setOpen,
  children,
  cardSx,
  closeIcon,
  hiddenCloseButton = false
}: ArcherModalProps) => {
  return (
    <Modal
      className="flex justify-center items-center"
      open={open}
      onClose={() => setOpen && setOpen(false)}
      sx={{ overflow: 'scroll' }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: '#010417B2'
          }
        }
      }}
    >
      <Card
        className="rounded-3xl pt-[18px] pr-6 pb-2 pl-6"
        sx={{
          margin: '0 20px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          ...cardSx
        }}
      >
        {!hiddenCloseButton && (
          <IconButton
            onClick={setOpen ? () => setOpen(false) : closeIcon}
            sx={{ position: 'absolute', right: '1rem', top: '1rem' }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {children}
      </Card>
    </Modal>
  )
}

export const ArcherModalBottomActions = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="">
      <Divider className="bg-background.tertiary opacity-100 -mx-3 md:-mx-6" />
      <div className="py-3">{children}</div>
    </div>
  )
}
