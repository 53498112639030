import {
  CircularProgressProps,
  CircularProgress as MuiCircularProgress,
  circularProgressClasses
} from '@mui/material'

function CircularProgress(props: CircularProgressProps) {
  return (
    <div className="relative">
      <MuiCircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.background.default
        }}
        {...props}
        value={100}
      />
      <MuiCircularProgress
        variant="indeterminate"
        sx={{
          position: 'absolute',
          left: 0,
          color: 'primary.main',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        {...props}
      />
    </div>
  )
}

export default CircularProgress
