/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import FloatingButton from '../../assets/icons/ic-floating-button.svg'

function ScrollTopButton() {
  const [scrollY, setScrollY] = useState(0)

  const handleScroll = () => {
    setScrollY(window.scrollY)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (scrollY < 200) {
    return null
  }

  return (
    <div
      className="floatbutton"
      onClick={handleScrollTop}
      style={{ position: 'fixed', bottom: 64, right: 20, cursor: 'pointer' }}
    >
      <img src={FloatingButton} alt="floating button" />
    </div>
  )
}

export default ScrollTopButton
