/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  styled
} from '@mui/material'
import {
  RowSelectionState,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { Asset, AssetStatus } from '@/types/assets'
import { PAGE } from '@/analytics'
import React from 'react'
import { ReactComponent as AscendingOrder } from '@/assets/icons/ic-asc-order.svg'

export type Order = 'asc' | 'desc'

interface OrderingIconProps {
  color: string
  order?: Order
  isActive?: boolean
}

const StyledOrderingIcon: React.FC<OrderingIconProps> = ({ color, order, isActive }) => {
  return (
    <div className="px-2 pb-1">
      <AscendingOrder
        fill={color}
        className={isActive && order === 'desc' ? '-scale-y-100' : 'scale-x-100'}
      />
    </div>
  )
}

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 700,
  color: theme.palette.text.quaternary,
  fill: theme.palette.text.quaternary
}))

const StyledTableRow = styled(TableRow)({
  '& td:first-child': {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10
  },
  '& td:last-child': {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10
  }
})

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.text.quaternary
  },
  '&:focus': {
    color: theme.palette.text.primary
  }
}))

const AssetsTable = ({
  allMatches,
  columns,
  showStatus = false,
  page,
  rowSelection,
  setRowSelection,
  order,
  setOrder,
  orderingColumn,
  setOrderingColumn
}: {
  allMatches: Asset[]
  columns: any
  showStatus?: boolean
  page: PAGE
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  rowSelection: RowSelectionState
  order: Order
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  orderingColumn: string
  setOrderingColumn: React.Dispatch<React.SetStateAction<Required<keyof Asset>>>
}) => {
  const table = useReactTable({
    enableRowSelection: true,
    getRowId: (row) => {
      return row._id
    },
    onRowSelectionChange: (e) => {
      return setRowSelection(e)
    },
    data: allMatches,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        status: showStatus
      },
      rowSelection
    }
  })

  const columnsThatAllowSorting = ['name', 'createdAt', 'filename', 'label', 'status', 'company']

  const getColor = (currentOrder: Order) => {
    if (currentOrder === 'asc') {
      return '#2721ae'
    }
    if (currentOrder === 'desc') {
      return '#2721ae'
    }
    return '#515468'
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <StyledTableHeaderCell key={header.id}>
                  {page === 'History' && columnsThatAllowSorting.includes(header.id) ? (
                    <StyledTableSortLabel
                      IconComponent={() => (
                        <StyledOrderingIcon
                          color={orderingColumn === header.id ? getColor(order) : '#515468'}
                          order={order}
                          isActive={orderingColumn === header.id}
                        />
                      )}
                      active={true}
                      direction={order}
                      onClick={() => {
                        if (header.id === orderingColumn) {
                          setOrder(order === 'asc' ? 'desc' : 'asc')
                        } else {
                          setOrder('asc')
                          setOrderingColumn(header.id as Required<keyof Asset>)
                        }
                      }}
                    >
                      {!header.isPlaceholder &&
                        flexRender(header.column.columnDef.header, header.getContext())}
                    </StyledTableSortLabel>
                  ) : (
                    flexRender(header.column.columnDef.header, header.getContext())
                  )}
                </StyledTableHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => {
            if (row.original.status === AssetStatus.PROCESSING) {
              return (
                <StyledTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    if (cell.column.id === 'enrichedEmail') {
                      return (
                        <TableCell key={cell.id} colSpan={3}>
                          <LinearProgress className="rounded" />
                        </TableCell>
                      )
                    }

                    if (
                      cell.column.id === 'enrichedWorkEmail' ||
                      cell.column.id === 'enrichedPhone'
                    ) {
                      return null
                    }

                    return (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    )
                  })}
                </StyledTableRow>
              )
            }

            return (
              <StyledTableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.column.id}>
                    {cell.column.id === 'select' ||
                    cell.column.id === 'rating' ||
                    cell.getValue() !== undefined
                      ? flexRender(cell.column.columnDef.cell, cell.getContext())
                      : '-'}
                  </TableCell>
                ))}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AssetsTable
