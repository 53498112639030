/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'

import ScrollTopButton from '../../components/Button/ScrollTopButton'

import { ReactComponent as LeftIcon } from '@/assets/icons/ic-left-arrow.svg'
import { usePageTracker } from '@/analytics'

const PRIVACY_POLICY_EMAIL = 'privacy@helloarcher.io'

const trackingPayload = { page: 'Privacy' } as const

const PrivacyPage = () => {
  const navigate = useNavigate()
  usePageTracker('Visits Privacy Page', trackingPayload)

  return (
    <Container fixed sx={{ mt: 5, pb: 5 }}>
      <Button onClick={() => navigate(-1)} variant="outlined" size="small" startIcon={<LeftIcon />}>
        Back
      </Button>
      <Typography variant="title" color="primary.main" mt={2} mb={5}>
        Privacy Policy
      </Typography>
      <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 6 }}>
        <Typography
          variant="h6"
          fontWeight={700}
          mb={2}
          align="center"
          sx={[{ textDecoration: 'underline' }]}
        >
          PRIVACY POLICY
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} mb={2}>
          Section 1 – Introduction.
        </Typography>

        <Typography variant="body1">
          1.1. <b>Intro.</b> This Privacy Policy describes the Personal Information (as defined
          below) collected or processed when you interact with Archer. It also explains how your
          Personal Information is used, shared, and is protected by us, and what choices you have
          relating to your Personal Information. If you have any questions about this Privacy Policy
          or your Personal Information, please email us at the email listed in <i>Section 10</i>,
          below.
          <br />
          <br />
          This Privacy Policy governs your privacy rights. We recommend reading this Privacy Policy
          in its entirety. By using our Website, you consent to this Privacy Policy (and any
          subsequent amendments). If you do not agree to this Privacy Policy, please do not use our
          Website.
          <br />
          <br />
          1.2 <b>Ownership & Services.</b> Our Website is owned and operated by Archer LLC, a
          Delaware limited liability company (“Archer”, “we” or “us”). Archer is a company engaged
          in assisting candidates, campaigns, and non-profits with contact information enrichment
          efforts, with a variety of products and services (referred to herein as our “Services”).
          <br />
          <br />
          1.3 <b>Scope.</b> This Privacy Policy applies to all users of our websites -{' '}
          <Link href="https://www.sterlingstrategies.co/" target="_blank" rel="noreferrer">
            https://www.sterlingstrategies.co/
          </Link>
          ,{' '}
          <Link href="https://sterlingdatacompany.com/" target="_blank" rel="noreferrer">
            https://sterlingdatacompany.com/
          </Link>
          ,{' '}
          <Link href="https://www.sterling.ai/" target="_blank" rel="noreferrer">
            https://www.sterling.ai/
          </Link>
          , and{' '}
          <Link href="https://www.helloarcher.io/" target="_blank" rel="noreferrer">
            https://www.helloarcher.io/
          </Link>{' '}
          (each, a “Website”) and our application (“Services”){' '}
          <Link href="https://app.helloarcher.io" target="_blank" rel="noreferrer">
            https://app.helloarcher.io
          </Link>
          . This Privacy Policy also applies to your information that we have acquired, by license
          or ownership, from third parties. We collect certain information, including Personal
          Information, from our third-party sources.
          <br />
          <br />
          1.4 <b>Additional.</b> Our Website may contain links to third party sites that are not
          owned or controlled by Archer. Please be aware that we are not responsible for the
          practices of such other sites. We encourage you to read the privacy statements of every
          third-party website that you visit as these websites have no affiliation with Archer and
          may have privacy policies that contain different terms from what is included here.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 2 – Information We Collect.
        </Typography>

        <Typography variant="body1">
          2.1 <b>Information Provided by You.</b> You have provided us information through various
          means. We collect “Personal Information”, which identifies you as an individual, or you as
          an entity. The information we collect may include the following: name, address, email
          address(es), age, and inferences drawn from any or all of the foregoing. This information
          is collected directly from you when you sign up for a user account on our Website.
          <br />
          <br />
          2.2 <b>Information We Collect from You.</b> We collect certain information automatically
          as you use our Website, including your Internet Protocol address, browser type, computer
          type, type of mobile device, mobile carrier, computer operating system, the site from
          where you navigated to our Website, the time and date of using our Website, how long you
          use (or have used) the Website, what events you trigger through your usage, and the pages
          that you view.
          <br />
          <br />
          2.3 <b>Cookies.</b> When you visit or use our Website, we may send one or more “cookies”
          to your computer or other device. A cookie allows that site to recognize your browser.
          Cookies may store unique identifiers, user preferences and other information. You can
          reset your browser to refuse all cookies or to indicate when a cookie is being sent.
          However, if you do, some Website features may not function properly without cookies.
          <br />
          <br />
          We use cookies for a number of reasons, including without limitation:
          <br />
          <br />
          <List>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                ●
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="Authenticating and identifying you on our Websites so we can provide you the services
                you requested;"
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                ●
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="Keeping track of information you have provided to us;"
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                ●
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="Remembering your
                preferences or where you left off;"
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                ●
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="Measuring your use of our Website so we can improve
                its quality; and"
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                ●
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary="Understanding what you may be interested in."
              ></ListItemText>
            </ListItem>
          </List>
          <br />
          2.4 <b>Pixel Tags.</b> We may use "pixel tags," which are small graphic files that allow
          us to monitor the use of our Website. A pixel tag can collect information such as the IP
          address of the computer that downloaded the page on which the tag appears; the URL of the
          page on which the pixel tag appears; the time the page containing the pixel tag was
          viewed; the type of browser that fetched the pixel tag; and the identification number of
          any cookie on the computer previously placed by that server.
          <br />
          <br />
          2.5 <b>Physical Location.</b> We may collect the physical location of your device by, for
          example, using satellite, cell phone tower, or Wi-Fi signals. We may use your device’s
          physical location to better understand who you are.
          <br />
          <br />
          2.6 <b>Age Restriction.</b> Our Website and our Services are not intended for or directed
          to children under the age of thirteen (13) and we do not knowingly collect Personal
          Information from children under the age of thirteen (13) on our Website. If we become
          aware that a child under the age of thirteen (13) has provided us with Personal
          Information, we will delete the information from our records.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 3 – How Archer Uses Your Information.
        </Typography>

        <Typography variant="body1">
          3.1 <b>To Provide Services and Information.</b> As described above, we collect or have
          collected information from you so that we can effectively offer our Services to you, for
          data or analytic reasons, data trends, usage, to determine the effectiveness of our
          Services, or any reasonable reason(s) now or hereinafter adopted.
          <br />
          <br />
          3.2 <b>Our Business Purposes.</b> We may share your information with our clients who may
          be political campaigns, political organizations, NGO’s, non-profits, and other persons or
          entities looking for contact information enrichment, financing, or similar services. We
          may also use your information for our Services and other reasons such as data analysis,
          developing new Services, sending marketing campaigns, enhancing, improving or modifying
          our Website, identifying usage trends, determining the effectiveness of our campaigns and
          any reasonable reason(s) now or hereinafter adopted.
          <br />
          <br />
          3.3 <b>Other Purposes.</b> We may use non-Personal Information for other purposes, except
          when not permitted by applicable law. We may use analytics services, such as Google
          Analytics, on our Website and Our Services, our social media pages, or our mobile Website
          to help us evaluate and analyze how visitors use our Website. For specific details on how
          Google collects and uses information on our Website, social media pages, or mobile
          applications, please visit Google’s website.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 4 – How Your Information is Shared.{' '}
        </Typography>

        <Typography variant="body1">
          4.1 <b>Our Third-Party Service Providers.</b> We may share your information with our
          third-party service providers, who provide services including, without limitation, website
          hosting, data analytics, payment processing, customer service, email delivery services, or
          other services that are used in connection with the Services that we offer.
          <br />
          <br />
          4.2 <b>Legal Requests or Requirements and to Prevent Harm.</b> We reserve the right to
          disclose your Personal Information and any other information collected by you as required
          by law or when that disclosure is necessary to protect our rights, the rights of a
          third-party, or to comply with a judicial proceeding or otherwise.
          <br />
          <br />
          4.3 <b>Transfer of Your Personal Information.</b> As a data broker, we reserve the right
          to transfer your information to another person or entity, with or without consideration,
          unless you opt out (see below) or unless barred by applicable law. We also reserve the
          right to transfer your information in the event of a sale of Archer. For the sake of
          clarity, “Personal Information”, is only the information that identifies you, the user, as
          an individual, or you as an entity.
          <br />
          <br />
          4.4 <b>Other Sharing of Non-Personally Identifiable Information.</b> We may share
          non-personally identifiable information with third parties unless you opt out or unless
          barred by applicable law.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 5 - Access to Your Information and Opt Out.
        </Typography>

        <Typography variant="body1">
          Subject to applicable law, you may have the right to request access to and receive details
          about the Personal Information we have collected and maintain about you, update and
          correct inaccuracies in your Personal Information, and have the Personal Information
          deleted. These rights may be limited in some circumstances by local law. We may take
          reasonable steps to verify your identity before granting access or making corrections.
          <br />
          <br />
          You can opt-out of us using your Personal Information by sending us an email to the
          contact listed on our Website or unsubscribing on the communication you receive. If you
          send us an email, please be sure to provide (i) sufficient information that allows us to
          verify that you are the person whom we collected Personal Information; and (ii)
          sufficiently describe your request. Please note if your opt-out request is specific to a
          particular type of Personal Information.
          <br />
          <br />
          Finally, we are not obligated to delete Personal Information if we are unable to verify
          that you are the person whom we collected Personal Information, if we have reason to
          believe that you are a bot and not a person, or for any other reason that indicates that
          you may not be the person whom we collected Personal Information.
          <br />
          <br />
          We will endeavor to comply with your request(s) as soon as reasonably practicable, which
          may depend on the number of requests we have received at or around the time of your
          request. If we need additional time, which we reasonably expect to be completed within
          thirty (30) days, we will contact you in writing and inform you of the reason for the
          delay.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 6 - Security of Your Information.{' '}
        </Typography>

        <Typography variant="body1">
          We use industry standard security measures designed to protect your personally
          identifiable information. However, no data transmission or storage system is impenetrable,
          and you acknowledge this. If you have reason to believe that the security of your Personal
          Information might have with us has been compromised, please immediately notify us.
          <br />
          <br />
          Unless you’re using an encrypted email, we strongly advise you not to communicate any
          confidential information in your emails to us.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 7 – Changes to Our Privacy Policy.{' '}
        </Typography>

        <Typography variant="body1">
          If we decide to change our Privacy Policy, we will post those changes on our Website so
          that you are aware of what information we collect, how we use it, and under what
          circumstances we disclose or transfer it.
          <br />
          <br />
          We reserve the right to modify this Privacy Policy at any time, so please review the
          Privacy Policy from time to time. Any changes to this Privacy Policy will become effective
          immediately upon being made public.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 8 – Additional Information If You Live in California.{' '}
        </Typography>

        <Typography variant="body1">
          Under California privacy laws, including the California Consumer Privacy Act ("CCPA"), we
          are required to notify California residents about how we use their Personal Information.
          <br />
          <br />
          Under the CCPA, "Personal Information" is any information that identifies, relates to,
          describes, is reasonably capable of being associated with, or could reasonably be linked,
          directly or indirectly, with a particular California resident or household.
          <br />
          <br />
          We collect the Personal Information in accordance with <i>Section 2</i>, above.
          <br />
          <br />
          <b>
            California residents have the right to opt-out of our sale of their Personal Information
            by contacting us at{' '}
            <Link href={`mailto:${PRIVACY_POLICY_EMAIL}`} target="_blank" rel="noreferrer">
              {PRIVACY_POLICY_EMAIL}
            </Link>{' '}
            or by unsubscribing on the communication you have received.
          </b>{' '}
          Please be prepared to provide proof of your residence in California.
          <br />
          <br />
          We do not use or transfer Personal Information about California residents under 16 years
          old without opt-in consent.
          <br />
          <br />
          We are required to notify California residents, at or before the point of collection of
          their Personal Information, the categories of Personal Information collected and the
          purposes for which such information is used.
          <br />
          <br />
          <b>Verifiable Requests to Delete, and Requests to Know.</b> Subject to certain exceptions,
          California residents have the right to make the following requests, up to twice every
          twelve (12) months:
          <br />
          <br />
          <List>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                ●
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary={
                  <>
                    <u>Right of Deletion</u>: California residents have the right to request that we
                    delete their Personal Information that we have collected about them, subject to
                    certain exemptions.
                  </>
                }
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                ●
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary={
                  <>
                    <u>Right to Know & to Receive a Copy</u>: California residents have the right to
                    request a copy of the specific pieces of Personal Information that we have
                    collected about them in the prior twelve (12) months and to have this
                    information sent to you via email.
                  </>
                }
              ></ListItemText>
            </ListItem>
            <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon
                sx={{ justifyContent: 'center', typography: 'body1', color: 'text.primary' }}
              >
                ●
              </ListItemIcon>
              <ListItemText
                sx={{ typography: 'body1', color: 'text.primary', margin: 0 }}
                primary={
                  <>
                    <u>Right to Know & Right to Information</u>: California residents have the right
                    to request that we provide them certain information about how we have handled
                    their Personal Information in the prior twelve (12) months.
                  </>
                }
              ></ListItemText>
            </ListItem>
          </List>
          <br />
          <b>Submitting Requests.</b> Requests to exercise the right of deletion, right to a copy,
          or the right to information may be submitted by contacting us at {PRIVACY_POLICY_EMAIL} or
          the form listed on our Website.
          <br />
          <br />
          <b>Right to Non-Discrimination.</b> The CCPA prohibits discrimination against California
          residents for exercising their rights under the CCPA. We do not and will not discriminate
          against you for exercising any of your CCPA rights.
          <br />
          <br />
          <b>Financial Incentive.</b> We may offer you various financial incentives such as special
          offers when you provide us with contact information and identifiers such as your name and
          email address. You may withdraw from a financial incentive at any time by opting out.
          Generally, we do not assign monetary or other value to Personal Information, however,
          California law requires that we assign such value in the context of financial incentives.
          In such context, the value of the Personal Information is related to the estimated cost of
          providing the relevant financial incentive(s) for which the information was collected.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 9 – Notice for Residents of Nevada.{' '}
        </Typography>

        <Typography variant="body1">
          Nevada law, specifically Senate Bill 220, allows customers who are residents of the state
          of Nevada to “opt out” of the sale of certain Personal Information, called “covered
          information.”
          <br />
          <br />
          You have the right to opt out at any time and direct us not to use or transfer your
          Personal Information.
          <br />
          <br />
          Requests to exercise your right to opt out may be submitted by contacting us at{' '}
          {PRIVACY_POLICY_EMAIL} or unsubscribing on the communication you receive.
          <br />
          <br />
          We will endeavor to comply with your request(s) as soon as reasonably practicable, which
          may depend on the number of requests we have received at or around the time of your
          request. If we need additional time, which we reasonably expect to be completed within
          thirty (30) days, we will contact you in writing and inform you of the reason for the
          delay.
        </Typography>

        <Typography variant="subtitle1" fontWeight={700} my={2}>
          Section 10 – Disclaimers.{' '}
        </Typography>

        <Typography variant="body1">
          Archer is a data broker. We buy, sell, and license the sale of Personal Information.
          <br />
          <br />
          Your Personal Information may have been acquired by our third-party sources and
          transferred to Archer in accordance with the privacy policies of our third-party sources.
          If your Personal Information was acquired by our third-party sources, what we can and
          cannot do shall be based on, and in accordance with the privacy policies of our
          third-party sources, which we invite you to read.
          <br />
          <br />
          Please be advised that we may not be the only person or entity with access to your
          Personal Information. While we may have acquired your information, including Personal
          Information from our third-party sources, our third-party sources may have also sold or
          licensed your Personal Information to other persons or entities, or may still be in
          possession of your Personal Information. It is conceivable, if not likely, that if you
          submit a request for us to delete your Personal Information, and we do, that your Personal
          Information may be in the hands of multiple persons or entities, and that you may need to
          contact each person or entity to prevent your Personal Information from being sold. We may
          have no relationship with and may be ignorant of the names of other persons or entities
          who have access to your Personal Information. Thus, we shall only be responsible for your
          Personal Information with Archer and not of third parties
        </Typography>
      </Box>

      <ScrollTopButton />
    </Container>
  )
}

export default PrivacyPage
