import { Button, Typography } from '@mui/material'

import { ReactComponent as BuildingIcon } from '@/assets/icons/ic-building.svg'
import { ReactComponent as TrendingDownIcon } from '@/assets/icons/ic-trending-down.svg'
import { useState } from 'react'
import ContactUsModal from './ContactUsModal'

const PricingInfo = () => {
  const [open, setOpen] = useState(false)

  return (
    <div className="flex flex-col gap-4 md:max-w-[320px] lg:mt-8">
      <div className="flex gap-3">
        <TrendingDownIcon className="flex-shrink-0 w-4 h-4 mt-1 fill-primary.main" />
        <div className="flex flex-col gap-1">
          <Typography variant="caption" fontWeight={700}>
            Volume Discount (33% OFF)
          </Typography>
          <Typography variant="caption">
            After 500 credits used in a single billing cycle, the cost per credits drops from $0.75
            to $0.50. That discount will be applied for the remainder of that billing cycle.
          </Typography>
        </div>
      </div>
      <div className="flex gap-3">
        <BuildingIcon className="flex-shrink-0 w-4 h-4 mt-1 fill-primary.main" />
        <div className="flex flex-col gap-1">
          <Typography variant="caption" fontWeight={700}>
            Enterprise Trial
          </Typography>
          <Typography variant="caption">
            Need to convince more people at your company about the value of Archer?
            <br />
            <strong>We offer company-wide trials for teams, small and large!</strong>
          </Typography>
          <Button className="mt-2 w-28" variant="outlined" onClick={() => setOpen(true)}>
            Contact Us
          </Button>
        </div>
      </div>
      <ContactUsModal open={open} setOpen={setOpen} />
    </div>
  )
}

export default PricingInfo
