import { Alert, Box, Typography } from '@mui/material'
import { useFetchPaymentIntent } from '../settings/billing/usePaymentMethods'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { formatMoney } from '@/utils'
import StripeCheckout from './StripeCheckout'

const useCheckout = ({ internalPaymentIntentId }: { internalPaymentIntentId: string }) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [showSuccess, setShowSuccess] = useState(false)

  const { payload } = useFetchPaymentIntent(internalPaymentIntentId)

  useEffect(() => {
    console.log(payload)
    if (payload) {
      setClientSecret(payload.client_secret)
    }

    if (payload?.status === 'succeeded') {
      setShowSuccess(true)
    }
  }, [payload])

  const handleSuccess = () => {
    setShowSuccess(true)
  }

  return {
    clientSecret,
    payload,
    handleSuccess,
    showSuccess
  }
}

const CheckoutWithPaymentIdGuard = ({
  internalPaymentIntentId,
  infoBoxRef
}: {
  internalPaymentIntentId: string
  infoBoxRef: React.RefObject<HTMLDivElement>
}) => {
  const { clientSecret, payload, handleSuccess, showSuccess } = useCheckout({
    internalPaymentIntentId
  })

  if (showSuccess) {
    return (
      <Alert variant="filled" severity="success">
        Payment completed successfully
      </Alert>
    )
  }

  return clientSecret ? (
    <>
      <StripeCheckout onSuccess={handleSuccess} clientSecret={clientSecret} />
      {infoBoxRef?.current && payload
        ? createPortal(
            <Box sx={{ padding: 4, border: '2px solid #dde3f6', borderRadius: 3 }}>
              <Typography variant="h2" mb={2}>
                Payment required
              </Typography>
              <Typography variant="body1" mb={2}>
                We tried to charge your card but it was declined. Please update your payment.
              </Typography>

              {payload?.amount ? (
                <Typography variant="body1" mb={2}>
                  We tried charging you <strong>{formatMoney(payload?.amount / 100)}</strong>.
                </Typography>
              ) : null}

              {payload?.created ? (
                <Typography variant="body1" mb={2}>
                  And we did that at&nbsp;
                  <strong>{new Date(payload.created * 1000).toLocaleString()}</strong>
                </Typography>
              ) : null}
            </Box>,
            infoBoxRef.current
          )
        : null}
    </>
  ) : null
}

export default CheckoutWithPaymentIdGuard
