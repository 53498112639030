import { AxiosError } from 'axios'
import { NavigateFunction } from 'react-router-dom'

export enum BILLING_ERROR_CODES {
  NO_PAYMENT_METHOD = 'NO_PAYMENT_METHOD',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  OUTSTANDING_PAYMENT = 'OUTSTANDING_PAYMENT'
}

type BILLING_ERROR =
  | {
      code: BILLING_ERROR_CODES.NO_PAYMENT_METHOD
    }
  | {
      code: BILLING_ERROR_CODES.PAYMENT_REQUIRED
      billingSheet: {
        _id: string
      }
    }

export const handleBillingError = ({
  error,
  navigate,
  assetId
}: {
  error: AxiosError<{ errors: Array<BILLING_ERROR> }>
  navigate: NavigateFunction
  assetId?: string
}) => {
  if (error.response?.status !== 402) {
    throw error
  }

  const billingError = error.response?.data?.errors?.[0]

  if (!billingError) {
    throw error
  }

  if (billingError.code === BILLING_ERROR_CODES.NO_PAYMENT_METHOD) {
    navigate('/settings/billing', {
      state: {
        error: billingError.code,
        assetId: assetId
      }
    })
    throw error
  }

  if (billingError.code === BILLING_ERROR_CODES.PAYMENT_REQUIRED) {
    navigate(`/payment/${billingError.billingSheet._id}`, {
      state: {
        billingSheet: billingError.billingSheet,
        assetId: assetId
      }
    })

    throw error
  }
}
