import { useState } from 'react'
import { Box, Button, CardActionArea } from '@mui/material'
import { PaymentMethod } from '@stripe/stripe-js'
import { CardBox } from './CardBox'
import CreditCardComp from './CreditCardComp'

const EditCreditCardComp = ({
  paymentMethod,
  onDelete,
  canDelete,
  onMarkDefault,
  disabled,
  isDefault,
  isExpired
}: {
  paymentMethod: PaymentMethod
  onMarkDefault: (id: string) => unknown
  onDelete: (id: string) => unknown
  canDelete: boolean
  disabled: boolean
  isDefault?: boolean
  isExpired?: boolean
}) => {
  const [showOverlay, setShowOverlay] = useState(false)

  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    event.preventDefault()

    onDelete(paymentMethod.id)
  }

  const handleMarkDefault: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    event.preventDefault()

    onMarkDefault(paymentMethod.id)
    setShowOverlay(false)
  }

  return (
    <CardBox sx={{ minWidth: '300px' }}>
      <CardActionArea
        disabled={disabled}
        sx={{ padding: '24px 32px' }}
        onClick={() => {
          setShowOverlay(true)
        }}
      >
        <CreditCardComp
          brand={paymentMethod?.card?.brand}
          last4={paymentMethod?.card?.last4}
          exp_year={paymentMethod?.card?.exp_year}
          exp_month={paymentMethod?.card?.exp_month}
          isDefault={isDefault}
          isExpired={isExpired}
        />
      </CardActionArea>
      <Box>
        {showOverlay ? (
          <CardActionArea
            sx={{
              display: showOverlay ? 'flex' : 'none',
              position: 'absolute',
              background: 'rgba(1, 4, 23, 0.8)',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              zIndex: 10000
            }}
            onClick={() => {
              setShowOverlay(false)
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Button
                variant="contained"
                disabled={disabled || isDefault}
                size="large"
                onClick={handleMarkDefault}
              >
                Set as default
              </Button>
              <Button
                disabled={disabled}
                variant="contained"
                size="large"
                color="error"
                title={canDelete ? '' : 'You must have at least one card'}
                onClick={handleDelete}
              >
                Remove card
              </Button>
            </Box>
          </CardActionArea>
        ) : null}
      </Box>
    </CardBox>
  )
}

export default EditCreditCardComp
