import { trackEvent } from '@/analytics'
import { Credits } from '@/components/common/Credits'
import { setRetry } from '@/reducers/lookup'
import { Box, Button, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'

interface Props {
  onRestart: () => void
}

const NewLookup = ({ onRestart }: Props) => {
  const dispatch = useDispatch()
  return (
    <div className="flex justify-between">
      <div>
        <Typography variant="caption" fontWeight={700} color="text.quaternary">
          More Search Tips:
        </Typography>
        <ul className="text-text.quaternary pl-4 mt-2 font-medium text-sm">
          <li>Check for typos in the Name and Company inputs</li>
          <li>
            Nickname - If the person you’re looking for uses a nickname, you may try it (e.g.
            William → Will)
          </li>
        </ul>
      </div>
      <Box className="flex flex-col flex-shrink-0 items" alignItems="end">
        <Button
          className="w-[110px]"
          variant="contained"
          size="medium"
          onClick={() => {
            trackEvent('New Lookup', { page: 'Dashboard' })
            dispatch(setRetry(false))
            if (onRestart) {
              return onRestart()
            }
          }}
        >
          New Lookup
        </Button>
        <Credits />
      </Box>
    </div>
  )
}

export default NewLookup
