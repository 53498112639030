import { styled, Tooltip as MuiTooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { PartialBy } from '@/utils/types'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: color,
    fontSize: '14px',
    padding: '8px 16px'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color
  }
}))

export const BaseTooltip = ({
  children,
  title,
  color = '#474D66',
  placement = 'right',
  ...rest
}: TooltipProps) => {
  return (
    <StyledTooltip
      color={color}
      title={title}
      arrow
      {...rest}
      placement={placement}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -6]
              }
            }
          ]
        }
      }}
    >
      {children}
    </StyledTooltip>
  )
}

export const AccuracyTooltip = ({
  children,
  title = `We assign match likelihood scores to contact information to display our confidence in the results. Even a "low" or "medium" rating has a good chance of belonging to the individual.`,
  ...rest
}: PartialBy<TooltipProps, 'title'>) => {
  return (
    <BaseTooltip title={title} {...rest}>
      {children}
    </BaseTooltip>
  )
}

export const EmailTooltip = ({
  children,
  title = `The deliverability status reflects our confidence in the validity of an email address. "Deliverable" means the email address is valid and should accept emails. An "Accept-all" email address is linked to an accept-all domain therefore its validity cannot be determined. While it is less certain for individual deliverability, it still offers a reasonable opportunity for successful email communication.`,
  ...rest
}: PartialBy<TooltipProps, 'title'>) => {
  return (
    <BaseTooltip title={title} {...rest}>
      {children}
    </BaseTooltip>
  )
}

export const CompanyAdvancedSearchTooltip = ({
  children,
  title = `Get 20% more matches`,
  ...rest
}: PartialBy<TooltipProps, 'title'>) => {
  return (
    <BaseTooltip title={title} {...rest}>
      {children}
    </BaseTooltip>
  )
}
