import { Link } from 'react-router-dom'
import { Container } from '@mui/material'

const Footer = ({ className }: { className?: string }) => {
  return (
    <footer
      className={`${className} flex-shrink-0 w-full h-12 border-solid border-t border-b-0 border-r-0 border-l-0 border-background.tertiary`}
    >
      <Container className="h-full flex items-center gap-8">
        <span className="copyright mr-6">&copy;&nbsp;{new Date().getFullYear()} Archer</span>
        <Link className="nav-link text-primary.main" to="/terms-of-use">
          Terms of Use
        </Link>
        <Link className="nav-link text-primary.main" to="/privacy">
          Privacy Policy
        </Link>
        <Link className="nav-link text-primary.main" to="/privacy-requests">
          Opt out
        </Link>
      </Container>
    </footer>
  )
}

export default Footer
