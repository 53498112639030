import { Asset } from '@/types/assets'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { useAuth } from '@/reducers/user'
import { ROLES } from '@/types/user'
// import { useProfileAdminPanel } from '../settings/profile/AdminPanel'
import { withAdminPanelGuard } from '@/utils/hoc/withAdminPanelGuard'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const AdminPanel = ({ asset }: { asset: Asset }) => {
  const { user } = useAuth()

  // const { mockTotalEnriched, setMockTotalEnriched, handleSubmit } = useProfileAdminPanel()

  if (user?.role.name !== ROLES.ADMIN) {
    return null
  }

  return (
    <>
      <Accordion className="mt-10">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h2">Admin panel</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography>
            Since you&apos;re an ADMIN, your file won&apos;t be enriched, but mock enriched and the
            total enrichments will be:&nbsp;
            <code>
              {user?.adminConfigs && user?.adminConfigs.mockTotalEnriched > 0
                ? Math.min(user?.adminConfigs.mockTotalEnriched, asset.statistics.totalSubmitted)
                : asset.statistics.totalSubmitted}
            </code>
          </Typography>

          {/* <form onSubmit={handleSubmit}>
            <Typography mt={2}>
              Mock total enriched - this number will be used to mock the enrichments.
            </Typography>
            <input
              type="number"
              value={mockTotalEnriched}
              onChange={(event) => {
                setMockTotalEnriched(event.target.value as unknown as number)
              }}
            />
            <Button type="submit">Update</Button>
          </form> */}

          <Typography>Current asset payload</Typography>
          <pre>{JSON.stringify(asset, null, 2)}</pre>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default withAdminPanelGuard(AdminPanel)
