import FormInput from '@/components/Form/Input/Input'
import FormInputForAdvancedCompanySearch from '@/components/Form/Input/InputForAdvancedSearch'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material'
import { CompanyFormValues, companyFormValuesValidationSchema } from '@/types/form-data'
import SubmitArea from './SubmitArea'
import { Apis } from '@/providers/ApiProvider'
import { useNavigate } from 'react-router-dom'
import { processAsset } from '@/services/asset'
import { useRequestMatchTypes } from './QuickPersonalForm'
import { trackEvent } from '@/analytics'
import { TypewriterProps, useTypewriter } from 'react-simple-typewriter'
import axios from 'axios'
import { isUserNotActivatedError } from '@/utils/errors/utils'
import SendEmailVerificationModal from '@/components/Modal/SendEmailVerificationModal'
import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const typewriterConfig: Omit<TypewriterProps, 'words'> = {
  loop: 0,
  delaySpeed: 3500,
  deleteSpeed: 50,
  typeSpeed: 110
}

const variationsName = ['Tim Cook', 'Sam Altman']
const variationsCompany = ['Apple   ', 'openai.com']

const QuickCompanyForm = ({
  setActiveAssetId
}: {
  children?: React.ReactNode
  setActiveAssetId: (assetId: string) => unknown
}) => {
  const { control, handleSubmit, formState } = useForm<CompanyFormValues>({
    resolver: yupResolver(companyFormValuesValidationSchema)
  })
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false)

  const navigate = useNavigate()

  const { requestPhones, setRequestPhones, requestWorkEmails, setRequestEmails } =
    useRequestMatchTypes()

  useEffect(() => {
    setRequestPhones(false)
  }, [])

  const handleCompanyFormSubmit = async (formData: CompanyFormValues) => {
    trackEvent('Single Search - Get Contact Info', {
      page: 'Dashboard',
      type: 'company',
      requestWorkEmails: requestWorkEmails,
      formPayload: formData
    })
    const { data } = await Apis.postAsset({
      filename: `${formData.fullName}`,
      requestWorkEmails: requestWorkEmails,
      requestedType: 'company',
      rows: [formData]
    })

    await processAsset({ assetId: data.asset._id, navigate: navigate })

    return data.asset._id
  }

  const handleFormSubmit = async (values: CompanyFormValues) => {
    try {
      const id = await handleCompanyFormSubmit(values)

      setActiveAssetId(id)
    } catch (error) {
      if (axios.isAxiosError(error) && isUserNotActivatedError(error)) {
        setSendEmailModalOpen(true)
      }
    }
  }

  const [namePlaceholder] = useTypewriter({
    words: variationsName,
    ...typewriterConfig
  })
  const [companyPlaceholder] = useTypewriter({
    words: variationsCompany,
    ...typewriterConfig
  })

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container columns={2} columnSpacing={2}>
          <Grid item xs={2} md={1}>
            <FormInput
              formState={formState}
              control={control}
              name="fullName"
              label="FULL NAME"
              placeholder={namePlaceholder}
              controlId="fieldFullName"
              rules={{ required: true }}
            />
          </Grid>

          <Grid item xs={2} md={1}>
            <FormInput
              control={control}
              formState={formState}
              name="company"
              label="COMPANY NAME OR WEBSITE"
              placeholder={companyPlaceholder}
              controlId="fieldCompany"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={2} className="pt-6">
            <Accordion className="w-full rounded-md">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="flex flex-row">
                  <Typography className="mx-auto pl-1 sm:pl-4 uppercase text-xs sm:text-sm font-extrabold w-32 sm:w-44 my-auto">
                    Advanced Search
                  </Typography>
                  <Box
                    className="rounded-full flex items-center sm:px-2 w-36 sm:w-48 h-7 my-auto "
                    sx={{
                      background:
                        'linear-gradient(74.37deg, #A7A4EE 6.07%, #352ED8 42.6%, #4052F0 84.16%, #04137D 126.99%)'
                    }}
                  >
                    <Typography
                      color="white"
                      className="text-xs sm:text-sm font-bold sm:p-2 mx-auto"
                    >
                      Get 20% more matches
                    </Typography>
                  </Box>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container columns={2} columnSpacing={2}>
                  <Grid item xs={2} md={1} className="sm:pl-4">
                    <FormInputForAdvancedCompanySearch
                      formState={formState}
                      control={control}
                      name="industry"
                      tooltipText="Add industry info as you would in a Google search "
                      label="COMPANY INDUSTRY"
                      controlId="fieldIndustry"
                      className="sm:pl-4"
                      placeholder="e.g. IT Services"
                    />
                  </Grid>

                  <Grid item xs={2} md={1}>
                    <FormInputForAdvancedCompanySearch
                      control={control}
                      formState={formState}
                      name="location"
                      tooltipText="Enter the name of a Country, State, Region, City, or Town for better results. Please do not enter a direct address or zip code."
                      label="COMPANY LOCATION"
                      placeholder="California"
                      controlId="fieldLocation"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={2}>
            <SubmitArea
              lookupType="company"
              isLoading={formState.isSubmitting}
              requestPhones={requestPhones}
              requestWorkEmails={requestWorkEmails}
              setRequestPhones={setRequestPhones}
              requestEmails={requestPhones}
              setRequestEmails={setRequestEmails}
            />
          </Grid>
        </Grid>
      </form>
      <SendEmailVerificationModal
        open={sendEmailModalOpen}
        setOpen={setSendEmailModalOpen}
        page="Dashboard"
      />
    </>
  )
}

export default QuickCompanyForm
