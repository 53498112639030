import { Typography, Box, useTheme } from '@mui/material'
import { usePricingSchema } from './hooks'
import React, { Fragment } from 'react'
import { formatMoney, formatNumber } from '@/utils'
import { useBillingTracker } from '@/pages/dashboard/hooks'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

const bgColors = ['bg-primary.main', 'bg-green-600']

const Divider = () => {
  return (
    <div className="group self-stretch w-0 border-l-0 border-y-0 border-r-2 border-dashed border-slate-400" />
  )
}

const Segment = ({
  topText,
  bottomText,
  className,
  progress
}: {
  topText: string | React.ReactNode
  bottomText: string
  className?: string
  progress: number
}) => {
  return (
    <div className="flex-1 group">
      <div className="group-first:mx-0 mx-2 mb-3">{topText}</div>
      <div
        className={`w-full h-1 bg-opacity-20 group-first:rounded-l-xl group-last:rounded-r-xl relative overflow-hidden ${className}`}
      >
        <span
          className={`absolute inset-0 rounded-xl ${className}`}
          style={{ transform: `translateX(-${100 - progress}%)` }}
        />
      </div>
      <div className="group-first:mx-0 mx-2 mt-2 break-keep">{bottomText}</div>
    </div>
  )
}

const BillingCycleProgress = () => {
  const theme = useTheme()
  const pricingSchema = usePricingSchema()
  const { data: billingTracker } = useBillingTracker()
  const accruedCredits = billingTracker?.accrued_credits ?? 0
  const freeCredits = billingTracker?.free_credits ?? 0

  return (
    <Box
      sx={{
        borderRadius: 4,
        border: `1px solid ${theme.palette.background.tertiary}`,
        px: 3,
        py: 2
      }}
    >
      <div className="flex justify-between">
        <Typography variant="body1" fontWeight={700}>
          Pricing
        </Typography>
        <Typography variant="caption" fontWeight={500}>
          {freeCredits > 0 ? (
            <>
              {`Available free matches: ${formatNumber(freeCredits)}`}
              <CheckCircleRoundedIcon className="w-4 h-4 ml-1 mb-0.5" color="success" />
            </>
          ) : null}

          {freeCredits <= 0 && accruedCredits > 0
            ? `Total matches this billing cycle: ${formatNumber(accruedCredits)}`
            : null}
        </Typography>
      </div>

      <div className="flex mt-4">
        {pricingSchema.data?.pricingSchema.map((item, index, array) => {
          const progress =
            item.ceiling === Number.MAX_SAFE_INTEGER && accruedCredits + 1 >= item.floor
              ? 100
              : Math.min(
                  100,
                  Math.round(((accruedCredits ?? 0) / (item.ceiling - item.floor)) * 100)
                )

          const additionalText =
            item.floor > 1 + accruedCredits ? `(${item.floor - accruedCredits - 1} to go)` : ''

          return (
            <Fragment key={item.floor}>
              <Segment
                topText={<>{formatMoney(item.price)}/match</>}
                bottomText={
                  index === 0 ? `current price` : `above ${item.floor} matches ${additionalText}`
                }
                className={bgColors[index % bgColors.length]}
                progress={progress}
              />
              {index !== array.length - 1 ? <Divider /> : null}
            </Fragment>
          )
        })}
      </div>
    </Box>
  )
}

export default BillingCycleProgress
