import { Box, styled } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const WrapperBox = styled(Box)({
  backgroundColor: '#FFEEC3',
  borderRadius: '8px'
})

const PaymentWarning = ({ children }: { children: React.ReactNode }) => {
  return (
    <WrapperBox
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      padding={2}
      gap={2}
    >
      <ErrorOutlineIcon />
      {children}
    </WrapperBox>
  )
}

export default PaymentWarning
