import { useAuth } from '@/reducers/user'
import React from 'react'

export function withAuthGuard<T extends object>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

  const InnerComponent = (props: Omit<T, keyof object>) => {
    const { user } = useAuth()

    if (!user) {
      return null
    }

    return <WrappedComponent {...props} />
  }

  InnerComponent.displayName = `withAuthGuard(${displayName})`

  return InnerComponent
}
