import { styled, Theme } from '@mui/material'
import { useCallback } from 'react'

import { ReactComponent as IcEmailCircle } from '@/assets/icons/ic-email-circle.svg'
import { ReactComponent as IcPhoneCircle } from '@/assets/icons/ic-phone-circle.svg'

import MatchSelectBox from '@/components/MatchSelectBox'
import { LookupRequestType } from '@/types/assets'
import { AccuracyMode, KeyOfMatchMode, MatchMode, MatchSelectConfig } from '@/types/match'

type MatchSelectBoxType = {
  [K in LookupRequestType]: {
    fieldName: KeyOfMatchMode
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
    label: string
  }[]
}

const iconStyles = ({ theme }: { theme: Theme }) => ({
  background: theme.palette.background.default,
  fill: theme.palette.primary.main,
  borderRadius: '50%'
})

const EmailIcon = styled(IcEmailCircle)(iconStyles)
const PhoneIcon = styled(IcPhoneCircle)(iconStyles)

const matchSelectBoxes: Partial<MatchSelectBoxType> = {
  address: [
    {
      icon: PhoneIcon,
      label: 'Phone numbers',
      fieldName: 'phone'
    },
    {
      icon: EmailIcon,
      label: 'Personal Email addresses',
      fieldName: 'email'
    }
  ],
  company: [
    {
      icon: EmailIcon,
      label: 'Work Emails',
      fieldName: 'workEmail'
    }
  ]
}

interface MatchSelectBoxListProps {
  matchSelectConfig: MatchSelectConfig
  requestedType: LookupRequestType
  setMatchSelectConfig: React.Dispatch<React.SetStateAction<MatchSelectConfig>>
}

const MatchSelectBoxList = ({
  matchSelectConfig,
  requestedType,
  setMatchSelectConfig
}: MatchSelectBoxListProps) => {
  const handleChangeSelection = useCallback(
    (name: KeyOfMatchMode) => {
      setMatchSelectConfig((prevState) => {
        const isCompanyNotWorkEmail = requestedType === 'company' && name !== MatchMode.workEmail

        const newState = {
          ...prevState,
          [name]: {
            ...prevState[name],
            active: !matchSelectConfig[name].active
          }
        }

        if (isCompanyNotWorkEmail) {
          newState.phone = {
            ...prevState.phone,
            active: !prevState.phone.active
          }
        }

        return newState
      })
    },
    [matchSelectConfig, requestedType, setMatchSelectConfig]
  )

  return (
    <>
      {matchSelectBoxes?.[requestedType]?.map(({ fieldName, icon: Icon, label }) => {
        return (
          <MatchSelectBox
            key={fieldName}
            icon={<Icon />}
            labelText={label}
            accuracyMode={matchSelectConfig[fieldName].accuracy || AccuracyMode.ALL}
            onChanged={handleChangeSelection}
            name={MatchMode[fieldName]}
            isChecked={matchSelectConfig[fieldName].active}
          />
        )
      })}
    </>
  )
}

export default MatchSelectBoxList
