import { useCallback, useEffect, useState } from 'react'
import {
  getCustomerPaymentMethods,
  getPaymentIntent,
  getUserPaymentInfo
} from '@/services/payments'
import axios from 'axios'
import { atom, useAtom } from 'jotai'
import { PaymentMethod } from '@stripe/stripe-js'
import { StripeCustomer } from '@/types/user'

const fetchPaymentMethods = async (controller?: AbortController) => {
  const response = await getCustomerPaymentMethods(
    controller ? { signal: controller.signal } : undefined
  )

  return response.data
}

const fetchUserPaymentInfo = async (controller?: AbortController) => {
  const response = await getUserPaymentInfo(controller ? { signal: controller.signal } : undefined)

  return response.data
}

const useFetch = <T>(fetcher: (controller?: AbortController) => Promise<T>) => {
  const [payload, setPayload] = useState<T | undefined>(undefined)

  const fetchFn = async (controller?: AbortController) => {
    try {
      const data = await fetcher(controller)
      setPayload(data)
    } catch (error) {
      if (axios.isCancel(error)) {
        // do nothing as this is expected
        return
      }
    }
  }

  useEffect(() => {
    const controller = new AbortController()

    fetchFn(controller)

    return () => {
      controller.abort()
    }
  }, [])

  return { payload, refetch: fetchFn }
}

const paymentMethodsAtom = atom(undefined, (_get, set, arg: PaymentMethod[]) => {
  set(paymentMethodsAtom, arg)
})

export const useFetchPaymentMethods = (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
): {
  payload: PaymentMethod[] | undefined
  refetch: () => unknown
} => {
  const onMount = useCallback(
    (set: any) => {
      setIsLoading(true)

      fetchPaymentMethods()
        .then((result) => {
          set(result)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [setIsLoading]
  )

  paymentMethodsAtom.onMount = onMount

  const [payload, setPayload] = useAtom(paymentMethodsAtom)

  const refetch = useCallback(async () => {
    const result = await fetchPaymentMethods()
    setPayload(result)
  }, [])

  return { payload: payload, refetch }
}

const userPaymentInfoAtom = atom(undefined, (_get, set, arg: StripeCustomer) => {
  set(userPaymentInfoAtom, arg)
})

userPaymentInfoAtom.onMount = (set) => {
  fetchUserPaymentInfo().then((result) => {
    set(result)
  })
}

export const useUserPaymentInfo = (): {
  payload: StripeCustomer | undefined
  refetch: () => unknown
} => {
  const [payload, setPayload] = useAtom(userPaymentInfoAtom)

  const refetch = useCallback(async () => {
    const result = await fetchUserPaymentInfo()
    setPayload(result)
  }, [])

  return { payload: payload, refetch }
}

export const useFetchPaymentIntent = (internalPaymentMethodId: string) => {
  return useFetch(async (controller?: AbortController) => {
    const response = await getPaymentIntent(
      internalPaymentMethodId,
      controller ? { signal: controller.signal } : undefined
    )

    return response.data
  })
}
