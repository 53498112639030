import { Box, Chip, Typography, useTheme } from '@mui/material'
import { CreditCard } from '@mui/icons-material'
import { ReactComponent as MasterCardIcon } from '@/assets/icons/ic-card-mastercard.svg'
import { ReactComponent as VisaIcon } from '@/assets/icons/ic-card-visa.svg'
import { ReactComponent as AmexIcon } from '@/assets/icons/ic-card-amex.svg'

export const CardIcon = ({
  brand,
  size = 'normal'
}: {
  brand?: string
  size?: 'normal' | 'small'
}) => {
  const commonProps =
    size === 'normal'
      ? {
          height: 28,
          width: 44
        }
      : {
          height: 24,
          width: 38
        }

  switch (brand) {
    case 'visa':
      return <VisaIcon {...commonProps} />
    case 'mastercard':
      return <MasterCardIcon {...commonProps} />
    case 'amex':
      return <AmexIcon {...commonProps} />
    default:
      return <CreditCard />
  }
}

const CreditCardComp = ({
  brand,
  last4,
  exp_year,
  exp_month,
  isDefault,
  isExpired
}: {
  brand?: string
  last4?: string
  exp_year?: number
  exp_month?: number
  isDefault?: boolean
  isExpired?: boolean
}) => {
  const theme = useTheme()

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" height={32}>
        <Box display="flex" gap={2} alignItems="center">
          <div>
            <CardIcon brand={brand} />
          </div>
          <Typography variant="h3">
            <span style={{ letterSpacing: 3, fontSize: 30 }} className="hidden md:inline">
              •••• ••••{' '}
            </span>
            <span style={{ letterSpacing: 3, fontSize: 30 }}>••••&nbsp;</span>
            {last4}
          </Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center" className="flex-col">
          {isDefault && (
            <Chip
              label="Default"
              variant="filled"
              color="primary"
              sx={{ fontSize: 14, fontWeight: 600 }}
            />
          )}
          {isExpired && (
            <Chip
              label="Expired"
              variant="filled"
              color="error"
              sx={{ fontSize: 14, fontWeight: 600 }}
            />
          )}
        </Box>
      </Box>
      <Typography fontSize={12} fontWeight={600} mt={6} color={theme.palette.text.quaternary}>
        EXPIRY DATE
      </Typography>
      {exp_year && exp_month ? (
        <Typography variant="body1" fontWeight={700}>
          {exp_month < 10 ? (exp_month / 100).toString().slice(-2) : exp_month}/
          {exp_year.toString().slice(-2)}
        </Typography>
      ) : null}
    </>
  )
}

export default CreditCardComp
