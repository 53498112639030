import { Box, Card, CardContent, Checkbox, Link, Stack, Typography } from '@mui/material'
import { ReactComponent as ArrowIcon } from '@/assets/icons/ic-arrow-signup.svg'
import { ReactComponent as PartyIcon } from '@/assets/icons/ic-party-horn.svg'
import { ReactComponent as CCIcon } from '@/assets/icons/ic-credit-card.svg'
import { HighAccuracyMatchChip } from '@/components/Chip'

const Chip = ({ icon, text }: any) => {
  return (
    <div className="flex items-center gap-2 py-1 px-3 bg-white border border-background.tertiary rounded-full">
      {icon}
      <span className="text-sm font-bold text-primary.main whitespace-nowrap">{text}</span>
    </div>
  )
}

export const MobileMarketingSideScreen2 = () => {
  return (
    <div className="flex-1 flex-col gap-3 flex items-center justify-center mb-10">
      <Typography color="primary.main" variant="h3" textAlign="center" className="mx-4 mt-4">
        Look Up 10 Leads For Free!
      </Typography>

      <div className="flex justify-center gap-2 -mx-10 flex-wrap">
        <Chip
          icon={<CCIcon className="w-4 h-4 fill-primary.main" />}
          text="No credit card required"
        />
        <Chip icon={<PartyIcon className="w-4 h-4 fill-primary.main" />} text="Free Trial" />
      </div>
    </div>
  )
}

export const MobileMarketingSideScreen = () => {
  return (
    <div className="flex-1 flex-col gap-6 flex items-center justify-center mb-12">
      <Typography color="primary.main" variant="h3" textAlign="center" className="mx-4 mt-4">
        Find Accurate Emails & Mobiles, Pay-as-you-go
      </Typography>

      <div className="flex flex-col gap-3 p-4 bg-white border border-background.tertiary rounded-2xl shadow-md -mx-6">
        <Typography fontWeight={700} variant="caption" className="text-center w-[309px] px-4">
          <span className="font-black text-[#4052F0]">Boost conversion by up to 30%</span> with new,
          accurate contact information for your leads.
        </Typography>

        <div className="flex items-center self-stretch mt-3">
          <div className="flex-grow flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <Typography className="text-text.quaternary font-bold text-[9px] whitespace-nowrap">
                FULL NAME
              </Typography>
              <div className="bg-background.tertiary bg-opacity-60 px-3 py-1 rounded-xl border border-background.tertiary text-sm whitespace-nowrap">
                Jane Reed
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <Typography className="text-text.quaternary font-bold text-[9px] whitespace-nowrap">
                COMPANY
              </Typography>
              <div className="bg-background.tertiary bg-opacity-60 px-3 py-1 rounded-xl border border-background.tertiary text-sm whitespace-nowrap">
                BayTech
              </div>
            </div>

            <div className="bg-primary.main rounded-full text-background.secondary text-center font-bold text-sm py-1 px-3 whitespace-nowrap">
              Get contact info
            </div>
          </div>

          <ArrowIcon className="w-4 mx-1" />

          <div className="flex-grow self-stretch justify-evenly items-center flex flex-col border border-background.tertiary p-3 rounded-xl shadow-2xl">
            <img
              src="/assets/images/signup-avatar-2.png"
              alt="Jane Reed avatar"
              className="h-11 w-11 rounded-full object-cover"
            />

            <Typography className="font-medium text-[#1F1F23] text-xs">Jane Reed</Typography>
            <Typography className="text-primary.main font-bold text-xs">
              janereed@baytech.co
            </Typography>
            <Typography className="text-primary.main font-bold text-xs">(123) 456-7890</Typography>
          </div>
        </div>
      </div>

      <Link
        className="text-xs text-primary.main font-bold no-underline"
        href="https://helloarcher.io/"
      >
        Learn more about Archer
      </Link>
    </div>
  )
}

const MarketingSideScreen = () => {
  return (
    <div className="flex-1 bg-light flex-col gap-10 flex items-center justify-center">
      <img src="/assets/images/logo-su.svg" alt="Hero" />

      <Typography fontWeight={900} variant="h2" className="text-center mx-10">
        Turn Names and Addresses into Verified Emails and Phone Numbers
      </Typography>

      <div className="mx-10 flex p-5 flex-col justify-center items-center self-stretch rounded-3xl bg-background.secondary/90 shadow-xl">
        <Typography color="primary.main" className="font-bold">
          Find Personal Emails & Mobiles
        </Typography>
        <Typography className="font-medium mt-1 text-sm">US Only</Typography>

        <div className="flex items-center self-stretch mt-3">
          <div className="flex-grow flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <div className="bg-[#F0F7FE] bg-opacity-60 pl-3 pr-3 pt-2 pb-2 rounded-xl border border-background.tertiary">
                Jane Reed
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="bg-[#F0F7FE] bg-opacity-60 pl-3 pr-3 pt-2 pb-2 rounded-xl border border-background.tertiary">
                BayTech
              </div>
            </div>
            <Stack className="bg-white rounded-lg">
              <Box>
                <Checkbox color="primary" size="small" defaultChecked />
                Phone number
              </Box>
              <Box>
                <Checkbox color="primary" size="small" defaultChecked />
                Personal email
              </Box>
            </Stack>
            <div className="bg-primary.main rounded-full text-background.secondary text-center font-bold text-sm py-2">
              Get contact info
            </div>
          </div>

          <ArrowIcon className="mx-3" />

          <div className=" self-stretch justify-evenly items-center flex flex-col p-3 rounded-xl shadow-xl transition ease-in-out delay-150 hover:rotate-2 hover:-translate-y-1 hover:scale-105 duration-300">
            <Card className="w-[155px] rounded-md shadow-none">
              <CardContent className="bg-[#F0F7FE] p-2">
                <Typography className="font-bold text-sm text-[#1F1F23]">Jonathan Smith</Typography>
                <Typography className="text-xs leading-4 text-[#1F1F23]">
                  123 Main Street Anytown, CA, 12345
                </Typography>
              </CardContent>
            </Card>
            <Box>
              <Typography component="h2" className="text-primary.main mt-4 text-base font-bold">
                Personal contacts
              </Typography>
              <Stack className="mt-2" spacing="1rem">
                <Box>
                  <Box className="font-bold text-sm mb-1">(987) 654-3210</Box>
                  <HighAccuracyMatchChip />
                </Box>
                <Box>
                  <Box className="font-bold text-sm mb-1">jonsmith@gmail.com</Box>
                  <HighAccuracyMatchChip />
                </Box>
              </Stack>
            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketingSideScreen
