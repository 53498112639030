import { styled, Card as MUICard } from '@mui/material'

export const CardBox = styled(MUICard)(({ theme }) => ({
  borderRadius: 12,
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.secondary,
  flexShrink: 1,
  position: 'relative',
  width: 'min(100%, 500px)',
  minWidth: '370px'
}))
