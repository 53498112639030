import { Button, Typography } from '@mui/material'
import { ArcherModal, ArcherModalBottomActions } from './ArcherModal'
import { useAuth } from '@/reducers/user'
import { useVerifyEmail } from '@/pages/settings/profile/hooks'
import { PAGE } from '@/analytics'

const SendEmailVerificationModal = ({
  open,
  setOpen,
  page,
  onSent
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  page: PAGE
  onSent?: () => unknown
}) => {
  const { user } = useAuth()
  const { canSend, handleSendEmail, isLoading, countdown } = useVerifyEmail(page)

  const onClick = async () => {
    await handleSendEmail()

    if (onSent) {
      onSent()
    }
  }

  return (
    <ArcherModal
      open={open}
      setOpen={setOpen}
      cardSx={{
        maxWidth: 550
      }}
    >
      <Typography variant="h2" marginRight={2}>
        Please, verify your email address to continue using Archer!
      </Typography>
      <Typography variant="body1" mt={3} mb={4}>
        We&apos;ve send an email to <b>{user?.email}</b>.
      </Typography>
      <Typography variant="body1" mt={3} mb={4}>
        If you don&apos;t receive the email within a few minutes, check your spam folder.
      </Typography>
      <ArcherModalBottomActions>
        <div className="flex items-center justify-end gap-2">
          {canSend || !Number(countdown) ? null : (
            <Typography variant="caption" color="text.quaternary">
              You can resend in {countdown} seconds
            </Typography>
          )}
          <Button
            variant="contained"
            size="large"
            onClick={onClick}
            disabled={!canSend || isLoading}
          >
            Resend
          </Button>
        </div>
      </ArcherModalBottomActions>
    </ArcherModal>
  )
}

export default SendEmailVerificationModal
