import { Box, Container, Typography } from '@mui/material'

import PricingInfo from './PricingInfo'
import UsageBasedPricing from './UsageBasedPricing'
import FAQ from './FAQ'
import { useLTOModal } from './LimitedTimeOfferModal'
import Banners from '../dashboard/Banners'

const PlansPage = () => {
  //   useAuthGuardPageTracker('Visits Billing Page', trackingPayload)
  const [, setOpen, , setProps] = useLTOModal()

  return (
    <>
      <Banners page="Plans" />
      {/* TODO: Create a single container for any page */}
      <Container className="md:pr-12" sx={{ py: 5, flex: 1 }}>
        <div className="flex gap-x-10 gap-y-6 flex-wrap">
          <div className="flex flex-col gap-10 flex-start">
            <Typography variant="h1" color="primary.main">
              Usage-Based Pricing
            </Typography>

            <div className="flex flex-col gap-6 ">
              <div className="flex items-start gap-10 flex-wrap">
                <UsageBasedPricing
                  onModalOpen={() => {
                    setProps({
                      largeWithLink: false,
                      page: 'Plans'
                    })
                    setOpen(true)
                  }}
                />
                <PricingInfo />
              </div>
            </div>
          </div>
        </div>
        <Box className="w-full mt-10">
          <Typography className="mb-4 text-base" variant="h2">
            Pricing FAQs
          </Typography>
          <FAQ />
        </Box>
      </Container>
    </>
  )
}

export default PlansPage
